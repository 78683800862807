export const gmsOrganizationSelector = {
  name: 'gmsOrganizationSelector',
  template: `\
    <ui-select ng-model="$ctrl.selectedOrganization" theme="select2" class="organization-picker-drop">
      <ui-select-match placeholder="Select an organization" allow-clear="true">
        <span ng-bind="$select.selected.name"></span><span class='down-arrow'>
      </ui-select-match>
      <ui-select-choices repeat="item in (
                                   appState.organizationTreeFlat |
                                   filter: $select.search |
                                   limitTo: $ctrl.maxOrgs
                                 ) track by item.id"
                         refresh="$ctrl.userOrganizationOptions.query($select.search)"
                         ng-style="{
                           'max-height' : 'calc( {{ 10 }} * 49px )'
                         }"
      >
        <span ng-bind="item.name" ngClass="level-{{item.level}}"></span>
      </ui-select-choices>
    </ui-select>`,

  controller: class GmsOrganizationSelectorCtrl {
    static $inject = ['$scope', 'appState'];

    constructor($scope, appState) {
      const $ctrl = this;
      $ctrl.selectedOrganization = null;
      $ctrl.maxOrgs = 20;

      $scope.appState = appState;

      $scope.$watch('appState.currentOrganization', function (a, b) {
        $ctrl.selectedOrganization = a;
      });

      $scope.$watch('$ctrl.selectedOrganization', function (a, b) {
        if (!a || a === b) {
          return;
        }

        appState.switchOrganization(a.id);
      });
    }
  },
};
