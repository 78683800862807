// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './embedSummary.html';
angular.module('idonate.gms.donations').directive('embedSummary', function () {
  return {
    templateUrl: '/scripts/donations/embedSummary.html',
    restrict: 'E',
    replace: true,
    controllerAs: 'ctrl',
    scope: {
      current: '=',
      previous: '=',
    },
    bindToController: true,
    controller($scope, organizationService, analyticsService) {
      const ctrl = this;
      $scope.$watch('ctrl.current', function () {
        if (ctrl.current) {
          const obj = {
            organization_id: organizationService.getCurrentOrganization().id,
            start_date: ctrl.current.start.toISOString(),
            end_date: ctrl.current.end.toISOString(),
            previous_start_date: ctrl.previous.start.toISOString(),
            previous_end_date: ctrl.previous.end.toISOString(),
            limit: 3,
            use_finalized: true,
            transaction_status: 'complete',
            timezone: moment.tz.guess(),
          };

          return analyticsService
            .getOrganizationEmbeds(obj)
            .then((result) => (ctrl.embeds = result.result));
        }
      });
    },
  };
});
