// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let SelectorRowCtrl;
angular.module('idonate.gms').component('selectorRow', {
  transclude: true,
  template: `\
<div>
  <div class="heading-buttons pull-left">
    <div class="btn-group" data-toggle="buttons" ng-transclude>
    </div>
  </div>
</div>\
`,
  controller: (SelectorRowCtrl = class SelectorRowCtrl {
    /* @ngInject */
    constructor() {
      this.register = this.register.bind(this);
      this.select = this.select.bind(this);
      this.addOnSelect = this.addOnSelect.bind(this);
      this.selectors = {};
      this.onSelects = [];
    }
    register(name, selector) {
      return (this.selectors[name] = selector);
    }
    select(name) {
      if (this.selectors[name].onSelect) {
        this.selectors[name].onSelect();
        return this.selectors[name].toggle();
      } else {
        angular.forEach(this.selectors, function (selector) {
          if (!selector.onSelect) {
            return selector.deactivate();
          }
        });
        this.selectors[name].activate();
        return (() => {
          const result = [];
          for (let onSelect of Array.from(this.onSelects)) {
            result.push(onSelect(name));
          }
          return result;
        })();
      }
    }
    addOnSelect(onSelect) {
      return this.onSelects.push(onSelect);
    }
  }),
});
