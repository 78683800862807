/*
[
  {
    percent: ''
    figure: ''
    figure_currency: ''
    subfigure: ''
    label: ''
    sublabel: ''
  }
]
*/

angular.module('idonate.gms').directive('gmsAnalytics', () => ({
  template: `\
      <div class="col-12 summary-figures v_separator_cols">
        <div class="row summary-figures-row">
          <div class="col-12 col-md-{{12 / $ctrl.analytics.length}} col" ng-repeat="item in $ctrl.analytics">
            <h3 class="summary-title">
              {{item.label}}
              <gms-changemark percent="item.percent"></gms-changemark>
            </h3>
            <h1 ng-if="item.figure !== undefined" class="animated fadeIn summary-figure">{{((item.figure || 0) | number:0) || '0'}}</h1>
            <h1 ng-if="item.figureC !== undefined" class="animated fadeIn summary-figure">{{((item.figureC || 0) | currency:$root.currencyCode:0) || '$0'}}</h1>
            <h1 ng-if="item.figureP !== undefined" class="animated fadeIn summary-figure">{{((item.figureP || 0) | percentage:0) || '0%'}}</h1>
            <h3 class="summary-subfigure">{{item.sublabelF}} {{item.subfigure | number:0}}{{item.subfigureC | currency:$root.currencyCode:0}} {{item.sublabelB}}</h3>
          </div>
        </div>
      </div>
    `,
  scope: {
    analytics: '=',
  },
  bindToController: true,
  controllerAs: '$ctrl',
  controller() {},
}));
