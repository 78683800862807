import './funding-list.html';
import { toUtc } from '../util/date-functions';
('use strict');

angular
  .module('idonate.gms.donations')
  .controller(
    'FundingListCtrl',
    function ($scope, $location, transactionService) {
      $scope.transactionService = transactionService;

      $scope.tableOptions = {
        sortByColumn: 1,
        sortOrder: 'desc',
      };

      $scope.intervalCallback = function (current, previous) {
        //moment objects
        $scope.current = current;
        $scope.previous = previous;
        generateFundingList();
      };

      $scope.toUtc = function (date) {
        return toUtc(date);
      };

      const generateFundingList = function () {
        const start_date = $scope.current.start.toISOString();
        const end_date = $scope.current.end.toISOString();

        const params = {
          start_date,
          end_date,
        };

        return transactionService.getFundingList(params).then(function (data) {
          $scope.fundingList = data.result.fundings;
          return $scope.$broadcast('reloadTable');
        });
      };

      $scope.done = function () {
        return $location.path('donations');
      };
    }
  );
