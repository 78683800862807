import './p2p-module';
import './all';
import './anchor-site-setup';
import './manage-advocate-modal';
import './manage-donor-modal';
import './manage-program';
import './manage-team-modal';
import './p2p-metrics';
import './p2p-program-details';
import './p2p-service';
import './p2p-single-metric';
import './program-button';
import './all.html';
import './anchor-site-setup.html';
import './confirm-archive-modal.html';
import './confirm-delete-modal.html';
import './confirm-expire-modal.html';
import './manage-advocate-modal.html';
import './manage-donor-modal.html';
import './manage-program.html';
import './manage-team-modal.html';
import './p2p-metrics.html';
import './p2p-program-details.html';
import './p2p-single-metric.html';
import './program-button.html';
