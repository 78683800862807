angular.module('idonate.gms').component('chapterHierarchy', {
  template: `
    <div class="page-heading">
      <ol class="breadcrumb">
        <li>Chapter</li>
      </ol>
    </div>

    <div class="row">
      <nav class="tab-menu">
        <ul>
          <li class="menu-item"><a ng-href="/chapter">Performance</a></li>
          <li class="menu-item selected"><a ng-href="/chapter/hierarchy">Hierarchy</a></li>
        </ul>
      </nav>
    </div>
    <div class="page-full-container summary-dashboard">
      <div class="panel">
        <interval-container time-callback="$ctrl.setTime">
        </interval-container>
      </div>
      <div class="panel">
        <div class="panel-body clearfix organizations-section" ng-repeat="organization in $ctrl.chapter">
          <div class="pull-left">
            <div class="org-logo">
              <div class="logo-wrapper" ng-show="appState.currentOrganization.theme_logo.url">
                <img ng-src="{{appState.currentOrganization.theme_logo.url}}">
              </div>
            </div>
          </div>
          <div class="head-organization mb-3"><strong>{{organization.name}}</strong>
            <div class="total float-right"><strong>
              Total Donations: {{ organization.donation_total + $ctrl.childTotalDonations | currency: $root.currencyCode}}
            </strong></div>
          </div>
          <ul class="dd-list child-organization">
            <li>
              <div class="panel">{{organization.name}}
                <div class="text-muted float-right">
                  Donations: {{ organization.donation_total | currency: $root.currencyCode }}
                </div>
              </div>
              <ul class="dd-list" ng-if="organization.children">
                <li ng-repeat="organization in organization.children">
                  <div class="panel">{{organization.name}}
                    <div class="text-muted float-right">
                      Donations: {{ organization.donation_total | currency: $root.currencyCode }}
                    </div>
                  </div>
                  <ul class="dd-list" ng-if="organization.children">
                    <li class="dd-item" ng-repeat="organization in organization.children">
                      <div class="panel">
                        {{organization.name}}
                        <div class="text-muted float-right">
                          Donations: {{ organization.donation_total | currency: $root.currencyCode }}
                        </div>
                      </div>
                      <ul class="dd-list" ng-if="organization.children">
                        <li class="dd-item" ng-repeat="organization in organization.children">
                          <div class="panel">{{organization.name}}
                            <div class="text-muted float-right">
                              Donations: {{ organization.donation_total | currency: $root.currencyCode }}
                            </div>
                          </div>
                          <ul class="dd-list" ng-if="organization.children">
                            <li class="dd-item" ng-repeat="organization in organization.children">
                              <div class="panel">{{organization.name}}
                                <div class="text-muted float-right">
                                  Donations: {{ organization.donation_total | currency: $root.currencyCode }}
                                </div>
                              </div>
                              <ul class="dd-list" ng-if="organization.children">
                                <li class="dd-item" ng-repeat="organization in organization.children">
                                  <div class="panel">{{organization.name}}
                                    <div class="text-muted float-right">
                                      Donations: {{ organization.donation_total | currency: $root.currencyCode}}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  `,
  controller(organizationService, appState) {
    this.appState = appState;

    this.childTotalDonations = 0;
    this.onInit = () =>
      this.dateRange &&
      organizationService
        .getCampaignChapter({
          start_date: this.dateRange.current.start.toISOString(),
          end_date: this.dateRange.current.end.toISOString(),
          previous_start_date: this.dateRange.previous.start.toISOString(),
          previous_end_date: this.dateRange.previous.end.toISOString(),
          timezone: moment.tz.guess(),
        })
        .then((data) => {
          this.childTotalDonations = calculateChildDonation(data.result[0]);
          this.chapter = data.result;
        });
    this.setTime = (current, previous) => {
      this.dateRange = { current, previous };
      this.onInit();
    };
  },
});

function calculateChildDonation(data) {
  let totalDonations = 0;
  if (data.children && data.children.length > 0) {
    data.children.forEach((item) => {
      item.donation_total = item.donation_total || 0;
      totalDonations += item.donation_total;
      if (item.children && item.children.length > 0) {
        totalDonations += calculateChildDonation(item.children);
      }
    });
  }
  return totalDonations;
}
