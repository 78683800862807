// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './recurring-item-billing-update-modal.html';
import './recurring-item-delete-modal.html';
import './recurring-item-reset-modal.html';
import { STATE_CHOICES } from '../../util/get-states';
angular.module('idonate.gms.donations').directive('billingUpdateModal', () => ({
  restrict: 'A',
  scope: {},
  controller(
    $q,
    $scope,
    $uibModal,
    organizationService,
    recurringService,
    notyService
  ) {
    const updateBilling = function (id, address) {
      const defer = $q.defer();
      address.id = id;
      recurringService
        .updateRecurringScheduleAddress(address)
        .then((result) => defer.resolve(true));
      return defer.promise;
    };
    $scope.$on('showBillingUpdateModal', function (event, data) {
      const modal = $uibModal.open({
        size: 'lg',
        scope: $scope,
        windowClass: 'copy-code-modal',
        templateUrl:
          '/scripts/donations/recurring/recurring-item-billing-update-modal.html',
        controller($scope, $uibModalInstance) {
          organizationService
            .loadFullOrganizationInformation()
            .then((result) => ($scope.org = result.result));
          $scope.address = data.address;
          if (!$scope.address.country) {
            $scope.address.country = 'US';
          }
          $scope.showAddress = () => ($scope.editDonorAddress = true);
          $scope.closeModal = () => $uibModalInstance.dismiss('cancel');
          $scope.submitModal = function () {
            if (_.isObject($scope.address.country)) {
              $scope.address.country = $scope.address.country.id;
            }
            return updateBilling(data.id, $scope.address).then(
              function (success) {
                if (success) {
                  var n = notyService.confirm(
                    'Retry schedule?',
                    {
                      label: 'Yes',
                      action() {
                        n.close();
                        recurringService.retryRecurringSchedule({
                          id: data.id,
                        });
                      },
                    },
                    {
                      label: 'No',
                      action() {
                        n.close();
                      },
                    }
                  );
                  return $uibModalInstance.dismiss('update');
                }
              }
            );
          };
          $scope.$watch('address.country', function (newCountry, oldCountry) {
            $scope.states = STATE_CHOICES[newCountry];
            if (newCountry !== oldCountry) {
              // clear state on country change
              $scope.address.state = null;
            }
          });
        },
      });
      return modal.result.then(
        function () {},
        function () {}
      );
    });
  },
}));
