// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular
  .module('idonate.gms.website')
  .controller(
    'OrganizationEditpageCtrl',
    function (
      $rootScope,
      $scope,
      $location,
      $http,
      websiteService,
      $route,
      pageData,
      organizationEmbedInstances,
      $sce,
      notyService
    ) {
      const { page } = pageData.result;
      page.id = __guard__(
        $route.current != null ? $route.current.params : undefined,
        (x) => x.pageId
      );

      const filteredFieldsets = [];
      angular.forEach(page.fieldsets, function (fieldset) {
        const countHiddenFields = _.filter(fieldset.fields, { hidden: true });
        if (countHiddenFields.length < fieldset.fields.length) {
          return filteredFieldsets.push(fieldset);
        }
      });

      page.fieldsets = filteredFieldsets;
      $scope.page = page;
      $scope.page.enabled = true;

      $rootScope.back = '/website/pages';
      $scope.activeFieldset = $scope.page.fieldsets[0];
      $scope.gmsDynamicFieldsData = {
        embedInstances: organizationEmbedInstances.result.embed_instances,
      };

      const settingsFields = {
        title: 'Settings',
        fields: [
          {
            data_type: 'display',
            description: '',
            label: 'Theme',
            value: $scope.page.title,
          },
          {
            data_type: 'prefixText',
            prefix: page.url.substring(0, page.url.lastIndexOf('/')),
            description:
              'The Unique Landing Page ID is the unique indentification number of the Landing Page you are editing',
            label: 'Unique Landing Page ID',
            value: $scope.page.slug,
            name: 'slug',
          },
        ],
      };

      $('.page-preview iframe')[0].src = $sce.trustAsResourceUrl(
        location.protocol + page.url.slice(page.url.indexOf('//'))
      );

      $scope.enabledDisabledPage = function () {
        if ($scope.page.enabled === true) {
          websiteService.enablePage($scope.page.id).then(
            function () {},
            () => ($scope.page.enabled = false)
          );
        } else {
          websiteService.deletePage($scope.page.id).then(
            function () {},
            () => ($scope.page.enabled = true)
          );
        }
      };

      $scope.page.fieldsets.push(settingsFields);

      $scope.setActiveTab = function (index, tab) {
        $scope.activeTab = tab;
        return ($scope.activeTab.index = index);
      };

      $scope.setActiveTab(0, $scope.page.fieldsets[0]);

      $scope.goBack = () => $location.path('pages');

      return ($scope.saveData = function () {
        let embedSet = false;
        $scope.savingData = true;
        const _inputsToSave = {
          slug: $scope.page.slug,
        };

        angular.forEach($scope.page.fieldsets, (fieldVal, fieldKey) =>
          angular.forEach(fieldVal.fields, function (val, key) {
            if (
              val.name != null &&
              val.data_type === 'embed' &&
              val.value !== null
            ) {
              embedSet = true;
            }
            if (
              val.name != null &&
              (val.data_type !== 'image' ||
                (val.data_type === 'image' && val.value === ''))
            ) {
              return (_inputsToSave[val.name] = val.value);
            }
          })
        );

        if (embedSet) {
          return websiteService
            .editOrganizationPage($route.current.params.pageId, _inputsToSave)
            .then(function (data) {
              $scope.savingData = false;
              if (data.status >= 200 || data.status < 300) {
                return $location.path('pages');
              }
            });
        } else {
          $scope.savingData = false;
          return notyService.info(
            'You must select an embed before publishing...'
          );
        }
      });
    }
  );

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
