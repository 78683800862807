import './campaign-item-edit.html';

let cachedCampaign = null;

angular
  .module('idonate.gms.campaign')
  .controller('CampaignEditCtrl', ($scope, $routeParams, CampaignService) => {
    if (cachedCampaign) {
      $scope.campaign = cachedCampaign;
    }
    CampaignService.getCampaign($routeParams.campaignId).then(
      (campaign) => (cachedCampaign = $scope.campaign = campaign)
    );
  });
