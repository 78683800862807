import './cash-options.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
('use strict');

angular
  .module('idonate.gms')
  .controller('organizationCashOptionsCtrl', function ($scope, fullData) {
    $scope.fullData = fullData;
  });
