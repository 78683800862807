// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let LineChartCtrl;
angular.module('idonate.gms').component('lineChart', {
  template: `\
<div class="row">
  <div class="col-12">
    <canvas
      height="300"
      class="chart chart-line"
      ng-if="$ctrl.chart.data.length > 0"
      chart-data="$ctrl.chart.data"
      chart-labels="$ctrl.chart.labels"
      chart-series="$ctrl.chart.types"
      chart-options="$ctrl.chartOptions"
      chart-colors="$ctrl.chart.colors">
    </canvas>
    <span ng-if="$ctrl.chart.data.length == 0">No Data</span>
  </div>
</div>\
`,
  bindings: {
    chart: '<',
    options: '<',
    format: '&?',
  },
  controller: (LineChartCtrl = class LineChartCtrl {
    /* @ngInject */
    constructor($filter, $rootScope) {
      this.addCommas = this.addCommas.bind(this);
      this.$onChanges = this.$onChanges.bind(this);
      this._setOptions = this._setOptions.bind(this);
      this.$filter = $filter;
      this.$rootScope = $rootScope;
      this.chartOptions = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                maxTicksLimit: 10,
                beginAtZero: true,
                display: true,
                callback: (value, index, values) => {
                  return this.addCommas(value);
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                zeroLineColor: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
        tooltips: {
          titleFontSize: 14,
          bodyFontSize: 14,
          bodySpacing: 5,
          callbacks: {
            label: (tooltipItem, data) => {
              return this.addCommas(tooltipItem.yLabel);
            },
          },
        },
      };
      this._setOptions();
    }
    addCommas(value) {
      if (this.format) {
        return this.format({ value });
      } else {
        return this.$filter('currency')(
          value,
          this.$rootScope.currencyCode,
          '0'
        );
      }
    }
    $onChanges(changes) {
      if (changes.options) {
        return this._setOptions;
      }
    }
    _setOptions() {
      return (this.chartOptions = angular.extend(
        this.chartOptions,
        this.options || {}
      ));
    }
  }),
});
