import './tribute-item.html';

export class TributeItemCtrl {
  /* @ngInject */
  constructor(
    $scope,
    organizationTributeDefinitions,
    organizationService,
    $route,
    Upload,
    SessionService,
    $location,
    notyService
  ) {
    if (
      $route.current.params.tributeId &&
      $route.current.params.tributeId !== 'new'
    ) {
    }

    $scope.tributeGift =
      $route.current.params.tributeId &&
      $route.current.params.tributeId !== 'new' &&
      _.find(organizationTributeDefinitions.result.tribute_definitions, {
        id: $route.current.params.tributeId,
      })
        ? _.find(organizationTributeDefinitions.result.tribute_definitions, {
            id: $route.current.params.tributeId,
          })
        : {};
    $scope.tributeGift.is_enabled = !$scope.tributeGift.is_disabled;
    //Prevent get image from cache
    $scope.tributeGift.image_url = $scope.tributeGift.image_url
      ? `${$scope.tributeGift.image_url}?ts=` + new Date().getTime()
      : $scope.tributeGift.image_url;
    let file = null;
    $scope.uploadProgress = 0;
    $scope.loading = false; //Show the spinner

    $scope.saveTributeGift = function (removeImage) {
      $scope.loading = true; //Show the spinner
      if ($scope.tributeGift.id) {
        if (removeImage) {
          $scope.tributeGift.remove_image = true;
        }
        $scope.tributeGift.tribute_definition_id = $scope.tributeGift.id;
        return organizationService
          .updateTributeDefinition($scope.tributeGift)
          .then(function (data) {
            if (removeImage) {
              $scope.tributeGift.remove_image = false;
              $scope.tributeGift.image_url = null;
            }
            $scope.loading = false;
            notyService.success('A Tribute has been updated successfully.');
            return $location.path('/tributes');
          });
      } else {
        return organizationService
          .postOrganizationTributeDefinition($scope.tributeGift)
          .then(function (data) {
            $scope.tributeGift = data.result.tribute_definition;
            if (file) {
              return uploadFile(true);
            } else {
              $scope.loading = false;
              notyService.success('A Tribute has been created successfully.');
              return $location.path('/tributes');
            }
          });
      }
    };

    $scope.$watch('files', (files) => $scope.upload(files));

    $scope.removeImage = function () {
      $scope.files = [];
      file = null;
      if ($scope.tributeGift.id && $scope.tributeGift.image_url) {
        return $scope.saveTributeGift(true);
      }
    };

    var uploadFile = function (redirectAtEnd) {
      return SessionService.uploadToGmsApi(
        `/organization/${
          organizationService.getCurrentOrganization().id
        }/tribute_definitions/${$scope.tributeGift.id}`,
        {
          data: {
            image: file,
          },
          method: 'PUT',
          fileFormDataName: $scope.tributeGift.image_key,
        }
      )
        .progress(
          (evt) =>
            ($scope.uploadProgress = parseInt((100.0 * evt.loaded) / evt.total))
        )
        .success(function (data, status, headers, config) {
          $scope.uploadProgress = 0;
          if (data.result.tribute_definition.image_url) {
            $scope.tributeGift.image_url =
              `${data.result.tribute_definition.image_url}?ts=` +
              new Date().getTime();
          } else {
            $scope.tributeGift.image_url = '';
          }
          $scope.tributeGift.image_key =
            data.result.tribute_definition.image_key;
          $scope.file = null;
          $scope.loading = false;
          if (redirectAtEnd) {
            notyService.success('A Tribute has been created successfully.');
            return $location.path('/tributes');
          }
        });
    };

    $scope.upload = function (files) {
      if (!files || files.size === 0) {
        return;
      }

      file = files;
      if (
        ($scope.tribugeGift != null
          ? $scope.tribugeGift.image_key
          : undefined) === '[FALLBACK]'
      ) {
        $scope.tributeGift.image_key = `public/organizations/${
          organizationService.getCurrentOrganization().id
        }/tributes/${$scope.tributeGift.id}.jpg`;
      }
      if ($scope.tributeGift.id) {
        return uploadFile();
      }
    };

    $scope.activeDesactive = function () {
      $scope.tributeGift.tribute_definition_id = $scope.tributeGift.id;
      $scope.tributeGift.is_disabled = !$scope.tributeGift.is_enabled;
      return organizationService
        .updateTributeDefinition($scope.tributeGift)
        .then(function (result) {
          notyService.success('A Tribute has been updated successfully.');
        });
    };
  }
}
