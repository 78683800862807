// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let ChartHeaderCtrl;
angular.module('idonate.gms').component('chartHeader', {
  template: `\
  <div class="row chart-heading">
    <id-radio-row options="$ctrl.chartSelectorOptions"></id-radio-row>
    <div class="col-12">
      <ng-transclude></ng-transclude>
    </div>
  </div>`,
  transclude: true,
  bindings: {
    chartSelectorOptions: '=selectors',
    legends: '=',
    direction: '@',
  },
  controller: (ChartHeaderCtrl = class ChartHeaderCtrl {
    constructor() {}
  }),
});
