export class CampaignAPI {
  static $inject = ['RestAPI', 'appState'];
  constructor(RestAPI, appState) {
    this.RestAPI = RestAPI;

    this.baseUrl = () => {
      return `organization/${appState.currentOrganization.id}/campaigns`;
    };
  }

  getOrganizationCampaigns(data) {
    return this.RestAPI.get(this.baseUrl(), data);
  }

  postOrganizationCampaign(obj) {
    return this.RestAPI.post(this.baseUrl(), obj);
  }

  deleteCampaign(campaignId) {
    return this.RestAPI.delete(`${this.baseUrl()}/${campaignId}`);
  }

  getCampaignDetails(campaignId) {
    return this.RestAPI.get(`${this.baseUrl()}/${campaignId}`);
  }

  getCampaignActiveTools(campaignId) {
    return this.RestAPI.get(`${this.baseUrl()}/${campaignId}/active_tools`);
  }

  putCampaign(obj) {
    return this.RestAPI.put(`${this.baseUrl()}/${obj.id}`, obj);
  }

  getCampaignDesignations(campaignId) {
    return this.RestAPI.get(`${this.baseUrl()}/${campaignId}/designations`);
  }

  postCampaignDesignation(campaignId, obj) {
    return this.RestAPI.post(
      `${this.baseUrl()}/${campaignId}/designations`,
      obj
    );
  }

  deleteCampaignDesignation(campaignId, designation_id) {
    return this.RestAPI.delete(
      `${this.baseUrl()}/${campaignId}/designations/${designation_id}`
    );
  }

  postDesignationOrder(campaignId, orderedList) {
    return this.RestAPI.post(
      `${this.baseUrl()}/${campaignId}/designations/order`,
      orderedList
    );
  }

  getCampaignChannels(campaignId) {
    return this.RestAPI.get(`${this.baseUrl()}/${campaignId}/channels`);
  }

  getAllCampaignsChannels() {
    return this.RestAPI.get(`${this.baseUrl()}/channels`);
  }

  postCampaignDuplicate(campaignId) {
    return this.RestAPI.post(`${this.baseUrl()}/${campaignId}/duplicate`);
  }
}
