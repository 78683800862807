export class NewCampaignModal {
  /* @ngInject */
  static init(Modals) {
    Modals.registerModal('NewCampaignModal', NewCampaignModal.modalRegister);
    return { restrict: 'A' };
  }
  static modalRegister(resolve) {
    return {
      size: 'md',
      windowClass: 'advocate-modal',
      template: `\
        <div class="modal-header">
          <h2 class="modal-title">Create New Campaign</h2>
          <a class="close" ng-click="$ctrl.cancel()"><i class="icon-closex"></i></a>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-md-8 col-12">
              <label for="name">Name</label>
              <input id="name" name="name" ng-model="$ctrl.campaign.title" type="text" class="form-control" required>
            </div>
            <div class="form-group col-md-8 col-12">
              <label for="goal">Goal</label>
              <input gms-currency id="goal" name="goal" ng-model="$ctrl.campaign.goal" type="text" class="form-control" required>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button ng-click="$ctrl.save()" class="float-right btn btn-primary right-arrow">
            Save
            <span>
              <div ng-show="$ctrl.loading" class="idonate-spinner" alt="Loader"></div>
            </span>
          </button>
        </div>
      `,
      controllerAs: '$ctrl',
      resolve,
      /* @ngInject */
      controller($uibModalInstance, CampaignService, $location) {
        this.$uibModalInstance = $uibModalInstance;
        this.CampaignService = CampaignService;
        this.$location = $location;
        this.campaign = {
          title: '',
          goal: 0,
        };
        this.validate = (campaign) => {
          let valid = true;
          this.invalid = [];
          if (!this.campaign.title || this.campaign.title === '') {
            valid = false;
            this.invalid.push('title');
          }
          if (!this.campaign.goal) {
            valid = false;
            this.invalid.push('goal');
          }
          return valid;
        };
        this.cancel = () => {
          this.$uibModalInstance.dismiss('cancel');
        };
        this.save = () => {
          this.loading = true;
          if (!this.validate(this.campaign)) {
            this.loading = false;
          } else {
            CampaignService.addOrganizationCampaign({
              title: this.campaign.title,
              goal: this.campaign.goal,
            }).then((data) => {
              $location.path(`campaign/${data.campaign.id}/edit`);
              $uibModalInstance.close();
              this.loading = false;
            });
          }
        };
      },
    };
  }
}
