// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const P2PProgramDetails = function (
  $scope,
  $location,
  Modals,
  $routeParams,
  p2pService,
  $timeout
) {
  const program_id = $routeParams.programId;
  $scope.visitPageLink =
    window.iDonateConfig.legacyP2PBaseUrl + 'p/' + program_id;
  const updateTables = function () {
    const params = { program_id };
    return p2pService.getP2PProgram(params).then(function (data) {
      const { result } = data;
      $scope.teams = result.teams;
      $scope.advocates = result.individuals;
      return ($scope.donors = result.transaction_table.data);
    });
  };
  $scope.editProgram = () =>
    $location.path(`legacy-p2p/program/edit/${program_id}`);
  const openDetailsModal = function (data) {
    if ($scope.selected === 'donor') {
      return openDonorModal(data);
    } else if ($scope.selected === 'fundraiser') {
      return openAdvocateModal(data);
    } else if ($scope.selected === 'team') {
      return openTeamModal(data);
    }
  };
  var openDonorModal = (donor) => {
    Modals.open('ManageDonorModal', {
      donorInfo() {
        return {
          teams: $scope.teams,
          advocates: $scope.advocates,
          transactionId: donor[0],
          donorName: `${donor[1]} ${donor[2]}`,
          programId: program_id,
        };
      },
    }).then(
      (result) => updateTables(),
      function () {}
    );
  };
  var openTeamModal = function (team) {
    if (!team.id) {
      return;
    }

    return Modals.open('ManageTeamModal', {
      team() {
        return team;
      },
      baseLink() {
        return $scope.visitPageLink;
      },
    }).then(
      function () {},
      function () {}
    );
  };
  var openAdvocateModal = (advocate) => {
    Modals.open('ManageAdvocateModal', {
      advocate() {
        return advocate;
      },
      baseLink() {
        return $scope.visitPageLink;
      },
      teams() {
        return $scope.teams;
      },
    }).then((result) => updateTables());
  };
  $scope.tableOptions = {
    clickableRowAction: openDetailsModal,
    outsideSearchId: 'outside-search',
  };
  $scope.view = {
    teams: {
      tableColumns: [
        'Created',
        'Team Name',
        'Fundraiser Pages',
        'Total Raised',
      ],
    },
    advocates: {
      tableColumns: [
        'Joined',
        'Team Member',
        'Email',
        'Team Name',
        'Total Raised',
        'Status',
      ],
    },
    donors: {
      active: true,
      tableColumns: [
        'Date',
        'First Name',
        'Middle Name',
        'Last Name',
        'Transaction ID',
        'Amount',
        'In Support of',
      ],
    },
  };
  // default table
  $scope.tableColumns = $scope.view.donors.tableColumns;
  $scope.$on('selectedChanged', function (evnt, name) {
    $scope.selected = name;
    if ($scope.selected === 'donor') {
      $scope.title = 'Donors';
      $scope.tableColumns = $scope.view.donors.tableColumns;
    } else if ($scope.selected === 'fundraiser') {
      $scope.title = 'Fundraisers';
      $scope.tableColumns = $scope.view.advocates.tableColumns;
    } else if ($scope.selected === 'team') {
      $scope.title = 'Teams';
      $scope.tableColumns = $scope.view.teams.tableColumns;
    }
    return $timeout(() => $scope.$broadcast('reloadTable'));
  });
  $scope.$watchGroup(
    ['teams', 'advocates', 'donors'],
    function (nv, ov) {
      if (nv !== ov) {
        return $scope.$broadcast('reloadTable');
      }
    },
    true
  );
  $scope.viewTeams = function (val) {
    $scope.view.teams.active = val;
    $scope.view.advocates.active = false;
    $scope.view.donors.active = false;
    if (val) {
      $scope.tableColumns = $scope.view.teams.tableColumns;
      return $scope.$broadcast('reloadTable');
    }
  };
  $scope.viewAdvocates = function (val) {
    $scope.view.teams.active = false;
    $scope.view.advocates.active = val;
    $scope.view.donors.active = false;
    if (val) {
      $scope.tableColumns = $scope.view.advocates.tableColumns;
      return $scope.$broadcast('reloadTable');
    }
  };
  $scope.viewDonors = function (val) {
    $scope.view.teams.active = false;
    $scope.view.donors.active = val;
    $scope.view.advocates.active = false;
    if (val) {
      $scope.tableColumns = $scope.view.donors.tableColumns;
      return $scope.$broadcast('reloadTable');
    }
  };
  updateTables();
};

angular
  .module('idonate.gms.legacy-p2p')
  .controller('P2PProgramDetails', P2PProgramDetails);
