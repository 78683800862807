import './campaign-email.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let open = false;

var CampaignEmail = (function () {
  let _filterTextarea = undefined;
  CampaignEmail = class CampaignEmail {
    static initClass() {
      _filterTextarea = function (fieldset) {
        angular.forEach(fieldset.fields, function (field, index) {
          if (field.data_type === 'textarea') {
            field['optionsSummerNote'] = {
              disableDragAndDrop: true,
              toolbar: [
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['insert', ['link', 'picture']],
              ],
            };
            return (fieldset.fields[index] = field);
          }
        });
        return fieldset;
      };
    }
    blurContent = true;
    /* @ngInject */
    constructor(
      $scope,
      $routeParams,
      CampaignService,
      ReceiptService,
      $uibModal
    ) {
      this._showModal = this._showModal.bind(this);
      this._setupFieldsets = this._setupFieldsets.bind(this);
      this._initializeFieldset = this._initializeFieldset.bind(this);
      this._onCustomizeContentChange =
        this._onCustomizeContentChange.bind(this);
      this._setupPreview = this._setupPreview.bind(this);
      this.publish = this.publish.bind(this);
      this.$scope = $scope;
      this.ReceiptService = ReceiptService;
      this.$uibModal = $uibModal;
      this.campaignId = $routeParams.campaignId;
      if (this.campaignId === 'new') {
        if (!open) {
          this._showModal();
        }
        return;
      }
      CampaignService.getCampaign(this.campaignId).then(this._setupFieldsets);
      this.ReceiptService.getCampaignReceipt(this.campaignId).then(
        this._setupPreview
      );
    }
    _showModal() {
      this.$uibModal.open({
        template:
          '<div class="modal-header">' +
          '<h2>New Campaign</h2>' +
          '</div>' +
          '<div class="modal-body">' +
          'The campaign must be saved before emails can be edited.' +
          '</div>' +
          '<div class="modal-footer">' +
          '<button class="btn btn-primary" ng-click="$ctrl.closeAndOut()">' +
          'Edit Details</button>' +
          '</div>',
        controllerAs: '$ctrl',
        controller($uibModalInstance, $location) {
          this.closeAndOut = function () {
            $uibModalInstance.dismiss();
            $location.path('campaign/new/edit/details');
            return (open = false);
          };
        },
        backdrop: 'static',
        keyboard: false,
      });
      return (open = true);
    }
    _setupFieldsets(campaign) {
      this.campaign = campaign;
      const { fieldsets } = campaign;
      for (let fieldset of Array.from(fieldsets)) {
        if (fieldset.title === 'Email Customization') {
          this.fieldset = _filterTextarea(fieldset);
        }
      }
      return this._initializeFieldset();
    }
    _initializeFieldset() {
      let disableFields = false;
      let index = 0;
      for (let field of Array.from(this.fieldset.fields)) {
        if (field.label === 'Signature Image') {
          field.name = `${this.campaignId}.${field.name}`;
        }
        if (field.label === 'Customize Content') {
          disableFields = !field.value;
          this.blurContent = disableFields;
          var customizeIndex = index;
          this.$scope.$watch(() => {
            return this.fieldset.fields[customizeIndex].value;
          }, this._onCustomizeContentChange);
        } else {
          field.disabled = disableFields;
        }
        index++;
      }
    }
    _onCustomizeContentChange(val) {
      return (() => {
        const result = [];
        for (let field of Array.from(this.fieldset.fields)) {
          var disableFields;
          if (field.label === 'Customize Content') {
            disableFields = !field.value;
            this.indicate = disableFields;
            this.blurContent = disableFields;
            if (disableFields) {
              result.push($('iframe').css('filter', 'blur(5px)'));
            } else {
              result.push($('iframe').css('filter', ''));
            }
          } else {
            result.push((field.disabled = disableFields));
          }
        }
        return result;
      })();
    }
    _setupPreview(result) {
      return (this.preview = result.html);
    }
    publish() {
      this.loading = true;
      const data = this.campaign;
      for (let field of Array.from(this.fieldset.fields)) {
        let fieldName = field.name.split(`${this.campaignId}.`);
        fieldName = fieldName[fieldName.length - 1];
        data[fieldName] = field.value;
      }
      return this.ReceiptService.saveCampaignReceipt(
        this.campaignId,
        data
      ).then((result) => {
        this.loading = false;
        return this.ReceiptService.getCampaignReceipt(this.campaignId).then(
          this._setupPreview
        );
      });
    }
    static register() {
      return {
        templateUrl: '/scripts/campaign/campaign-item/campaign-email.html',
        controller: this,
      };
    }
  };
  CampaignEmail.initClass();
  return CampaignEmail;
})();

angular
  .module('idonate.gms.campaign')
  .component('campaignEmail', CampaignEmail.register());
