import { getStateName } from '../../util/get-states';
// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './recurring-item.html';
import { formatSingleLineAddress } from '../../util/format';

('use strict');

angular
  .module('idonate.gms.donations')
  .controller(
    'DonationsRecurringItemCtrl',
    function (
      $scope,
      $routeParams,
      recurringService,
      recurringSchedule,
      $timeout,
      $rootScope,
      notyService,
      $location,
      $uibModal,
      $filter
    ) {
      // need to set in scope to leverage in html
      $scope.recurringService = recurringService;
      $scope.recurringSchedule =
        recurringSchedule.result.recurring_donation_schedule;

      $scope.showHistory = false;
      const _getState = () => {
        if (moment($scope.recurringSchedule.expires).diff() < 0) {
          return 'expired';
        } else {
          return 'disabled';
        }
      };
      $scope.state = _getState();
      $scope.formattedHistory = angular.copy($scope.recurringSchedule.history);
      angular.forEach($scope.formattedHistory, function (item) {
        if (item.attribute === 'Expires' || item.attribute === 'Next Payment') {
          item.new_value = $filter('gmsDate')(item.new_value);
          return (item.old_value = $filter('gmsDate')(item.old_value));
        } else if (item.attribute === 'Amount') {
          item.new_value = $filter('currency')(
            item.new_value,
            $rootScope.currencyCode
          );
          return (item.old_value = $filter('currency')(
            item.old_value,
            $rootScope.currencyCode
          ));
        }
      });

      const postCancelSchedule = function () {
        const _objToPost = {
          id: $scope.recurringSchedule.id,
        };
        return recurringService
          .cancelRecurringSchedule(_objToPost)
          .then(function (data) {
            $scope.recurringSchedule.history =
              data.result.recurring_donation_schedule.history;
            $scope.recurringSchedule.expires =
              data.result.recurring_donation_schedule.expires;
            $scope.recurringSchedule.next_payment =
              data.result.recurring_donation_schedule.next_payment;
            return $location.path('/donations/recurring');
          });
      };

      $scope.getAddress = () => {
        return formatSingleLineAddress($scope.recurringSchedule.donor.contact.address);
      };

      $scope.cancelSchedule = () =>
        $uibModal
          .open({
            size: 'md',
            scope: $scope,
            windowClass: 'advocate-modal',
            templateUrl:
              '/scripts/donations/recurring/recurring-item-delete-modal.html',
            controller($scope, $uibModalInstance) {
              $scope.modalHeader = 'Warning';
              $scope.cancel = () => $uibModalInstance.dismiss('cancel');
              return ($scope.deleteRecurring = function () {
                postCancelSchedule();
                return $uibModalInstance.dismiss('cancel');
              });
            },
          })
          .result.then(angular.noop, angular.noop);

      const putResetSchedule = function (next, expires, reason) {
        recurringService
          .updateRecurringSchedule({
            id: $scope.recurringSchedule.id,
            next_payment: moment(next).toISOString(),
            //# '' if the org would like to restart the schedule with no expiration
            expires: expires ? moment(expires).toISOString() : '',
            reason,
          })
          .then(() => $location.path('/donations/recurring'));
      };

      $scope.restartSchedule = () =>
        $uibModal
          .open({
            size: 'md',
            scope: $scope,
            windowClass: 'advocate-modal',
            templateUrl:
              '/scripts/donations/recurring/recurring-item-reset-modal.html',
            controller($scope, $uibModalInstance) {
              $scope.reset = { invalid: true };
              $scope.pickerOpts = {
                format: 'YYYY-MM-DD',
                maxView: 'day',
              };
              $scope.modalHeader = 'Restart recurring schedule';
              $scope.cancel = () => $uibModalInstance.dismiss('cancel');
              $scope.resetRecurring = function (next, expires, reason) {
                putResetSchedule(next, expires, reason);
                return $uibModalInstance.dismiss('cancel');
              };
              return ($scope.nextBlur = function () {
                if ($scope.reset.next) {
                  return ($scope.reset.invalid = false);
                }
              });
            },
          })
          .result.then(angular.noop, angular.noop);

      $scope.requestUpdate = function () {
        const _objToPost = { id: $scope.recurringSchedule.id };
        return recurringService
          .requestUpdateRecurringSchedule(_objToPost)
          .then((data) => notyService.success(data.result));
      };

      $scope.billingUpdate = () => {
        $scope.$broadcast('showBillingUpdateModal', {
          address: $scope.recurringSchedule.donor.contact.address,
          id: $scope.recurringSchedule.id,
        });
      };

      if ($routeParams.update_billing) {
        return $timeout(() => $scope.billingUpdate());
      }

      $scope.detailUpdate = () => {
        $scope.$broadcast('showDetailUpdateModal', {
          id: $scope.recurringSchedule.id,
          details: $scope.recurringSchedule.details,
          edittable: $scope.recurringSchedule.allowed_detail_edits,
        });
      };
    }
  );
