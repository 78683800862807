import './item-detail-update-modal.html';
import './item-transaction-update-modal.html';
const QUERIES = require('../../graphql/queries.gql');

angular.module('idonate.gms.donations').directive('detailUpdateModal', () => ({
  restrict: 'A',
  scope: {
    detailDeleter: '=delete',
    detailUpdater: '=update',
  },
  controller(
    $route,
    $scope,
    $uibModal,
    organizationService,
    transactionService,
    notyService,
    SessionService
  ) {
    $scope.details = [];
    $scope.addableDetails = [];
    $scope.allowedEdits = [];
    $scope.addDetailValue = '';
    $scope.parent_id = null;
    $scope.modalInstance = null;
    $scope.changed = false;
    $scope.campaigns = [];
    $scope.selectedCampaign = '';
    $scope.designationList = [];
    $scope.designations = [
      {
        id: '',
        val: '',
      },
    ];
    $scope.closeModal = () => {
      if ($scope.changed) {
        notyService.info('Detail changes saved!');
        $scope.changed = false;
        $route.reload();
      } else {
        if ($scope.modalInstance) {
          $scope.modalInstance.dismiss('cancel');
        }
      }
    };
    $scope.$on('showDetailUpdateModal', function (event, data) {
      const modal = $uibModal
        .open({
          size: 'lg',
          scope: $scope,
          windowClass: 'copy-code-modal',
          templateUrl: '/scripts/donations/item-detail-update-modal.html',
          controller($scope, $uibModalInstance) {
            $scope.$parent.modalInstance = $uibModalInstance;
            organizationService
              .loadFullOrganizationInformation()
              .then((result) => ($scope.org = result.result));
            // trim existing details from potential edits
            $scope.filterExistingDetails = (edits, detail) => {
              //sometimes the value can be "false" which confuses filter a little
              return edits.filter((e) => detail[e.key] == undefined);
            };
            // excise details we shouldn't see or edit.
            // I tried displaying all details in the modal but
            // it breaks down with too many.
            $scope.visibleDetails = (details, allowed) => {
              return allowed.reduce(function (acc, item) {
                if (details[item.key] != undefined) {
                  var visibleEntry = { ...item, value: details[item.key] };
                  acc.push(visibleEntry);
                }
                return acc;
              }, []);
            };
            $scope.parent_id = data.id;
            $scope.allowedEdits = data.edittable;
            $scope.details = $scope.visibleDetails(
              data.details,
              $scope.allowedEdits
            );
            $scope.addableDetails = $scope.filterExistingDetails(
              data.edittable,
              data.details
            );
            $scope.addDetail = (addDetailValue) => {
              let addingDetail = $scope.addableDetails.find(
                (a) => a.key == addDetailValue
              );
              $scope.addableDetails = $scope.addableDetails.filter(
                (a) => a.key != addDetailValue
              );
              addingDetail.value = '';
              $scope.details.push(addingDetail);
            };
            $scope.updateDetail = (detailUpdating) => {
              detailUpdating.parent_id = $scope.parent_id;
              $scope.detailUpdater(detailUpdating);
              $scope.$parent.changed = true;
              $scope.details = $scope.details.filter(
                (a) => a.key != detailUpdating.key
              );
              $scope.details.push(detailUpdating);
            };
            $scope.removeDetail = (detailRemove) => {
              detailRemove.parent_id = $scope.parent_id;
              $scope.detailDeleter(detailRemove);
              $scope.$parent.changed = true;
              $scope.details = $scope.details.filter(
                (a) => a.key != detailRemove.key
              );
              $scope.addableDetails.push(detailRemove);
            };
          },
        })
        .result.then(_, $scope.closeModal);
      // success is prompted by an ng-click that calls closeModal directly
    });
    $scope.$on('showTransactionUpdateModal', function (event, data) {
      const model = $uibModal
        .open({
          size: 'lg',
          scope: $scope,
          templateUrl: '/scripts/donations/item-transaction-update-modal.html',
          controller($scope, $uibModalInstance) {
            $scope.$parent.modalInstance = $uibModalInstance;
            $scope.campaign = {
              all: [],
              selected: {},
            };
            $scope.designation = {
              all: [],
              selected: {},
            };
            $scope.fundraiser = {
              all: [],
              selected: {},
            };
            $scope.loading = true;

            function getFundraiserDisplayName(fundraiser) {
              const creator = fundraiser.creator || {};
              const user = creator.user || {};

              if (fundraiser.title) {
                return fundraiser.title;
              }

              // created by
              const createdByName =
                fundraiser.name ||
                creator.name ||
                creator.title ||
                user.name ||
                user.username ||
                user.email;

              return `Fundraiser created by ${createdByName || 'user'}`;
            }

            if (
              data.activeTransaction.fundraiser.program ||
              data.activeTransaction.fundraiser.team
            ) {
              SessionService.graphql
                .query({
                  query: QUERIES.getProgram,
                  variables: {
                    id: data.activeTransaction.fundraiser.program.id,
                  },
                })
                .then((result) => {
                  const fundraisers =
                    result.data.program.fundraisers.fundraiser;

                  $scope.fundraiser.all = fundraisers.filter((fundraiser) => {
                    if (fundraiser.status !== 'archived') {
                      fundraiser.advocate_name =
                        getFundraiserDisplayName(fundraiser);
                      return fundraiser;
                    }
                  });

                  $scope.fundraiser.selected =
                    $scope.fundraiser.all.find(
                      (fundraiser) =>
                        fundraiser.id === data.activeTransaction?.fundraiser?.id
                    ) || {};

                  $scope.loading = false;
                });
            }

            organizationService
              .loadFullOrganizationInformation()
              .then((result) => {
                $scope.campaign.all = result.result.campaigns;
                $scope.campaign.selected = data.activeTransaction.campaign;

                $scope.designation.all = result.result.designations;
                $scope.designation.selected = result.result.designations.find(
                  (designation) =>
                    designation.id == data.activeTransaction.designation_id
                );

                $scope.initialCampaign = $scope.campaign.selected;
                $scope.initialDesignation = $scope.designation.selected;
              });

            $scope.updateTransactionCampaign = () => {
              /* Checks if either campaign or designation has been selected */
              /* If none of them have been selected, the patch request won't continue */
              if (
                $scope.campaign.selected?.id !== $scope.initialCampaign?.id ||
                $scope.designation.selected?.id !==
                  $scope.initialDesignation?.id ||
                $scope.fundraiser.selected?.id !==
                  data.activeTransaction?.fundraiser?.id
              ) {
                transactionService
                  .updateTransactionCampaign({
                    campaign_id: $scope.campaign.selected?.id,
                    designation_id: $scope.designation.selected?.id,
                    fundraiser_id: $scope.fundraiser.selected?.id,
                    transactionId: data.activeTransaction.id,
                  })
                  .then(() => $route.reload());
              } else {
                $scope.closeModal();
              }
            };
          },
        })
        .result.then(_, $scope.closeModal);
    });
  },
}));
