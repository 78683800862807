// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class ReceiptPreview {
  /* @ngInject */
  constructor($element, $timeout) {
    this.$onChanges = this.$onChanges.bind(this);
    this.$element = $element;
    this.$timeout = $timeout;
  }
  $onChanges(changes) {
    if (changes.model && changes.model.currentValue) {
      const doc = __guard__(
        this.$element.children('iframe')[0].contentWindow,
        (x) => x.document
      );
      if (doc) {
        $(doc.body).empty();
        $(doc.body).append(changes.model.currentValue);
        $(this.$element.children('iframe')[0]).css(
          'height',
          doc.body.scrollHeight
        );
      }
    }
  }
}

angular.module('idonate.gms').component('receiptPreview', {
  template:
    '<iframe frameborder="0" id="receiptPreview" ng-class="$ctrl.blur ? \'blur\' : \'\'"></iframe>',
  bindings: {
    model: '<',
    blur: '=',
  },
  controller: ReceiptPreview,
});

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
