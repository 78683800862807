import './donors.html';

angular
  .module('idonate.gms.donors')
  .controller('DonorsCtrl', function ($scope, organizationService, $location) {
    $scope.orgId = organizationService.getCurrentOrganization().id;

    $scope.clickOnRow = function (id) {
      $location.path(`/donors/detail/${id}`);
      $scope.$apply();
    };
  });
