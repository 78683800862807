// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './recurringtable.html';
const RecurringTable = ($timeout, $filter, $rootScope, SessionService) => ({
  restrict: 'E',
  templateUrl: '/scripts/donations/recurring/recurringtable.html',
  controllerAs: 'ctrl',
  controller(
    $scope,
    $element,
    $attrs,
    $timeout,
    $uibModal,
    $filter,
    recurringColumns,
    $rootScope,
    appState
  ) {
    const init = function () {
      $scope.openColumnSelector = openColumnSelector;
      $scope.reload = reload;
      $scope.export = exportTable;
      $scope.$watch('reportData.cascade', cascadeWatch);
      setColumns();
      activate();
      return buildTable();
    };
    var activate = function () {
      $scope.uniqueId = `GMS-ST${Math.floor(Math.random() * 10000000 + 1000)}`;
      $scope.reportData = {
        cascade: false,
        format: 'json',
        organization_id: $scope.orgId,
      };
      $scope.customTime = {};
      const table = null;
      const resizeTO = null;
      $scope.firstGenerate = true;

      $scope.colums = angular.copy($scope.newColumns);
      $scope.pageLimit = gmsStorage.gmsTablePageLimit
        ? parseInt(gmsStorage.gmsTablePageLimit)
        : 10;
      angular.forEach($scope.colums, function (val, key) {
        if (
          val.name === 'created' ||
          val.name === 'expires' ||
          val.name === 'next_payment'
        ) {
          return ($scope.colums[key].render = (data, type, row) =>
            $filter('gmsDate')(data));
        } else if (val.name === 'detail.sale_price' || val.name === 'amount') {
          return ($scope.colums[key].render = (data, type, row) =>
            $filter('currency')(data, $rootScope.currencyCode));
        } else if (val.name === 'schedule_type') {
          return ($scope.colums[key].render = function (data, type, row) {
            val = data.charAt(0).toUpperCase() + data.slice(1);
            val = val.replace('Semiannually', 'Semi-Annually');
            return val;
          });
        } else if (val.name === 'hide_name') {
          return ($scope.colums[key].render = function (data, type, row) {
            return data.toLowerCase();
          });
        } else {
          return ($scope.colums[key].render = $.fn.dataTable.render.text());
        }
      });
      $scope.columnsActiveDesactive = {};
      return angular.forEach(
        $scope.colums,
        (val, $key) => ($scope.columnsActiveDesactive[val.name] = val.active)
      );
    };
    var setColumns = () => ($scope.newColumns = recurringColumns.defaults);
    var openColumnSelector = function () {
      let forceInitialColumns = false;
      const modalInstance = $uibModal.open({
        size: 'lg',
        scope: $scope,
        windowClass: 'column-selector-modal',
        templateUrl: 'columnSelectorModal.html',
        controller($scope, $uibModalInstance, $route) {
          $scope.changeColumns = function (checkName, value) {
            if (checkName === 'all') {
              if (value) {
                angular.forEach($scope.colums, function (val, $i) {
                  $scope.colums[$i].active = true;
                  return ($scope.columnsActiveDesactive[val.name] = true);
                });
                return (forceInitialColumns = false);
              } else {
                forceInitialColumns = true;
                $scope.colums = angular.copy($scope.newColumns);
                return angular.forEach(
                  $scope.colums,
                  (val, $i) =>
                    ($scope.columnsActiveDesactive[val.name] = val.active)
                );
              }
            } else {
              let _counter = 0;
              forceInitialColumns = false;
              angular.forEach($scope.colums, function (val, $i) {
                $scope.colums[$i].active =
                  $scope.columnsActiveDesactive[val.name];
                if (!$scope.colums[$i].active) {
                  return ($scope.columnsActiveDesactive['all'] = false);
                } else {
                  return _counter++;
                }
              });
              if (_counter === $scope.colums.length) {
                return ($scope.columnsActiveDesactive['all'] = true);
              }
            }
          };
          // checks if the user has clicked to
          // navigate away from the page
          // -> close the modal automatically
          $scope.$on('$routeChangeStart', (next, current) =>
            $uibModalInstance.dismiss('cancel')
          );
          return ($scope.closeModal = () =>
            $uibModalInstance.dismiss('cancel'));
        },
      });
      modalInstance.result.then(null, function () {
        if (forceInitialColumns) {
          $scope.colums = angular.copy($scope.newColumns);
          forceInitialColumns = false;
        }
        return $scope.reload();
      });
    };
    var cascadeWatch = function (a, b) {
      if (!$scope.firstGenerate) {
        return $scope.reload();
      }
    };
    const removeTable = function () {
      if ($scope.table) {
        $scope.table.destroy();
        return $(`#${$scope.uniqueId} table`).remove();
      }
    };
    var buildTable = function () {
      //console.log("buildTable")
      const buildColumns = _.filter($scope.colums, { active: true });
      return $timeout(function () {
        $(`#${$scope.uniqueId}_temp .table`)
          .clone()
          .appendTo(`#${$scope.uniqueId}`);
        $scope.firstGenerate = false;
        const urlParams = {
          timezone: moment.tz.guess(),
          cascade: $scope.reportData.cascade,
          organization_id: $scope.reportData.organization_id,
        };
        const encodedParams = $.param(urlParams);
        const recurringRequestUrl = `${window.iDonateConfig.baseUrl}organization/${$scope.reportData.organization_id}/recurring/schedules-table?${encodedParams}`;
        $scope.table = $(`#${$scope.uniqueId} table`).DataTable({
          createdRow(row, data, dataIndex) {
            const column = $scope.colums[dataIndex];
            if ((column != null ? column.name : undefined) === 'created') {
              const cell = $('td', row).eq(dataIndex);
              return cell.attr('data-order', data[dataIndex]);
            }
          },
          serverSide: true,
          processing: true,
          sDom: "<'row'<'col-6'T><'col-6'f>r><'panel panel-table table-responsive't><'row'<'col-sm-3'l><'col-sm-6'p>>",
          oLanguage: {
            sLengthMenu: 'Show _MENU_ per page',
            oPaginate: {
              sNext: '<i class="icon-right"></i>',
              sPrevious: '<i class="icon-left"></i>',
            },
          },
          pageLength: $scope.pageLimit,
          order: [[0, 'desc']],
          //positioning elements - https://datatables.net/examples/basic_init/dom.html
          dom: '<tilp>',
          ajax: {
            url: recurringRequestUrl,
            type: 'post',
            beforeSend: (request) => {
              // grab authentication headers from API client
              const headers = SessionService.getAuthHeaders();

              Object.keys(headers).forEach((key) => {
                request.setRequestHeader(key, headers[key]);
              });
            },
          },
          columns: buildColumns,
          rowCallback(row, data) {
            let getIconNumber = null;
            row.className = 'clickable-row';
            angular.forEach(buildColumns, function (val, $key) {
              if (val.name === 'icon') {
                return (getIconNumber = $key);
              }
            });
            if (getIconNumber && data[getIconNumber]) {
              const _icon = $filter('typeToGlyph')(data[getIconNumber]);
              return $(`td:eq(${getIconNumber})`, row).html(
                `<i class="glyphicons ${_icon}"></i>`
              );
            }
          },
        });
        $scope.table
          .columns()
          .iterator('column', (ctx, idx) =>
            $($scope.table.column(idx).header()).append(
              '<span class="sort-icon"/>'
            )
          );
        $('#recurring-search').keyup(function () {
          const _self = this;
          if ($scope.searchingTable) {
            $timeout.cancel($scope.searchingTable);
          }
          return ($scope.searchingTable = $timeout(
            () => $scope.table.search($(_self).val()).draw(),
            800
          ));
        });
        return $(`#${$scope.uniqueId} table`)
          .off()
          .on('xhr.dt', function () {
            const params = $scope.table.ajax.params();
            $scope.pageLimit = params.length;
            gmsStorage.gmsTablePageLimit = params.length;
            let getIdNumber = _.filter(params.columns, { name: 'id' });
            if (getIdNumber.length > 0) {
              getIdNumber = getIdNumber[0].data;
              const json = $scope.table.ajax.json();
              return $(`#${$scope.uniqueId} tbody`)
                .off()
                .on('click', 'tr', function (e) {
                  const data = $scope.table.row(this).data();
                  if (!data) {
                    return;
                  }
                  const reportId = data[getIdNumber];
                  return $scope.clickOnRow(reportId);
                });
            } else {
              return $(`#${$scope.uniqueId} tbody`)
                .off()
                .on('click', 'tr', (e) => alert('Please Activate ID column'));
            }
          });
      });
    };
    var reload = function () {
      removeTable();
      buildTable();
      return $('.dataTables_filter').parent().remove();
    };
    var exportTable = function (format) {
      const params = {
        export: format,
        timezone: moment.tz.guess(),
        cascade: $scope.reportData.cascade,
        organization_id: $scope.reportData.organization_id,
        'order[0][column]': '0',
        'order[0][dir]': 'asc',
      };

      const preBuildColumns = _.filter($scope.colums, { active: true });

      for (let idx = 0; idx < preBuildColumns.length; idx++) {
        const c = preBuildColumns[idx];

        params[`columns[${idx}][name]`] = c.name;
      }

      SessionService.downloadGmsApiResult(
        `organization/${$scope.reportData.organization_id}/recurring/schedules-table`,
        params,
        `Schedules.${format}`,
        'formdata'
      );
    };
    return init();
  },
});
angular
  .module('idonate.gms.donations')
  .directive('recurringTable', RecurringTable);

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
