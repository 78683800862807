export const CampaignMetrics = {
  name: 'campaignMetrics',
  template: `\
    <div class="v_separator_cols">
      <div class="row">
        <div class="col-12 summary-topbar">
          <interval-container class="float-right input-sm" time-callback="$ctrl.generateMetrics"></interval-container>
          <h2 class="section-title">Summary</h2>
        </div>
        <div class="col-12 summary-figures">
          <div class="row summary-figures-row">
            <div class="col-12 col-md-3 col">
              <gms-changemark percent="$ctrl.totalPercent"></gms-changemark>
              <h3 class="summary-title">All Donations</h3>
              <h1 class="animated fadeIn summary-figure">{{($ctrl.allDonations || 0) | currency:$root.currencyCode:0}}</h1>
              <h3 class="summary-subfigure">Total Donations: {{ $ctrl.totalDonations || 0 }}</h3>
            </div>
            <div class="col-12 col-md-3 col">
              <gms-changemark percent="$ctrl.avgPercent"></gms-changemark>
              <h3 class="summary-title">Avg Donations</h3>
              <h1 class="animated fadeIn summary-figure">{{($ctrl.avgDonations || 0) | currency:$root.currencyCode:0}}</h1>
              <h3 class="summary-subfigure">Range: {{$ctrl.avgRange.low | currency:$root.currencyCode:0}}-{{$ctrl.avgRange.high | currency:$root.currencyCode:0}}</h3>
            </div>
            <div class="col-12 col-md-3 col">
              <gms-changemark percent="$ctrl.visitsPercent"></gms-changemark>
              <h3 class="summary-title">Total Visits</h3>
              <h1 class="animated fadeIn summary-figure">{{($ctrl.allVisits || 0) | number:0}}</h1>
              <h3 class="summary-subfigure">Daily Avg: {{ ($ctrl.avgVisits || 0) | number:0}}</h3>
            </div>
            <div class="col-12 col-md-3 col">
              <gms-changemark percent="$ctrl.conversionsPercent"></gms-changemark>
              <h3 class="summary-title">Total Conversions</h3>
              <h1 class="animated fadeIn summary-figure">{{($ctrl.allConversions || 0) | number:0}}</h1>
              <h3 class="summary-subfigure">Daily Avg: {{($ctrl.avgConversions || 0) | number:0 }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  controller: class {
    /* @ngInject */
    constructor(organizationService, transactionService, DashboardAPI, $q) {
      this.generateMetrics = this.generateMetrics.bind(this);
      this.setCampaignMetrics = this.setCampaignMetrics.bind(this);
      this.transactionService = transactionService;
      this.DashboardAPI = DashboardAPI;
      this.$q = $q;
      this.orgId = organizationService.getCurrentOrganization().id;
    }
    generateMetrics(current, previous) {
      const queryParams = {
        start_date: current.start.toISOString(),
        end_date: current.end.toISOString(),
        previous_start_date: previous.start.toISOString(),
        previous_end_date: previous.end.toISOString(),
        timezone: moment.tz.guess(),
      };
      const transQueryParams = angular.extend(
        {
          organization_id: this.orgId,
          transaction_status: 'complete',
          use_finalized: true,
        },
        queryParams
      );
      this.$q
        .all({
          trans:
            this.transactionService.getTransactionReportSummary(
              transQueryParams
            ),
          dash: this.DashboardAPI.getCampaignSummary(this.orgId, queryParams),
        })
        .then(this.setCampaignMetrics);
    }
    setCampaignMetrics(allResult) {
      const trans = allResult.trans.result;
      const { dash } = allResult;
      const conversionsPercent =
        this.getPercent(
          trans.aggregate.total.average,
          dash.current.total_sessions
        ) /
        this.getPercent(
          dash.previous.total_count,
          dash.previous.total_sessions
        );
      angular.extend(this, {
        totalPercent: Math.round(trans.aggregate_delta.total.sum || 0),
        allDonations: Math.round(trans.aggregate.total.sum),
        totalDonations: trans.aggregate.total.count,
        avgPercent: Math.round(trans.aggregate_delta.total.average || 0),
        avgDonations: trans.aggregate.total.average,
        avgRange: {
          high: trans.aggregate.total.maximum,
          low: trans.aggregate.total.minimum,
        },
        visitsPercent: this.getPercent(
          dash.current.total_sessions,
          dash.previous.total_sessions
        ),
        allVisits: dash.current.total_sessions,
        avgVisits: dash.current.average_sessions,
        allConversions: this.getPercent(
          dash.current.total_sessions,
          trans.aggregate.total.average
        ),
        avgConversions: this.getPercent(
          dash.current.average_sessions,
          trans.aggregate.total.average
        ),
        conversionsPercent,
      });
    }
    getPercent(first, second) {
      if (second !== 0) {
        return first / second;
      } else {
        return 0;
      }
    }
  },
};
