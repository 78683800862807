// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
options: [
  * Group 1
    selectors: [
        name: 'selector1'
        title: 'Selector 1'
      ,
        name: 'selector2'
        title: 'Selector 2'
    ]
    onSelect: selectorFnc
  , # Group 2
    selectors: [
        name: 'selector1'
        title: 'Selector 1'
        perms: ['SuperAdmin']
      ,
        name: 'selector2'
        title: 'Selector 2'
        perms: ['SuperAdmin'] # Optional
    ]
    onSelect: (selectorArg) ->
      return
]
*/

angular.module('idonate.gms').component('idRadioRow', {
  bindings: {
    options: '=',
  },
  template: `\
    <div class="col-md-8 col-12" ng-if="$ctrl.selectors.length > 0">
      <div class="heading-buttons pull-left">
        <div class="btn-group" data-toggle="buttons">
          <label ng-repeat="selector in $ctrl.selectors"
              class="btn {{ $ctrl.active[selector.group] == selector.name ? 'btn-clear' : 'btn-gray'}}"
              ng-click="$ctrl.onSelect(selector)">
            <input type="radio" name="options">{{selector.title}}
          </label>
        </div>
      </div>
    </div>
  `,
  controller: class IdRadioRow {
    /* @ngInject */
    constructor($rootScope, $scope) {
      this.onSelect = this.onSelect.bind(this);
      this.$rootScope = $rootScope;
      $scope.$watch('$ctrl.options', () => {
        this.selectors = this._transformOptions(this.options);
        return (this.active = this._findActive(this.options));
      });
    }
    _transformOptions(options) {
      let newOptions = [];
      _.forEach(options, (group, index) => {
        _.forEach(group.selectors, (selector) => (selector.group = index));
        _.filter(group.selectors, (selector) => {
          if (!selector.perms) {
            return true;
          }
          return this.$rootScope.allowRole(selector.perms);
        });
        return (newOptions = newOptions.concat(group.selectors));
      });
      return newOptions;
    }
    _findActive(groups) {
      const active = [];
      _.forEach(groups, function (group, index) {
        const found = _.find(group.selectors, (selector) => selector.active);
        return (active[index] = found ? found.name : found);
      });
      return active;
    }
    onSelect(selector) {
      this.options[selector.group].onSelect(selector.name);
      return (this.active[selector.group] = selector.name);
    }
  },
});
