// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

angular.module('idonate.gms.legacy-p2p', []).config(($routeProvider) =>
  $routeProvider
    .when('/legacy-p2p', {
      templateUrl: '/scripts/legacy-p2p/all.html',
      controller: 'P2PAllController',
      menu: {
        style: 'icon-peer',
        name: 'Peer-to-Peer',
        level: 1,
        position: 7,
        allowFor: 'allRoles',
        allowFeatureFor: 'p2p',
      },
      reloadOnSearch: false,
      resolve: {
        allAvailableTemplates(p2pService) {
          'ngInject';
          return p2pService.getAllTemplates();
        },
      },
    })
    .when('/legacy-p2p/program/edit/:programId', {
      templateUrl: '/scripts/legacy-p2p/manage-program.html',
      controller: 'P2PManageProgram',
      menu: {
        parent: 'Peer-to-Peer',
        name: 'Manage Program',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'p2p',
      },
      resolve: {
        fullOrgData(organizationService) {
          'ngInject';
          return organizationService.loadFullOrganizationInformation();
        },
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
        programDetails(p2pService, $route) {
          'ngInject';
          return p2pService.getP2PFormData($route.current.params.programId);
        },
        allAvailableTemplates(p2pService) {
          'ngInject';
          return p2pService.getAllTemplates();
        },
      },
    })
    .when('/legacy-p2p/anchor-site-setup', {
      templateUrl: '/scripts/legacy-p2p/anchor-site-setup.html',
      controller: 'AnchorSiteSetup',
      menu: {
        parent: 'Peer-to-Peer',
        name: 'Anchor Site Setup',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'p2p',
      },
      resolve: {
        fullOrgData(organizationService) {
          'ngInject';
          return organizationService.loadFullOrganizationInformation();
        },
      },
    })
    .when('/legacy-p2p/program/:programId', {
      templateUrl: '/scripts/legacy-p2p/p2p-program-details.html',
      controller: 'P2PProgramDetails',
      menu: {
        parent: 'Peer-to-Peer',
        allowFor: 'allRoles',
        allowFeatureFor: 'p2p',
      },
    })
);
