// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.text')
  .controller(
    'SmsKeywordsCtrl',
    function (
      $scope,
      $timeout,
      organizationService,
      organizationDesignations,
      organizationCampaigns,
      organizationKeywords,
      $location,
      $routeParams
    ) {
      let p;
      $scope.campaignId = $routeParams.campaign_id;
      let keywords = organizationKeywords.result.sms_keywords;
      $scope.autoResponderKeywords = [];
      $scope.giveKeywords = [];
      $scope.designations = organizationDesignations.result.designations;
      $scope.campaigns = organizationCampaigns.campaigns;
      $scope.showDisabledItems = false;
      $scope.setCurrent = function (id) {
        const type = _.find($scope.autoResponderKeywords, { id })
          ? 'autoresponder'
          : 'give';
        if (type !== 'autoresponder') {
          return ($scope.current = _.find($scope.giveKeywords, { id }));
        } else {
          return ($scope.current = _.find($scope.autoResponderKeywords, {
            id,
          }));
        }
      };

      const allKeywords = _.map(keywords, function (item) {
        item.campaign = _.find(
          $scope.campaigns,
          (campaign) => item.campaign_id === campaign.id
        );
        return item;
      });

      if ($scope.campaign != null ? $scope.campaign.id : undefined) {
        keywords = _.filter(
          allKeywords,
          (item) => item.campaign_id === $scope.campaign.id
        );
      } else {
        keywords = allKeywords;
      }

      if (!$scope.showDisabledItems) {
        keywords = (() => {
          const result = [];
          for (p of Array.from(keywords)) {
            if (p.is_active) {
              result.push(p);
            }
          }
          return result;
        })();
      }

      $scope.campaign = _.find(
        $scope.campaigns,
        (item) => item.id === $scope.campaignId
      );

      $scope.$watch('campaign', function (a, b) {
        if (a != null && a !== b) {
          $location.search({ campaign_id: a.id });
          return reloadKeywords();
        } else if (!a && b != null) {
          $location.search({});
          return reloadKeywords();
        }
      });

      var reloadKeywords = function () {
        $scope.autoResponderKeywords = [];
        if ($scope.campaign != null ? $scope.campaign.id : undefined) {
          keywords = _.filter(
            allKeywords,
            (item) => item.campaign_id === $scope.campaign.id
          );
        } else {
          keywords = allKeywords;
        }
        $scope.giveKeywords = [];
        angular.forEach(keywords, function (keyword) {
          if (getKeywordType(keyword) === 'give') {
            return $scope.giveKeywords.push(keyword);
          } else {
            return $scope.autoResponderKeywords.push(keyword);
          }
        });
        if (!$scope.showDisabledItems) {
          $scope.giveKeywords = (() => {
            const result1 = [];
            for (p of Array.from($scope.giveKeywords)) {
              if (p.is_active) {
                result1.push(p);
              }
            }
            return result1;
          })();
        }
        return $timeout(() => $scope.$broadcast('reloadTable'), 250);
      };

      var getKeywordType = function (keywordObj) {
        if (
          keywordObj.template_auto_responder === null ||
          keywordObj.campaign_id ||
          keywordObj.designation_id
        ) {
          return 'give';
        } else {
          return 'autoresponder';
        }
      };

      angular.forEach(keywords, function (keyword) {
        if (getKeywordType(keyword) === 'give') {
          return $scope.giveKeywords.push(keyword);
        } else {
          return $scope.autoResponderKeywords.push(keyword);
        }
      });

      $scope.$watch('showDisabledItems', (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return reloadKeywords();
          }
        })
      );

      $scope.editKeyword = function (keywordId, type) {
        if (!type) {
          type = _.find($scope.autoResponderKeywords, { id: keywordId })
            ? 'autoresponder'
            : 'give';
        }
        if (type === 'autoresponder') {
          $location.path(`/text/autoresponder-keyword/${keywordId}`);
        } else {
          $location.path(`/text/giving-keyword/${keywordId}`);
        }
      };

      $scope.tableOptions = {
        outsideSearchId: 'outside-search',
        clickableRowAction: $scope.editKeyword,
      };

      return ($scope.enableDisableKeyword = function (keywordId) {
        const keyword = _.find(keywords, { id: keywordId });
        if (keyword) {
          const _postObject = {};
          _postObject.keyword = keyword.keyword;
          _postObject.id = keyword.id;

          keyword.type = getKeywordType(keyword);

          if (keyword.type === 'give') {
            if (keyword.designation_id) {
              _postObject.designation_id = keyword.designation_id;
            }
            if (keyword.campaign_id) {
              _postObject.campaign_id = keyword.campaign_id;
            }
          } else {
            _postObject.response_template =
              keyword.template_auto_responder.response_template;
          }

          _postObject.is_active = keyword.is_active ? false : true;
          return organizationService
            .updateOrganizationKeyword(_postObject)
            .then(function (data) {
              const rKeyword = data.result.sms_keyword;
              const arrayScope =
                getKeywordType(rKeyword) === 'give'
                  ? $scope.giveKeywords
                  : $scope.autoResponderKeywords;
              return angular.forEach(arrayScope, function (val, key) {
                if (val.id === data.result.sms_keyword.id) {
                  arrayScope[key].is_active = data.result.sms_keyword.is_active;
                  return $scope.$broadcast('reloadTable');
                }
              });
            });
        }
      });
    }
  );
