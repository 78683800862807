// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('textareaField', () => ({
  templateUrl: '/scripts/input/textarea-field.html',
  scope: {
    field: '=field',
  },
  restrict: 'E',
  controller($scope, $timeout, $element) {
    $timeout(() =>
      $scope.$watch('field.disabled', function () {
        if ($scope.field.disabled) {
          return $($element.find('.summernote')).summernote('disable');
        } else {
          return $($element.find('.summernote')).summernote('enable');
        }
      })
    );
    return ($scope.optionsSummerNote = $scope.field.optionsSummerNote || {
      disableDragAndDrop: true,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link']],
      ],
    });
  },
}));
