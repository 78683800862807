import './campaign-details.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class CampaignDetails {
  /* @ngInject */
  constructor(
    $scope,
    $routeParams,
    $uibModal,
    $timeout,
    $location,
    CampaignService,
    organizationService,
    notyService
  ) {
    $scope.campaignForm = {};
    $scope.designationOptions = [];
    $scope.startDatePickerOpts = {
      format: 'YYYY-MM-DD',
      maxView: 'day',
      clearable: false,
    };
    $scope.endDatePickerOpts = {
      format: 'YYYY-MM-DD',
      maxView: 'day',
      clearable: true,
    };

    CampaignService.getCampaign($routeParams.campaignId).then(
      function (campaign) {
        $scope.campaign = campaign;
        $scope.updateCampaignStatus = function () {
          const status = $scope.campaign.is_active;
          if (!status) {
            // if not active
            if (!$scope.hideDisableWarning) {
              return $uibModal.open({
                size: 'md',
                scope: $scope,
                windowClass: 'advocate-modal',
                templateUrl:
                  '/scripts/campaign/campaign-item/confirm-disable-campaign-modal.html',
                controller($scope, $uibModalInstance) {
                  $scope.modalHeader = 'Warning: Campaign has active tools';
                  return ($scope.cancel = function () {
                    // reset campaign active button
                    $scope.campaign.is_active = true;
                    return $uibModalInstance.dismiss('cancel');
                  });
                },
              });
            } else {
              // allow campaign to be disabled if there are no active tools
              return updateCampaignStatus(false, function () {
                notyService.success('Campaign has been disabled');
                return $location.path('/campaigns');
              });
            }
          } else {
            // if active
            return updateCampaignStatus(true, () =>
              notyService.success('Campaign has been enabled')
            );
          }
        };
        var updateCampaignStatus = function (status, cb) {
          const objToPost = {
            id: $scope.campaign.id,
            title: $scope.campaign.title,
            is_active: status,
          };
          return CampaignService.editCampaign(objToPost).then(
            function () {
              $scope.campaign.is_active = status;
              if (cb) {
                return cb();
              }
            },
            () => ($scope.campaign.is_active = !$scope.campaign.is_active)
          );
        };
        const updateDesignations = function () {
          const designations = [];
          angular.forEach($scope.campaign.designations, (desig) =>
            designations.push([desig, desig.title])
          );
          designations.sort((a, b) => a[0].weight - b[0].weight);
          return ($scope.campaignField = {
            value_data_type: 'custom',
            label: 'Designated Fund Label',
            value: designations,
            limit: 9999,
          });
        };
        $scope.removeInProgress = false;
        $scope.reOrderInProgress = false;
        $scope.$watch(
          'campaignField',
          (nv, ov) =>
            $timeout(function () {
              if (ov && ov !== nv && nv.value.length > 0) {
                // update the campaign designations when the list is re-ordered
                // the weight should be the re-ordered index field
                let orderedList = '';
                $scope.campaign.designations = [];
                angular.forEach(nv.value, function (newDesig, newOrder) {
                  newDesig[0].weight = newOrder + 1;
                  let alreadyAdded = false;
                  angular.forEach(
                    $scope.campaign.designations,
                    function (desig) {
                      if (desig.id === newDesig[0].id) {
                        return (alreadyAdded = true);
                      }
                    }
                  );
                  if (!alreadyAdded) {
                    $scope.campaign.designations.push(newDesig[0]);
                    return (orderedList += newDesig[0].id + ',');
                  }
                });
                if (
                  !$scope.removeInProgress &&
                  !$scope.reOrderInProgress &&
                  $scope.campaign.id
                ) {
                  $scope.reOrderInProgress = true;
                  const sendObj = { ids: orderedList.replace(/,\s*$/, '') };
                  return CampaignService.reorderDesignations(
                    $scope.campaign.id,
                    sendObj
                  ).then(function (data) {
                    $scope.reOrderInProgress = false;
                  });
                }
              }
            }),

          true
        );
        $scope.$watch('addDesignation', function (desingation) {
          if (_.isArray(desingation) && _.isObject(desingation[0])) {
            if (
              !_.find($scope.campaign.designations, { id: desingation[0].id })
            ) {
              $scope.campaign.designations.push(desingation[0]);
              if ($scope.campaign.id) {
                CampaignService.addDesignationToCampaign($scope.campaign.id, {
                  designation_id: desingation[0].id,
                });
              }
            }
          }
          $timeout(function () {
            $scope.addDesignation = '';
            return updateDesignations();
          });
        });

        $scope.onRemove = function (item) {
          $scope.removeInProgress = true;
          const { id } = item.title;
          angular.forEach($scope.campaign.designations, function (val, $i) {
            if (val.id === id) {
              $scope.campaign.designations.splice($i, 1);
              if ($scope.campaign.id) {
                return CampaignService.removeDesignationFromCampaign(
                  $scope.campaign.id,
                  id
                ).then((data) => ($scope.removeInProgress = false));
              }
            }
          });
        };
        $timeout(
          () =>
            $scope.$watch('campaign.startDate', function (a, b) {
              if (a && a !== b) {
                const mindate = a.split('-').map(Number);
                return ($scope.endDatePickerOpts.min = new Date(
                  mindate[0],
                  mindate[1] - 1,
                  mindate[2]
                ));
              } else {
                return ($scope.endDatePickerOpts.min = undefined);
              }
            }),

          750
        );
        $scope.$watchCollection('campaign', function (nv) {
          if (!nv) {
            return;
          }
          if ($scope.campaignForm != null) {
            if (nv.title && nv.goal && nv.startDate) {
              return ($scope.campaignForm.$pristine = false);
            } else {
              return ($scope.campaignForm.$pristine = true);
            }
          }
        });
        CampaignService.getDesignations().then(function (result) {
          $scope.designationOptions = _.filter(result.result.designations, {
            is_active: true,
          });
        });
        $timeout(
          function () {
            $scope.campaign.endDate = $scope.campaign.end_date
              ? moment.utc($scope.campaign.end_date)
              : null;
            return ($scope.campaign.startDate = $scope.campaign.start_date
              ? moment.utc($scope.campaign.start_date)
              : moment());
          },
          //$scope.campaignForm.$setPristine()
          250
        );
        $scope.loading = false;
        $scope.hideDisableWarning = true;
        if ($scope.campaign.id && $scope.campaign.id !== 'new') {
          CampaignService.getCampaignActiveTools($scope.campaign.id).then(
            function (activeTools) {
              activeTools = activeTools;
              return angular.forEach(activeTools, function (tool) {
                if ($scope.hideDisableWarning && Object.keys(tool).length > 0) {
                  return ($scope.hideDisableWarning = false);
                }
              });
            }
          );
        }
      }
    );
    $scope.saveCampaign = function () {
      $scope.loading = true;

      $scope.campaign.start_date = moment
        .utc($scope.campaign.startDate)
        .format('YYYY-MM-DD');
      if (!$scope.campaign.startDate) {
        $scope.campaign.start_date = 'null';
      }

      if ($scope.campaign.endDate) {
        $scope.campaign.end_date = moment
          .utc($scope.campaign.endDate)
          .format('YYYY-MM-DD');
      }
      if (!$scope.campaign.endDate) {
        $scope.campaign.end_date = 'null';
      }

      if ($scope.campaign.id && $scope.campaign.id !== 'new') {
        return CampaignService.editCampaign($scope.campaign).then(
          (data) => ($scope.loading = false)
        );
      } else {
        return CampaignService.addOrganizationCampaign($scope.campaign).then(
          function (data) {
            angular.forEach($scope.campaign.designations, (val, $i) =>
              CampaignService.addDesignationToCampaign(data.campaign.id, {
                designation_id: val.id,
              })
            );
            angular.forEach($scope.campaign.gifts, (val, $i) =>
              organizationService.postCampaignGift(data.campaign.id, {
                gift_id: val.id,
              })
            );
            angular.forEach($scope.campaign.tribute_definitions, (val, $i) =>
              organizationService.postOrganizationCampaignTributeDefinitions(
                data.campaign.id,
                val.id
              )
            );
            $scope.loading = false;
            notyService.success('A campaign has been created successfully.');
            $location.path('/campaigns');
          },
          (error) => ($scope.loading = false)
        );
      }
    };
  }
}

angular.module('idonate.gms.campaign').component('campaignDetails', {
  templateUrl: '/scripts/campaign/campaign-item/campaign-details.html',
  controller: CampaignDetails,
});
