// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular
  .module('idonate.gms.donations')
  .directive('gmsCurrency', ($filter, $rootScope) => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      const format = $filter('currency');

      ngModel.$parsers.unshift(function (value) {
        value = `${value}`;
        if (value) {
          let number = value.replace($rootScope.currencyCode, '');
          number = number.replace(new RegExp(',', 'g'), '');
          number = number.replace('.00', '');
          if (isNaN(number)) {
            return 0;
          } else {
            number = parseFloat(number);
            if (number > 0) {
              return number;
            } else {
              return 0;
            }
          }
        } else {
          return 0;
        }
      });

      ngModel.$formatters.unshift(function (value) {
        const _formattedModel = format(value, $rootScope.currencyCode);
        return _formattedModel;
      });

      element.blur('blur', function () {
        const _actualValue = element.val();
        const _actualValueSplit = `${_actualValue}`.split('.');
        if (
          (_actualValueSplit[1] != null
            ? _actualValueSplit[1].length
            : undefined) === 1
        ) {
          return element.val(`${_actualValue}0`);
        } else if (_actualValueSplit.length === 1) {
          return element.val(`${_actualValue}.00`);
        } else if (
          (_actualValueSplit[1] != null
            ? _actualValueSplit[1].length
            : undefined) === 0
        ) {
          return element.val(`${_actualValue}00`);
        }
      });

      return element.bind('keyup', function (event) {
        const _actualValue = element.val();
        const _actualValueSplit = `${_actualValue}`.split('.');
        if (
          `${_actualValue}`.slice(-1) === '.' &&
          _actualValueSplit.length === 2
        ) {
          return;
        } else if (`${_actualValue}`.length === 5 && event.which === 190) {
          element.val(`${$rootScope.currencyCode}0.`);
          return;
        } else if (
          _actualValueSplit.length === 2 &&
          _actualValueSplit[1].length >= 1 &&
          _actualValueSplit[1].length < 3 &&
          event.which === 48
        ) {
          return;
        } else {
          let formattedModel = format(ngModel.$modelValue);
          formattedModel = formattedModel.replace('.00', '');
          if (
            `${formattedModel}`.slice(-1) === '0' &&
            `${formattedModel}`.split('.').length === 2
          ) {
            formattedModel = formattedModel.slice(0, -1);
          }
          if (formattedModel !== ngModel.$viewValue) {
            return element.val(formattedModel);
          }
        }
      });
    },
  }));
