// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').component('gatewaySettings', {
  templateUrl: '/scripts/organization/gateway-settings.html',
  controller($scope, $timeout, organizationService) {
    //Service calls
    $scope.gateways = [
      { id: 'none', name: 'None' },
      { id: 'redirect', name: 'Redirect' },
      { id: 'vanco', name: 'Vanco' },
      { id: 'spreedly', name: 'Spreedly' },
    ];
    $scope.frequencies = [
      { id: 'NONE', name: 'None' },
      { id: 'once', name: 'One Time' },
      { id: 'weekly', name: 'Weekly' },
      { id: 'monthly', name: 'Monthly' },
      { id: 'quarterly', name: 'Quarterly' },
      { id: 'semiannually', name: 'Semi-Annually' },
      { id: 'annually', name: 'Annually' },
    ];
    $scope.organizationData = {};
    $scope.currentOrganization = organizationService.getCurrentOrganization();
    organizationService
      .getOrganizationPaymentGateways()
      .then((result) => ($scope.availableGateways = result.result.gateways));
    organizationService
      .loadFullOrganizationInformation()
      .then((result) => angular.extend($scope.organizationData, result.result));
    organizationService
      .getOrganizationInstalledGateways()
      .then(function (result) {
        $scope.installedGateways = result.result.installed_gateways;
        $scope.spreedlyCredentials = {};
        $scope.spreedlyInstalledGateways = {};
        return angular.forEach(
          $scope.installedGateways,
          (val, k) =>
            ($scope.spreedlyInstalledGateways[val.id] = val.is_enabled)
        );
      });
    $scope.spreedlyCredentialsToUpdate = {};
    $scope.showInstallGatewayBoxVar = false;
    $scope.gatewayToUpdate = {};
    $scope.gatewayToUpdateBox = false;

    //Local scopes
    $scope.disableSaveBtn = [];
    $scope.disabledChange = [];
    $scope.disabledChange['saveCashOptionsWidget'] = true;
    $scope.disabledChange['spreedlyWidget'] = true;

    $scope.optionsSummerNote = {
      disableDragAndDrop: true,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['picture']],
      ],
    };

    const showInstallGatewayBtn = () => true;

    $scope.showInstallGatewayBtn = showInstallGatewayBtn();

    //Watches
    $scope.$watch('organizationData.attributes.config.cash.gateway', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['saveCashOptionsWidget'] = false);
        }
      })
    );

    $scope.$watch(
      'organizationData.attributes.config.cash.debit_only',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange['saveCashOptionsWidget'] = false);
          }
        })
    );
    $scope.$watch(
      'organizationData.attributes.config.disable_receipts',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange['saveCashOptionsWidget'] = false);
          }
        })
    );

    $scope.$watch(
      'organizationData.attributes.config.cash.payment.default_recurring_schedule',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange['saveCashOptionsWidget'] = false);
          }
        })
    );

    $scope.$watch('spreedlySelectedGatewayType', function (a, b) {
      if (a !== undefined && a !== '') {
        $scope.spreedlySelectedGateway = _.find($scope.availableGateways, {
          gateway_type: a,
        });
        $scope.spreedlyCredentials = {};
        if (_.isEmpty($scope.spreedlyInstalledGateways)) {
          $scope.disabledChange['spreedlyWidget'] = true;
        } else {
          $scope.disabledChange['spreedlyWidget'] = false;
        }
        return;
      }
    });

    $scope.$watchCollection('spreedlyCredentials', function (a, b) {
      if (a !== undefined && !_.isEmpty(a)) {
        $scope.disabledChange['spreedlyWidget'] = false;
        return;
      }
    });

    $scope.$watchCollection('spreedlyCredentialsToUpdate', function (a, b) {
      if (a !== undefined && !_.isEmpty(a)) {
        return ($scope.disabledChange['spreedlyWidget'] = false);
      }
    });

    $scope.$watchCollection('spreedlyInstalledGateways', function (a, b) {
      if (a !== undefined && !_.isEmpty(a)) {
        $scope.disabledChange['spreedlyWidget'] = false;
        angular.forEach(a, (v, k) =>
          angular.forEach($scope.installedGateways, function (val, key) {
            if ($scope.installedGateways[key].id === k) {
              return ($scope.installedGateways[key].is_enabled = v);
            }
          })
        );
        $scope.showInstallGatewayBtn = showInstallGatewayBtn();
        return;
      }
    });

    //Functionality to validate fields
    const validate = function (requiredFields) {
      const validateInputs = requiredFields || [];
      const validation = [];
      let validationResult = true;

      //Empty fields Validation
      angular.forEach(validateInputs, function (obj, $i) {
        validation[$i] = true; //Set the input as true by default
        $scope[obj.errorClassModel] = false;

        //Check if the object is required
        if (obj.required === true) {
          const localString = String(obj.content);
          if (
            localString === undefined ||
            localString === null ||
            localString.trim().length === 0
          ) {
            validation[$i] = false;
          }
        }

        //Check if the object has some special validation
        if (obj.type) {
          switch (obj.type) {
            case 'email':
              var pattern =
                /^[a-zA-Z0-9\-]{1}([\.\_\-]{0,}[a-zA-Z0-9\-]{1}){0,}@[a-zA-Z0-9]{1,}([\-]{0,}[a-zA-Z0-9]{1,}){0,}([\.]{1}[a-zA-Z0-9]{1,}){1,}$/;
              if (obj.content && pattern.test(obj.content) === false) {
                return (validation[$i] = false);
              }
              break;
            case 'url':
              pattern =
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
              if (obj.content && pattern.test(obj.content) === false) {
                return (validation[$i] = false);
              }
              break;
            case 'number':
              pattern = /^[0-9]{1,}$/;
              if (obj.content && pattern.test(obj.content) === false) {
                return (validation[$i] = false);
              }
              break;
          }
        }
      });

      //Seek in the array for errors
      let counter = 0;
      while (counter < validation.length) {
        if (validation[counter] === false) {
          validationResult = false;
          $scope[validateInputs[counter].errorClassModel] = true;
        }
        counter++;
      }

      return validationResult;
    };

    //Save cash options functionality
    $scope.saveCashOptionsWidget = function () {
      $scope.disableSaveBtn['saveCashOptionsWidget'] = true;
      const postValues = {
        config: {
          cash: {
            gateway:
              $scope.organizationData.attributes.config.cash !== undefined
                ? $scope.organizationData.attributes.config.cash.gateway
                : undefined,
            default_frequency:
              $scope.organizationData.attributes.config.cash !== undefined
                ? $scope.organizationData.attributes.config.cash
                    .default_frequency
                : undefined,
            debit_only:
              $scope.organizationData.attributes.config.cash !== undefined
                ? $scope.organizationData.attributes.config.cash.debit_only
                : undefined,
            payment: {
              default_recurring_schedule:
                $scope.organizationData.attributes.config.cash.payment !==
                undefined
                  ? $scope.organizationData.attributes.config.cash.payment
                      .default_recurring_schedule
                  : undefined,
            },
          },
          disable_receipts:
            $scope.organizationData.attributes.config !== undefined
              ? $scope.organizationData.attributes.config.disable_receipts
              : undefined,
        },
      };
      organizationService.updateAttributes(postValues).then(function (data) {
        $scope.disableSaveBtn['saveCashOptionsWidget'] = false;
        return ($scope.disabledChange['saveCashOptionsWidget'] = true);
      });
    };

    $scope.setDefaultGateway = function (id) {
      const objToUpdate = _.find($scope.installedGateways, { id });
      if (objToUpdate && !objToUpdate.is_default) {
        objToUpdate.is_default = true;
        objToUpdate.gateway_id = objToUpdate.id;
        return organizationService
          .updateOrganizationInstalledGateway(objToUpdate)
          .then(
            (data) =>
              angular.forEach($scope.installedGateways, function (v, k) {
                if (v.id === objToUpdate.id) {
                  return ($scope.installedGateways[k].is_default = true);
                } else {
                  return ($scope.installedGateways[k].is_default = false);
                }
              }),

            (error) => (objToUpdate.is_default = false)
          );
      }
    };

    $scope.showInstallGatewayBox = function () {
      $scope.showInstallGatewayBoxVar = true;
      $scope.gatewayToUpdateBox = false;
      $scope.spreedlyCredentialsToUpdate = {};
      return ($scope.gatewayToUpdate = {});
    };

    $scope.updatePaymentGateway = function (gateway) {
      $scope.showInstallGatewayBoxVar = false;
      return organizationService
        .getInstalledPaymentGateway(gateway.id)
        .then(function (data) {
          $scope.gatewayToUpdateBox = true;
          $scope.gatewayToUpdate = data.result.gateway;
          if (
            ($scope.gatewayToUpdate != null
              ? $scope.gatewayToUpdate.credentials.length
              : undefined) === 0
          ) {
            return ($scope.disabledChange['spreedlyWidget'] = false);
          }
        });
    };

    return ($scope.saveSpreedlyWidget = function () {
      let _objToPost;

      if (!_.isEmpty($scope.spreedlyInstalledGateways)) {
        angular.forEach($scope.spreedlyInstalledGateways, function (k, $i) {
          $scope.disableSaveBtn['spreedlyWidget'] = true;
          return organizationService
            .updateOrganizationInstalledGateway({
              gateway_id: $i,
              name: _.find($scope.installedGateways, { id: $i }).name,
              is_enabled: k,
            })
            .then(
              function (data) {
                $scope.disableSaveBtn['spreedlyWidget'] = false;
                return ($scope.disabledChange['spreedlyWidget'] = true);
              },
              (error) => ($scope.disableSaveBtn['spreedlyWidget'] = false)
            );
        });
      }
      if (
        !_.isEmpty($scope.spreedlyCredentials) ||
        ($scope.spreedlySelectedGateway != null
          ? $scope.spreedlySelectedGateway.credentials.length
          : undefined) === 0
      ) {
        $scope.disableSaveBtn['spreedlyWidget'] = true;
        _objToPost = angular.copy($scope.spreedlyCredentials);
        _objToPost.gateway_type = $scope.spreedlySelectedGateway.gateway_type;
        _objToPost.auth_mode = 'default';

        organizationService.installPaymentGateway(_objToPost).then(
          function (data) {
            $scope.installedGateways.push(data.result.gateway);
            $scope.disableSaveBtn['spreedlyWidget'] = false;
            $scope.spreedlySelectedGatewayType =
              $scope.availableGateways[0].gateway_type;
            $scope.showInstallGatewayBtn = showInstallGatewayBtn();
            $scope.showInstallGatewayBoxVar = false;
            return ($scope.disabledChange['spreedlyWidget'] = true);
          },
          (error) => ($scope.disableSaveBtn['spreedlyWidget'] = false)
        );
      }
      if (
        (($scope.gatewayToUpdate != null
          ? $scope.gatewayToUpdate.id
          : undefined) &&
          !_.isEmpty($scope.spreedlyCredentialsToUpdate)) ||
        (($scope.gatewayToUpdate != null
          ? $scope.gatewayToUpdate.id
          : undefined) &&
          ($scope.gatewayToUpdate != null
            ? $scope.gatewayToUpdate.credentials.length
            : undefined) === 0)
      ) {
        $scope.disableSaveBtn['spreedlyWidget'] = true;
        _objToPost = angular.copy($scope.spreedlyCredentialsToUpdate);
        _objToPost.gateway_id = $scope.gatewayToUpdate.id;
        return organizationService
          .updateOrganizationInstalledGateway(_objToPost)
          .then(
            function (data) {
              $scope.disableSaveBtn['spreedlyWidget'] = false;
              $scope.gatewayToUpdateBox = false;
              $scope.spreedlyCredentialsToUpdate = {};
              $scope.gatewayToUpdate = {};
              return ($scope.disabledChange['spreedlyWidget'] = true);
            },
            function (error) {
              $scope.disableSaveBtn['spreedlyWidget'] = false;
              return ($scope.disabledChange['spreedlyWidget'] = false);
            }
          );
      }
    });
  },
});
