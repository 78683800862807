// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ScatterChartCtrl;
let uniqueId = 0;

angular.module('idonate.gms').component('scatterChart', {
  template: `\
<div id="chartjs-tooltip{{$ctrl.uniqueId}}" class="chartjs-tooltip"></div>
<div class="row">
  <div class="col-12">
    <canvas id="scatter-chart"
            height="250"
            class="chart chart-line"
            ng-if="$ctrl.chart.data.length > 0"
            chart-options="$ctrl.options"
            chart-data="$ctrl.chart.data"
            chart-series="$ctrl.chart.series"
            chart-labels="$ctrl.chart.labels"
            chart-legend="false"
            chart-dataset-override="$ctrl.chart.override"
            chart-colors="$ctrl.chart.colors">
    </canvas>
    <span ng-if="$ctrl.chart.data.length == 0">No Data</span>
  </div>
</div>\
`,
  bindings: {
    data: '<',
    donations: '<',
  },
  controller: (ScatterChartCtrl = class ScatterChartCtrl {
    /* @ngInject */
    constructor($filter, $rootScope) {
      this.customLabel = this.customLabel.bind(this);
      this.customTooltip = this.customTooltip.bind(this);
      this.$onChanges = this.$onChanges.bind(this);
      this.currencyFormat = this.currencyFormat.bind(this);
      this.avgDonation = this.avgDonation.bind(this);
      this.$filter = $filter;
      this.$rootScope = $rootScope;
      this.uniqueId = uniqueId++;
      document.addEventListener('mousemove', (e) => {
        const elem = document.getElementById(`chartjs-tooltip${this.uniqueId}`);
        const el = angular.element(elem);
        return el.css({
          left: `${e.pageX - 245}px`,
        });
      });

      this.options = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                maxTicksLimit: 10,
                beginAtZero: true,
                display: true,
                callback: (value, index, values) => {
                  return this.currencyFormat(value);
                },
              },
              gridLines: {
                zeroLineColor: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                zeroLineColor: 'rgba(0, 0, 0, 0)',
              },
              type: 'linear',
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'single',
          enabled: false,
          custom: this.customTooltip,
          callbacks: {
            labelColor() {
              return {
                backgroundColor: '#fff',
                borderColor: 'rgb(241, 107, 130)',
              };
            },
            label: this.customLabel,
          },
        },
      };
    }

    customLabel(tooltipItem, data) {
      return `${
        (this.data.datasets[tooltipItem.datasetIndex].meta != null
          ? this.data.datasets[tooltipItem.datasetIndex].meta.name
          : undefined) ||
        (this.data.datasets[tooltipItem.datasetIndex].meta != null
          ? this.data.datasets[tooltipItem.datasetIndex].meta.advocate_name
          : undefined) ||
        this.data.datasets[tooltipItem.datasetIndex].label
      }:${tooltipItem.yLabel}`;
    }
    customTooltip(tooltip) {
      if (!tooltip) {
        return;
      }
      let el = document.getElementById(`chartjs-tooltip${this.uniqueId}`);
      el = angular.element(el);
      if (!tooltip || !tooltip.body) {
        el.css({ opacity: 0 });
        return;
      }
      if (tooltip.body) {
        const tooltipBody = tooltip.body[0].lines[0];

        //Breaking up string, organization name could have ':' in it.
        let firstPart = tooltipBody.substring(
          0,
          tooltipBody.lastIndexOf(':') + 1
        );
        if (firstPart.substring(firstPart.length - 1) === ':') {
          firstPart = firstPart.substring(0, firstPart.length - 1);
        }
        const lastPart = tooltipBody.substring(
          tooltipBody.lastIndexOf(':') + 1,
          tooltipBody.length
        );

        const innerHtml = `\
<div>
  <div>
    <b>${firstPart}</b>
  </div>
  <div class="text-muted">
    ${this.currencyFormat(lastPart, true)} donated
  </div>
  <div class="text-muted">
    ${this.avgDonation(lastPart, tooltip.title)} average
  </div>
  <div class="text-muted">
    ${tooltip.title} donations
  </div>
</div>\
`;
        el.html(innerHtml);
        const pTop = tooltip.y - tooltip.yPadding * 2 - 48;
        const css = {
          opacity: 1,
          top: `${pTop}px`,
          fontFamily: tooltip.fontFamily,
          background: '#fff',
          color: '#000',
          fontSize: 12,
          padding: '12px 15px',
          borderRadius: '3px',
          fontStyle: tooltip.fontStyle,
        };
        if (parseInt(lastPart) < this.bound.lower) {
          el.removeClass('tooltip-green tooltip-yellow');
          el.addClass('tooltip-red');
          css.border = '2px solid #F16B82';
          el.css(css);
        } else if (parseInt(lastPart) < this.bound.upper) {
          el.removeClass('tooltip-red tooltip-green');
          el.addClass('tooltip-yellow');
          css.border = '2px solid #F6D879';
          el.css(css);
        } else {
          el.removeClass('tooltip-red tooltip-yellow');
          el.addClass('tooltip-green');
          css.border = '2px solid #40BEB3';
          el.css(css);
        }
        return;
      }
    }
    $onChanges(changes) {
      let item, key;
      this.chart = {
        data: [],
        labels: [],
        colors: [],
        series: [],
        override: [{ showLine: false }],
      };
      if (!angular.isDefined(this.data)) {
        return;
      }
      this.chart.labels = this.data.labels;
      let points = [];
      for (key = 0; key < this.data.datasets.length; key++) {
        item = this.data.datasets[key];
        points.push(_.max(item.data));
      }
      points = _.sortBy(points);
      this.bound = {
        lower: points[Math.round(points.length / 3)],
        upper: points[Math.round((points.length / 3) * 2)],
      };
      for (key = 0; key < this.data.datasets.length; key++) {
        item = this.data.datasets[key];
        for (let val of Array.from(item.data)) {
          if (val !== null) {
            const red = '#F16B82';
            const yellow = '#F6D879';
            const green = '#40BEB3';
            // Red shall represent Good: lowest 30% of total dollars raised
            // Yellow shall represent Great: 31-69%
            // Green shall represent Best: Top 30% of total dollars raised
            let color = null;
            if (val < this.bound.lower) {
              color = red;
            } else if (val < this.bound.upper) {
              color = yellow;
            } else {
              color = green;
            }
            this.chart.colors.push(color);
            if (this.data.datasets.length === 1) {
              this.chart.colors.push(color);
            }
          }
        }
        this.chart.data.push(item.data);
      }
    }
    currencyFormat(num, ignore) {
      if (!ignore) {
        num = Math.round(parseInt(num));
        return this.$filter('currency')(num, this.$rootScope.currencyCode, 0);
      } else {
        return this.$filter('currency')(num, this.$rootScope.currencyCode);
      }
    }
    avgDonation(total, count) {
      total = parseInt(total);
      count = parseInt(count);
      if (count === 0) {
        return this.currencyFormat(0, true);
      } else if (count === 1) {
        return this.currencyFormat(total, true);
      } else {
        return this.currencyFormat(total / count);
      }
    }
  }),
});
