import './main.html';

export class ReportingCtrl {
  static templateUrl = '/scripts/reporting/main.html';
  /* @ngInject */
  constructor($scope, $timeout, SessionService, appState) {
    function loadFromState() {
      const user = {};
      const org = {};
      $scope.apiAuthorization = `Bearer ${SessionService.getCurrentSessionKey()}`;
      if (appState.currentUser.contact) {
        Object.assign(user, appState.currentUser.contact);
      }
      if (appState.currentOrganization) {
        Object.assign(org, appState.currentOrganization);
      }
      $scope.orgInfo = org;
      $scope.userInfo = user;

      $scope.disableReporting = !$scope.orgInfo.features.includes('reporting');
    }

    loadFromState();
  }
}
