// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular
  .module('idonate.gms')
  .factory('emailMarketingService', (SessionService) => ({
    getEmailCampaigns(organizationId) {
      return SessionService.restangularGmsApi
        .all(`email_marketing/${organizationId}/campaigns`)
        .customGET();
    },

    getMailingLists(organizationId) {
      return SessionService.restangularGmsApi
        .all(`email_marketing/${organizationId}/lists`)
        .customGET();
    },

    getMailingListRules() {
      return SessionService.restangularGmsApi
        .all('email_marketing/rules')
        .customGET();
    },

    addOrganizationMailingListRule(organizationId, data) {
      return SessionService.restangularGmsApi
        .all(`email_marketing/${organizationId}/rules`)
        .post(data);
    },

    removeOrganizationMailingListRule(organizationId, mailingListRuleId) {
      return SessionService.restangularGmsApi
        .all(`email_marketing/${organizationId}/rules/${mailingListRuleId}`)
        .customDELETE();
    },

    getOrganizationMailingListRules(organizationId) {
      return SessionService.restangularGmsApi
        .all(`email_marketing/${organizationId}/rules`)
        .customGET();
    },
  }));
