// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.text')
  .controller(
    'SmsSettingsCtrl',
    function (
      $scope,
      SessionService,
      smsNumbers,
      organizationService,
      organizationAttributes,
      $timeout,
      appState
    ) {
      //Default Vars
      $scope.formBoxes = [];
      $scope.orgSmsNumbers = smsNumbers.result.sms_numbers;
      $scope.selectedNumber = '';
      $scope.disableSaveBtn = [];
      $scope.disabledChange = [];
      $scope.disabledChange['smsSocial'] = true;
      $scope.disableSaveBtn['smsSocial'] = false;

      $scope.organizationAttributes = organizationAttributes.result.attributes;

      //Watch for changes on social
      $scope.$watch(
        'organizationAttributes.sms.social_sharing.twitter',
        (a, b) =>
          $timeout(function () {
            if (a !== b) {
              return ($scope.disabledChange['smsSocial'] = false);
            }
          })
      );
      $scope.$watch(
        'organizationAttributes.sms.social_sharing.facebook',
        (a, b) =>
          $timeout(function () {
            if (a !== b) {
              return ($scope.disabledChange['smsSocial'] = false);
            }
          })
      );
      $scope.$watch(
        'organizationAttributes.sms.social_sharing.facebook_link',
        (a, b) =>
          $timeout(function () {
            if (a !== b) {
              return ($scope.disabledChange['smsSocial'] = false);
            }
          })
      );
      $scope.$watch(
        'organizationAttributes.sms.social_sharing.sms_message',
        (a, b) =>
          $timeout(function () {
            if (a !== b) {
              return ($scope.disabledChange['smsSocial'] = false);
            }
          })
      );
      $scope.$watch(
        'organizationAttributes.sms.social_sharing.email_subject',
        (a, b) =>
          $timeout(function () {
            if (a !== b) {
              return ($scope.disabledChange['smsSocial'] = false);
            }
          })
      );
      $scope.$watch(
        'organizationAttributes.sms.social_sharing.email_body',
        (a, b) =>
          $timeout(function () {
            if (a !== b) {
              return ($scope.disabledChange['smsSocial'] = false);
            }
          })
      );

      //----- BEGIN BUY A NUMBER BOX ----
      $scope.formBoxes['buyNumber'] = {
        disabled: true,
        btnState: 'disabled',
        actionTitle: '',
      };

      //Disable the box to prevent the user purchase more numbers when the org already has numbers
      if ($scope.orgSmsNumbers.length === 0) {
        $scope.formBoxes['buyNumber'].disabled = false;
      }

      $scope.refreshSelect = function (search) {
        if (!search) {
          return;
        }
        const query = SessionService.restangularGmsApi.one(
          `organization/${appState.currentOrganization.id}/sms/available-numbers`
        );
        const promise = query.get({ search_term: search });
        return promise.then((result) => ($scope.phoneNumbers = result.result));
      };

      //Watchers
      $scope.$watch('selectedNumber', function (val) {
        if (val && _.isObject(val)) {
          return ($scope.formBoxes['buyNumber'].btnState = 'active');
        }
      });

      //Actions
      $scope.buyNumber = function () {
        if (_.isObject($scope.selectedNumber)) {
          $scope.formBoxes['buyNumber'].btnState = 'loading';
          return organizationService
            .buyOrganizationNumber({
              phone_number: $scope.selectedNumber.phone_number,
            })
            .then(
              function (data) {
                //TODO add the obj returned by the api to orgSmsNumbers array
                $scope.orgSmsNumbers.push({
                  friendly_name: data.result.friendly_name,
                  phone_number: $scope.selectedNumber.phone_number,
                });
                $scope.formBoxes['buyNumber'].disabled = true;
                return ($scope.formBoxes['buyNumber'].btnState = 'disabled');
              },
              (error) => ($scope.formBoxes['buyNumber'].btnState = 'active')
            );
        }
      };

      //----- END BUY A NUMBER BOX ----

      //Functionality for the saveSMSSocialWidget form post
      return ($scope.saveSMSSocialWidget = function () {
        $scope.disabledChange['smsSocial'] = true;
        $scope.disableSaveBtn['smsSocial'] = true;

        const postValues = {
          sms: {
            social_sharing: {
              twitter:
                $scope.organizationAttributes.sms.social_sharing !== undefined
                  ? $scope.organizationAttributes.sms.social_sharing.twitter
                  : undefined,
              facebook:
                $scope.organizationAttributes.sms.social_sharing !== undefined
                  ? $scope.organizationAttributes.sms.social_sharing.facebook
                  : undefined,
              facebook_link:
                $scope.organizationAttributes.sms.social_sharing !== undefined
                  ? $scope.organizationAttributes.sms.social_sharing
                      .facebook_link
                  : undefined,
              sms_message:
                $scope.organizationAttributes.sms.social_sharing !== undefined
                  ? $scope.organizationAttributes.sms.social_sharing.sms_message
                  : undefined,
              email_subject:
                $scope.organizationAttributes.sms.social_sharing !== undefined
                  ? $scope.organizationAttributes.sms.social_sharing
                      .email_subject
                  : undefined,
              email_body:
                $scope.organizationAttributes.sms.social_sharing !== undefined
                  ? $scope.organizationAttributes.sms.social_sharing.email_body
                  : undefined,
            },
          },
        };

        return organizationService
          .updateAttributes(postValues)
          .then(function (data) {
            $scope.disabledChange['smsSocial'] = true;
            return ($scope.disableSaveBtn['smsSocial'] = false);
          });
      });
    }
  );
