angular.module('idonate.gms').directive('elipseExpand', () => ({
  restrict: 'A',
  scope: {
    elipseExpand: '=',
    limit: '@',
  },
  controller($scope, $element, $filter) {
    let shortText;
    if ($scope.elipseExpand && $scope.elipseExpand.length > $scope.limit) {
      shortText =
        $filter('limitTo')($scope.elipseExpand, $scope.limit - 3) + '...';
    } else {
      shortText = $scope.elipseExpand;
    }
    $element.text(shortText);
    $element.on('mouseenter', () => $element.text($scope.elipseExpand));
    $element.on('mouseleave', () => $element.text(shortText));
    $scope.$watch('elipseExpand', function (n) {
      if (n) {
        if (n.length > $scope.limit) {
          shortText = $filter('limitTo')(n, $scope.limit - 3) + '...';
        } else {
          shortText = $scope.elipseExpand;
        }
        $element.text(shortText);
      }
    });
  },
}));
