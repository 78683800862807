// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').component('dashboardIndicators', {
  templateUrl: '/scripts/dashboard/dashboard-indicators.html',
  bindings: {
    dateRange: '=?',
  },
  controller(
    $scope,
    $location,
    CampaignAPI,
    DashboardAPI,
    organizationService
  ) {
    const getPercent = function (first, second) {
      if (second !== 0) {
        return (first - second) / second;
      } else {
        return undefined;
      }
    };
    const getConversion = function (first, second) {
      if (second !== 0) {
        return first / second;
      } else {
        return 0;
      }
    };
    const getConversionPercent = function (
      c_initial_count,
      c_total_sessions,
      p_initial_count,
      p_total_sessions
    ) {
      if (c_total_sessions !== 0) {
        if (p_total_sessions !== 0) {
          let a = c_initial_count / c_total_sessions;
          let b = p_initial_count / p_total_sessions;
          let c = a - b;
          return c;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    };
    this.$onInit = function () {
      const organizationID = organizationService.getCurrentOrganization().id;
      const populate = (dateRange) => {
        const query = {
          start_date: dateRange.current.start.toISOString(),
          end_date: dateRange.current.end.toISOString(),
          previous_start_date: dateRange.previous.start.toISOString(),
          previous_end_date: dateRange.previous.end.toISOString(),
          plot_by: 'day',
          timezone: moment.tz.guess(),
        };
        const dateDiff = dateRange.current.end.diff(
          dateRange.current.start,
          'days'
        );
        this.dateFmt = 'MM-DD-YYYY';
        if (dateDiff > 45) {
          query['plot_by'] = 'month';
          this.dateFmt = "MMM 'YY";
        }
        DashboardAPI.getTransactionChart(query).then(this.setSummaryChart);
        return DashboardAPI.getSummary(query).then(this.setSummary, () => {});
      };

      $scope.$watch(() => {
        return this.dateRange;
      }, populate);
    };
    this.view = (link) => $location.path(link).search({});
    this.setSummary = (result) => {
      const { current } = result;
      const { previous } = result;

      // setting default values because backend may not provide them if there are no recurring donations made (within the date range)
      // this doesn't apply to other fields of total_sessions

      if (!current['recurring']) {
        current['recurring'] = {
          total_value: 0,
          total_count: 0,
        };
      }
      if (!previous['recurring']) {
        previous['recurring'] = {
          total_value: 0,
          total_count: 0,
        };
      }

      this.summary = {
        traffic: {
          figure: current.total_sessions,
          percent: getPercent(current.total_sessions, previous.total_sessions),
          label: 'Traffic',
          sublabel: 'Visits',
        },
        conversion: {
          figure: getConversion(current.initial_count, current.total_sessions),
          percent: getConversionPercent(
            current.initial_count,
            current.total_sessions,
            previous.initial_count,
            previous.total_sessions
          ),
          label: 'Conversion',
        },
        averageGift: {
          figure: current.avg_value,
          percent: getPercent(current.avg_value, previous.avg_value),
          label: 'Average Gift',
        },
        totalGiving: {
          figure: current.total_value,
          percent: getPercent(current.total_value, previous.total_value),
          label: 'Total Giving',
        },
        donors: {
          figure: current.new_donor_count,
          percent: getPercent(
            current.new_donor_count,
            previous.new_donor_count
          ),
          label: 'New Donors',
        },
        recurring: {
          figure: current.recurring.total_value,
          percent: getPercent(
            current.recurring.total_value,
            previous.recurring.total_value
          ),
          label: `${current.recurring.total_count} Recurring Gifts of`,
        },
      };
    };

    this.setSummaryChart = (result) => {
      this.chart = { donation: angular.extend({}, result.chart) };
      this.chart.donation.series.cash.data['fill'] = false;
      this.chart.donation.series.noncash.data['fill'] = false;
      this.chart.donation.series.average.data['fill'] = false;
      this.chart.donation.data = [
        this.chart.donation.series.cash.data,
        this.chart.donation.series.noncash.data,
        this.chart.donation.series.average.data,
      ];
      this.chart.donation.labels = [];
      angular.forEach(this.chart.donation.categories, (val, key) => {
        return this.chart.donation.labels.push(
          moment(val).format(this.dateFmt)
        );
      });
      this.chart.donation.types = ['Cash', 'Noncash', 'Average'];
      this.chart.donation.colors = ['#F16B82', '#F6D879', '#16ABD3'];
      this.chart.cash = angular.extend({}, this.chart.donation);
      this.chart.cash.data = [this.chart.cash.series.cash.data];
      this.chart.cash.types = ['Cash'];
      this.chart.cash.colors = ['#F16B82'];
      this.chart.noncash = angular.extend({}, this.chart.donation);
      this.chart.noncash.data = [this.chart.noncash.series.noncash.data];
      this.chart.noncash.types = ['Noncash'];
      return (this.chart.noncash.colors = ['#F6D879']);
    };
  },
});
