// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('designationField', () => ({
  templateUrl: '/scripts/input/designation-field.html',
  scope: {
    field: '=field',
    extraData: '=extra',
  },
  restrict: 'E',
  controller($scope) {
    $scope.$watch(
      'extraData',
      function (nv, ov) {
        if (nv !== ov) {
          return ($scope.designations = _.filter(nv.designations, {
            is_active: true,
          }));
        }
      },
      true
    );
    const { campaigns } = $scope.extraData;
    let campaignFieldKey = null;
    angular.forEach($scope.completeFieldSet, function (val, key) {
      if (val && val.data_type === 'campaign') {
        return (campaignFieldKey = key);
      }
    });
    if (campaignFieldKey !== null) {
      //filter the designations with the campaign
      return $scope.$watchCollection(
        `completeFieldSet[${campaignFieldKey}]`,
        function (val) {
          if (val.value && val.value !== '') {
            const _campaignDesignations = _.find(campaigns, { id: val.value });
            return ($scope.designations = _.filter(
              _campaignDesignations.designations,
              { is_active: true }
            ));
          } else {
            return ($scope.designations = _.filter(
              $scope.extraData.designations,
              { is_active: true }
            ));
          }
        }
      );
    } else {
      return ($scope.designations = _.filter($scope.extraData.designations, {
        is_active: true,
      }));
    }
  },
}));
