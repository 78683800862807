import { Auth0Client } from '@auth0/auth0-spa-js';
import authModule from './auth.module';

// adapted from https://stackoverflow.com/a/13419367, NOT THE SAME as getUrlVars()
function parseQuery(queryString) {
  var query = {};
  var pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

authModule.factory(
  'auth',
  function (
    SessionService,
    $route,
    $location,
    organizationService,
    appState,
    GlobalService,
    Platform
  ) {
    function clearQueryParams() {
      // TODO : figure out why this only sometimes works, and otherwise leaves junk in the query string
      // history.replaceState also does not work.

      $location.search({
        code: null,
        state: null,
        error: null,
        error_description: null,
      });

      // history.replaceState({}, document.title, location.href.split('?')[0]);
    }

    function showAuthenticationError(errorPayload) {
      let errSubject = 'Authentication Error';
      let errMessage =
        "<p>An unknown error has occurred, please Log Out and try again.</p><p>If the error persists, please contact {{Platform.getText('name')}} for more information.</p>";

      switch (errorPayload.error) {
        case 'access_denied':
          errSubject = errorPayload.error_description;
          errMessage =
            '<p>Access Denied: <i>' +
            errorPayload.error_description +
            '</i></p>' +
            '<p>Please contact ' +
            Platform.getText('name') +
            ' for more information.</p>';
          break;
        default:
          console.error('Unknown Authentication Error:', errorPayload);
          break;
      }

      // display error and give the user the option to log out.
      GlobalService.forceLogOutError(errSubject, errMessage);
    }

    function logout() {
      SessionService.logOut(() => {});
      // this does depend on the authenticate promise having populated auth0 before now, which is gross...
      // ... but you can't log out if you haven't logged in yet, so it's fine, right?
      if (window !== parent && window.eventHub) {
        window.eventHub.emit('logout');
      } else {
        Platform.getConfig().then((config) => {
          const auth0 = new Auth0Client(config.environment.auth0);
          auth0.logout({
            returnTo: auth0.options.redirect_uri,
          });
        });
      }
    }

    function parseIdTokenClaims(claims) {
      const idonateClaims = {};

      Object.keys(claims).forEach((key) => {
        if (key.startsWith('http://idonate/')) {
          idonateClaims[
            key.replace('http://idonate/', '') // strip prefix
          ] = claims[key];
        }
      });

      return idonateClaims;
    }

    async function authenticate() {
      const config = await Platform.getConfig();
      const auth0 = new Auth0Client({
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        ...config.environment.auth0,
      });

      if (window !== parent) {
        // if embedded in an iframe, initialize as "ui-plugin"
        return appState.initializeUiPluginAsync();
      } else {
        return auth0
          .isAuthenticated()
          .then(
            (isAuthenticatedResult) => {
              const query = window.location.search;
              if (isAuthenticatedResult) {
                return auth0.getTokenSilently();
              } else if (
                query.includes('error=') &&
                query.includes('error_description=')
              ) {
                showAuthenticationError(parseQuery(query));

                // we're not usefully authenticated, but we're asking the user to log out and it's easier to
                // turn on the authenticated UI than to properly fix error messages to work without it:
                appState.isAuthenticated = true; // NOTE: NOT ACTUALLY AUTHENTICATED and user should only be able to log out.

                clearQueryParams();
              } else if (query.includes('code=') && query.includes('state=')) {
                return auth0.handleRedirectCallback().then(
                  ({ appState: authAppState }) => {
                    // get the id token claims when coming back from a login redirect
                    auth0.getIdTokenClaims().then((rawClaims) => {
                      const claims = parseIdTokenClaims(rawClaims);

                      // check if user is opted into the GMS2 redirect
                      if (
                        claims &&
                        claims.opt_ins &&
                        claims.opt_ins.gms2_redirect
                      ) {
                        // redirect them if so:
                        window.location.href = claims.opt_ins.gms2_redirect;
                        return null;
                      }
                    });
                    clearQueryParams();

                    // on successful login, route to requested path, or dashboard otherwise
                    if (authAppState && authAppState.targetPath) {
                      $location.path(authAppState.targetPath);
                    } else {
                      $location.path('/dashboard?noAuth=true');
                    }

                    return auth0.getTokenSilently();
                  },
                  (tokenError) => {
                    throw tokenError;
                  }
                );
              } else {
                // no errors or authentication attempts in play, set up to login and redirect back to the current route.
                const nextAppState = {};
                const currentPath = $location.path();

                // login to auth0, redirect back to current route
                if (currentPath && currentPath !== '/') {
                  nextAppState.targetPath = currentPath;
                }

                return auth0.loginWithRedirect({
                  appState: nextAppState,
                });
              }
            },
            (isAuthenticatedError) => {
              console.error(
                'Error while checking isAuthenticated',
                isAuthenticatedError
              );

              throw isAuthenticatedError;
            }
          )
          .then(
            (token) => {
              if (token) {
                // token exchange successful, continue loading
                clearQueryParams();

                return appState.initializeSessionAsync(token);
              }
            },
            (error) => {
              if (error.message === 'Multifactor authentication required') {
                // MFA missing during getTokenSilently -- 'Remember Me' has expired but token is otherwise good
                // make them log back in anyway.
                console.warn('MFA required after login - forcing log out');
                logout();
              } else {
                // otherwise some other unhandled error occurred.
                console.error(
                  'Error while acquiring Authentication Token',
                  error
                );
                showAuthenticationError({});
                throw error;
              }
            }
          );
      }
    }

    // kick off authentication during loading
    authenticate().then(() => {
      // initialize pendo with the hard assumption that it's already available
      window.pendo.initialize(appState.resolvePendoConfig());
    });

    return {
      logout,
      allowRole(satisfyingRoles) {
        if (
          !appState.isAuthenticated ||
          !appState.currentUser ||
          !appState.currentOrganization
        ) {
          return false;
        }

        if (satisfyingRoles === 'alwaysAllow') {
          return true;
        }

        if (satisfyingRoles === 'allRoles') {
          satisfyingRoles = ['SuperAdmin', 'Admin', 'Editor', 'Viewer'];
        }

        const roleOnOrg =
          appState.organizationRoles[appState.currentOrganization.id];

        return roleOnOrg && satisfyingRoles.indexOf(roleOnOrg) >= 0;
      },

      allowFeature(feature) {
        if (
          !appState.isAuthenticated ||
          !appState.currentUser ||
          !appState.currentOrganization
        ) {
          return false;
        }

        if (feature === 'alwaysAllow' || feature === 'allFeatures') {
          return true;
        }

        // special case for array-like allowFeatureFor entries (SMS only!)
        if (Array.isArray(feature)) {
          const intersection = appState.currentOrganization.features.filter(
            (activeFeature) => feature.includes(activeFeature)
          );

          return intersection.length > 0;
        }

        return appState.currentOrganization.features.includes(feature);
      },
    };
  }
);
