// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const IntervalContainer = function () {
  let uniqueId = 1;
  return {
    template: `<div class="interval-container">
      <div class="form-group daterange-box">
        <input id="{{::ctrl.uniqueId}}" date-range-picker class="form-control date-picker" type="text" ng-model="ctrl.time" options="ctrl.options"/>
        <label for="{{::ctrl.uniqueId}}"><span class="icon-down-caret"></span></label>
      </div>
    </div>`,
    controllerAs: 'ctrl',
    scope: {
      timeCallback: '=',
    },
    replace: true,
    bindToController: true,
    restrict: 'E',
    controller($scope, $timeout, $element, $location) {
      const ctrl = this;
      let lastRefresh = moment() - 1000;

      const activate = function () {
        ctrl.uniqueId = `interval-field${uniqueId++}`;

        $scope.$watch('ctrl.time.startDate', timeChanged);
        $scope.$watch('ctrl.time.endDate', timeChanged);

        const urlParams = $location.search();

        ctrl.time = {
          startDate:
            (urlParams.start_date && moment(urlParams.start_date)) ||
            moment().subtract(12, 'months'),
          endDate:
            (urlParams.end_date && moment(urlParams.end_date)) || moment(),
        };

        ctrl.options = {
          format: 'MM-DD-YYYY',
          opens: 'left',
          autoApply: true,
          startDate: ctrl.time.startDate,
          endDate: ctrl.time.endDate,
          maxDate: moment(),
          ranges: {
            'This Week': [moment().subtract(6, 'days'), moment()],
            'Last Week': [
              moment().subtract(13, 'days'),
              moment().subtract(7, 'days'),
            ],
            'Last Month': [moment().subtract(30, 'days'), moment()],
            'Last 6 Months': [moment().subtract(6, 'months'), moment()],
            'Last 12 Months': [moment().subtract(12, 'months'), moment()],
          },
          locale: {
            customRangeLabel: 'Custom',
          },
        };
      };

      var timeChanged = function (a, b) {
        if (
          (ctrl.time != null ? ctrl.time.startDate : undefined) &&
          (ctrl.time != null ? ctrl.time.endDate : undefined)
        ) {
          const current = {
            start: moment(ctrl.time.startDate).startOf('day'),
            end: moment(ctrl.time.endDate).endOf('day'),
          };

          if (current.start.isBefore(current.end)) {
            let dayDiff = current.end.diff(current.start, 'days');
            if (dayDiff === 0) {
              dayDiff = 1;
            }
            const previous = {
              end: moment(current.start).subtract(1, 'day').endOf('day'),
              start: moment(current.start)
                .subtract(dayDiff, 'days')
                .startOf('day'),
            };

            if (
              ctrl.timeCallback !== undefined &&
              moment() - lastRefresh >= 1000
            ) {
              lastRefresh = moment();
              ctrl.timeCallback(current, previous);
            }
          }
        }
      };
      activate();
    },
  };
};

angular.module('idonate.gms').directive('intervalContainer', IntervalContainer);
