export const CONSTANTS = [
  {
    name: 'app4Iconset',
    val: {
      iconClass: 'glyphicons idonate-icons',
      iconClassFix: 'icon-',
      icons: [
        'atv',
        'baby-bottle',
        'backpack',
        'bed',
        'bible',
        'bicycle',
        'boat',
        'building',
        'calendar',
        'camp',
        'car',
        'cargo',
        'cars',
        'cash',
        'chart-1',
        'chat-bubble',
        'chat-bubbles',
        'child-care',
        'classes',
        'close',
        'clothes',
        'clock',
        'coat',
        'coins',
        'commodities',
        'cutlery',
        'diapers',
        'donors',
        'download',
        'echeck',
        'email',
        'email-circle',
        'employer-match',
        'facebook',
        'facebook-circle',
        'facetime',
        'family',
        'games',
        'gas',
        'glasses',
        'globe',
        'handshake',
        'heart',
        'house-1',
        'hygiene-kit',
        'idonate-tag',
        'ipad',
        'ipod',
        'keys',
        'laptop',
        'living-expense',
        'lock',
        'map',
        'meals',
        'medicine',
        'mosquito-net',
        'motorcycle',
        'paypal',
        'payroll',
        'phone-shake',
        'pickup',
        'pledge',
        'plus-circle',
        'puzzle-1',
        'shoes',
        'shopping-cart',
        'sms',
        'sort',
        'sponsor-child',
        'stocks',
        'stroller',
        'supplies',
        'tiger',
        'tithe',
        'twitter',
        'twitter-square',
        'umbrella',
        'usd',
        'video',
        'water-well',
        'radio',
        'refresh',
        'right-arrow',
        'ring',
        'rv',
        'scholarship',
        'marriage',
        'question-mark',
        'phone-1',
        'address-book',
        'chapters',
        'chart',
        'contact',
        'dashboard',
        'flag',
        'gift',
        'phone',
        'puzzle',
        'settings',
        'target',
        'ticket',
        'website',
        'sports',
        'shower-head',
        'underwear',
        'groceries',
        'horse',
        'tooth',
        'music',
        'turkey',
        'dog',
      ],
    },
  },
  {
    name: 'app2Iconset',
    val: {
      iconClass: 'glyphicons idonate',
      iconClassFix: 'idonate-',
      icons: [
        'adoption',
        'atv',
        'baby-formula',
        'bed',
        'bible',
        'bicycle',
        'boat',
        'building',
        'camp',
        'car',
        'cargo',
        'cars',
        'charts',
        'checked',
        'child-care',
        'classes',
        'clothes',
        'coins',
        'commodities',
        'cutlery',
        'diapers',
        'download-alt',
        'family',
        'gamepad',
        'gas',
        'gift',
        'glasses',
        'home',
        'hygiene-kit',
        'iphone-shake',
        'keys',
        'living-expenses',
        'macbook',
        'map',
        'marriage',
        'meals',
        'medicine',
        'mosquito-net',
        'pickup',
        'plate-of-food',
        'scholarships',
        'shoes',
        'sponsor-child',
        'stroller',
        'supplies',
        'tithe',
        'water-well',
        'winter-coat',
        'puzzle-piece',
        'backpack',
        'fm-radio',
        'tiger',
        'chat-bubble',
        'chat-bubble-1',
        'facetoface',
        'handshake',
        'trees',
        'catdog',
        'dog',
      ],
    },
  },
  {
    name: 'transactionColumns',
    val: {
      defaults: [
        {
          active: true,
          name: 'created',
          text: 'Created',
        },
        {
          active: true,
          name: 'contact.title',
          text: 'Title/Salutation',
          searchable: 'ilike',
        },
        {
          active: true,
          name: 'contact.firstname',
          text: 'First Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'contact.middlename',
          text: 'Middle Name',
          searchable: 'ilike',
        },
        {
          active: true,
          name: 'contact.lastname',
          text: 'Last Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'contact.phone',
          text: 'Donor Phone',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'contact.email',
          text: 'Donor Email',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'contact.address.street',
          text: 'Donor Street',
        },
        {
          active: false,
          name: 'contact.address.street2',
          text: 'Donor Street2',
        },
        {
          active: false,
          name: 'contact.address.city',
          text: 'Donor City',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'contact.address.state',
          text: 'Donor State',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'contact.address.zip',
          text: 'Donor Zip',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'contact.address.country',
          text: 'Donor Country',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor_id',
          text: 'iDonate Donor ID',
          searchable: 'uuid',
        },
        {
          active: true,
          name: 'id',
          text: 'ID',
          searchable: 'uuid',
          class: 'truncate-right',
        },
        {
          active: false,
          name: 'icon',
          text: 'Icon',
        },
        {
          active: true,
          name: 'type',
          text: 'Type',
          class: 'capitalize',
        },
        {
          active: false,
          name: 'subtype',
          text: 'Subtype',
          class: 'capitalize',
        },
        {
          active: true,
          name: 'description',
          text: 'Description',
        },
        {
          active: true,
          name: 'status',
          text: 'Status',
        },
        {
          active: false,
          name: 'frequency',
          text: 'Frequency',
        },
        {
          active: false,
          name: 'detail.sale_price',
          text: 'Sale Price',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'net_proceeds',
          text: 'Net Proceeds',
        },
        {
          active: false,
          name: 'client_proceeds',
          text: 'Charity Proceeds',
        },
        {
          active: false,
          name: 'detail.donor_paid_fee',
          text: 'Donor paid fee',
        },
        {
          active: false,
          name: 'initial_donation_amount',
          text: 'Initial Donation',
        },
        {
          active: false,
          name: 'organization.name',
          text: 'Organization Name',
        },
        {
          active: false,
          name: 'organization_id',
          text: 'Organization Id',
        },
        {
          active: false,
          name: 'designation_id',
          text: 'Designation Id',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'designation.title',
          text: 'Designation Title',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'designation.code',
          text: 'Designation Fund Id',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'designation_note',
          text: 'Designation Note',
        },
        {
          active: false,
          name: 'parent.corporate_matching_record.corporate_matching_company.name',
          text: 'Corp Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'external_tracking_id',
          text: 'External Tracking Id',
        },
        {
          active: false,
          name: 'detail.recurring_count',
          text: 'Recurring Count',
        },
        {
          active: false,
          name: 'detail.check_number',
          text: 'Check Number',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.final_date',
          text: 'Final Date',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.card_type',
          text: 'Card Type',
          searchable: 'ilike',
          class: 'text-capitalize',
        },
        {
          active: false,
          name: 'payment_transaction_id',
          text: 'iDonate Payment ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'payment_transaction.organization_payment_gateway.id',
          text: 'Gateway Id',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'payment_transaction.organization_payment_gateway.name',
          text: 'Gateway Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.expiration_month',
          text: 'Expiration Month',
          searchable: 'exact',
        },
        {
          active: false,
          name: 'detail.expiration_year',
          text: 'Expiration Year',
          searchable: 'exact',
        },
        // {
        //   active: false
        //   name: 'detail.first_six_digits'
        //   text: 'First 6 Digits'
        //   searchable: 'ilike'
        // }
        {
          active: false,
          name: 'detail.last_four_digits',
          text: 'Last 4 Digits',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.additional_info',
          text: 'Additional Info',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'embed.id',
          text: 'Embed Id',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'embed.name',
          text: 'Embed Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.email_opt_in',
          text: 'Email Opt In',
        },
        {
          active: false,
          name: 'detail.company_name',
          text: 'Company',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'schedule_id',
          text: 'Schedule ID',
        },
        {
          active: false,
          name: 'schedule.start_date',
          text: 'Schedule Start Date',
        },
        {
          active: false,
          name: 'schedule.created',
          text: 'Schedule Created Date',
        },
        {
          active: false,
          name: 'schedule.first_transaction',
          text: 'Schedule First Transaction Date',
        },
        {
          active: false,
          name: 'schedule.latest_transaction',
          text: 'Schedule Latest Transaction Date',
        },
        {
          active: false,
          name: 'schedule.expires',
          text: 'Schedule Expires Date',
        },
      ],
      anonymousOption: [
        {
          active: false,
          name: 'detail.hide_name',
          text: 'Anonymous',
        },
      ],
      customFields: [
        {
          active: false,
          name: 'detail.custom_note_1',
          text: 'Custom Note 1',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.custom_note_2',
          text: 'Custom Note 2',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.custom_note_3',
          text: 'Custom Note 3',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.custom_note_4',
          text: 'Custom Note 4',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.custom_note_5',
          text: 'Custom Note 5',
          searchable: 'ilike',
        },
      ],
      smsGiving: [
        {
          active: false,
          name: 'detail.sms_keyword',
          text: 'Txt+ Keyword',
          searchable: 'ilike',
        },
      ],
      campaign: [
        {
          active: false,
          name: 'campaign_id',
          text: 'Campaign Id',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'campaign.title',
          text: 'Campaign Name',
        },
      ],
      pledge: [
        {
          active: false,
          name: 'contact.employee_id',
          text: 'Employee Id',
          searchable: 'ilike',
        },
      ],
      thankYou: [
        {
          active: false,
          name: 'gift_extra',
          text: 'Thank You Gift Additional Information',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'gift.gift_value',
          text: 'Thank You Gift Value',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'gift.description',
          text: 'Thank You Gift Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'gift.sku',
          text: 'SKU',
          searchable: 'ilike',
        },
      ],
      tribute: [
        {
          active: false,
          name: 'tribute.definition.description',
          text: 'Tribute Name',
        },
        // {
        //   active:false
        //   name:'tribute.definition_id'
        //   text:'Tribute Definition Id'
        //   searchable: 'ilike'
        // }
        {
          active: false,
          name: 'tribute.honoree_first_name',
          text: 'Honoree First Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.honoree_last_name',
          text: 'Honoree Last Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.from_name',
          text: 'Tribute From Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.message',
          text: 'Tribute Message',
          searchable: 'ilike',
        },
        // {
        //   active:false
        //   name:'tribute.include_amount'
        //   text:'Tribute Include Amount'
        // }
        // {
        //   active:false
        //   name:'tribute.send_card'
        //   text:'Tribute Send Card'
        // }
        {
          active: false,
          name: 'tribute.recipient.firstname',
          text: 'Tribute Recipient First Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.recipient.lastname',
          text: 'Tribute Recipient Last Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.recipient.email',
          text: 'Tribute Email',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.recipient.address.street',
          text: 'Tribute Address 1',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.recipient.address.street2',
          text: 'Tribute Address 2',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.recipient.address.city',
          text: 'Tribute City',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.recipient.address.state',
          text: 'Tribute State',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'tribute.recipient.address.zip',
          text: 'Tribute Zip Code',
          searchable: 'ilike',
        },
      ],
      utm: [
        {
          active: false,
          name: 'utm.source',
          text: 'utm_source',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'utm.medium',
          text: 'utm_medium',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'utm.term',
          text: 'utm_term',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'utm.content',
          text: 'utm_content',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'utm.campaign',
          text: 'utm_campaign',
          searchable: 'ilike',
        },
      ],
      referenceCode: [
        {
          active: false,
          name: 'detail.reference_code',
          text: 'Reference Code',
          searchable: 'ilike',
        },
      ],
      paymentReconciliation: [
        {
          active: false,
          name: 'detail.settlement_date',
          text: 'Settlement Date',
        },
        {
          active: false,
          name: 'detail.transaction_fees',
          text: 'Transaction Fees',
        },
        {
          active: false,
          name: 'detail.batch_id',
          text: 'Batch ID',
        },
      ],
      p2p_2019: [
        {
          active: false,
          name: 'p2p_fundraiser.name',
          text: 'P2P Fundraiser Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'p2p_fundraiser.title',
          text: 'P2P Fundraiser Title',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'p2p_fundraiser.program.name',
          text: 'P2P Program Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'p2p_fundraiser.id',
          text: 'P2P Fundraiser ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'p2p_fundraiser.program_id',
          text: 'P2P Program ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'p2p_team_name',
          text: 'P2P Team Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'detail.p2p_fundraiser_comment',
          text: 'P2P Fundraiser Comment',
        },
        {
          active: false,
          name: 'detail.p2p_fundraiser_comment_reply',
          text: 'P2P Fundraiser Comment Reply',
        },
      ],
      p2p: [
        {
          active: false,
          name: 'advocate_name',
          text: 'Fundraiser Name',
        },
        {
          active: false,
          name: 'advocacy_team.name',
          text: 'Fundraiser Team Name',
        },
        {
          active: false,
          name: 'advocacy_program.name',
          text: 'Fundraiser Program Name',
        },
      ],
      funding: [
        {
          active: false,
          name: 'funding.date',
          text: 'Funding Date',
        },
        {
          active: false,
          name: 'funding.card_connect_id',
          text: 'Funding External ID',
        },
        {
          active: false,
          name: 'funding.id',
          text: 'Funding Detail Link',
        },
      ],
    },
  },
  {
    name: 'recurringColumns',
    val: {
      defaults: [
        {
          active: true,
          name: 'created',
          text: 'Create Date',
        },
        {
          active: true,
          name: 'donor.contact.title',
          text: 'Title/Salutation',
          searchable: 'ilike',
        },
        {
          active: true,
          name: 'donor.contact.firstname',
          text: 'First Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor.contact.middlename',
          text: 'Middle Name',
          searchable: 'ilike',
        },
        {
          active: true,
          name: 'donor.contact.lastname',
          text: 'Last Name',
          searchable: 'ilike',
        },
        {
          active: true,
          name: 'schedule_type',
          text: 'Frequency',
        },
        {
          active: true,
          name: 'expires',
          text: 'Expires',
        },
        {
          active: true,
          name: 'amount',
          text: 'Amount',
        },
        {
          active: true,
          name: 'id',
          text: 'Schedule ID',
          searchable: 'uuid',
        },
        {
          active: true,
          name: 'status',
          text: 'Status',
        },
        {
          active: true,
          name: 'next_payment',
          text: 'Next Payment',
        },
        // non-default fields
        {
          active: false,
          name: 'updated',
          text: 'Updated',
        },
        {
          active: false,
          name: 'failed_attempts',
          text: 'Failed Attempts',
        },
        {
          active: false,
          name: 'campaign.title',
          text: 'Campaign Title',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'campaign_id',
          text: 'Campaign ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'designations.id',
          text: 'Designation ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'designations.title',
          text: 'Designation Title',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'designations.fund_id',
          text: 'Designation Fund Id',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'last_transaction.designation_note',
          text: 'Designation Note',
        },
        {
          active: false,
          name: 'advocacy_team_id',
          text: 'Advocacy Team ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'advocacy_program_id',
          text: 'Advocacy Program ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'advocate_id',
          text: 'Fundraiser ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'advocate.user.contact.fullname',
          text: 'Fundraiser Contact',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'company_name',
          text: 'Company Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor_id',
          text: 'Donor ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'last_transaction.email_opt_in',
          text: 'Email Opt-in',
        },
        {
          active: false,
          name: 'donor.contact.phone',
          text: 'Donor Phone',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor.contact.email',
          text: 'Donor Email',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor.contact.address.street',
          text: 'Donor Address Street 1',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor.contact.address.street2',
          text: 'Donor Address Street 2',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor.contact.address.city',
          text: 'Donor City',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor.contact.address.state',
          text: 'Donor State',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'donor.contact.address.zip',
          text: 'Donor Zip',
        },
        {
          active: false,
          name: 'donor.contact.address.country',
          text: 'Donor Country',
        },
        {
          active: false,
          name: 'gateway_id',
          text: 'Gateway ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'gateway.name',
          text: 'Gateway Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'organization_id',
          text: 'Organization ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'organization.name',
          text: 'Organization Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'payment_method_id',
          text: 'Payment Method ID',
        },
        {
          active: false,
          name: 'last_transaction.embed.id',
          text: 'Embed ID',
          searchable: 'uuid',
        },
        {
          active: false,
          name: 'last_transaction.embed.name',
          text: 'Embed Name',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'last_transaction.external_tracking_id',
          text: 'Tracking ID',
        },
        {
          active: false,
          name: 'last_transaction.sms_keyword',
          text: 'SMS Keyword',
          searchable: 'ilike',
        },
        {
          active: false,
          name: 'last_transaction.utm.campaign',
          text: 'UTM Campaign',
        },
        {
          active: false,
          name: 'last_transaction.utm.content',
          text: 'UTM Content',
        },
        {
          active: false,
          name: 'last_transaction.utm.medium',
          text: 'UTM Medium',
        },
        {
          active: false,
          name: 'last_transaction.utm.source',
          text: 'UTM Source',
        },
        {
          active: false,
          name: 'last_transaction.utm.term',
          text: 'UTM Term',
        },
        {
          active: false,
          name: 'hide_name',
          text: 'Hide Name From Public Display',
        },
        {
          active: false,
          name: 'custom_note_1',
          text: 'Custom Note 1',
        },
        {
          active: false,
          name: 'custom_note_2',
          text: 'Custom Note 2',
        },
        {
          active: false,
          name: 'custom_note_3',
          text: 'Custom Note 3',
        },
        {
          active: false,
          name: 'custom_note_4',
          text: 'Custom Note 4',
        },
        {
          active: false,
          name: 'custom_note_5',
          text: 'Custom Note 5',
        },
      ],
    },
  },
  {
    name: 'donorColumns',
    val: {
      defaults: [
        {
          active: true,
          data: 'contact.title',
          name: 'title',
          text: 'Salutation/Title',
        },
        {
          active: true,
          data: 'contact.firstname',
          name: 'firstname',
          text: 'First Name',
        },
        {
          active: true,
          data: 'contact.lastname',
          name: 'lastname',
          text: 'Last Name',
        },
        {
          active: true,
          data: 'contact.email',
          name: 'email',
          text: 'Email',
        },
        {
          active: true,
          data: 'contact.phone',
          name: 'phone',
          text: 'Phone',
        },
        {
          active: true,
          data: 'contact.address.street',
          text: 'Address 1',
          name: 'street',
        },
        {
          active: true,
          data: 'contact.address.street2',
          text: 'Address 2',
          name: 'street2',
        },
        {
          active: true,
          data: 'contact.address.city',
          name: 'city',
          text: 'City',
        },
        {
          active: true,
          data: 'contact.address.state',
          name: 'state',
          text: 'State',
        },
        {
          active: true,
          data: 'contact.address.zip',
          name: 'zip',
          text: 'Zip',
        },
        {
          active: true,
          data: 'contact.address.country',
          name: 'country',
          text: 'Country',
        },
        {
          active: true,
          name: 'totalgiving',
          text: 'Total Giving',
          data: 'totalgiving',
          render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        },
      ],
    },
  },
];
