import './exception-list.html';
('use strict');

angular
  .module('idonate.gms.donations')
  .controller(
    'ExceptionListCtrl',
    function ($scope, $rootScope, $location, transactionService) {
      $scope.transactionService = transactionService;

      $scope.intervalCallback = function (current, previous) {
        //moment objects
        $scope.current = current;
        $scope.previous = previous;
        generateExceptionList();
      };

      $scope.done = function () {
        return $location.path('donations');
      };

      const generateExceptionList = function () {
        const start_date = $scope.current.start.toISOString();
        const end_date = $scope.current.end.toISOString();

        const params = {
          start_date,
          end_date,
        };

        return transactionService
          .getExceptionList(params)
          .then(function (data) {
            $scope.exceptionList = data.result.exceptions;
            return $scope.$broadcast('reloadTable');
          });
      };
    }
  );
