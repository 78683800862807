import './load-plugins';

import angular from 'angular';
import 'angular1-apollo';
import 'angular-animate/angular-animate';
import 'angular-aria/angular-aria';
import 'angular-cookies/angular-cookies';
import 'angular-messages/angular-messages';
import 'angular-resource/angular-resource';
import 'angular-route/angular-route';
import 'angular-sanitize/angular-sanitize';
import 'angular-socialshare/dist/angular-socialshare';
import 'angular-touch/angular-touch';
import 'lodash/lodash';
import 'restangular/dist/restangular';
import 'bootstrap';
import codemirror from 'codemirror';
import 'moment-timezone/builds/moment-timezone-with-data';
import 'angular-bootstrap-colorpicker/js/bootstrap-colorpicker-module';
import './modified_lib/bootsrap-iconpicker.v4.js';
import 'summernote/dist/summernote-bs4';
import 'angular-summernote/dist/angular-summernote';
import 'angular-file-upload/dist/angular-file-upload';
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls';
import 'angular-clipboard/angular-clipboard';
import 'bootstrap-daterangepicker/daterangepicker';
import 'angular-daterangepicker/js/angular-daterangepicker';
import 'angular-google-chart/ng-google-chart';
import 'ng-autocomplete/src/ngAutocomplete';
import 'ng-file-upload/dist/ng-file-upload-all';
import 'angular-loading-bar/src/loading-bar';
import 'angular-input-masks/releases/angular-input-masks-standalone';
import 'chart.js/dist/Chart';
import 'angular-chart.js/dist/angular-chart';
import 'ui-select/dist/select';
import 'angular-moment-picker/dist/angular-moment-picker';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists';
import 'jquery-caret-plugin/dist/jquery.caret';
import 'angular-ui-codemirror';
import 'inputmask/dist/jquery.inputmask.bundle';

import './api/api.module';
import './donors';
import './donations';
import './website';
import './campaign';
import './text';
import './legacy-p2p';

import baseModule from './base/base.module';

import './app.module';
import './appState.factory';
import './auth';
import './user';
import './organization';
import './platform/platform-service';
import './output';
import './charts';
import './dashboard';
import './embed';
import './input';
import './reporting';
import './mobile-app/noncash-embed-component';
import './modals/urlShorteningModal.html';
import './structure';
import './util';

import 'codemirror/mode/css/css';
import 'codemirror/addon/edit/closebrackets';
window.CodeMirror = codemirror;

// import "vue-gms/dist/vue-gms.js";
