// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('idonate.gms').directive('gmsAlphanumeric', ($timeout) => ({
  restrict: 'A',
  require: '?ngModel',
  scope: {
    gmsAlphanumeric: '=',
  },
  link(scope, element, attrs, ctrl) {
    if (!ctrl) {
      return;
    }
    let regex = /^[a-zA-Z\d\-]*$/;
    if (scope.gmsAlphanumeric != null) {
      let extra = '';
      angular.forEach(scope.gmsAlphanumeric, function (filter) {
        if (filter === 'allow-space') {
          extra += '\\s';
        }
        if (filter === 'allow-punctuation') {
          return (extra += "\\.\\,\\_\\'");
        }
      });
      regex = new RegExp(`^[a-zA-Z\\d\\-${extra}]*$`);
    }
    ctrl.$parsers.unshift(function (viewValue) {
      if (regex.test(`${viewValue}`)) {
        return viewValue;
      } else {
        return ctrl.$modelValue;
      }
    });

    return element.keyup(function () {
      if (element.val() !== ctrl.$modelValue) {
        let caretPostion = $(element).caret() + 1;
        if (!regex.test(element.val())) {
          caretPostion = caretPostion - 1;
          element.val(ctrl.$modelValue);
        }
        return $timeout(() => $(element).caret(caretPostion), 50);
      }
    });
  },
}));
