// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.website')
  .controller(
    'OrganizationEmbedCtrl',
    function ($scope, Modals, websiteService, $route, organizationService) {
      $scope.embedType =
        $route.current.$$route.originalPath === '/embeds'
          ? 'unified_giving'
          : 'goal_meter';
      $scope.campaignId = $route.current.params.campaign_id
        ? $route.current.params.campaign_id
        : null;
      $scope.currentOrganization = organizationService.getCurrentOrganization();
      $scope.canCreateEmbed = $scope.currentOrganization.features.includes(
        'create_legacy_embeds'
      );

      return websiteService.getEmbedBases().then(function (embedBases) {
        if ($scope.embedType === 'goal_meter') {
          $scope.customEmbedBases = _.filter(
            embedBases.result.available_embed_bases,
            { type: $scope.embedType }
          );
        } else {
          const types = ['single_page', 'unified_giving', 'unified_giving_v2'];
          $scope.customEmbedBases = _.filter(
            embedBases.result.available_embed_bases,
            (base) => _.includes(types, base.type)
          );
          $scope.customEmbedBases = $scope.customEmbedBases.sort();
        }

        return ($scope.openEmbedModal = (type) => {
          Modals.open('NewEmbedModal', {
            bases() {
              return $scope.customEmbedBases;
            },
            type() {
              return type;
            },
          }).then(angular.noop, angular.noop);
        });
      });
    }
  );
