import './campaign-item.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
('use strict');

angular
  .module('idonate.gms.campaign')
  .controller(
    'CampaignItemCtrl',
    function (
      $scope,
      organizationService,
      emailMarketingService,
      campaign,
      $timeout,
      notyService,
      $location,
      embedBases,
      fullOrgData,
      allTemplates,
      goalInstances
    ) {
      $scope.campaign = campaign;
      $scope.loading = false;

      // prepare data for goal meters
      $scope.goalEmbeds = goalInstances.result.embed_instances;

      // prepare data for channel items
      $scope.embedBases = embedBases.result.available_embed_bases;
      $scope.themeLimits = fullOrgData.result.attributes.limits.pages;
      $scope.allTemplates = allTemplates.result.page_templates;

      return ($scope.editCampaign = function () {
        $location.path(`/campaign/${$scope.campaign.id}/edit`);
      });
    }
  );
