// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('idonate.gms').directive('charLimit', ($timeout) => ({
  restrict: 'A',
  require: '?ngModel',
  link(scope, element, attrs, ctrl) {
    //return  unless ctrl
    let caretPostion = 0;
    const _limit = attrs.charLimit ? parseInt(attrs.charLimit) : 20;
    ctrl.$parsers.unshift((viewValue) => viewValue.substring(0, _limit));

    return element.keyup(function (e) {
      if (element.val().length > _limit) {
        caretPostion = $(element).caret();
        $timeout(function () {
          element.val(ctrl.$modelValue);
          return $(element).caret(caretPostion);
        }, 50);
      }
    });
  },
}));
