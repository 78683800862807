import Vue from 'vue';
import axios from 'axios';
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-boost';
import { ReportingCtrl } from './main';

Vue.use(VueApollo);

// Bootstrap and register vue and components
require('../../vue/reporting/reporting.js');

angular
  .module('idonate.gms.reporting', [])
  .controller('ReportingCtrl', ReportingCtrl)
  .directive('vueWrapper', () => {
    return {
      restrict: 'A',
      link: function (scope, $el) {
        if (scope.disableReporting) {
          console.error('Reporting not available');
          document.location.href = '/dashboard?noReporting=true';
          return;
        }

        // Set up axios for rest api
        axios.defaults.headers.common['Authorization'] = scope.apiAuthorization;

        // Set up apollo for graphql interaction
        const apolloClient = new ApolloClient({
          uri: `${window.iDonateConfig.baseUrl}graphql`,
          headers: {
            Authorization: scope.apiAuthorization,
          },
        });
        const apolloProvider = new VueApollo({
          defaultClient: apolloClient,
        });
        // Pass relevant info from gms to vue root
        scope.vue = new Vue({
          el: $el[0].querySelector('[ng-non-bindable]'),
          apolloProvider,
          data: function () {
            return {
              gmsUser: scope.userInfo,
              gmsOrg: scope.orgInfo,
            };
          },
        });
      },
    };
  })
  .config(($routeProvider) =>
    $routeProvider
      // Reporting
      .when('/reporting', {
        templateUrl: ReportingCtrl.templateUrl,
        controller: 'ReportingCtrl',

        menu: {
          style: 'icon-stocks',
          name: 'Reporting',
          level: 1,
          position: 5,
          allowFor: ['SuperAdmin', 'Admin', 'Editor', 'Viewer'],
          allowFeatureFor: 'reporting',
        },
      })
  );
