// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const RecurringService = (SessionService, $http, organizationService) => ({
  getRecurringSchedules(columns) {
    let _columns = '';
    angular.forEach(
      columns,
      (val, key) =>
        (_columns = `${_columns}columns[${key}][data]=${key}&columns[${key}][name]=${val.name}&`)
    );
    _columns = _columns.substring(0, _columns.length - 1);

    return SessionService.restangularGmsApi
      .one(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/schedules-table?${_columns}`
      )
      .get();
  },
  getRecurringSchedule(scheduleId) {
    return SessionService.restangularGmsApi
      .one(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${scheduleId}`
      )
      .get();
  },
  updateRecurringSchedule(obj) {
    return SessionService.restangularGmsApi
      .all(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${obj.id}`
      )
      .customOperation('put', null, null, null, obj);
  },
  requestUpdateRecurringSchedule(obj) {
    return SessionService.restangularGmsApi
      .all(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${obj.id}/update_request`
      )
      .customPOST();
  },
  invertAnonymousFlag(obj) {
    return SessionService.restangularGmsApi
      .all(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${obj.id}/invert_anon`
      )
      .customPUT(obj);
  },
  updateScheduleDetail(obj) {
    return SessionService.restangularGmsApi
      .all(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${obj.parent_id}/details`
      )
      .customPOST(obj);
  },
  deleteScheduleDetail(obj) {
    return SessionService.restangularGmsApi
      .all(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${obj.parent_id}/details?key=${obj.key}`
      )
      .customDELETE(null);
  },
  cancelRecurringSchedule(obj) {
    return SessionService.restangularGmsApi
      .all(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${obj.id}/cancel`
      )
      .customPOST();
  },
  updateRecurringScheduleAddress(obj) {
    return SessionService.restangularGmsApi
      .all(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${obj.id}/address`
      )
      .customPUT(obj);
  },
  retryRecurringSchedule(obj) {
    return SessionService.restangularGmsApi
      .all(
        `organization/${
          organizationService.getCurrentOrganization().id
        }/recurring/${obj.id}/retry`
      )
      .customPOST();
  },
});
angular
  .module('idonate.gms.donations')
  .factory('recurringService', RecurringService);
