// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('chartSelector', ($timeout) => ({
  restrict: 'A',
  require: ['selector-row', '^^chart-container'],
  link($scope, $element, $attrs, $ctrls) {
    const chartSelectorElements = $($element).find('[chart]');
    const chartSelectors = [];
    for (let element of Array.from(chartSelectorElements)) {
      chartSelectors.push($(element).attr('name'));
      if (typeof $(element).attr('selected') !== 'undefined') {
        $ctrls[1].selectChart($(element).attr('name'));
      }
    }
    const onChartSelect = function (name) {
      if (Array.from(chartSelectors).includes(name)) {
        return $ctrls[1].selectChart(name);
      }
    };
    return $ctrls[0].addOnSelect(onChartSelect);
  },
}));
