import { AppConfig } from './app.config';
import { AppRun } from './app.run';
import { CONSTANTS } from './app.constants';

const IMPORTS = [
  '720kb.socialshare',
  'ngAnimate',
  'ngCookies',
  'ngMessages',
  'ngResource',
  'ngRoute',
  'ngSanitize',
  'ngAutocomplete',
  'ui.bootstrap',
  'ui.select',
  'restangular',
  'summernote',
  'colorpicker.module',
  'angularFileUpload',
  'angular-clipboard',
  'googlechart',
  'ngFileUpload',
  'angular-loading-bar',
  'ui.utils.masks',
  'ui.codemirror',
  'chart.js',
  'dndLists',
  'daterangepicker',
  'moment-picker',
  // apollo currently provided at SessionService.graphql,
  // angular-apollo features are currently unused but may be needed again
  // 'angular-apollo',

  // Idonate Modules
  'idonate.gms.auth',
  'idonate.gms.user',
  'idonate.gms.base',
  'idonate.gms.api',
  'idonate.gms.donations',
  'idonate.gms.donors',
  'idonate.gms.website',
  'idonate.gms.campaign',
  'idonate.gms.analytics',
  'idonate.gms.reporting',
  'idonate.gms.text',
  'idonate.gms.legacy-p2p',
];

const app = angular.module('idonate.gms', IMPORTS).config(AppConfig);

CONSTANTS.map((item) => app.constant(item.name, item.val));

// application entrypoint
app.run(AppRun);
