const baseUrl = 'user/messages';
export class UserMessagesAPI {
  static $inject = ['RestAPI'];
  constructor(RestAPI) {
    this.restAPI = RestAPI;
  }
  getMessages(data) {
    return this.restAPI.get(baseUrl, data);
  }
  getMessage(item, data) {
    return this.restAPI.get(`${baseUrl}/${item}`, data);
  }
  deleteMessage(item) {
    return this.restAPI.delete(`${baseUrl}/${item}`);
  }
}
