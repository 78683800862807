import axios from 'axios';
import { FilterType } from '@idonatedev/ui-library/components/gms-new-report-modal/gms-new-report-modal.constants';

export default {
  COLUMNS_API_URL: `${window.iDonateConfig.baseUrl}report/columns`,

  // for new reports, the default set of columns (per focus) that will appear is defined here
  defaultColumns: {
    transaction: [
      'created',
      'contact_first_name',
      'contact_last_name',
      'transaction_id',
      'type',
      'subtype',
      'client_proceeds',
      'status',
    ],
    donor: [
      'contact_salutation',
      'contact_first_name',
      'contact_last_name',
      'contact_email',
      'contact_phone',
      'contact_address1',
      'contact_address2',
      'contact_city',
      'contact_state',
      'contact_zip',
      'contact_country',
      'total_giving_amount',
    ],
    schedule: [
      'created',
      'contact_salutation',
      'contact_first_name',
      'contact_last_name',
      'frequency',
      'amount',
      'status',
      'next_payment',
      'expires',
      'schedule_id',
    ],
    campaign: ['name', 'amount_raised', 'goal', 'start_date', 'end_date'],
  },

  // similarly, the default sort for new reports
  defaultSort: {
    transaction: { created: 'desc' },
    donor: { contact_last_name: 'desc' },
    schedule: { created: 'desc' },
    campaign: { start_date: 'desc' },
  },

  focusOptions: [
    {
      label: 'Transaction',
      value: 'transaction',
    },
    {
      label: 'Donor',
      value: 'donor',
    },
    {
      label: 'Campaign',
      value: 'campaign',
    },
    {
      label: 'Schedule',
      value: 'schedule',
    },
  ],

  visibilityOptions: [
    {
      label: 'Private to me',
      value: 'CREATOR',
    },
    {
      label: 'Public to Organization',
      value: 'ORG',
    },
    // {
    //   "label": "Share with a group",
    //   "value": "PUBLIC",
    //   "hasInvitation": true
    // }
  ],

  downloadOptions: [
    {
      label: 'CSV',
      value: 'CSV',
    },
    {
      label: 'Excel',
      value: 'XLSX',
    },
    // {
    //   "label": "PDF",
    //   "value": "PDF"
    // }
  ],

  // for mapping column types to column filter types
  filterTypes: {
    string: FilterType.STRING,
    number: FilterType.NUMBER,
    integer: FilterType.NUMBER,
    currency: FilterType.NUMBER,
    datetime: FilterType.DATE,
    uuid: FilterType.UUID,
    dropdown: FilterType.DROPDOWN,
    multiselect: FilterType.MULTI_SELECT,
    array: FilterType.ARRAY,
  },

  // for DROPDOWN and MULTI_SELECT filter types, these are the options presented to the user
  selectOptions: {
    channel: ['embed', 'organization_event', 'p2p', 'sms'],
    currency_code: ['AUD', 'USD'],
    source: [
      'api',
      'api-subtransaction',
      'copy',
      'corporate_matching',
      'donate_double',
      'embed',
      'offline',
      'organization-event',
      'payment-transaction:spawn_transaction',
      'picatic',
      'proto',
      'reconcile_vanco',
      'recurring_donation_schedule',
      'sms',
      'transaction_importer',
      'vanco_file',
    ],
    status: [
      'approved',
      'cancelled',
      'complete',
      'declined',
      'pending',
      'received',
      'refunded',
      'sold',
      'transferred',
      'unknown_None',
      'voided',
    ],
    recurring_status: [
      'Active',
      'Failing',
      'Terminally Failed',
      'Expired',
      'Cancelled',
    ],
    payment_method_type: [{
      label: 'Credit Card',
      value: 'credit_card'
    }, {
      label: 'Bank Account',
      value: 'bank_account'
    }, {
      label: 'Apple Pay',
      value: 'apple_pay'
    }, {
      label: 'Google Pay',
      value: 'google_pay'
    }],
    subtype: ['credit', 'echeck', 'none'],
    type: ['cash', 'ticket'],
    frequency: ['Annually', 'Monthly', 'Once', 'Quarterly', 'Weekly'],
    email_opt_in: ['true', 'false'],
    tribute_ecard_sent: ['true', 'false'],
    hide_name: ['true', 'false'],
    show_name_to_fundraiser: ['true', 'false'],
    contact_state: [
      'AK',
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DE',
      'FL',
      'GA',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
  },

  queryableFields: [
    // donor focus
    'contact_state',
    // transaction focus
    'channel',
    'currency_code',
    'source',
    'status',
    'type',
    'subtype',
    'frequency',
    'email_opt_in',
    'tribute_ecard_sent',
    'hide_name',
    'show_name_to_fundraiser'
  ],

  fetchAllColumns() {
    return new Promise((resolve, reject) => {
      axios
        .get(this.COLUMNS_API_URL)
        .then(
          (result) => {
            if (result.data.status == '200') {
              resolve(result.data.result);
            } else {
              reject();
            }
          },
          (error) => {
            reject();
          }
        )
        .catch((error) => {
          reject();
        });
    });
  },
  loadColumnData(json, columns, focus) {
    // valid types for return array:
    // STRING: "string",
    // NAME: "name", - requires an object with first and last
    // NUMBER: "number",
    // MONEY: "money",
    // DATE: "Date",
    // THANK: "Thank",
    // STATUS: "Status",
    // SELECT: "Select",
    // AVATAR: "Avatar"
    // LINK: "Link"
    let columnData = Object.keys(json[`${focus}_focus`]).map((key) => {
      let column = json[`${focus}_focus`][key];
      let data = {
        name: column.label,
        id: key,
        type: column.type,
        group: column.group,
        align: 'TableHeaderCell--left',
        size: 'Table--small',
        mode: 'Table--subtle',
        display: columns.includes(key),
        sortable: true,
        // Push key into options for all columns.
        // This is probably not intended usage but prevents data structure change
        options: [key],
      };
      if (['batch_id'].includes(key)) {
        // this comes in as uuid, but we need to make it a link instead
        data.type = 'Link';
      }
      if (key === 'giving_form_variant') {
        data.type = 'custom';
        data.format = (cell) => {
          const variant = parseInt(cell);
          if (variant === 0) {
            return 'A';
          }
          if (variant === 1) {
            return 'B';
          }
          return '';
        };
      }
      if (key === 'payment_method_type') {
        data.type = 'custom';
        data.format = (cell) => {
          if (!cell) {
            return '';
          }
          
          return _.startCase(cell);
        };
      }

      switch (data.type) {
        case 'uuid':
          data.type = 'string';
          data.sortable = false;
          break;
        case 'Link':
          data.size = 'Table--large';
          data.sortable = false;
          break;
        case 'datetime':
          data.type = 'Date';
          data.align = 'TableHeaderCell--right';
          data.sortOptions = [
            {
              label: 'Sort Oldest -> Newest',
              mode: 'asc',
            },
            {
              label: 'Sort Newest -> Oldest',
              mode: 'desc',
            },
          ];
          break;
        case 'integer':
          data.type = 'number';
          data.align = 'TableHeaderCell--right';
          data.sortOptions = [
            {
              label: 'Sort Low -> High',
              mode: 'asc',
            },
            {
              label: 'Sort High -> Low',
              mode: 'desc',
            },
          ];
          break;
        case 'number':
        case 'money':
        case 'currency':
          if (data.type == 'currency') {
            data.type = 'money';
          }
          data.align = 'TableHeaderCell--right';
          data.filterOptions = [];
          data.sortOptions = [
            {
              label: 'Sort Low -> High',
              mode: 'asc',
            },
            {
              label: 'Sort High -> Low',
              mode: 'desc',
            },
          ];
          break;

        default:
          if (['description', 'client_proceeds'].includes(key)) {
            data.size = 'Table--medium';
          }
          if (key in this.selectOptions) {
            // this column has a defined set of options, make those the filter options
            // data.filterOptions = this.selectOptions[key].map(option => {
            //     return {
            //         label: option,
            //         selected: true
            //     };
            // });
          } else {
            data.filterOptions = [];
          }
          data.sortOptions = [
            {
              label: 'Sort Ascending',
              mode: 'asc',
            },
            {
              label: 'Sort Descending',
              mode: 'desc',
            },
          ];
          break;
      }
      return data;
    });
    // preserve requested column order
    return columnData.sort((a, b) => {
      return columns.indexOf(a.id) - columns.indexOf(b.id);
    });
  },
  generateFilterConfig(json, cachedFilterData, focus = 'transaction') {
    // takes the result of fetchAllColumns to generate the filters array for the new report and configure report modals
    // the ui will use the default column type (in types below) unless an override is set in the options array of the config clause (in config below)
    let types = Object.keys(json[`${focus}_focus`]).map((key) => {
      let column = json[`${focus}_focus`][key];
      let [type, subtype] = column.type.split('|');
      return {
        label: column.label,
        value: key,
        header_ref: key,
        type: this.filterTypes[type],
        subtype: this.filterTypes[subtype],
      };
    });
    let config = {};
    types.forEach((type) => {
      if (type.value in this.selectOptions) {
        // this column has a defined set of options. We're only using multi select for now,
        // until there's a reason to allow only one option
        type.type = FilterType.MULTI_SELECT;
        const field = type.value;
        // do we have a cached value for this field?
        let sourceCollection =
          cachedFilterData[field] || this.selectOptions[field];
        // Status field is named the same in transaction and schedule
        // focus, so special case this one.
        if (focus === 'schedule' && field === 'status') {
          sourceCollection = this.selectOptions.recurring_status;
        }

        config[field] = [
          {
            placeholder: 'Select a condition',
            options: [
              {
                label: 'in',
                value: 'in',
              },
              {
                label: 'not in',
                value: 'not_in',
              },
            ],
          },
          {
            placeholder: 'Select an option',
            options: sourceCollection.map((option) => {
              if (typeof option === 'string') {
                // use string for label and value.
                return { label: option, value: option };
              } else if (
                typeof option === 'object' &&
                'label' in option &&
                'value' in option
              ) {
                // the option is in the right format, just use it.
                return option;
              }

            }),
          },
        ];
      } else if (type.type == FilterType.STRING) {
        config[type.value] = [
          {
            placeholder: 'Select a condition',
            options: [
              {
                label: 'like',
                value: 'like',
              },
              {
                label: 'starts with',
                value: 'starts_with',
              },
              {
                label: 'ends with',
                value: 'ends_with',
              },
              {
                label: 'contains',
                value: 'contains',
              },
              {
                label: 'equals',
                value: 'equals',
              },
              {
                label: 'not equal to',
                value: 'not_equal_to',
              },
              {
                label: 'is null',
                value: 'is_null',
                type: FilterType.NONE,
              },
              {
                label: 'is not null',
                value: 'is_not_null',
                type: FilterType.NONE,
              },
            ],
          },
        ];
      } else if (type.type == FilterType.DATE) {
        config[type.value] = [
          {
            placeholder: 'Select a condition',
            options: [
              {
                label: 'on',
                value: 'day_equals',
              },
              {
                label: 'after',
                value: 'gt',
              },
              {
                label: 'before',
                value: 'lt',
              },
              {
                label: 'greater than or equal',
                value: 'gte',
              },
              {
                label: 'less than or equal',
                value: 'lte',
              },
              {
                label: 'this month',
                value: 'month_to_date',
                type: FilterType.NONE,
              },
              {
                label: 'this year',
                value: 'year_to_date',
                type: FilterType.NONE,
              },
              {
                label: 'last month',
                value: 'last_month',
                type: FilterType.NONE,
              },
              {
                label: 'in the last __ days',
                value: 'past_n_days',
                type: FilterType.NUMBER,
              },
              {
                label: 'not on',
                value: 'day_not_equals',
              },
              {
                label: 'is null',
                value: 'is_null',
                type: FilterType.NONE,
              },
              {
                label: 'is not null',
                value: 'is_not_null',
                type: FilterType.NONE,
              },
            ],
          },
        ];
      } else if (type.type == FilterType.UUID) {
        config[type.value] = [
          {
            placeholder: 'Select a condition',
            options: [
              {
                label: 'equals',
                value: 'equals',
              },
              {
                label: 'not equal to',
                value: 'not_equal_to',
              },
              {
                label: 'is null',
                value: 'is_null',
                type: FilterType.NONE,
              },
              {
                label: 'is not null',
                value: 'is_not_null',
                type: FilterType.NONE,
              },
            ],
          },
        ];
      } else if (type.type == FilterType.ARRAY) {
        config[type.value] = [
          {
            placeholder: 'Select a condition',
            options: [
              {
                label: 'has',
                value: 'has',
                type: type.subtype,
              },
              {
                label: 'does not have',
                value: 'does_not_have',
                type: type.subtype,
              },
            ],
          },
        ];
      } else {
        config[type.value] = [
          {
            placeholder: 'Select a condition',
            options: [
              {
                label: 'equals',
                value: 'equals',
              },
              {
                label: 'greater than',
                value: 'gt',
              },
              {
                label: 'greater than or equal',
                value: 'gte',
              },
              {
                label: 'less than',
                value: 'lt',
              },
              {
                label: 'less than or equal',
                value: 'lte',
              },
              {
                label: 'not equal to',
                value: 'not_equal_to',
              },
              {
                label: 'is null',
                value: 'is_null',
                type: FilterType.NONE,
              },
              {
                label: 'is not null',
                value: 'is_not_null',
                type: FilterType.NONE,
              },
            ],
          },
        ];
      }
    });
    // console.log("Generated filters", types.map(t => `${t.label}, ${t.value}, ${t.type}`));
    return { types, config };
  },
  generateFilterData(data) {
    // translate filter data from ui to an array consumable by graphql
    let filters = {};
    data.forEach((filter) => {
      if (typeof filter.filterValue === 'object') {
        // convert from Set -> Array for multiple selections
        filter.filterValue = Array.from(filter.filterValue);
      } else if (typeof filter.filterValue === 'undefined') {
        // some filters don't have a value, but graphql needs one
        filter.filterValue = true;
      }
      if (filter.condition.label == 'is_null') {
        filter.filterValue = true;
      }
      if (filter.condition.label == 'is_not_null') {
        filter.condition.label = 'is_null';
        filter.filterValue = false;
      }
      if (typeof filters[filter.header_ref] !== 'object') {
        filters[filter.header_ref] = {};
      }
      filters[filter.header_ref][filter.condition.label] = filter.filterValue;
    });
    return filters;
  },
};
