// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class TextField {
  constructor() {}
}

angular.module('idonate.gms').component('textField', {
  templateUrl: '/scripts/input/text-field.html',
  bindings: {
    field: '=',
    hasClear: '=?',
  },
  controller: TextField,
});
