angular
  .module('idonate.gms')
  .controller(
    'OrganizationUsersItemCtrl',
    function (
      $scope,
      $routeParams,
      UserService,
      organizationService,
      $location,
      orgUserService,
      $rootScope
    ) {
      $scope.user = {};
      $scope.roles = ['Admin', 'Editor', 'Viewer'];
      $scope.saveUser = function () {
        const user = {
          firstname: $scope.user.firstname,
          middlename: $scope.user.middlename,
          lastname: $scope.user.lastname,
          phone: $scope.user.phone,
          username: $scope.user.email,
          user_email: $scope.user.email,
          role: $scope.user.role,
        };
        if ($routeParams.userid === 'new') {
          orgUserService.newUserOrganization(user).then(redirectUser);
        } else {
          orgUserService.updateUserOrganization(user).then(redirectUser);
        }
      };

      function redirectUser() {
        $location.path('organization/users');
      }

      $scope.$watch('user.role', (newVal) => {
        if (newVal) {
          $scope.validRole = true;
        }
      });

      if ($routeParams.userid === 'new') {
        return;
      }

      $scope.user.id = $routeParams['userid'];
      UserService.getUserInfo($routeParams.userid).then((result) => {
        const { user } = result;
        $scope.user.id = user.id;
        $scope.user.firstname = user.contact.firstname;
        $scope.user.middlename = user.contact.middlename;
        $scope.user.lastname = user.contact.lastname;
        $scope.user.phone = parseInt(user.contact.phone);
        $scope.user.email = user.email;
        for (let role of result.roles) {
          if (
            role.target_id === organizationService.getCurrentOrganization().id
          ) {
            $scope.user.role = role.role;
          }
        }
      });
      if ($rootScope.allowRole(['SuperAdmin', 'Admin'])) {
        UserService.getApi($scope.user.id).then((result) => {
          $scope.apiKey = result.key;
        });
      }
      $scope.generateApiKey = () => {
        UserService.postApi($scope.user.id).then((result) => {
          $scope.apiKey = result.key;
        });
      };
    }
  );
