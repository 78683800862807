// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('booleanField', function () {
  let uniqueId = 1;

  return {
    templateUrl: '/scripts/input/boolean-field.html',
    scope: {
      value: '=model',
      label: '@?',
      description: '@?',
      on: '@?',
      off: '@?',
      itemChanged: '=?',
      disabled: '=?isDisabled',
      type: '@?',
    },
    controllerAs: 'ctrl',
    bindToController: true,
    restrict: 'E',
    controller($scope, $timeout) {
      const ctrl = this;
      ctrl.uniqueId = `booleanField${uniqueId++}`;
      if (ctrl.on === undefined) {
        ctrl.on = 'On';
      }
      if (ctrl.off === undefined) {
        ctrl.off = 'Off';
      }
      if (!ctrl.type) {
        ctrl.type = 'switch';
      }

      ctrl.changed = () =>
        $timeout(function () {
          if (ctrl.itemChanged) {
            return ctrl.itemChanged();
          }
        });
    },
  };
});
