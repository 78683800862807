angular.module('idonate.gms').directive('telephoneInput', [
  '$log',
  '$rootScope',
  '$window',
  '$parse',
  '$timeout',
  (function (_this) {
    return function ($log, $rootScope, $window, $parse, $timeout) {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attr, ctrl) {
          var _elem, msk;
          _elem = $window.intlTelInput(elm[0], {
            initialCountry: attr.telCountryCode || 'us',
          });
          msk = elm[0].placeholder.replace(/\d/g, 9);
          elm[0].placeholder = msk;
          elm.inputmask(msk);
          if (
            (!!attr.type && attr.type !== 'text' && attr.type !== 'tel') ||
            elm[0].tagName !== 'INPUT'
          ) {
            $log.warn(
              'intl-tel-input can only be applied to a *text* or *tel* input'
            );
            return;
          }
          angular.element($window).on('countrychange', function (e) {
            elm[0].val = '';
            msk = elm[0].placeholder.replace(/\d/g, 9);
            elm[0].placeholder = msk;
            return elm.inputmask(msk);
          });
          ctrl.$validators.intlTelInput = function (value) {
            if (value && String(value).length > 0) {
              _elem.setNumber(value);
              if (!_elem.isValidNumber()) {
                angular.element(elm[0]).parent().addClass('invalid-phone');
                return false;
              } else {
                angular.element(elm[0]).parent().removeClass('invalid-phone');
                return true;
              }
            }

            return _elem.required !== true;
          };
          ctrl.$parsers.push(function (value) {
            var num;
            num = _elem.getNumber();
            return num;
          });
          ctrl.$formatters.push(function (value) {
            var dc, newVal;
            if (value) {
              if (value.charAt(0) === '+') {
                _elem.setNumber(value);
                dc = String(_elem.getSelectedCountryData().dialCode).length;
                newVal = value.substring(1 + dc);
                $timeout(function () {
                  return _elem.setNumber(newVal);
                });
              }
            }
            return value;
          });
        },
      };
    };
  })(this),
]);
