// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class ManageAdvocateModal {
  /* @ngInject */
  constructor(
    advocate,
    teams,
    baseLink,
    $uibModalInstance,
    $window,
    $scope,
    p2pService
  ) {
    this.removeAdvocatePage = this.removeAdvocatePage.bind(this);
    this.endAdvocatePage = this.endAdvocatePage.bind(this);
    this.reload = this.reload.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.advocate = advocate;
    this.$uibModalInstance = $uibModalInstance;
    this.$window = $window;
    this.p2pService = p2pService;
    this.viewPage = `${baseLink}/a/${this.advocate.advocate_id}`;
    this.disabled = false;
    this.removedPage = false;
    this.endedPage = false;
    this.changedTeam = false;
    if (!this.advocate.team.is_public) {
      this.privateTeam = true;
    }
    this.teamNames = { '': '' };
    this.teamMembersLength = {};
    for (let team of Array.from(teams)) {
      this.teamNames[team.id] = team.name;
      this.teamMembersLength[team.id] =
        (team.members != null ? team.members.length : undefined) || 0;
    }
    this.teamChoices = {
      label: 'Add Fundraiser to Team:',
      value: '',
      choices: this.teamNames,
      disabled: false,
      placeholder: 'Pick a team',
    };
    $scope.$watch('$ctrl.teamChoices.value', (nv, ov) => {
      if (nv !== ov && nv !== '' && nv !== this.advocate.team_id) {
        this.changedTeam = true;
        this.message =
          "Are you sure you want to change the team? Click 'Cancel' or 'Save'.";
        if (!this.teamMembersLength[this.advocate.team_id]) {
          this.message =
            "Are you sure you want to convert to team member page? This action cannot be undone. Click 'Cancel' or 'Save'.";
        }
        return (this.dataChanged = true);
      }
    });
  }
  removeAdvocatePage() {
    this.message =
      "This page's URL will no longer be accessible. This action cannot be undone. Click 'Save' to confirm this action";
    this.dataChanged = true;
    return (this.removedPage = true);
  }
  endAdvocatePage() {
    this.message =
      "Ending this Fundraiser's page cannot be undone. If this Fundraiser is a Team Sponsor, all pages for team members will also be ended. Click 'Save' to confirm this action.";
    this.dataChanged = true;
    return (this.endedPage = true);
  }
  reload() {
    return this.$uibModalInstance.close('saved');
  }
  save() {
    const selectedTeamId = this.teamChoices.value;
    // only save if the new team id is different
    if (selectedTeamId !== '' && selectedTeamId !== this.advocate.team_id) {
      this.p2pService
        .addAdvocateToTeam(selectedTeamId, this.advocate.advocate_id)
        .then((res) => {
          this.reload();
          return this.cancel();
        });
    } else if (!this.removedPage && !this.endedPage) {
      this.cancel();
      return;
    }
    if (this.removedPage) {
      this.p2pService
        .kickAdvocateFromTeam(this.advocate.team_id, this.advocate.advocate_id)
        .then((res) => {
          this.reload();
          return this.cancel();
        });
    }
    if (this.endedPage) {
      return this.p2pService
        .expireAdvocate(this.advocate.team_id, this.advocate.advocate_id)
        .then((res) => {
          this.reload();
          return this.cancel();
        });
    }
  }
  cancel() {
    return this.$uibModalInstance.dismiss('cancel');
  }
}

angular
  .module('idonate.gms.legacy-p2p')
  .directive('manageAdvocateModal', (Modals) => {
    Modals.registerModal('ManageAdvocateModal', (resolve) => {
      return {
        size: 'lg',
        windowClass: 'advocate-modal',
        templateUrl: '/scripts/legacy-p2p/manage-advocate-modal.html',
        controllerAs: '$ctrl',
        resolve,
        controller: ManageAdvocateModal,
      };
    });
    return { restrict: 'A' };
  });
