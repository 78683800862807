// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').component('noncashEmbed', {
  template: `\
<div class="page-heading">
  <h1>Noncash Embed for Mobile App</h1>
</div>
<form class="row page-full-container" ng-submit="$ctrl.save()">
  <div class="page-main">
    <div class="panel">
      <div class="panel-body">
        <div class="form-group">
          <label for="">
            Add Embed
            <i uib-tooltip="Choose the embed you'd like to use for noncash gifts within the Mobile App." class="icon-question-mark"></i>
          </label>
          <ui-select ng-model="$ctrl.embed">
            <ui-select-match placeholder="Embeds">
              <span ng-bind="$select.selected.name"></span>
            </ui-select-match>
            <ui-select-choices repeat="item.id as item in $ctrl.embeds | filter: $select.search">{{item.name}}</ui-select-choices>
          </ui-select>
        </div>
        <div class="form-group float-right">
          <button type="submit" class="btn btn-primary">Save Changes
            <span ng-if="$ctrl.pending"><div class="idonate-spinner" alt="Loader"></div></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>\
`,
  controller($timeout, websiteService, organizationService) {
    this.embeds = [];
    this.embed = null;
    this.pending = false;
    websiteService
      .getEmbedInstances()
      .then((result) =>
        result.result.embed_instances.filter(
          (item) => item.is_enabled && item.type === 'unified_giving_v2'
        )
      )
      .then((instances) => {
        return (this.embeds = instances);
      });
    organizationService.loadFullOrganizationInformation().then((result) => {
      return (this.embed =
        result.result.attributes.config.mobile.noncash_embed != null
          ? result.result.attributes.config.mobile.noncash_embed.id
          : undefined);
    });
    this.save = () => {
      this.pending = true;
      return organizationService
        .updateAttributeWithPath('config.mobile.noncash_embed', this.embed)
        .then(() => {
          return (this.pending = false);
        });
    };
  },
});
