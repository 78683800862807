import './campaign-item-metrics.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const metrics = () => ({
  templateUrl: '/scripts/campaign/campaign-item/campaign-item-metrics.html',
  restrict: 'E',
  controller: metricsController,
});
angular
  .module('idonate.gms.campaign')
  .directive('campaignItemMetrics', metrics);

var metricsController = function (
  $scope,
  $routeParams,
  $rootScope,
  $element,
  $timeout,
  $filter,
  $window,
  organizationService,
  DashboardAPI,
  transactionService,
  appState
) {
  $scope.currentOrganization = appState.currentOrganization;
  $scope.organizations = appState.organizationList;

  $scope.allowRole = $rootScope.allowRole;

  $scope.utcToggle = false;

  //Time Period
  $scope.time = {
    start: moment().subtract(12, 'months'),
    end: moment(),
  };

  //Summary
  $scope.donations = {};
  $scope.conversions = {};
  $scope.CustomTime = {};

  const updateSummaries = function (data) {
    $scope.donations.Total = Math.round(data.result.aggregate.total.sum);
    $scope.donations.TotalPercent =
      Math.round(data.result.aggregate_delta.total.sum * 100) || 0;
    $scope.donations.Count = data.result.aggregate.total.count;
    $scope.donations.Average = data.result.aggregate.total.average;
    $scope.donations.AveragePercent =
      Math.round(data.result.aggregate_delta.total.average * 100) || 0;
    $scope.donations.range = {};
    $scope.donations.range.low = data.result.aggregate.total.minimum;
    return ($scope.donations.range.high = data.result.aggregate.total.maximum);
  };

  const generateSummary = function () {
    let organizationID = $scope.currentOrganization.id;

    //CAMPAIGN
    const campaignID = $routeParams.campaignId;
    const start_date = $scope.current.start.toISOString();
    const end_date = $scope.current.end.toISOString();
    const previous_start_date = $scope.previous.start.toISOString();
    const previous_end_date = $scope.previous.end.toISOString();

    if ($scope.utcToggle) {
      console.log('UTC MODE');
    }

    const obj = {
      organization_id: organizationID,
      campaign_id: campaignID,
      start_date,
      end_date,
      previous_start_date,
      previous_end_date,
      transaction_status: 'complete',
      use_finalized: true,
      plot_by: 'day',
      timezone: $scope.utcToggle ? 'UTC' : moment.tz.guess(),
    };

    const dateDiff = $scope.current.end.diff($scope.current.start, 'days');
    let dateFmt = 'MM-DD-YYYY';

    if (dateDiff > 45) {
      obj['plot_by'] = 'month';
      dateFmt = "MMM 'YY";
    } else {
      obj['plot_by'] = 'day';
    }

    transactionService.getTransactionReportSummary(obj).then(function (data) {
      updateSummaries(data);
      return setChartStuff(data, dateFmt);
    });

    return DashboardAPI.getCampaignSummary(obj).then(
      (response) => ($scope.visits = response)
    );
  };

  var setChartStuff = function (data, dateFmt) {
    $scope.chart = data.result.chart;
    $scope.chart.series.cash.data['fill'] = false;
    $scope.chart.series.noncash.data['fill'] = false;
    $scope.chart.series.average.data['fill'] = false;
    $scope.chart.data = [
      $scope.chart.series.cash.data,
      $scope.chart.series.noncash.data,
      $scope.chart.series.average.data,
    ];
    $scope.chart.labels = [];
    $scope.chart.options = {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              maxTicksLimit: 10,
              beginAtZero: true,
              display: true,
              callback(value, index, values) {
                return addCommas(value);
              },
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              zeroLineColor: 'rgba(0, 0, 0, 0)',
            },
          },
        ],
      },
      tooltips: {
        titleFontSize: 14,
        bodyFontSize: 14,
        bodySpacing: 5,
        callbacks: {
          label(tooltipItem, data) {
            return addCommas(tooltipItem.yLabel);
          },
        },
      },
    };
    angular.forEach($scope.chart.categories, (val, key) =>
      $scope.chart.labels.push(moment(val).format(dateFmt))
    );

    $scope.chart.types = ['Cash', 'Noncash', 'Average'];
    return ($scope.chart.colors = ['#F16B82', '#F6D879', '#16ABD3']);
  };

  $window.addCommas = (value) =>
    $filter('currency')(value, $rootScope.currencyCode, '0');

  $scope.graphOnClick = function (points, evt) {};
  //console.log points, evt

  $scope.setChart = function (type) {
    if (type === 'total') {
      $scope.chart.data = [
        $scope.chart.series.cash.data,
        $scope.chart.series.noncash.data,
        $scope.chart.series.average.data,
      ];
      $scope.chart.types = ['Cash', 'Noncash', 'Average'];
      return ($scope.chart.colors = ['#F16B82', '#F6D879', '#16ABD3']);
    } else if (type === 'cash') {
      $scope.chart.data = [$scope.chart.series.cash.data];
      $scope.chart.types = ['Cash'];
      return ($scope.chart.colors = ['#F16B82']);
    } else if (type === 'noncash') {
      $scope.chart.data = [$scope.chart.series.noncash.data];
      $scope.chart.types = ['Noncash'];
      return ($scope.chart.colors = ['#F6D879']);
    }
  };

  $scope.reloadSummary = function () {
    $scope.utcToggle = !$scope.utcToggle;
    if ($scope.current) {
      return generateSummary();
    }
  };

  return ($scope.intervalCallback = function (current, previous) {
    $scope.current = current;
    $scope.previous = previous;
    generateSummary();
  });
};
