class PasswordField {
  constructor() {}
}

angular.module('idonate.gms').component('passwordField', {
  templateUrl: '/scripts/input/password-field.html',
  bindings: {
    field: '=',
    hasClear: '=?',
  },
  controller: PasswordField,
});
