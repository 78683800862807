angular.module('idonate.gms.donors', []).config(($routeProvider) =>
  $routeProvider
    .when('/donors', {
      templateUrl: '/scripts/donors/donors.html',
      controller: 'DonorsCtrl',
      menu: {
        style: 'icon-donors',
        name: 'Donors',
        level: 1,
        position: 4,
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/donors/detail/:donorId', {
      templateUrl: '/scripts/donors/donor-detail.html',
      controller: 'DonorsDetailCtrl',
      menu: {
        parent: 'Donors',
        name: 'Detail',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        donor($route, donorService) {
          'ngInject';
          return donorService.getDonor($route.current.params.donorId);
        },
      },
    })
    .when('/donors/mapping', {
      template: '<donor-mapping></donor-mapping>',
      menu: {
        parent: 'Donors',
        name: 'Mapping',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'external_service_mapping',
      },
    })
    .when('/donors/merge', {
      templateUrl: '/scripts/donors/donor-merge.html',
      controller: 'DonorsMergeCtrl',
      menu: {
        parent: 'Donors',
        name: 'Merge',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
    })
);
