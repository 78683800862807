// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './campaignGoals.html';
angular.module('idonate.gms.donations').directive('campaignGoals', function () {
  return {
    templateUrl: '/scripts/donations/campaignGoals.html',
    restrict: 'E',
    scope: {},
    controllerAs: 'ctrl',
    bindToController: {},
    replace: true,
    controller(organizationService) {
      const ctrl = this;
      organizationService
        .getOrganizationGoalMeters()
        .then((result) => (ctrl.goalMeters = result.result));
    },
  };
});
