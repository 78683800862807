// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './contactsAjaxSortableTable.html';
('use strict');

angular
  .module('idonate.gms.donations')
  .directive(
    'contactsAjaxSortableTable',
    ($timeout, $filter, $rootScope, SessionService, appState) => ({
      restrict: 'E',
      templateUrl: '/scripts/donations/contactsAjaxSortableTable.html',
      controller($scope, $element, $attrs) {
        return ($scope.uniqueId = `GMS-ST${Math.floor(
          Math.random() * 10000000 + 1000
        )}`);
      },
      link(scope, element, attrs) {
        let table = null;
        let firstGenerate = true;
        const pageLimit = 10;
        scope.showTable = true;

        scope.columns = [
          {
            name: 'firstname',
            text: 'First Name',
            searchable: 'ilike',
            render: $.fn.dataTable.render.text(),
          },
          {
            name: 'middlename',
            text: 'Middle Name',
            searchable: 'ilike',
            render: $.fn.dataTable.render.text(),
          },
          {
            name: 'lastname',
            text: 'Last Name',
            searchable: 'ilike',
            render: $.fn.dataTable.render.text(),
          },
          {
            name: 'email',
            text: 'Email',
            searchable: 'ilike',
            render: $.fn.dataTable.render.text(),
          },
          {
            name: 'phone',
            text: 'Phone',
            searchable: 'ilike',
            render: $.fn.dataTable.render.text(),
          },
        ];

        const removeTable = function () {
          if (table != null) {
            table.fnDestroy(true);
          }
          return $(`#${scope.uniqueId} .dataTables_wrapper`).remove();
        };

        const buildTable = () =>
          $timeout(function () {
            $(`#${scope.uniqueId}_temp .table`)
              .clone()
              .appendTo(`#${scope.uniqueId}`);
            firstGenerate = false;
            table = $(`#${scope.uniqueId} table`).dataTable(
              {
                serverSide: true,
                processing: true,
                sDom: "<'row'<'col-6'T><'col-6'f>r><'panel panel-table table-responsive't><'row'<'col-sm-3'l><'col-sm-6'p>>",
                oLanguage: {
                  sLengthMenu: 'Show _MENU_ per page',
                  oPaginate: {
                    sNext: '<i class="icon-right"></i>',
                    sPrevious: '<i class="icon-left"></i>',
                  },
                },
                pageLength: pageLimit,
                columns: scope.columns,
                order: [[0, 'desc']],
                ajax: {
                  url: `${window.iDonateConfig.baseUrl}organization/${appState.currentOrganization.id}/contacts?tags=donor&output=lead`,
                  dataType: 'json',
                  beforeSend: (request) => {
                    // grab authentication headers from API client.
                    const headers = SessionService.getAuthHeaders();

                    Object.keys(headers).forEach((key) => {
                      request.setRequestHeader(key, headers[key]);
                    });
                  },
                },
              },

              $('#contacts-outside-search').keyup(function () {
                return table.fnFilter($(this).val());
              })
            );
          });
        $timeout(() => buildTable());

        scope.export = function (format) {
          const urlParams = {
            export: format,
            tags: 'donor',
            columns: scope.columns,
            order: [{ column: 0, dir: 'asc' }],
          };

          SessionService.downloadGmsApiResult(
            `organization/${appState.currentOrganization.id}/contacts`,
            urlParams,
            `Contacts-donor.${format}`
          );
        };

        return (scope.reload = function () {
          removeTable();
          return buildTable();
        });
      },
    })
  );
