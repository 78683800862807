export class Modals {
  static $inject = ['$uibModal'];
  constructor($uibModal) {
    this.open = this.open.bind(this);
    this.registerModal = this.registerModal.bind(this);
    this.$uibModal = $uibModal;
  }
  open(name, resolve) {
    if (this.modals && this.modals[name]) {
      return this.$uibModal.open(this.modals[name](resolve)).result;
    }
  }
  registerModal(name, modal) {
    if (!this.modals) {
      this.modals = {};
    }
    return (this.modals[name] = modal);
  }
}
