export class GlobalService {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  _openModals = [];

  closeAll() {
    this._openModals.map((modal) => modal.close());
  }

  addSentryInformation(obj) {
    let actualSentryData = gmsStorage.sentryAdditionalData
      ? JSON.parse(gmsStorage.sentryAdditionalData)
      : {};
    actualSentryData = angular.extend(actualSentryData, obj);
    gmsStorage.sentryAdditionalData = JSON.stringify(actualSentryData);
    return actualSentryData;
  }

  forceLogOutError(title, msg) {
    const logOutModal = this.$uibModal.open({
      template:
        '<div class="modal-header">' +
        '<h2>' +
        title +
        '</h2>' +
        '</div>' +
        '<div class="modal-body">' +
        msg +
        '</div>' +
        '<div class="modal-footer">' +
        '<button class="btn btn-primary" ng-click="closeAndOut()">Log out</button> ' +
        '</div>',
      controller($scope, auth, $uibModalInstance) {
        return ($scope.closeAndOut = function () {
          $uibModalInstance.close();
          auth.logout();
          // clear localStorage on forceLogOut
          gmsStorage.clear();

          // explicitly clear session cookie too
          document.cookie = `session=; expires=${new Date()}; domain=${
            document.domain
          }; path=;`;
        });
      },
      backdrop: 'static',
      keyboard: false,
    });
    this._openModals.push(logOutModal);
  }

  alertModal(title, msg, actionBtn, callback, cancelMsg, cancelCallback) {
    const alertModal = this.$uibModal.open({
      template:
        '<div class="modal-header">' +
        '<h2>' +
        title +
        '</h2>' +
        '</div>' +
        '<div class="modal-body">' +
        msg +
        '</div>' +
        '<div class="modal-footer">' +
        '<button class="btn btn-error" ng-click="cancel()">{{cancelMsg || "Cancel"}}</button> ' +
        '<button class="btn btn-primary" ng-click="callback()">' +
        actionBtn +
        '</button> ' +
        '</div>',
      controller($scope, $uibModalInstance) {
        $scope.cancelMsg = cancelMsg;
        $scope.cancel = function () {
          if (cancelCallback) {
            return cancelCallback($uibModalInstance.close);
          }
        };
        return ($scope.callback = () => callback($uibModalInstance.close));
      },
      backdrop: 'static',
      keyboard: false,
    });
    this._openModals.push(alertModal);
  }

  cleargmsStorageData() {
    const username = gmsStorage.username ? gmsStorage.username : null;

    const gmsTablePageLimit = gmsStorage.gmsTablePageLimit
      ? gmsStorage.gmsTablePageLimit
      : undefined;

    const gmscolumns = gmsStorage.gmscolumns
      ? gmsStorage.gmscolumns
      : undefined;

    gmsStorage.clear();

    if (username) {
      gmsStorage.username = username;
    }
    if (gmsTablePageLimit) {
      gmsStorage.gmsTablePageLimit = gmsTablePageLimit;
    }
    if (gmscolumns) {
      gmsStorage.gmscolumns = gmscolumns;
    }
  }
}
