// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('embedField', () => ({
  templateUrl: '/scripts/input/embed-field.html',
  scope: {
    field: '=field',
    extraData: '=extra',
  },
  restrict: 'E',
  controller($scope) {
    let tags = ['unified_giving', 'unified_giving_v2', 'single_page'];
    if ($scope.field.tags.includes('Goal Meter')) {
      tags = ['goal_meter'];
    }
    $scope.instances = {
      choices: _.filter($scope.extraData.embedInstances, (item) =>
        tags.includes(item.type)
      ),
    };
    $scope.val = _.find($scope.extraData.embedInstances, {
      id: $scope.field.value,
    });
    return $scope.$watch('val.id', function (a) {
      $scope.field.value = a || '';
    });
  },
}));
