// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const singleMetric = () => ({
  templateUrl: '/scripts/legacy-p2p/p2p-single-metric.html',
  restrict: 'E',
  scope: {
    current: '=',
    previous: '=',
    teams: '=',
    advocates: '=',
  },
  controller: metricsController,
});
angular
  .module('idonate.gms.legacy-p2p')
  .directive('p2pSingleMetric', singleMetric);

var metricsController = function ($scope, p2pService, $route, $filter) {
  $scope.pageTabSelected = {
    all: true,
    program: false,
  };
  $scope.metricsTooltips = {
    top: 'Programs identified in green are in the top 1/3 of all programs in total donations',
    middle:
      'Programs identified in yellow are in the middle 1/3 of all programs in total donations',
    low: 'Programs identified in red are in the bottom 1/3 of all programs in total donations',
  };

  const program = null;

  $scope.lineFormat = function (value) {
    const filterVal = $filter('number')(value, 0);
    if (filterVal !== '1') {
      return `${filterVal} donors`;
    }
    return `${filterVal} donor`;
  };

  return ($scope.intervalCallback = function (current, previous) {
    const params = {
      start_date: new Date(current.start._d).toISOString(),
      end_date: new Date(current.end._d).toISOString(),
      previous_start_date: new Date(previous.start._d).toISOString(),
      previous_end_date: new Date(previous.end._d).toISOString(),
      program_id: $route.current.params.programId,
      timezone: moment.tz.guess(),
    };

    p2pService.getP2PProgram(params).then(function (data) {
      const { result } = data;
      $scope.totalRaised = result.amount_raised;
      $scope.raisedPercentage = result.amount_raised_delta_percent;

      $scope.totalAdvocates = result.individuals.length;
      $scope.advocatePercentage = result.advocate_count_delta_percent;

      $scope.programStart = result.program.start_date;
      $scope.programEnd = result.program.end_date;
      $scope.designationLabel = result.program.designation_title;
      $scope.programName = result.program.name;

      const dateFmt = "MMM 'YY";
      $scope.donorChart = result.donors_chart;
      $scope.donorChart.data = [$scope.donorChart.datasets.data];
      $scope.donorChart.data['fill'] = false;
      $scope.donorChart.colors = ['#40BEB3'];
      const { labels } = $scope.donorChart;
      $scope.donorChart.labels = [];
      angular.forEach(labels, (val, key) =>
        $scope.donorChart.labels.push(moment(val).format(dateFmt))
      );
      $scope.fundraiserChart = result.advocates_chart;
      return ($scope.teamChart = result.teams_chart);
    });
  });
};
