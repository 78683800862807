import './text-module';
import './autoResponderKeywords-item';
import './getAttbyId';
import './giveKeywords-item';
import './keywords';
import './settings';
import './autoresponder-keywords-item.html';
import './autoresponder-keywords.html';
import './giving-keywords-item.html';
import './giving-keywords.html';
import './settings.html';
