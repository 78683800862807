angular.module('idonate.gms').component('designatedFundImport', {
  template: `
    <div class="page-heading">
      <h1>Import Designated Funds</h1>
      <div class="heading-buttons">
        <a class="btn btn-default" href="/organization/designated-funds">Back</a>
      </div>
    </div>
    <div class="row page-full-container">
      <form class="page-main" ng-submit="$ctrl.save()" novalidate name="designationForm">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="form-group col-md-8 col-12">
                <button class="btn btn-primary" ng-click="$ctrl.download()">Download Template</button>
              </div>
              <file-uploader class="col-md-8 col-12" model="$ctrl.file"></file-uploader>
            </div>
            <button class="float-right btn btn-primary" type="submit">
              Import
              <span>
                <div ng-show="$ctrl.loading" class="idonate-spinner" alt="Loader"></div>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  `,
  controller: function (
    $window,
    notyService,
    organizationService,
    CampaignService,
    SessionService
  ) {
    this.file = null;
    this.progress = 0;

    this.download = () => {
      CampaignService.downloadImportTemplate();
    };
    this.save = () => {
      if (!this.file) {
        this.loading = false;
      } else {
        this.loading = true;
        const orgId = organizationService.getCurrentOrganization().id;
        SessionService.uploadToGmsApi(
          `/import/designation?organization_id=${orgId}`,
          { data: { file: this.file } }
        ).then(
          () => {
            notyService.success('Added import file to processing queue.');
            this.loading = false;
          },
          (error) => {
            notyService.error(error.data.message);
            this.loading = false;
          },
          (evt) => {
            this.progress = parseInt((100.0 * evt.loaded) / evt.total);
          }
        );
      }
    };
  },
});
