// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class ManageDonorModal {
  /* @ngInject */
  constructor(
    donorInfo,
    p2pService,
    transactionService,
    $uibModalInstance,
    $scope
  ) {
    this._setInitialValues = this._setInitialValues.bind(this);
    this._teamWatch = this._teamWatch.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.p2pService = p2pService;
    this.$uibModalInstance = $uibModalInstance;
    this.donorName = donorInfo.donorName;
    this.transactionId = donorInfo.transactionId;
    this.teamNames = { '': '' };
    this.programId = donorInfo.programId;
    this.advocates = donorInfo.advocates;
    for (let team of Array.from(donorInfo.teams)) {
      this.teamNames[team.id] = team.name;
    }
    this.teamChoices = {
      value: '',
      choices: this.teamNames,
      disabled: false,
      placeholder: 'Pick a team',
    };
    this.advocateNames = { '': '' };
    for (let advocate of Array.from(donorInfo.advocates)) {
      this.advocateNames[advocate.advocate_id] = advocate.advocate_name;
    }
    this.advocateChoices = {
      value: '',
      choices: this.advocateNames,
      disabled: false,
      placeholder: 'Pick an advocate',
    };
    $scope.$watch('$ctrl.teamChoices.value', this._teamWatch);
    transactionService
      .getTransaction(this.transactionId)
      .then(this._setInitialValues);
  }
  _setInitialValues(result) {
    const trans = result.result.transaction;
    if (trans.advocate_id) {
      this.advocateChoices.value = trans.advocate_id;
    }
    if (trans.advocacy_team_id) {
      return (this.teamChoices.value = trans.advocacy_team_id);
    }
  }
  _teamWatch(nv) {
    this.advocateNames = { '': '' };
    for (let advocate of Array.from(this.advocates)) {
      if (!nv || nv === advocate.team_id) {
        this.advocateNames[advocate.advocate_id] = advocate.advocate_name;
      }
    }
    return (this.advocateChoices.choices = this.advocateNames);
  }
  cancel() {
    return this.$uibModalInstance.dismiss('cancel');
  }
  save() {
    return this.p2pService
      .setDonorAdvocacy({
        programId: this.programId,
        transactionId: this.transactionId,
        teamId: this.teamChoices.value,
        advocateId: this.advocateChoices.value,
      })
      .then((result) => {
        return this.$uibModalInstance.close('saved');
      });
  }
}

angular
  .module('idonate.gms.legacy-p2p')
  .directive('manageDonorModal', (Modals) => {
    Modals.registerModal('ManageDonorModal', (resolve) => {
      return {
        size: 'md',
        windowClass: 'advocate-modal',
        templateUrl: '/scripts/legacy-p2p/manage-donor-modal.html',
        controllerAs: '$ctrl',
        resolve,
        controller: ManageDonorModal,
      };
    });
    return { restrict: 'A' };
  });
