angular.module('idonate.gms').component('waysToGrowGiving', {
  template: `\
    <a class="float-right grow-giving-notify username" href=""
        popover-trigger="'click'" tabindex="0"
        uib-popover-template="'ways-to-grow.html'" popover-placement="bottom">
      <span>
        <i class="icon-bell"></i>
        <span class="badge badge-pill badge-notify">{{$ctrl.messages.length}}</span>
      </span>
    </a>
    <script type="text/ng-template" id="ways-to-grow.html">
      <div>
        <div class="panel grow-giving">
          <h2 class="giving-title badge badge-pill badge-default">
            Ways to Grow Giving
          </h2>
          <div ng-repeat="message in $ctrl.messages" class="giving-row">
            <button type="button" ng-click="$ctrl.clearMessage(message.id)" class="close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h3 class="giving-item-title">{{message.title}}</h3>
            <div ng-bind-html="message.text | trust" class="giving-message"></div>
            <a ng-repeat="action in message.actions" class="action"
               ng-href="{{action.link.startsWith('http') ? action.link : '/' + action.link}}"
               target="{{action.link.startsWith('http') ? '_blank' : ''}}">
              {{action.text}}
            </a>
          </div>
        </div>
      </div>
    </script>
  `,
  controller(UserMessagesAPI, $route, organizationService, $rootScope) {
    const actionMap = {
      showTransactions() {
        return 'donations';
      },
      showTransaction(load) {
        return `donations/detail/${load.transaction_id}`;
      },
      showRecurring(load) {
        return `donations/recurring/${load.recurring_id}`;
      },
      showRecurringList() {
        return 'donations/recurring';
      },
      showCampaign(load) {
        return `campaign/${load.campaign_id}`;
      },
      showUrl(load) {
        return load.url;
      },
      showEmbed() {
        return 'embeds';
      },
      showGoalMeter() {
        return 'goal-meters';
      },
      showCustomizeGiftReceipt() {
        return 'organization/cash-options/customize-receipt';
      },
      showEvent() {
        return 'event';
      },
      showSms() {
        return 'text';
      },
      showDesignation() {
        return 'organization/designated-funds';
      },
      showCampaign() {
        return 'campaigns';
      },
      showHelp() {
        return 'help';
      },
    };
    const getAction = function (action) {
      const actionLink = actionMap[action.action];
      return {
        text: action.title,
        link: actionLink ? actionLink(action.payload) : undefined,
      };
    };
    $rootScope.$on('changeOrganization', (event, data) => getMessages(data));
    var getMessages = (newOrgId) => {
      this.messages = [];
      return UserMessagesAPI.getMessages({
        tag: 'grow-giving',
        organization_id: newOrgId,
      }).then(setMessages);
    };
    var setMessages = (response) => {
      return Array.from(response).map((item) =>
        this.messages.push({
          id: item.id,
          title: item.message,
          text: item.details != null ? item.details.content : undefined,
          actions: (item.details != null ? item.details.actions : undefined)
            ? Array.from(item.details.actions).map((action) =>
                getAction(action)
              )
            : undefined,
        })
      );
    };
    this.clearMessage = (id) => {
      return UserMessagesAPI.deleteMessage(id).then(() => {
        return _.remove(this.messages, (item) => item.id === id);
      });
    };

    getMessages(organizationService.getCurrentOrganization().id);
  },
});
