<template>
  <div>
    <PaginationReportTable
      :focus="currentReport.dataSrc"
      :canDelete="currentReport.canDelete"
      :currentPage="currentPage"
      :defaultFormat="defaultFormat"
      :defaultRowsPerPage="25"
      :downloadFormats="sendMessageProps.fileTypeConfig.options"
      :headers="headers"
      :initialFilters="currentFilters"
      :isDefaultReport="defaultReportId == reportId"
      :isLoading="isLoading"
      :newReport="newReport"
      :reportId="reportId"
      :reports="reports"
      :rollUpAllLocations="isRollup"
      :rows="rows"
      :rowsPerPageOptions="rowsPerPageOptions"
      :saveCustomViewProps="saveCustomViewProps"
      :searchValue="searchTerm"
      :sendMessageProps="sendMessageProps"
      :totalPageNumber="0"
      @rowClicked="handleRowClicked"
      @configChange="customizeReport"
      @deleteReport="deleteReport"
      @download="downloadReport"
      @focusChange="changeFocus"
      @onFiltersChanged="handleFiltersChanged"
      @goToCampaigns="goToCampaigns"
      @makeDefault="setDefaultReport"
      @refresh="reloadTable"
      @reportChange="changeReport"
      @request="reloadTable"
      @saveCustomView="saveCustomView"
      @saveNewReport="createReport"
      @saveOverCurrentReport="updateCurrentReport"
      @showSendMessage="showSendMessageModal"
      @closeSendMessage="closeSendMessageModal"
      @addEmail="addSubscriber"
      @removeEmail="removeSubscriber"
      @deleteSchedule="deleteReportSchedule"
      @send="createSchedule"
      @sendOnce="sendOnce"
    />
    <Modal
      v-if="showDialog"
      v-bind="dialogConfig"
      @close="showDialog = false"
    ></Modal>
  </div>
</template>
<script>
import reportingService from './service';
import { ButtonMode } from '@idonatedev/ui-library/components/button/button.constants';
import { ModalWidth } from '@idonatedev/ui-library/components/modal/modal.constants';
import Modal from '@idonatedev/ui-library/components/modal/modal.vue';
import PaginationReportTable from '@idonatedev/ui-library/components/pagination-report-table/pagination-report-table.vue';

const MUTATION = require('../../graphql/mutations.gql');
const QUERY = require('../../graphql/queries.gql');

const PRIMARY_REPORTS = ['Donations', 'Donors', 'Recurrings', 'Campaigns', 'Designations'];

export default {
  name: 'ReportingApp',
  components: {
    Modal,
    PaginationReportTable,
  },
  data: () => {
    const urlParams = new URLSearchParams(window.location.search);

    return {
      reportId: urlParams.get('reportId') || false,
      currentPage: parseInt(urlParams.get('pageNum')) || 0,
      filters: JSON.parse(urlParams.get('filters')) || {},
      searchTerm: urlParams.get('search_term') || '',
      ...(urlParams.get('columns') && {
        presetColumns: urlParams.get('columns').split(','),
      }),
      ...(urlParams.get('order_by') &&
        urlParams.get('sort_direction') && {
          sortBy: {
            [urlParams.get('order_by')]: urlParams.get('sort_direction'),
          },
        }),

      columnData: [],
      defaultFormat: 'CSV',
      defaultReportId: false,
      dialogConfig: {},
      downloadAttempts: 0,
      errors: '',
      headers: [],
      isLoading: false,
      isRollup: false,
      newReport: {
        error: 'Test Error message',
        emailInvitations: [
          {
            title: 'Chris',
            email: 'chris@vega.studio',
          },
          {
            title: 'Yoyo',
            email: 'yoyo@vega.studio',
          },
          {
            title: 'Dood',
            email: 'dood@vega.studio',
          },
        ],
        focusConfig: {
          placeholder: 'Select a focus',
          options: reportingService.focusOptions,
        },
        visibilityConfig: {
          placeholder: 'Select visibility',
          options: reportingService.visibilityOptions,
        },
        filterConfig: {},
      },
      pageLength: 25,
      reports: [],
      rows: [],
      rowsPerPageOptions: [
        {
          label: '10',
          value: 10,
        },
        {
          label: '25',
          value: 25,
        },
        {
          label: '50',
          value: 50,
        },
        {
          label: '100',
          value: 100,
        },
        {
          label: '200',
          value: 200,
        },
      ],
      saveCustomViewProps: {
        visibilityValues: reportingService.visibilityOptions,
      },
      showDialog: false,
      showSendMessage: false,
      status: '',
      subscribers: [],
      cachedFilterData: {},
    };
  },
  computed: {
    columns() {
      return this.headers
        .filter((header) => header.display)
        .map((header) => header.id);
    },
    currentReport() {
      let report = this.reports.filter(
        (report) => report.value == this.reportId
      );
      return report.length ? report[0] : {};
    },
    currentFilters() {
      // graphql and the edit report screen need the filters formatted differently
      // this prop is consumed by the edit report screen and is derived from the graphql version
      if (!this.newReport.filterConfig.types) {
        return [];
      }

      const currentFilters = Object.keys(this.filters).reduce(
        (result, header_ref) => {
          const filter = this.filters[header_ref];
          return [
            ...result,
            ...Object.keys(filter).map((condition) => {
              let filterValue = filter[condition];
              let type = this.newReport.filterConfig.types.find(
                (type) => type.header_ref == header_ref
              ).type;
              if (type == 'multi_select' && typeof filterValue === 'string') {
                if (condition === 'equals') {
                  // convert equals to in - for future compatibility
                  condition = 'in';
                }
                // some multi selects with only one selection were saved as a string
                filterValue = [filterValue];
              }
              if (condition == 'is_null') {
                if (filterValue == false) {
                  condition = 'is_not_null';
                }
                filterValue = '';
              }
              return {
                header_ref,
                type,
                filterValue,
                condition: {
                  label: condition,
                },
              };
            }),
          ];
        },
        []
      );
      return currentFilters;
    },
    org() {
      return this.$root.gmsOrg;
    },
    user() {
      return this.$root.gmsUser;
    },
    sendMessageProps() {
      return {
        emailInvitations: this.subscribers,
        showSendMessage: this.showSendMessage,
        error: this.errors,
        status: this.status,
        fileTypeConfig: {
          placeholder: 'File Type...',
          options: reportingService.downloadOptions,
        },
        frequencyConfig: {
          placeholder: 'Select a frequency...',
          options: [
            {
              label: 'Daily',
              value: 'DAILY',
            },
            {
              label: 'Weekly',
              value: 'WEEKLY',
            },
            {
              label: 'Monthly',
              value: 'MONTHLY',
            },
          ],
        },
      };
    },
  },
  methods: {
    tableToUrlParams() {
      const sortConfig = Object.entries(this.sortBy)[0];

      const urlParams = new URLSearchParams({
        pageNum: this.currentPage,
        organization_id: this.org.id,
        search_term: this.searchTerm || '',
        ...(sortConfig && {
          order_by: sortConfig[0],
          sort_direction: sortConfig[1],
        }),
        ...(Object.keys(this.filters).length && {
          filters: JSON.stringify(this.filters),
        }),
        columns: this.columns.toString(),
        reportId: this.currentReport.id,
        returnTo: 'beta', // 'legacy' or 'beta'
      }).toString();

      return urlParams;
    },
    handleRowClicked(row) {
      if (!row || !Object.keys(row).length) return;

      const tableConfig = this.tableToUrlParams();
      const eventHub = window['eventHub'];
      const urlPaths = {
        transactionId: `/donations/detail`,
        donorId: `/donors/detail`,
        scheduleId: `/donations/recurring`,
        campaignId: `/campaign`,
        designationId: `/designations`,
      };
      const rowType = Object.keys(row)[0];

      if (eventHub) {
        // ask GMS2 to route us to the correct record -- leave query params out of it because GMS2 can't handle them
        eventHub.emit('navigation', `${urlPaths[rowType]}/${row[rowType]}`);
      } else {
        window.location = `${urlPaths[rowType]}/${row[rowType]}${
          tableConfig ? `?${tableConfig}` : ''
        }`;
      }
    },
    handleFiltersChanged(filters) {
      filters.forEach(async (f) => {
        // check cached results
        const field = f.header_ref;
        if (reportingService.queryableFields.includes(field)) {
          // if we don't have it, look it up
          if (!this.cachedFilterData[field]) {
            const result = await this.fetchReportColumnValues(
              this.currentReport.id,
              field
            );

            // update filterConfig, which should update UI
            if (result) {
              const resultArray = result.data.reportColumnValues;
              this.cachedFilterData[field] = resultArray;
              this.newReport.filterConfig.config[field][1].options =
                resultArray.map((i) => ({ label: i, value: i }));
            }
          }
        }
      });
    },
    resetMessages() {
      this.errors = '';
      this.status = '';
    },
    createSchedule(schedule) {
      if (!this.subscribers.length) {
        this.errors = 'No email address provided.';
        return;
      }

      this.resetMessages();

      let dateString = new Date().toJSON().slice(0, 10)  // Just the date part, i.e. 2024-06-25
      if (schedule.frequencyMode === 'MONTHLY') {
        let { day, month, year } = schedule.monthlyFrequency;
        day = day.toString().padStart(2, '0');
        month = month.toString().padStart(2, '0');
        dateString = `${year}-${month}-${day}`;
      }

      let mappedSchedule = {
        frequency: schedule.frequencyMode,
        sendAt: `${dateString}T${schedule.sendAt}`,
        sendAs: schedule.fileType,
        email: this.subscribers.map((s) => s.email).join(', '),
      };

      // push frequency-specific mapping
      if (mappedSchedule.frequency === 'WEEKLY') {
        mappedSchedule.weeklyOn = schedule.weekOption;
      }

      if (schedule.id) {
        // we're updating an existing schedule
        mappedSchedule.id = schedule.id;
        this.$apollo
          .mutate({
            mutation: MUTATION.updateReportSubscriber,
            variables: {
              reportId: this.currentReport.id,
              subscriber: mappedSchedule,
            },
          })
          .then((response) => {
            const errors = response.data.report.updateSubscriber.errors;
            if (errors) {
              this.errors = errors.join(',');
            } else {
              this.currentReport.subscribers[0] =
                response.data.report.updateSubscriber.subscriber;
              this.closeSendMessageModal();
            }
          })
          .catch((error) => {
            console.error(error);
            this.errors = 'There was a problem updating the report schedule.';
          });
      } else {
        // we're saving a new one
        mappedSchedule.reportId = this.currentReport.id;
        this.$apollo
          .mutate({
            mutation: MUTATION.createReportSubscriber,
            variables: {
              orgId: this.org.id,
              subscriber: mappedSchedule,
            },
          })
          .then((response) => {
            const errors =
              response.data.organization.createReportSubscriber.errors;
            if (errors) {
              this.errors = errors.join(',');
            } else {
              this.currentReport.subscribers.push(
                response.data.organization.createReportSubscriber.subscriber
              );
              this.closeSendMessageModal();
            }
          })
          .catch((error) => {
            console.error(error);
            this.errors = 'There was a problem creating the report schedule.';
          });
      }
    },
    sendOnce(options) {
      if (!options.emails || !options.emails.length) {
        this.errors = 'No email addresses provided.';
        return;
      }

      this.resetMessages();

      this.status = 'Creating report export...';
      this.export(options.fileType, (data) => {
        this.sendEmail(
          data.data.report.exportReport.exportId,
          options.emails.split(',').map((e) => e.trim())
        );
      });
    },
    sendEmail(exportId, emails) {
      this.$apollo
        .query({
          query: QUERY.getReportDownload,
          fetchPolicy: 'network-only',
          variables: {
            id: exportId,
          },
        })
        .then((data) => {
          switch (data.data.reportExport.status) {
            case 'COMPLETE':
              this.status = 'Export created, sending email to recipients...';
              this.$apollo
                .mutate({
                  mutation: MUTATION.sendOnce,
                  variables: {
                    reportId: exportId,
                    emails: emails,
                  },
                })
                .then(() => {
                  this.status = 'Report export sent successfully';
                });
              break;
            case 'ERROR':
              throw new Error('Error from download API');
            default:
              setTimeout(() => {
                this.sendEmail(exportId, emails);
              }, 4000);
              break;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          this.showErrorDialog('There was a problem downloading this report');
        });
    },
    parseScheduleEmails(report) {
      if (!report || !report.subscribers.length) {
        return [];
      }

      return report.subscribers[0].email.split(',').map((e) => ({
        email: e.trim(),
        title: e.trim(),
      }));
    },
    deleteReportSchedule() {
      const reportId = this.currentReport.id;
      const scheduleId = this.currentReport.subscribers[0].id;

      return this.$apollo
        .mutate({
          mutation: MUTATION.deleteReportSubscriber,
          variables: {
            reportId: reportId,
            subscriberId: scheduleId,
          },
        })
        .then((response) => {
          const { errors, success } = response.data.report.deleteSubscriber;
          if (success) {
            this.currentReport.subscribers = [];
            this.subscribers = [];
            this.showSendMessage = false;
          } else {
            this.errors = 'There was a problem deleting the report schedule.';
          }
        })
        .catch((err) => {
          console.error(err);
          this.errors = 'There was a problem deleting the report schedule.';
        });
    },
    export(format, callback) {
      return this.$apollo
        .mutate({
          mutation: MUTATION.exportReport,
          variables: {
            reportId: this.reportId,
            format: format,
            orgId: this.org.id,
            search: this.searchTerm.length ? this.searchTerm : undefined,
            columns: this.columns,
            filters: JSON.stringify(this.filters),
            sortBy: JSON.stringify(this.getSortForCurrentReport()),
            isRollup: this.isRollup,
          },
        })
        .then(callback);
    },
    goToCampaigns() {
      window.location = '/campaigns';
    },
    showErrorDialog(msg, title = 'Error') {
      this.dialogConfig = {
        title: title,
        rightButtons: [
          {
            label: 'OK',
            click: (close) => {
              close();
            },
            mode: ButtonMode.TEXT,
          },
        ],
        width: ModalWidth.MEDIUM,
        text: msg,
      };
      this.showDialog = true;
    },
    showSendMessageModal() {
      this.showSendMessage = true;
    },
    closeSendMessageModal() {
      this.showSendMessage = false;
      this.subscribers = [];

      // re-map subscribers to saved state
      if (this.currentReport.subscribers.length) {
        this.subscribers = this.currentReport.subscribers[0].email
          .split(',')
          .map((s) => ({
            email: s.trim(),
            title: s.trim(),
          }));
      }
      this.resetMessages();
    },
    customizeReport(columns, rollup, name, visibility, filters) {
      // ad hoc changes made - these don't persist until saved
      this.isRollup = rollup;
      this.currentReport.label = name;
      this.currentReport.visibility = visibility;
      this.filters = reportingService.generateFilterData(filters);
      this.loadReport(
        this.reportId,
        columns.filter((header) => header.display).map((header) => header.id)
      );
    },
    reloadTable(options) {
      // pagination, search, refresh, or sort interaction
      this.isLoading = true;
      this.currentPage = options.currentPage > 0 ? options.currentPage : 0;
      this.pageLength = options.rowsPerPage;
      this.searchTerm = options.searchValue.length ? options.searchValue : '';
      if (options.sortId && options.sortMode) {
        this.sortBy = {};
        this.sortBy[options.sortId] = options.sortMode;
      }
      this.loadReport(this.reportId);
    },
    deleteReport() {
      this.isLoading = true;
      this.$apollo
        .mutate({
          mutation: MUTATION.deleteReport,
          variables: {
            reportId: this.reportId,
          },
        })
        .then((data) => {
          this.fetchReportList();
        })
        .catch((error) => {
          console.error(error);
          this.showErrorDialog('There was a problem deleting this report.');
        });
    },
    downloadReport(format) {
      this.isLoading = true;
      this.export(format, (data) => {
        let exportId = data.data.report.exportReport.exportId;
        this.downloadAttempts = 0;
        setTimeout(() => {
          this.getReportDownload(exportId);
        }, 2000);
      }).catch((error) => {
        this.isLoading = false;
        this.showErrorDialog('There was a problem generating the file');
      });
    },
    getReportDownload(exportId) {
      this.$apollo
        .query({
          query: QUERY.getReportDownload,
          fetchPolicy: 'network-only',
          variables: {
            id: exportId,
          },
        })
        .then((data) => {
          switch (data.data.reportExport.status) {
            case 'COMPLETE':
              this.isLoading = false;
              let exportUrl = data.data.reportExport.downloadUrl;
              window.location.assign(exportUrl);
              break;
            case 'ERROR':
              throw new Error('Error from download API');
            default:
              this.downloadAttempts++;
              if (this.downloadAttempts == 10) {
                this.showErrorDialog(
                  'This report download is taking some time to complete. Keep this tab open and we will continue to work on the download.',
                  'Download Report'
                );
              }
              setTimeout(() => {
                this.getReportDownload(exportId);
              }, 4000);
              break;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          this.showErrorDialog('There was a problem downloading this report');
        });
    },
    addSubscriber(emailAddress) {
      if (emailAddress) {
        this.subscribers.push({
          title: emailAddress,
          email: emailAddress,
        });
      }
    },
    removeSubscriber(index) {
      this.subscribers.splice(index, 1);
    },
    setDefaultReport() {
      this.isLoading = true;
      this.$apollo
        .mutate({
          mutation: MUTATION.setDefaultReport,
          variables: {
            reportId: this.reportId,
          },
        })
        .then((data) => {
          this.fetchReportList();
        })
        .catch((error) => {
          console.error(error);
          this.showErrorDialog(
            'There was a problem making this report the default.'
          );
        });
    },
    saveCustomView(data) {
      // save a new report with the current filters, sort and columns
      this.$apollo
        .mutate({
          mutation: MUTATION.createReport,
          variables: {
            orgId: this.org.id,
            name: data.name,
            visibility: data.visibility,
            focus: this.currentReport.dataSrc.toUpperCase(),
            columns: this.columns,
            isRollup: this.isRollup,
            filters: JSON.stringify(this.filters),
            sortBy: JSON.stringify(this.getSortForCurrentReport()),
          },
        })
        .then((data) => {
          let reportId = data.data.organization.createReport.report.id;
          this.fetchReportList(reportId);
        })
        .catch((error) => {
          console.error(error);
          this.showErrorDialog('There was a problem saving this report.');
        });
    },
    updateCurrentReport() {
      // update current report with the current filters, sort and columns
      this.$apollo
        .mutate({
          mutation: MUTATION.updateReport,
          variables: {
            reportId: this.reportId,
            name: this.currentReport.label,
            visibility: this.currentReport.visibility,
            focus: this.currentReport.dataSrc.toUpperCase(),
            columns: this.columns,
            filters: JSON.stringify(this.filters),
            sortBy: JSON.stringify(this.getSortForCurrentReport()),
            isRollup: this.isRollup,
          },
        })
        .then((data) => {
          let reportId = data.data.report.updateReport.report.id;
          this.fetchReportList(reportId);
        })
        .catch((error) => {
          console.error(error);
          this.showErrorDialog('There was a problem updating this report.');
        });
    },
    createReport(data) {
      let filters = reportingService.generateFilterData(data.filters);
      this.$apollo
        .mutate({
          mutation: MUTATION.createReport,
          variables: {
            orgId: this.org.id,
            name: data.reportName,
            visibility: data.visibility,
            focus: data.focus.toUpperCase(),
            columns: reportingService.defaultColumns[data.focus.toLowerCase()],
            filters: JSON.stringify(filters),
            sortBy: JSON.stringify(
              reportingService.defaultSort[data.focus.toLowerCase()]
            ),
          },
        })
        .then((data) => {
          let reportId = data.data.organization.createReport.report.id;
          this.fetchReportList(reportId);
        })
        .catch((error) => {
          console.error(error);
          this.showErrorDialog('There was a problem creating the report.');
        });
    },
    changeFocus(focus) {
      this.newReport.filterConfig = reportingService.generateFilterConfig(
        this.columnData,
        this.cachedFilterData,
        focus
      );
    },
    changeReport(id) {
      let report = this.reports.find((report) => report.value === id);

      // populate report state that can be modified ad hoc
      this.searchTerm = this.searchTerm || '';
      this.isRollup = report ? report.isRollup : false;
      // TODO mmeza - defaulting to this.filters breaks reports when changing
      // between reports where  filter/sort columns don't exist in the other.
      // There's probably a way to make both scenarios work but at the moment
      // this breaks persisten filters/sort.
      // this.filters = report ? this.filters || report.filters : {};
      // this.sortBy = report ? this.sortBy || report.sortBy : {};
      this.filters = report ? report.filters : {};
      this.sortBy = report ? report.sortBy : {};
      this.subscribers = this.parseScheduleEmails(report);
      this.cachedFilterData = {};
      this.changeFocus(report.dataSrc);

      // If the component hasn't been mounted and we have columns in the URL
      // Prioritize and use the URL columns over the report columns
      this.loadReport(
        id,
        !this.isMounted && this.presetColumns
          ? this.presetColumns
          : report.columns
      );
    },
    async loadReport(id, columns = false) {
      let headers = columns ? columns : this.columns;

      if (id && headers.length) {
        this.isLoading = true;
        this.reportId = id;
        await this.$apollo
          .query({
            query: QUERY.getReport,
            fetchPolicy: 'network-only',
            variables: {
              id: id,
              orgId: this.org.id,
              limit: this.pageLength,
              offset: this.currentPage * this.pageLength,
              search: this.searchTerm.length ? this.searchTerm : undefined,
              columns: headers,
              filters: JSON.stringify(this.filters),
              sortBy: JSON.stringify(this.getSortForCurrentReport()),
              isRollup: this.isRollup,
            },
          })
          .then((data) => {
            let focus = data.data.report.focus.toLowerCase();
            let headerData = reportingService.loadColumnData(
              this.columnData,
              headers,
              data.data.report.focus.toLowerCase()
            );
            let rowData = data.data.report.data.map((values) => {
              // when mapping values, offset by 1 so we don't try rendering the focus_id
              return {
                [`${focus}Id`]: values[0],
                data: headers.reduce(
                  (obj, key, index) => ({ ...obj, [key]: values[index + 1] }),
                  {}
                ),
              };
            });
            this.headers = headerData;
            this.rows = rowData;
            this.isMounted = true;
          })
          .catch((error) => {
            console.error(error);
            this.showErrorDialog('There was a problem loading the report.');
          });
        this.isLoading = false;
      }
    },
    getSortForCurrentReport() {
      return (
        this.sortBy ||
        reportingService.defaultSort[this.currentReport.dataSrc.toLowerCase()]
      );
    },
    async fetchReportColumnValues(id, columnName) {
      return this.$apollo.query({
        query: QUERY.getReportColumnValuesWithoutFilters,
        fetchPolicy: 'network-only',
        variables: {
          reportId: id,
          column: columnName,
          orgId: this.org.id,
        },
      });
    },
    async fetchReportList(reportIdToLoad = false) {
      this.isLoading = true;
      this.reportId = false; // trigger a refresh of the list DOM
      let defaultReport = false;

      this.$apollo
        .query({
          query: QUERY.getReports,
          fetchPolicy: 'network-only',
          variables: {
            orgId: this.org.id,
          },
        })
        .then((data) => {
          let reports = data.data.reportsList.map((report) => {
            if (report.isDefault) {
              defaultReport = report.id;
              this.defaultReportId = report.id;
            }
            return {
              id: report.id,
              label: report.name,
              value: report.id,
              favourite: report.isDefault ? true : undefined,
              icon: false,
              mode: report.type == 'CANNED' ? 'primary' : 'secondary',
              type: report.type,
              dataSrc: report.focus.toLowerCase(),
              columns: report.columns,
              filters: JSON.parse(report.filters),
              sortBy: JSON.parse(report.sortBy),
              visibility: report.visibility,
              canDelete: report.canDelete,
              isRollup: report.isRollup,
              subscribers: report.subscribers,
            };
          });

          reports = reports.filter(
            (report) =>
              report.type !== 'CANNED' || PRIMARY_REPORTS.includes(report.label)
          );

          // sort - canned, then user-created
          reports.sort((a, b) => {
            const isAPrimary = a.mode === 'primary';
            const isBPrimary = b.mode === 'primary';

            if (isAPrimary && isBPrimary) {
              // top 4 should be returned in fixed order
              return (
                PRIMARY_REPORTS.indexOf(a.label) -
                PRIMARY_REPORTS.indexOf(b.label)
              );
            } else if (isAPrimary && !isBPrimary) {
              return -1;
            } else if (!isAPrimary && isBPrimary) {
              return 1;
            } else {
              // all non-canned reports should be returned A-Z
              return a.label.localeCompare(b.label);
            }
          });

          if (!defaultReport) {
            defaultReport = reports[0].id;
          }
          this.reports = reports;
          this.defaultReport = defaultReport;
          this.changeReport(reportIdToLoad ? reportIdToLoad : defaultReport);
        })
        .catch((error) => {
          console.error(error);
          this.showErrorDialog('There was a problem loading your reports.');
        });
    },
  },
  async mounted() {
    // first set up column and filter data
    this.isMounted = false;
    this.columnData = await reportingService.fetchAllColumns();
    this.newReport.filterConfig = reportingService.generateFilterConfig(
      this.columnData,
      this.cachedFilterData
    );
    this.fetchReportList(this.reportId || false);
  },
};
</script>
<style>
.PaginationReportTable__Nav,
.PaginationTable {
  padding: 0 !important;
}

.PaginationTable__Perpage {
  margin-right: 22px !important;
}

.PaginationTable__Perpage .SelectionMenu__Arrow {
  width: 10px !important;
}

.PaginationTable a {
  color: #2356f6;
}
</style>
