angular
  .module('idonate.gms')
  .controller(
    'OrganizationWebhooksIndexCtrl',
    (
      $scope,
      organizationWebhooks,
      organizationRecentEvents,
      availableAdapters,
      $routeParams,
      $location
    ) => {
      $scope.breadcrumbs = [
        {
          title: 'Webhooks',
          finalUrl: '/organization/webhooks',
        },
      ];

      $scope.webhookList = organizationWebhooks;
      $scope.eventList = organizationRecentEvents;
      $scope.availableAdapters = availableAdapters;

      $scope.showWebhook = (webhookId) => {
        // $scope.webhookId = webhookId;
        $location.path(`/organization/webhooks/${webhookId}`);
      };

      $scope.showEvent = (eventId) => {
        $location.path(`/organization/events/${eventId}`);
      };

      $scope.startConfiguration = (adapterName) => {
        $scope.isSaving = true;
        $location.path(`/organization/webhooks/install/${adapterName}`);
      };
    }
  )
  .controller(
    'OrganizationWebhookDetailCtrl',
    ($scope, $location, webhook, recentEvents) => {
      $scope.breadcrumbs = [
        {
          title: 'Webhooks',
          finalUrl: '/organization/webhooks',
        },
        {
          title: `${webhook.adapter.title} ${webhook.id}`,
          finalUrl: `/organization/webhooks/${webhook.id}`,
        },
      ];

      $scope.webhook = webhook;
      $scope.recentEvents = recentEvents;

      $scope.editWebhook = () => {
        $location.path(`/organization/webhooks/${webhook.id}/edit`);
      };

      $scope.showEvent = (eventId) => {
        $location.path(`/organization/events/${eventId}`);
      };
    }
  )
  .controller(
    'OrganizationWebhookEditCtrl',
    ($scope, $location, webhook, webhookService) => {
      $scope.breadcrumbs = [
        {
          title: 'Webhooks',
          finalUrl: '/organization/webhooks',
        },
        {
          title: `${webhook.adapter.title} ${webhook.id}`,
          finalUrl: `/organization/webhooks/${webhook.id}`,
        },
      ];

      $scope.webhook = webhook;

      $scope.saveWebhook = (webhook) => {
        $scope.isSaving = true;

        webhookService.putConfiguration(webhook).then((result) => {
          $location.path(`/organization/webhooks/${result.webhook.id}`);
        });
      };

      if (webhook.adapter.key == 'Event') {
        /******************
         *    if Event    *
         ******************/
        $scope.testSelect = {
          choices:
            webhook.adapter.config_meta.subscribed_event_types.choices.map(
              (x) => ({ key: x[0], title: x[1] })
            ),
          selection: null,
        };

        $scope.sendTest = (config, testSelection) => {
          $scope.isSaving = true;
          $scope.testResponse = '(processing)';

          webhookService
            .testEventConfiguration(config, testSelection)
            .then((result) => {
              $scope.isSaving = false;
              $scope.testResponse = result.response;
            })
            .catch((error) => {
              // unlock form on error (no need to rethrow?)
              $scope.isSaving = false;
              $scope.testResponse = null;
            });
        };
      } else {
        /********************
         * else Transaction *
         ********************/
        let choices = [
          { key: 'transaction.completed', title: 'Transaction Completed' },
        ];

        $scope.testSelect = {
          choices: choices,
          selection: choices[0],
        };

        $scope.sendTest = (webhook, eventType) => {
          $scope.isSaving = true;
          $scope.testResponse = '(processing)';

          console.log(webhook);

          webhookService
            .testTransactionConfiguration(webhook)
            .then((result) => {
              $scope.isSaving = false;
              $scope.testResponse = result.response;
            })
            .catch((error) => {
              // unlock form on error (no need to rethrow?)
              $scope.isSaving = false;
              $scope.testResponse = null;
            });
        };
      }
    }
  )
  .controller(
    'OrganizationWebhookInstallCtrl',
    ($scope, $location, webhook, webhookService) => {
      $scope.breadcrumbs = [
        {
          title: 'Webhooks',
          finalUrl: '/organization/webhooks',
        },
        {
          title: `Install New ${webhook.adapter.title}`,
          finalUrl: `/organization/webhooks/install/${webhook.adapter.type}`,
        },
      ];

      $scope.webhook = webhook;

      if (webhook.adapter.key == 'Event') {
        /******************
         *    if Event    *
         ******************/
        $scope.testSelect = {
          choices:
            webhook.adapter.config_meta.subscribed_event_types.choices.map(
              (x) => ({ key: x[0], title: x[1] })
            ),
          selection: null,
        };

        $scope.sendTest = (webhook, eventType) => {
          $scope.isSaving = true;
          $scope.testResponse = '(processing)';

          webhookService
            .testEventConfiguration(webhook, eventType)
            .then((result) => {
              $scope.isSaving = false;
              $scope.testResponse = result.response;
            })
            .catch((error) => {
              // unlock form on error (no need to rethrow?)
              $scope.isSaving = false;
              $scope.testResponse = null;
            });
        };
      } else {
        /********************
         * else Transaction *
         ********************/
        let choices = [
          { key: 'transaction.completed', title: 'Transaction Completed' },
        ];

        $scope.testSelect = {
          choices: choices,
          selection: choices[0],
        };

        $scope.sendTest = (webhook) => {
          $scope.isSaving = true;

          webhookService
            .testTransactionConfiguration(webhook)
            .then((result) => {
              $scope.isSaving = false;
              $scope.testResponse = result.response;
            })
            .catch((error) => {
              // unlock form on error
              $scope.isSaving = false;
              $scope.testResponse = null;
            });
        };
      }

      $scope.saveWebhook = (webhook) => {
        $scope.isSaving = true;

        // TODO : error display
        webhookService.installConfiguration(webhook).then((result) => {
          $location.path(`/organization/webhooks/${result.webhook.id}`);
        });
      };
    }
  )
  .controller(
    'OrganizationEventDetailCtrl',
    ($scope, $location, webhookService, eventDetails) => {
      $scope.event = eventDetails;

      $scope.showWebhook = (webhookId) => {
        $location.path(`/organization/webhooks/${webhookId}`);
      };

      $scope.pushEvent = (webhookId) => {
        webhookService.pushEvent(webhookId, eventDetails.id);
      };
    }
  );
