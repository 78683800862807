// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

angular.module('idonate.gms.text', []).config(($routeProvider) =>
  $routeProvider
    //Text - Giving Keywords
    .when('/text', {
      templateUrl: '/scripts/text/giving-keywords.html',
      controller: 'SmsKeywordsCtrl',
      menu: {
        name: 'Text',
        style: 'icon-bubble-conversation-6',
        level: 1,
        position: 9,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: ['sms-giving'],
      },
      reloadOnSearch: false,
      resolve: {
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
        organizationKeywords(organizationService) {
          'ngInject';
          return organizationService.getOrganizationKeywords();
        },
      },
    })
    .when('/text/giving-keyword/:keywordId', {
      templateUrl: '/scripts/text/giving-keywords-item.html',
      controller: 'GiveKeywordsCtrl',
      menu: {
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: ['sms-giving'],
      },
      resolve: {
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
        keyword($q, organizationService, $route) {
          'ngInject';
          const defer = $q.defer();
          if ($route.current.params.keywordId === 'new') {
            defer.resolve({
              result: {
                sms_keyword: {
                  template_auto_responder: '',
                },
              },
            });
          } else {
            organizationService
              .getOrganizationKeyword($route.current.params.keywordId)
              .then(
                (data) => defer.resolve(data),
                (error) => defer.reject(error)
              );
          }
          return defer.promise;
        },
      },
    })
    .when('/text/autoresponder-keywords', {
      templateUrl: '/scripts/text/autoresponder-keywords.html',
      controller: 'SmsKeywordsCtrl',
      menu: {
        parent: 'Text',
        name: 'Autoresponders',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'sms-autoresponder',
      },
      resolve: {
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
        organizationKeywords(organizationService) {
          'ngInject';
          return organizationService.getOrganizationKeywords();
        },
      },
    })
    .when('/text/autoresponder-keyword/:keywordId', {
      templateUrl: '/scripts/text/autoresponder-keywords-item.html',
      controller: 'AutoResponderKeywordsCtrl',
      menu: {
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'sms-autoresponder',
      },
      resolve: {
        keyword($q, organizationService, $route) {
          'ngInject';
          const defer = $q.defer();
          if ($route.current.params.keywordId === 'new') {
            defer.resolve({
              result: {
                sms_keyword: {
                  template_auto_responder: '',
                },
              },
            });
          } else {
            organizationService
              .getOrganizationKeyword($route.current.params.keywordId)
              .then(
                (data) => defer.resolve(data),
                (error) => defer.reject(error)
              );
          }
          return defer.promise;
        },
      },
    })
    .when('/text/settings', {
      templateUrl: '/scripts/text/settings.html',
      controller: 'SmsSettingsCtrl',
      menu: {
        parent: 'Text',
        name: 'Settings',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: ['sms-autoresponder', 'sms-giving'],
      },
      resolve: {
        organizationAttributes(organizationService) {
          'ngInject';
          return organizationService.getOrganizationAttributes();
        },
        smsNumbers(organizationService) {
          'ngInject';
          return organizationService.getOrganizationSmsNumbers();
        },
      },
    })
);
