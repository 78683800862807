// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('cssField', () => ({
  templateUrl: '/scripts/input/css-field.html',
  scope: {
    field: '=field',
  },
  restrict: 'E',
  controller($scope) {
    'ngInject';
    $scope.editorOptions = {
      lineWrapping: true,
      lineNumbers: true,
      mode: 'text/css',
      matchBrackets: true,
      autoCloseBrackets: true,
    };
  },
}));
