import './campaign-tribute.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class CampaignTribute {
  /* @ngInject */
  constructor(
    $scope,
    $routeParams,
    notyService,
    organizationService,
    $location,
    CampaignService
  ) {
    $scope.campaignForm = {};
    $scope.select2GiftsOptionsData = [];
    $scope.select2TributesOptionsData = [];
    const generateSelect2Format = function (array, label) {
      const formatedCollection = [];
      angular.forEach(array, (item) =>
        formatedCollection.push(angular.extend(item, { text: item[label] }))
      );
      return formatedCollection;
    };
    CampaignService.getGifts().then(
      (result) =>
        ($scope.select2GiftsOptionsData = generateSelect2Format(
          _.filter(result.result.gifts, { is_disabled: false }),
          'description'
        ))
    );
    CampaignService.getTributeDefinitions().then(
      (result) =>
        ($scope.select2TributesOptionsData = generateSelect2Format(
          _.filter(result.result.tribute_definitions, { is_disabled: false }),
          'description'
        ))
    );
    CampaignService.getCampaign($routeParams.campaignId).then(
      function (campaign) {
        $scope.campaign = campaign;
        $scope.removeGift = function (id) {
          angular.forEach($scope.campaign.gifts, function (val, $i) {
            if (val.id === id) {
              $scope.campaign.gifts.splice($i, 1);
              if ($scope.campaign.id) {
                organizationService.deleteCampaignGift($scope.campaign.id, id);
              }
            }
          });
        };
        $scope.removeTribute = function (id) {
          angular.forEach(
            $scope.campaign.tribute_definitions,
            function (val, $i) {
              if (val.id === id) {
                $scope.campaign.tribute_definitions.splice($i, 1);
                if ($scope.campaign.id) {
                  organizationService.deleteCampaignTribute(
                    $scope.campaign.id,
                    id
                  );
                }
              }
            }
          );
        };
        $scope.$watch('addGift', function (gift) {
          if (_.isObject(gift) && gift.length > 0) {
            if (!_.find($scope.campaign.gifts, { id: gift[0].id })) {
              if ($scope.campaign.tribute_definitions.length > 0) {
                notyService.info('A Tribute has already been selected.');
                $scope.addGift = '';
              } else if ($scope.campaign.gifts.length < 3) {
                $scope.campaign.gifts.push(gift[0]);
                $scope.addGift = '';
                if ($scope.campaign.id) {
                  organizationService.postCampaignGift($scope.campaign.id, {
                    gift_id: gift[0].id,
                  });
                }
              }
            }
          }
        });
        $scope.$watch('addTribute', function (tribute) {
          if (_.isObject(tribute) && tribute.length > 0) {
            if (
              !_.find($scope.campaign.tribute_definitions, {
                id: tribute[0].id,
              })
            ) {
              if ($scope.campaign.gifts.length > 0) {
                notyService.info('A Thank You gift has already been selected.');
                $scope.addTribute = '';
              } else if ($scope.campaign.tribute_definitions.length < 3) {
                $scope.campaign.tribute_definitions.push(tribute[0]);
                $scope.addTribute = '';
                if ($scope.campaign.id) {
                  organizationService.postOrganizationCampaignTributeDefinitions(
                    $scope.campaign.id,
                    tribute[0].id
                  );
                }
              }
            }
          }
        });
        return $scope.$watchCollection('campaign', function (nv) {
          if (!nv) {
            return;
          }
          if ($scope.campaignForm != null) {
            if (nv.title && nv.goal && nv.startDate && nv.endDate) {
              return ($scope.campaignForm.$pristine = false);
            } else {
              return ($scope.campaignForm.$pristine = true);
            }
          }
        });
      }
    );
    $scope.saveCampaign = function () {
      $scope.loading = true;
      if ($scope.campaign.id) {
        return CampaignService.editCampaign($scope.campaign).then(
          function (data) {
            $scope.loading = false;
            notyService.success('A campaign has been updated successfully.');
            $location.path('/campaigns');
          }
        );
      } else {
        return CampaignService.addOrganizationCampaign($scope.campaign).then(
          function (data) {
            angular.forEach($scope.campaign.designations, (val, $i) =>
              CampaignService.addDesignationToCampaign(data.campaign.id, {
                designation_id: val.id,
              })
            );
            //After adding a new campaign we check if the gift array has any obj and we push it
            angular.forEach($scope.campaign.gifts, (val, $i) =>
              organizationService.postCampaignGift(data.campaign.id, {
                gift_id: val.id,
              })
            );
            angular.forEach($scope.campaign.tribute_definitions, (val, $i) =>
              organizationService.postOrganizationCampaignTributeDefinitions(
                data.campaign.id,
                val.id
              )
            );
            $scope.loading = false;
            notyService.success('A campaign has been created successfully.');
            $location.path('/campaigns');
          }
        );
      }
    };
  }
}

angular.module('idonate.gms.campaign').component('campaignTribute', {
  templateUrl: '/scripts/campaign/campaign-item/campaign-tribute.html',
  controller: CampaignTribute,
});
