AppRun.$inject = [
  '$route',
  '$rootScope',
  '$location',
  'SessionService',
  'organizationService',
  'Platform',
  'UserService',
  '$timeout',
  'GlobalService',
  '$interval',
  'notyService',
  'appState',
];

export function AppRun(
  $route,
  $rootScope,
  $location,
  SessionService,
  organizationService,
  Platform,
  UserService,
  $timeout,
  GlobalService,
  $interval,
  notyService,
  appState
) {
  $rootScope.safeApply = function (fn) {
    var phase = this.$root.$$phase;
    if (phase == '$apply' || phase == '$digest') {
      if (fn && typeof fn === 'function') {
        fn();
      }
    } else {
      this.$apply(fn);
    }
  };

  if (window !== window.parent) {
    $rootScope.isHostedMode = true;
    // This handles navigation requests from the parent app
    window.addEventListener(
      'eventHub_ready',
      async () => {
        let currentUrl;
        let pendingNavigation;

        $rootScope.$on('$locationChangeSuccess', (event, nextUrl) => {
          if (nextUrl === pendingNavigation) {
            window.eventHub.emit('navigate.success', nextUrl);
            currentUrl = nextUrl;
            pendingNavigation = undefined;
          } else if (nextUrl != currentUrl) {
            window.eventHub.emit('navigation', nextUrl);
            currentUrl = nextUrl;
          }
        });

        window.eventHub.subscribe('navigate', (payload) => {
          $rootScope.safeApply(() => {
            if (
              payload.organization &&
              (!appState.currentOrganization ||
                payload.organization.id !== appState.currentOrganization.id)
            ) {
              // switch org before navigating if needed
              appState.switchOrganization(payload.organization.id).then(() => {
                pendingNavigation = $location
                  .path(payload.path)
                  .search(payload.params).$$absUrl;
              });
            } else {
              // otherwise just navigate.
              pendingNavigation = $location
                .path(payload.path)
                .search(payload.params).$$absUrl;
            }
          });
        });

        // This calls configurePlatformStyles again after iframe has loaded in GMS 2.0 which is needed to apply the styles to the body
        await Platform.getConfig();
      },
      false
    );
  } else {
    $rootScope.isHostedMode = false;
  }

  $rootScope.Platform = Platform;

  $rootScope.$on(
    'cfpLoadingBar:started',
    () => ($rootScope.loadingBarActive = true)
  );

  $rootScope.$on(
    'cfpLoadingBar:completed',
    () => ($rootScope.loadingBarActive = false)
  );

  $rootScope.pageTitle = `${Platform.getText('name')} Administration`;

  // WTF
  if ($location.protocol() === 'http' && $location.port() < 8000) {
    window.location = `https://${$location.host()}#${$location.url()}`;
  }

  $rootScope.goProfile = function () {
    return $timeout(() => $location.path('/profile'));
  };

  //Preloader
  //$rootScope.preloaderDisplay = false
  $rootScope.showAccessMsg = true;

  const setTabMenu = function (parent) {
    const subMenu = _.filter($route.routes, {
      menu: { parent: parent.menu.name, level: 2 },
    });

    _.forEach(
      subMenu,
      (itemLevelTwo) =>
        (itemLevelTwo.subElements = _.filter($route.routes, {
          menu: { parent: itemLevelTwo.menu.name, level: 3 },
        }))
    );

    return subMenu;
  };

  // TODO WTF: deconstruct this and rewrite or at least leave comments explaining what it does.
  $rootScope.$on('$routeChangeSuccess', function () {
    if ($route.current == null) {
      return;
    }

    //Reset on change
    $rootScope.activeItemLevelOne = null;
    $rootScope.activeItemLevelTwo = null;
    $rootScope.tabMenu = null;
    $rootScope.showAccessMsg = true;

    if ($route.current.$$route) {
      GlobalService.addSentryInformation({
        template: $route.current.$$route.templateUrl,
        controller: $route.current.$$route.controller,
      });
    }

    // this is gross, but better than calling the __guard__s
    let menu;

    if ($route.current.$$route && $route.current.$$route.menu) {
      menu = $route.current.$$route.menu;
    } else {
      menu = {}; // all keys undefined
    }

    if (menu.level === 1) {
      $rootScope.tabMenu = setTabMenu($route.current.$$route);
      $rootScope.activeItemLevelOne = $route.current.$$route.originalPath;
    } else if (menu.level === 2) {
      const _parent = _.filter($route.routes, {
        menu: {
          name: menu.parent,
          level: 1,
        },
      });

      $rootScope.tabMenu = setTabMenu(_parent[0]);
      $rootScope.activeItemLevelOne = _parent[0].originalPath;
      $rootScope.activeItemLevelTwo = $route.current.$$route.originalPath;
    }

    $rootScope.pageTitle = menu.name
      ? `${menu.name} | ${Platform.getText('name')}`
      : `${Platform.getText('name')} Administration`;

    // emit more noise so gmssidebar can adjust TODO simplify?
    $rootScope.$emit('gmsRouteChangeSuccess', [
      $rootScope.activeItemLevelOne,
      $rootScope.activeItemLevelTwo,
    ]);

    if (
      $rootScope.allowRole(menu.allowFor) === false ||
      $rootScope.allowFeature(menu.allowFeatureFor) === false
    ) {
      const possibleRoutes = [];

      angular.forEach($route.routes, function (_obj) {
        if (_obj.menu && _obj.menu.parent === menu.parent) {
          if (
            $rootScope.allowRole(_obj.menu.allowFor) === true &&
            $rootScope.allowFeature(_obj.menu.allowFeatureFor) === true
          ) {
            possibleRoutes.push(_obj);
          }
        }
      });
    }
  });
}
