// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').filter(
  'capitalize',
  () =>
    function (input) {
      if (!!input) {
        return input.charAt(0).toUpperCase() + input.substr(1).toLowerCase();
      } else {
        return '';
      }
    }
);
