// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const PagesCtrl = function (
  $scope,
  $location,
  $timeout,
  $routeParams,
  websiteService,
  organizationService,
  fullOrgData,
  Modals
) {
  $scope.campaignId = $routeParams.campaign_id;
  $scope.showDisabledItems = false;
  $scope.hasCustomDomain = (
    fullOrgData.result != null ? fullOrgData.result.custom_domain : undefined
  )
    ? true
    : false;
  $scope.setCurrent = (id) =>
    ($scope.current = _.find($scope.pages, { id: parseInt(id) }));
  $scope.editPage = function (pageId) {
    $location.path(`/pages/edit/${pageId}`);
  };
  $scope.tableOptions = {
    outsideSearchId: 'outside-search',
    clickableRowAction: $scope.editPage,
  };
  $scope.deletePage = (pageId) =>
    websiteService.deletePage(pageId).then(function (res) {
      if (res) {
        return populatePages();
      }
    });
  $scope.enablePage = (pageId) =>
    websiteService.enablePage(pageId).then(function (res) {
      if (res) {
        return populatePages();
      }
    });
  var populatePages = (campaignId) =>
    websiteService.getOrganizationPages(campaignId).then(function (data) {
      $scope.pages = data.result.pages;
      if (!$scope.showDisabledItems) {
        $scope.pages = Array.from($scope.pages).filter((p) => !p.disabled);
      }
      themeCounter($scope.pages);
      return $scope.$broadcast('reloadTable');
    });
  if ($scope.campaignId) {
    populatePages($scope.campaignId);
  } else {
    populatePages();
  }
  $scope.$watch('campaign', function (a, b) {
    if (a != null ? a.id : undefined) {
      $location.search({ campaign_id: a.id });
    }
    return populatePages(a != null ? a.id : undefined);
  });
  $scope.$watch('showDisabledItems', (a, b) =>
    $timeout(function () {
      if (a !== b) {
        return populatePages();
      }
    })
  );
  var themeCounter = function (pages) {
    $scope.themeCounter = {};
    return angular.forEach(pages, function (page) {
      if (angular.isDefined($scope.themeCounter[page.template])) {
        return ($scope.themeCounter[page.template] += 1);
      } else {
        return ($scope.themeCounter[page.template] = 1);
      }
    });
  };
};

angular.module('idonate.gms.website').controller('PagesCtrl', PagesCtrl);
