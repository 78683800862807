angular.module('idonate.gms.donors').component('donorMapping', {
  template: `
  <div class="page-heading">
    <h1>Import External Donor Mappings</h1>
    <div class="heading-buttons">
      <a class="btn btn-default" href="/donors">Back</a>
    </div>
  </div>
  <div class="row page-full-container" ng-repeat="integration in $ctrl.integrations">
    <form class="page-main" ng-submit="$ctrl.save($index, integration)" novalidate name="{{integration.key}}Form">
      <div class="panel">
        <div class="panel-heading" data-original-title>
          <h2 class="section-title mb-3">{{integration.name}}</h2>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="form-group col-md-8 col-12">
              <button class="btn btn-primary" ng-click="$ctrl.download(integration)">Download Unmapped Donors</button>
            </div>
            <file-uploader class="col-md-8 col-12" model="$ctrl.integrations[$index].donationFile" file-type="mapping" label="Upload mapping file"></file-uploader>
          </div>
          <button class="float-right btn btn-primary" type="submit">
            Import
            <span>
              <div ng-show="$ctrl.loading" class="idonate-spinner" alt="Loader"></div>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>`,
  controller: class {
    // @ngInject
    constructor(
      organizationService,
      donorService,
      notyService,
      SessionService
    ) {
      this.mappingDownload = donorService.getExternalDonorMapping;
      this.mappingSave = donorService.postExternalDonorMapping;
      this.orgPromise = organizationService.loadFullOrganizationInformation();
      this.notyService = notyService;
      this.SessionService = SessionService;
    }

    $onInit() {
      this.orgPromise.then(({ result }) => {
        this.orgId = result.id;
        this.integrations = result.external_service_mapping_configurations;
      });
    }

    download(integration) {
      this.mappingDownload(integration.key);
    }

    save(index, integration) {
      if (!this.integrations[index].donationFile) return;

      this.loading = true;

      this.SessionService.uploadToGmsApi(
        `/organization/${this.orgId}/external-mapping/donor/${integration.key}`,
        { data: { mapping_file: this.integrations[index].donationFile } }
      ).then(
        (success) => {
          this.notyService.success('Imported Mappings');
          this.loading = false;
        },
        (error) => {
          this.notyService.error(
            error.data.messages.reduce(
              (acc, item) => (acc += `\n${item.message}`),
              ''
            )
          );
          this.loading = false;
        },
        (evt) => {
          this.progress = parseInt((100.0 * evt.loaded) / evt.total);
        }
      );
    }
  },
});
