angular.module('idonate.gms').component('chapterChart', {
  template: `
      <div class="panel-body">
        <chart-container>
          <chart-header>
          </chart-header>
          <chart-charts>
              <scatter-chart data="chart" donations="childTotalDonations">
              </scatter-chart>
          </chart-charts>
        </chart-container>
      </div>
      <div class="separator"></div>
    `,
  bindings: {
    dateRange: '<?',
  },
  controller(organizationService, $scope) {
    this.onInit = () =>
      this.dateRange &&
      organizationService
        .getCampaignChart({
          start_date: this.dateRange.current.start.toISOString(),
          end_date: this.dateRange.current.end.toISOString(),
          previous_start_date: this.dateRange.previous.start.toISOString(),
          previous_end_date: this.dateRange.previous.end.toISOString(),
          timezone: moment.tz.guess(),
        })
        .then((result) => {
          $scope.chart = {
            datasets: result.result.datasets.map((obj) => ({
              ...obj,
              showLine: false,
            })),
          };
        })
        .then(() => $scope.$broadcast('reloadTable'));
    this.$onChanges = () => this.onInit();
  },
});
