// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

'use strict';

const P2PManageProgram = function (
  $scope,
  $uibModal,
  ReceiptService,
  organizationDesignations,
  organizationCampaigns,
  programDetails,
  $location,
  p2pService,
  $sce,
  $timeout,
  allAvailableTemplates,
  fullOrgData,
  notyService
) {
  const programId = programDetails.result.id;
  const programName =
    __guard__(
      programDetails.result.property_fieldsets[0] != null
        ? programDetails.result.property_fieldsets[0].fields[0]
        : undefined,
      (x) => x.value
    ) || '';
  const templateId = programDetails.result.template_id;

  $scope.checkStatus = (status) => $scope.programStatus === status;
  $scope.programStatus = programDetails.result.status;

  $scope.steps = programDetails.result;
  $scope.selectedTemplate = templateId;

  // change previous template
  $scope.setTemplate = (id) => ($scope.selectedTemplate = id);

  // filter out the text areas in email templates to include pictures
  const _filterTextarea = function (fieldset) {
    angular.forEach(fieldset.fields, function (field, index) {
      if (field.data_type === 'textarea') {
        field['optionsSummerNote'] = {
          disableDragAndDrop: true,
          toolbar: [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link', 'picture']],
          ],
        };
        return (fieldset.fields[index] = field);
      }
    });
    return fieldset;
  };

  // make this resource trusted url
  const getIframeSource = function (preview) {
    $scope.loginUrl = $sce.trustAsResourceUrl(
      window.iDonateConfig.legacyP2PBaseUrl +
        fullOrgData.result.slug +
        '/login?program_id=' +
        programId
    );
    if (preview != null) {
      return $sce.trustAsResourceUrl(
        window.iDonateConfig.legacyP2PBaseUrl +
          'p/' +
          programId +
          '?preview=true'
      );
    } else {
      return $sce.trustAsResourceUrl(
        window.iDonateConfig.legacyP2PBaseUrl + 'p/' + programId
      );
    }
  };

  $scope.iframeSrc = getIframeSource();
  $scope.iframeSrcPreview = getIframeSource(true);

  $scope.steps.property_fieldsets.unshift({
    title: 'Design',
    active: false,
    templates: allAvailableTemplates.result,
  });

  // make event section toggle
  let eventIndex = null;
  let eventBoolean = true;
  // watch for campaign selection change
  // will be used to update end date of the program
  let campaignIndex = null;
  let emailIndex = -1;
  angular.forEach($scope.steps.property_fieldsets, function (step, id) {
    // find event index
    if (step.title === '' || step.title === null) {
      $scope.steps.property_fieldsets.splice(id, 1);
    }
    if (step.title === 'Details' && !$scope.currentCampaign) {
      angular.forEach(step.fields, function (field, fid) {
        if (field.name === 'start_date') {
          $scope.startDateIndex = [id, fid];
          if (field.value === null || field.value === '') {
            field.value = moment();
          }
        }

        if (field.data_type === 'textarea') {
          field.displayFlat = false;
          field.optionsSummerNote = {
            disableDragAndDrop: true,
            toolbar: [
              ['style', ['bold', 'italic', 'underline', 'clear']],
              ['insert', ['link']],
            ],
          };
        }
        if (field.data_type === 'campaign') {
          $scope.currentCampaign = field;
          return (campaignIndex = `steps.property_fieldsets[${id}].fields[${fid}]`);
        }
      });
    }

    if (step.title === 'Event Info' && !eventIndex) {
      angular.forEach(step.fields, function (field) {
        if (field.data_type === 'textarea') {
          field.displayFlat = false;
          return (field.optionsSummerNote = {
            disableDragAndDrop: true,
            toolbar: [
              ['style', ['bold', 'italic', 'underline', 'clear']],
              ['insert', ['link']],
            ],
          });
        }
      });
      eventIndex = id;
      eventBoolean = `steps.property_fieldsets[${id}].fields[0]`;
    }

    if (step.title === 'Email') {
      $scope.emailStep = step;
      emailIndex = id;
      const templates = {};
      let initialChoice = 'd_payment_complete';
      $scope.customizeFields = [];
      step = _filterTextarea(step);
      angular.forEach(step.fields, function (field, index) {
        const name = field.name.split('.');
        if (index === 0) {
          initialChoice = name[1];
        }
        if (field.email_name && !templates[name[1]]) {
          templates[name[1]] = field.email_name;
        }
        if (initialChoice !== name[1]) {
          field.hidden = true;
        }
        if (field.label === 'Customize Content') {
          return $scope.customizeFields.push(field);
        }
      });
      return step.fields.unshift({
        name: 'template_selector',
        label: 'Choose Email',
        description:
          'Select the Email that you wish to customize from the drop down menu.',
        choices: templates,
        value: initialChoice,
        sort: true,
        hidden: false,
        data_type: 'choice',
        blockClear: true,
      });
    }
  });
  angular.forEach($scope.customizeFields, (field, index) => {
    $scope.$watch(
      () => field,
      function (a, b) {
        if (a) {
          const disable = !a.value;
          if (disable && a.hidden === false) {
            $('#receiptPreview').css('filter', 'blur(5px)');
          } else if (a.hidden === false) {
            $('#receiptPreview').css('filter', 'blur(0)');
          }
          const name = field.name.split('.')[1];
          return angular.forEach(
            $scope.emailStep.fields,
            function (item, index) {
              const template = item.name.split('.')[1];
              if (name === template) {
                return (item.disabled = disable);
              }
            }
          );
        }
      },
      true
    );
  });

  $scope.$watch(
    'emailStep.fields[0].value',
    function (a) {
      angular.forEach(
        $scope.steps.property_fieldsets[emailIndex].fields,
        function (field, index) {
          if (field.name === 'template_selector') {
            ReceiptService.getProgramReceipt(field.value, programId).then(
              (result) => ($scope.receiptPreview = result.html)
            );
            return;
          }
          const name = field.name.split('.');
          if (a !== name[1]) {
            field.hidden = true;
          } else {
            field.hidden = false;
          }
        }
      );
    },
    true
  );

  $scope.gmsDynamicFieldsData = {
    designations: organizationDesignations.result.designations,
    campaigns: organizationCampaigns.campaigns,
    min_date: moment().toISOString(),
    dateOptions: {
      bindData: [
        'start_date',
        $scope.steps.property_fieldsets[$scope.startDateIndex[0]]['fields'][
          $scope.startDateIndex[1]
        ],
      ],
      bindTo: ['end_date', 'min_date'],
    },
  };

  const toggleEventFields = function () {
    if (eventIndex !== null) {
      const showEvents =
        $scope.steps.property_fieldsets[eventIndex].fields[0].value;
      return angular.forEach(
        $scope.steps.property_fieldsets[eventIndex].fields,
        function (field, index) {
          if (index !== 0) {
            if (showEvents === 'f' || showEvents === false) {
              return (field.hidden = true);
            } else {
              return (field.hidden = false);
            }
          }
        }
      );
    }
  };

  // watch boolean field for changes
  $scope.$watchCollection(
    eventBoolean,
    function (nv, ov) {
      if (nv !== ov) {
        return toggleEventFields();
      }
    },
    true
  );

  // watch campaign field for changes
  $scope.$watchCollection(
    campaignIndex,
    function (nv, ov) {
      if ($scope.checkStep('Details')) {
        $scope.campaignIndex = nv.value;
        if ($scope.campaignIndex) {
          $scope.gmsDynamicFieldsData['max_date'] = null;
          $scope.gmsDynamicFieldsData['min_date'] = null;
          return angular.forEach(
            organizationCampaigns.campaigns,
            function (camp) {
              if (camp.id === $scope.campaignIndex) {
                return ($scope.gmsDynamicFieldsData['min_date'] =
                  camp.start_date);
              }
            }
          );
        }
      }
    },
    true
  );

  // make first field set active
  $scope.steps.property_fieldsets[0].active = true;

  // show final page
  $scope.steps.property_fieldsets.push({
    title: 'Share',
    active: false,
  });

  $scope.expireProgram = function () {
    const modal = $uibModal.open({
      size: 'md',
      scope: $scope,
      templateUrl: '/scripts/legacy-p2p/confirm-expire-modal.html',
      controller($scope, $uibModalInstance) {
        $scope.modalHeader = `Expire the ${programName}?`;
        $scope.continue = () =>
          p2pService.expireProgram(programId).then(function (success) {
            $uibModalInstance.dismiss('cancel');
            return $location.path(`legacy-p2p/program/${programId}`);
          });

        return ($scope.cancel = () => $uibModalInstance.dismiss('cancel'));
      },
    });
    return modal.result.catch(angular.noop);
  };

  $scope.archiveProgram = function () {
    const modal = $uibModal.open({
      size: 'md',
      scope: $scope,
      templateUrl: '/scripts/legacy-p2p/confirm-archive-modal.html',
      controller($scope, $uibModalInstance) {
        $scope.modalHeader = `Archive the ${programName}?`;
        $scope.continue = () =>
          p2pService.archiveProgram(programId).then(function (success) {
            $uibModalInstance.dismiss('cancel');
            return $location.path('p2p');
          });

        return ($scope.cancel = () => $uibModalInstance.dismiss('cancel'));
      },
    });
    return modal.result.catch(angular.noop);
  };

  $scope.deleteProgram = function () {
    const modal = $uibModal.open({
      size: 'md',
      scope: $scope,
      templateUrl: '/scripts/legacy-p2p/confirm-delete-modal.html',
      controller($scope, $uibModalInstance) {
        $scope.modalHeader = `Delete the ${programName}?`;
        $scope.continue = () =>
          p2pService.deleteProgram(programId).then(function (success) {
            $uibModalInstance.dismiss('cancel');
            return $location.path('p2p');
          });

        return ($scope.cancel = () => $uibModalInstance.dismiss('cancel'));
      },
    });
    return modal.result.catch(angular.noop);
  };

  $scope.cancel = () => $location.path(`p2p/program/${programId}`);

  $scope.save = function (skipnext, stay) {
    // save information and go to the next page
    let disableSave = false;
    const formdata = {};
    angular.copy($scope.steps, formdata);

    formdata.property_fieldsets.shift();
    formdata.property_fieldsets.pop();

    angular.forEach(
      formdata.property_fieldsets,
      (k, i) => delete formdata.property_fieldsets[i].active
    );

    const _toPost = {};
    // check for active step
    let nextStep = true;
    let goTo = null;
    let currentStep = null;
    angular.forEach($scope.steps.property_fieldsets, function (step) {
      if (step.active) {
        currentStep = step;
      }
      if (!nextStep && !goTo) {
        goTo = step;
      }
      if (nextStep && step.active) {
        return (nextStep = false);
      }
    });
    angular.forEach(formdata.property_fieldsets, (fieldset) =>
      angular.forEach(fieldset.fields, function (field) {
        if (currentStep.title === 'Details') {
          if (
            field.data_type === 'campaign' &&
            (field.value === '' || field.value === null)
          ) {
            disableSave = true;
            notyService.info('You have to select a campaign...');
          }
        }
        if (field.data_type === 'datetime' && field.value !== null) {
          if (field.value === '') {
            field.value = null;
          }
        }
        return (_toPost[field.name] = field.value);
      })
    );

    _toPost.id = programId;
    _toPost.template_id = $scope.selectedTemplate;

    if (!disableSave) {
      p2pService.saveP2PForm(_toPost).then(function (res) {
        $scope.iframeSrcPreview = getIframeSource(true);
        if (!skipnext && !stay) {
          $scope.goToStep(goTo);
        } else if (!stay) {
          // redirect to program page if they save for later
          $scope.cancel();
        }

        if (currentStep.title === 'Email') {
          return ReceiptService.getProgramReceipt(
            _toPost.template_selector,
            programId
          ).then((result) => ($scope.receiptPreview = result.html));
        }
      });

      // refresh the iframe
      return ($scope.iframeSrcPreview = null);
    }
  };

  $scope.publish = function () {
    $scope.save();
    // go to social page after saving information
    return p2pService.publishP2P(programId).then(
      (success) => ($scope.programStatus = 'active'),
      (err) => console.log(err)
    );
  };

  $scope.goToStep = function (step) {
    if (step.title === 'Email') {
      $('#receiptPreview').css('filter', 'blur(0)');
    }
    const stepIndex = $scope.steps.property_fieldsets.indexOf(step);
    // disable other steps
    angular.forEach(
      $scope.steps.property_fieldsets,
      (key, index) => ($scope.steps.property_fieldsets[index]['active'] = false)
    );
    // activate current step
    $scope.steps.property_fieldsets[stepIndex]['active'] = true;
    if ($scope.steps.property_fieldsets[stepIndex]['title'] === 'Details') {
      if ($scope.campaignIndex) {
        $scope.gmsDynamicFieldsData['max_date'] = null;
        $scope.gmsDynamicFieldsData['min_date'] = null;
        return angular.forEach(
          organizationCampaigns.campaigns,
          function (camp) {
            if (camp.id === $scope.campaignIndex) {
              return $timeout(function () {
                $scope.gmsDynamicFieldsData['max_date'] = camp.end_date;
                return ($scope.gmsDynamicFieldsData['min_date'] =
                  camp.start_date);
              });
            }
          }
        );
      }
    }
  };

  $scope.checkStep = function (title) {
    let active = false;
    angular.forEach($scope.steps.property_fieldsets, function (step) {
      if (step.title === title && step.active) {
        return (active = true);
      }
    });
    return active;
  };

  $scope.gmsDynamicFieldsData = {
    designations: organizationDesignations.result.designations,
    campaigns: organizationCampaigns.campaigns,
    min_date: new Date(),
  };

  $scope.$watch(
    'steps.property_fieldsets',
    function (a, b) {
      const fieldset = _.filter(a, { title: 'Details' });
      const campaignField = _.filter(fieldset[0].fields, { name: 'campaign' });
      if (campaignField[0].value) {
        let desSet = false;
        angular.forEach(organizationCampaigns.campaigns, function (camp) {
          if (camp.id === campaignField[0].value) {
            $scope.gmsDynamicFieldsData.designations = camp.designations;
            return (desSet = true);
          }
        });
        if (!desSet) {
          return ($scope.gmsDynamicFieldsData.designations = []);
        }
      } else {
        return ($scope.gmsDynamicFieldsData.designations =
          organizationDesignations.result.designations);
      }
    },
    true
  );

  const startDateWatchObj = `steps.property_fieldsets[${$scope.startDateIndex[0]}]['fields'][${$scope.startDateIndex[1]}]`;
  $scope.$watchCollection(
    startDateWatchObj,
    function (nv, ov) {
      if (nv && nv !== ov && $scope.gmsDynamicFieldsData['dateOptions']) {
        return ($scope.gmsDynamicFieldsData['dateOptions']['bindData'] = [
          'start_date',
          nv.value,
        ]);
      }
    },
    true
  );

  $scope.openModal = function (text) {
    const modal = $uibModal.open({
      size: 'md',
      scope: $scope,
      windowClass: 'copy-code-modal',
      templateUrl: '/scripts/website/copy-snippet-modal.html',
      controller($scope, $uibModalInstance) {
        const ua = navigator.userAgent.toLowerCase();
        $scope.safari = false;
        if (ua.indexOf('safari') !== -1) {
          if (ua.indexOf('chrome') > -1) {
            // chrome should take care of it automatically
          } else {
            $scope.safari = true;
          }
        }

        $scope.text = text;

        return ($scope.close = () => $uibModalInstance.close());
      },
    });
    return modal.result.catch(angular.noop);
  };
};

angular
  .module('idonate.gms.legacy-p2p')
  .controller('P2PManageProgram', P2PManageProgram);

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
