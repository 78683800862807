angular
  .module('idonate.gms')
  .controller(
    'OrganizationMobilePaymentConfigurationCtrl',
    function (
      $scope,
      organizationService,
      organizationAttributes,
      notyService
    ) {
      $scope.attributes = organizationAttributes.result.attributes;
      $scope.isProcessing = false;

      $scope.savePaymentConfiguration = function () {
        $scope.isProcessing = true;

        let updateValues = {
          'config.mobile.accept.credit':
            $scope.attributes.config.mobile.accept.credit,
          'config.mobile.accept.echeck':
            $scope.attributes.config.mobile.accept.echeck,
        };

        organizationService.updateAttributes(updateValues).then(function () {
          $scope.isProcessing = false;
          notyService.success('Mobile Payment Method Options Updated');
        });
      };
    }
  );
