angular.module('idonate.gms').component('selectorBar', {
  templateUrl: '/scripts/dashboard/selector-bar.html',
  bindings: {
    date: '=?',
    dash: '=?',
    options: '=?',
  },
  controller($location) {
    this.$onInit = function () {
      const search = $location.search();
      if (!search.dash) {
        this.dash = this.options[0];
        $location.search({ dash: this.dash.id });
      } else {
        this.dash = _.find(this.options, (item) => item.id === search.dash);
      }
    };
    this.setTime = (current, previous) => {
      return (this.date = {
        current,
        previous,
      });
    };
    this.dashSelected = ($model) => {
      $location.search({ dash: $model.id });
    };
  },
});
