// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('showArchivedSwitch', function () {
  let uniqueId = 1;

  return {
    templateUrl: '/scripts/input/show-archived-switch.html',
    scope: {
      value: '=model',
      itemChanged: '=?',
    },
    controllerAs: 'ctrl',
    bindToController: true,
    restrict: 'E',
    controller($scope, $timeout) {
      const ctrl = this;
      ctrl.uniqueId = `showArchivedSwitch${uniqueId++}`;

      ctrl.setShowArchived = (showArchived) =>
        $timeout(function () {
          ctrl.value = showArchived;
          if (ctrl.itemChanged) {
            return ctrl.itemChanged();
          }
        });
    },
  };
});
