import './profile.html';

export class ProfileCtrl {
  /* @ngInject */
  constructor($scope, $timeout, UserService, appState) {
    function loadFromState() {
      const contact = {};

      if (appState.currentUser.contact) {
        // use detached copy of data for the form
        Object.assign(contact, appState.currentUser.contact);

        // also make a copy of address
        if (contact.address) {
          contact.address = { ...contact.address };
        }
      }

      $scope.userInfo = contact;
    }

    loadFromState();

    //Local variables
    $scope.disableSaveBtn = [];
    $scope.disabledChange = [];
    $scope.disabledChange['defaults'] = true;
    $scope.states = [
      'AK',
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'GU',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MH',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'PR',
      'PW',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VI',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ];

    // ..a separate $watch for every field, to enable the button when any field changes..
    $scope.$watch('userInfo.title', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    $scope.$watch('userInfo.firstname', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    $scope.$watch('userInfo.lastname', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    $scope.$watch('userInfo.address.street', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    $scope.$watch('userInfo.address.street2', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    $scope.$watch('userInfo.address.city', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    $scope.$watch('userInfo.address.state', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    $scope.$watch('userInfo.address.zip', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    $scope.$watch('userInfo.phone', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['defaults'] = false);
        }
      })
    );

    //Save Functionality
    $scope.saveProfileInfo = function () {
      $scope.disableSaveBtn['defaults'] = true;
      $scope.disabledChange['defaults'] = true;

      // not sure if the `undefined` fallbacks are really needed or were just an artifact
      // of decaffeinate -- decided to keep them for now "just in case".
      const obj = {
        user_id: 'current',
        address_city: $scope.userInfo.address.city || undefined,
        address_state: $scope.userInfo.address.state || undefined,
        address_street: $scope.userInfo.address.street || undefined,
        address_street2: $scope.userInfo.address.street2 || undefined,
        address_zip: $scope.userInfo.address.zip || undefined,
        email: $scope.userInfo.email || undefined,
        firstname: $scope.userInfo.firstname || undefined,
        middlename: $scope.userInfo.middlename || undefined,
        lastname: $scope.userInfo.lastname || undefined,
        phone: $scope.userInfo.phone || undefined,
        title: $scope.userInfo.title || undefined,
      };
      return UserService.updateUserContactCurrent(obj)
        .then(() => {
          return appState.loadUser();
        })
        .then(() => {
          // refresh form from reloaded state and re-enable the button.
          loadFromState();
          $scope.disableSaveBtn['defaults'] = false;
        });
    };
  }
}
