angular.module('idonate.gms').directive('multipleCheckboxField', () => ({
  templateUrl: '/scripts/input/multiple-checkbox-field.html',
  scope: { field: '=' },
  restrict: 'E',
  controller($scope) {
    $scope.selectAll = () => {
      $scope.field.value = $scope.choice_options.reduce((acc, v) => {
        acc[v.id] = true;
        return acc;
      }, {});
    };

    $scope.selectNone = () => {
      $scope.field.value = {};
    };

    if (_.isObject($scope.field.choices)) {
      $scope.choice_options = [];
      return angular.forEach($scope.field.choices, (v, k) =>
        $scope.choice_options.push({ id: k, text: v })
      );
    }
  },
}));
