// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms.text').filter(
  'getAttById',
  () =>
    function (id, collection, attr, preText) {
      if (!preText) {
        preText = '';
      }
      const element = _.find(collection, { id });
      if (element) {
        return preText + element[attr];
      } else {
        return '';
      }
    }
);
