import angular from 'angular';

import { GlobalService } from './global.service';
import { gmsOrganizationSelector } from './gmsorganizationselector';
import { gmsSidebar } from './gmssidebar';
import { Modals } from './modals';
import { SessionService } from './session.service';
import { UserService } from './user.service';

export default angular
  .module('idonate.gms.base', [])
  .service('GlobalService', GlobalService)
  .component('gmsOrganizationSelector', gmsOrganizationSelector)
  .component('gmsSidebar', gmsSidebar)
  .service('Modals', Modals)
  .service('SessionService', SessionService)
  .service('UserService', UserService);
