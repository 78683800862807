/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.website')
  .controller(
    'OrganizationEmbedIdCtrl',
    function (
      $routeParams,
      $rootScope,
      $scope,
      embedData,
      $sce,
      websiteService,
      $compile,
      $timeout,
      $injector,
      organizationEmbedInstances,
      organizationDesignations,
      designationService,
      organizationCampaigns,
      organizationDesignationAttributes,
      $location,
      appState
    ) {
      $scope.idonateiFrame = window.iDonateConfig.idonateiFrame;
      const embedInstance = embedData.result.embed_instance;
      const filteredFieldsets = [];
      let campaignFieldset = null;
      let campaignFieldIndex = null;
      let donorPaysFieldset = null;
      let donorPaysFieldIndex = null;
      let donorPaysMessageFieldset = null;
      let donorPaysMessageFieldIndex = null;
      let donorPaysDefaultFieldset = null;
      let donorPaysDefaultFieldIndex = null;
      $scope.campaign_id = $routeParams.campaign_id;

      window.addEventListener('message', function (evt) {
        let evtData;
        try {
          evtData = JSON.parse(evt.data);
        } catch (_err) {
          return;
        }

        // Once we're loaded, re-trigger tab action
        if (evtData.status) {
          const activeTabTitle = $scope.activeTab.title;

          if (activeTabTitle === 'Recurring Prompt') {
            return $timeout(function () {
              const iframe =
                document.getElementsByTagName('iframe')[0].contentWindow;
              return iframe.postMessage(
                JSON.stringify({ embedAction: 'recurring-prompt-preview' }),
                '*'
              );
            });
          }

          if (activeTabTitle === 'Thank You') {
            return $timeout(function () {
              const iframe =
                document.getElementsByTagName('iframe')[0].contentWindow;
              return iframe.postMessage(
                JSON.stringify({ embedAction: 'thank-you' }),
                '*'
              );
            });
          }

          return $timeout(function () {
            const iframe =
              document.getElementsByTagName('iframe')[0].contentWindow;
            return iframe.postMessage(
              JSON.stringify({
                embedAction: 'route',
                actionData: 'cash.credit',
              }),
              '*'
            );
          });
        }
      });

      angular.forEach(
        embedInstance.draft_property_fieldsets,
        function (fieldset, fieldset_index) {
          let countHiddenFields = 0;
          let countFeatureFields = 0;
          angular.forEach(fieldset.fields, function (field, field_index) {
            if (
              field.name === 'testimonials.cash' ||
              field.name === 'testimonials.noncash'
            ) {
              // increase the limit of cash and noncash testimonials to 3
              field.limit = 3;
            }
            if (field.data_type === 'campaign') {
              campaignFieldset = fieldset_index;
            }
            campaignFieldIndex = field_index;
            if (
              field.data_type === 'boolean' &&
              field.name === 'donor_pays_fee'
            ) {
              donorPaysFieldset = fieldset_index;
              donorPaysFieldIndex = field_index;
            }
            if (
              field.data_type === 'text' &&
              field.name === 'donor_pays_fee_message'
            ) {
              donorPaysMessageFieldset = fieldset_index;
              donorPaysMessageFieldIndex = field_index;
            }
            if (
              field.data_type === 'boolean' &&
              field.name === 'donor_pays_fee_default'
            ) {
              donorPaysDefaultFieldset = fieldset_index;
              donorPaysDefaultFieldIndex = field_index;
            }
            if (field.data_type === 'textarea') {
              field['optionsSummerNote'] = {
                disableDragAndDrop: true,
                toolbar: [
                  ['style', ['bold', 'italic', 'underline', 'clear']],
                  ['color', ['color']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['insert', ['link', 'picture']],
                ],
              };
              fieldset.fields[field_index] = field;
            }
            if (field.name.includes('components')) {
              const features = appState.currentOrganization.features;

              if (!features.includes('corporate_matching')) {
                delete field.choices.corporate_matching;
                field.value = field.value.filter(
                  (item) => item != 'corporate_matching'
                );
              }
              if (
                !features.includes('tribute') &&
                !features.includes('thank_you')
              ) {
                delete field.choices['tribute/gift'];
                field.value = field.value.filter(
                  (item) => item != 'tribute/gift'
                );
              }
            }
            // bind custom note lookup hidden property to that note's mode value
            if (field.name.includes('custom_note_lookup')) {
              let noteIndex = field.name.slice(-1);
              let noteMode = fieldset.fields.find(
                (f) => f.name === 'custom_note_mode_' + noteIndex
              );
              field.hidden = () => noteMode.value === 'free';
            }
            if (field.hidden) {
              countHiddenFields++;
            }
            if (
              field.require_feature &&
              !$rootScope.allowFeature(field.require_feature)
            ) {
              return countFeatureFields++;
            }
          });
          if (
            fieldset.fields.length > countHiddenFields &&
            fieldset.fields.length > countFeatureFields
          ) {
            return filteredFieldsets.push(fieldset);
          }
        }
      );

      embedInstance.draft_property_fieldsets = filteredFieldsets;
      $scope.embedInstance = embedInstance;
      $scope.disableSaveBtn = true;

      angular.forEach(
        embedInstance.draft_property_fieldsets,
        (fieldset, fieldset_index) =>
          angular.forEach(fieldset.fields, function (field, field_index) {
            if (field.data_type === 'campaign') {
              campaignFieldset = fieldset_index;
              return (campaignFieldIndex = field_index);
            }
          })
      );

      // $scope.gmsDynamicFieldsData is used to populate designation and campaign dropdowns.....
      // pre-fetch all the data that might be needed to edit an embed and stuff it in here.
      $scope.gmsDynamicFieldsData = {
        designations: organizationDesignations.result.designations,
        campaigns: organizationCampaigns.campaigns,
        designationAttributes: organizationDesignationAttributes,
      };

      $scope.settingsFields = {
        title: 'Settings',
        fields: [
          {
            data_type: 'text',
            label: 'Name',
            value: $scope.embedInstance.name,
          },
          {
            data_type: 'display',
            label: 'Embed Code',
            copy_text: `<script src='https://${$scope.idonateiFrame.url}/idonate.js'></script><div data-idonate-embed='${$scope.embedInstance.id}'></div>`,
            description:
              'The embed code below will need to be copied and pasted onto your website in order for it to appear. Your web team will know what to do with it',
          },
          {
            data_type: 'display',
            description:
              'The first piece of code that will need to be embedded on your website. Copy and paste in the source code of your website.',
            label: 'Head',
            value: `<script src='https://${$scope.idonateiFrame.url}/idonate.js'></script>`,
          },
          {
            data_type: 'display',
            description:
              'The second piece of code that will need to be embedded on your website. Copy and paste in the source code of your website.',
            label: 'Body',
            value: `<div data-idonate-embed='${$scope.embedInstance.id}'></div>`,
          },
        ],
      };

      $scope.embedInstance.draft_property_fieldsets.push($scope.settingsFields);

      $scope.$watch(
        'embedInstance.draft_property_fieldsets',
        function (nv, ov) {
          let donorPaysFee;
          const selectedCampaign =
            embedInstance.draft_property_fieldsets[campaignFieldset].fields[
              campaignFieldIndex
            ].value;
          if (donorPaysFieldset) {
            donorPaysFee =
              embedInstance.draft_property_fieldsets[donorPaysFieldset - 1]
                .fields[donorPaysFieldIndex].value;
          }
          if (donorPaysMessageFieldset) {
            $scope.embedInstance.draft_property_fieldsets[
              donorPaysMessageFieldset - 1
            ].fields[donorPaysMessageFieldIndex]['hidden'] = !donorPaysFee;
          }
          if (donorPaysDefaultFieldset) {
            $scope.embedInstance.draft_property_fieldsets[
              donorPaysDefaultFieldset - 1
            ].fields[donorPaysDefaultFieldIndex]['hidden'] = !donorPaysFee;
          }
          if (!donorPaysFee && donorPaysMessageFieldset) {
            $scope.embedInstance.draft_property_fieldsets[
              donorPaysMessageFieldset - 1
            ].fields[donorPaysMessageFieldIndex]['value'] =
              'I would like to add {{amount}} to help cover the transaction cost.';
          }

          //Filter list of designations of the selected campaign
          let isSet = false;
          angular.forEach(organizationCampaigns.campaigns, function (campaign) {
            if (campaign.id === selectedCampaign) {
              //in the instance of an organization having the designation_cart active,
              //we don't want to drive the designations by the campaigns
              if (
                appState.currentOrganization.features.includes(
                  'designation_cart'
                )
              ) {
                $scope.gmsDynamicFieldsData.designations =
                  campaign.designations;
              }
              return (isSet = true);
            }
          });
          //No campaign filter
          if (selectedCampaign === '' || !isSet) {
            return ($scope.gmsDynamicFieldsData.designations =
              organizationDesignations.result.designations);
          }
        },
        true
      );

      $scope.setActiveTab = function (index, tab) {
        $scope.activeTab = tab;
        $scope.activeTab.index = index;

        if (tab.title === 'Thank You') {
          return $timeout(function () {
            const iframe =
              document.getElementsByTagName('iframe')[0].contentWindow;
            return iframe.postMessage(
              JSON.stringify({ embedAction: 'thank-you' }),
              '*'
            );
          });
        }

        if (tab.title === 'Recurring Prompt') {
          return $timeout(function () {
            const iframe =
              document.getElementsByTagName('iframe')[0].contentWindow;
            return iframe.postMessage(
              JSON.stringify({ embedAction: 'recurring-prompt-preview' }),
              '*'
            );
          });
        }

        return $timeout(function () {
          const iframe =
            document.getElementsByTagName('iframe')[0].contentWindow;
          return iframe.postMessage(
            JSON.stringify({ embedAction: 'route', actionData: 'cash.credit' }),
            '*'
          );
        });
      };

      $scope.formBoxes = [];
      $scope.formBoxes['updateBox'] = {
        btnState: 'disabled',
        formValid: false,
        invalidFields: [],
      };
      $scope.publishBoxState = 'disabled';
      $scope.previewLoaded = false;

      $scope.buildPreview = function () {
        $('.preview-container #app-preview').remove();
        if ($scope.formBoxes['updateBox'].formValid) {
          $scope.previewLoaded = true;
          $('.preview-container').append('<div id="app-preview"></div>');
          return $timeout(() =>
            idonate.Embed($scope.embedInstance.id, 'app-preview', true)
          );
        }
      };

      const runValidation = function (data, preventPublishBtn) {
        const _invalidFields = [];
        angular.forEach(data, function (val, key) {
          if (val.required && val.required === 't' && !val.value) {
            return _invalidFields.push(val.label);
          }
        });
        $scope.formBoxes['updateBox'].invalidFields = _invalidFields;
        $scope.formBoxes['updateBox'].formValid =
          $scope.formBoxes['updateBox'].invalidFields.length === 0
            ? true
            : false;
        if (!preventPublishBtn) {
          $scope.disableSaveBtn = $scope.formBoxes['updateBox'].formValid
            ? false
            : true;
        }
      };

      $scope.updateBoxSaveEmbedChanges = function () {
        $scope.embedSaveButton = 'loading';
        const obj = {
          id: $scope.embedInstance.id,
          name: $scope.embedInstance.name,
        };
        return websiteService.updateEmbedInstance(obj).then(
          (data) => $scope.buildPreview(),
          function (error) {}
        );
      };

      $scope.updateBoxSaveChanges = function () {
        if ($scope.formBoxes['updateBox'].formValid) {
          $scope.formBoxes['updateBox'].btnState = 'loading';
          const _objToPost = {};
          angular.forEach(
            $scope.embedInstance.draft_property_fieldsets[
              $scope.activeTab.index
            ].fields,
            function (field, k) {
              let hidden =
                typeof field.hidden === 'function'
                  ? field.hidden()
                  : Boolean(field.hidden);
              if (field.data_type === 'multiple_choice') {
                // only post the id, not the whole object
                const ids = [];
                angular.forEach(field.value, function (v) {
                  if (_.isObject(v)) {
                    return ids.push(v.id);
                  } else {
                    return ids.push(v);
                  }
                });
                return (_objToPost[field.name] = ids);
              } else if (field.data_type !== 'image' && !hidden && field.name) {
                return (_objToPost[field.name] = field.value);
              }
            }
          );

          if (Object.keys(_objToPost).length > 0) {
            //If any of the properties being changed are related to designations, re-poll the embed and regenerate the designation listing.
            var bDesignationTouched =
              Object.keys(_objToPost).filter((a) =>
                a.toLocaleLowerCase().startsWith('designation_')
              ).length > 0;
            return websiteService
              .updateOrganizationEmbedInstanceProperties(
                $scope.embedInstance.id,
                _objToPost
              )
              .then(
                (data) => $scope.buildPreview(),
                function (error) {}
              )
              .then((data) => {
                if (bDesignationTouched) {
                  designationService
                    .getOrganizationDesignations($scope.embedInstance.id)
                    .then((result) => {
                      $scope.gmsDynamicFieldsData.designations =
                        result.result.designations;
                    });
                }
              });
          }
        }
      };

      let timeoutPromise = null;
      //the timeout function allow us to watch the changes until the view has been loaded
      $timeout(
        () =>
          $scope.$watch(
            'embedInstance.draft_property_fieldsets',
            function (newVal, oldVal) {
              if (newVal !== oldVal) {
                runValidation(
                  $scope.embedInstance.draft_property_fieldsets[
                    $scope.activeTab.index
                  ].fields
                );
                if (timeoutPromise) {
                  $timeout.cancel(timeoutPromise);
                }
                timeoutPromise = $timeout(
                  () => $scope.updateBoxSaveChanges(),
                  700
                );
                return;
              }
            },
            true
          ),

        1000
      );

      let timeoutNamePromise = null;
      $scope.$watch(
        'settingsFields',
        function (newVal, oldVal) {
          //Name has the 0 position inside fields array of settingsFields object
          if (newVal.fields[0].value !== oldVal.fields[0].value) {
            if (timeoutNamePromise) {
              $timeout.cancel(timeoutNamePromise);
            }
            timeoutNamePromise = $timeout(function () {
              $scope.embedInstance.name = newVal.fields[0].value;
              return $scope.updateEmbedInstance();
            }, 700);
            return;
          }
        },
        true
      );

      $scope.publishDraft = function () {
        $scope.publishBoxState = 'loading';
        return websiteService
          .publishEmbed($scope.embedInstance.id)
          .then(function (data) {
            if (data.status >= 200 || data.status < 300) {
              //go to different places, depending on type and origin
              if ($scope.embedInstance.type === 'goal_meter') {
                $location.path('/goal-meters');
              } else {
                $location.path('/embeds');
              }
            }

            return ($scope.publishBoxState = 'active');
          });
      };

      $scope.enableDisabledEmbed = function () {
        $scope.updateEmbedInstance();
      };
      $scope.updateEmbedInstance = function () {
        websiteService.updateEmbedInstance($scope.embedInstance);
      };

      // INIT METHODS
      $scope.setActiveTab(0, $scope.embedInstance.draft_property_fieldsets[0]);
      runValidation(
        $scope.embedInstance.draft_property_fieldsets[$scope.activeTab.index]
          .fields,
        !embedInstance.modified
      );
      return $timeout(() => $scope.buildPreview());
    }
  );
