import './campaign-item-ems.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const ems = () => ({
  templateUrl: '/scripts/campaign/campaign-item/campaign-item-ems.html',
  restrict: 'E',
  scope: {
    current: '=',
    previous: '=',
    programs: '=',
  },
  controller: metricsController,
});
angular.module('idonate.gms.campaign').directive('campaignItemEms', ems);

var metricsController = function (
  $scope,
  $route,
  $timeout,
  organizationService
) {
  $scope.campaigns = [];

  if (
    organizationService.getCurrentOrganization().mailchimp_account_name != null
  ) {
    return organizationService
      .getCampaignEmailAnalytics($route.current.params.campaignId)
      .then(function (data) {
        $scope.analytics = data.result;
        angular.forEach(data.result.campaigns, (campaign) =>
          $scope.campaigns.push(campaign)
        );
        $scope.$broadcast('reloadTable');
      });
  }
};
