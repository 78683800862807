import './recurring.html';

const DonationsRecurringCtrl = function (
  $scope,
  organizationService,
  $location
) {
  $scope.orgId = organizationService.getCurrentOrganization().id;
  $scope.clickOnRow = function (id) {
    $location.path(`/donations/recurring/${id}`);
    $scope.$apply();
  };
};

angular
  .module('idonate.gms.donations')
  .controller('DonationsRecurringCtrl', DonationsRecurringCtrl);
