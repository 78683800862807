// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('displayField', () => ({
  templateUrl: '/scripts/input/display-field.html',
  scope: {
    field: '=field',
  },
  restrict: 'E',
  controller($scope, $uibModal) {
    return ($scope.openModal = function (text) {
      // todo: move this into a separate directive
      // so we can use it throughout the application
      const modalInstance = $uibModal.open({
        size: 'md',
        scope: $scope,
        windowClass: 'copy-code-modal',
        templateUrl: '/scripts/website/copy-snippet-modal.html',
        controller($scope, $uibModalInstance) {
          const ua = navigator.userAgent.toLowerCase();
          $scope.safari = false;
          if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
              // chrome should take care of it automatically
            } else {
              $scope.safari = true;
            }
          }

          $scope.text = text;

          return ($scope.close = () => $uibModalInstance.close());
        },
      });
      return modalInstance.result.then(angular.noop, angular.noop);
    });
  },
}));
