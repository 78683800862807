import './donor-detail.html';
import { STATE_CHOICES } from '../util/get-states';

angular
  .module('idonate.gms.donors')
  .controller(
    'DonorsDetailCtrl',
    (
      $scope,
      $location,
      donor,
      donorService,
      organizationService,
      notyService
    ) => {
      $scope.selectedDonor = donor.result.donor;
      $scope.donorTransactions = donor.result.transactions;
      $scope.donorSchedules = donor.result.recurring;
      $scope.donorMappings = donor.result.external_mappings;
      $scope.mergeKeepMesssage = 'Merge - Set as Keep Donor';
      $scope.mergeAssimilateMessage = 'Merge - Set as Assimilate Donor';
      organizationService
        .loadFullOrganizationInformation()
        .then(function (result) {
          $scope.org = result.result;
        });

      $scope.getName = (donor) => {
        return donor.contact.lastname + ', ' + donor.contact.firstname;
      };

      if (gmsStorage.mergeAssimilate) {
        donor = JSON.parse(gmsStorage.mergeAssimilate);
        $scope.mergeAssimilateDonorName = $scope.getName(donor);
      } else {
        $scope.mergeAssimilateDonorName = '';
      }

      if (gmsStorage.mergeKeeper) {
        donor = JSON.parse(gmsStorage.mergeKeeper);
        $scope.mergeKeepDonorName = $scope.getName(donor);
      } else {
        $scope.mergeKeepDonorName = '';
      }

      $scope.setAssimilateDonor = () => {
        $scope.mergeAssimilateDonorName = $scope.getName($scope.selectedDonor);
        gmsStorage.mergeAssimilate = JSON.stringify($scope.selectedDonor);
      };

      $scope.setKeepDonor = () => {
        $scope.mergeKeepDonorName = $scope.getName($scope.selectedDonor);
        gmsStorage.mergeKeeper = JSON.stringify($scope.selectedDonor);
      };

      $scope.gotoMerge = () => {
        $location.path('donors/merge');
      };

      $scope.gotoTransaction = (id) => {
        $location.path(`donations/detail/${id}`);
      };

      $scope.gotoSchedule = (id) => {
        $location.path(`donations/recurring/${id}`);
      };
      $scope.updateDonor = () => {
        const donor = $scope.selectedDonor;
        const donorData = {
          organization_id: organizationService.getCurrentOrganization().id,
          donor_id: donor.donor_id,
          firstname: donor.contact.firstname,
          middlename: donor.contact.middlename,
          lastname: donor.contact.lastname,
          street: donor.contact.address.street,
          street2: donor.contact.address.street2,
          city: donor.contact.address.city,
          state: donor.contact.address.state,
          country: donor.contact.address.country,
          zip: donor.contact.address.zip,
          title: donor.contact.title,
          email: donor.contact.email,
          phone: donor.contact.phone,
        };
        const donorMappingData = $scope.donorMappings.map((item) => {
          return {
            donor_id: $scope.selectedDonor.donor_id,
            organization_id: organizationService.getCurrentOrganization().id,
            external_service: item.service,
            external_id: item.id,
          };
        });
        donorService.updateDonor(donorData).then((results) => {
          console.log(results);
          if (results.result.error) {
            notyService.error(results.result.error_message);
          } else {
            window.history.back() ?? $location.path('/reporting');
          }
        });
        donorService.updateDonorMapping(donorMappingData);
      };

      $scope.$watch(
        'selectedDonor.contact.address.country',
        function (newCountry, oldCountry) {
          $scope.stateOptions = STATE_CHOICES[newCountry];
          if (newCountry !== oldCountry) {
            // clear state on country change
            $scope.selectedDonor.contact.address.state = null;
          }
        }
      );
    }
  );
