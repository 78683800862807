import './account.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').controller(
  'OrganizationAccountCtrl',
  function (
    $scope,
    $rootScope,
    $timeout,
    $compile,
    $routeParams,
    fullOrgData,
    $httpParamSerializer,
    currentUserInfo,
    organizationService,
    GlobalService,
    UserService,
    organizationImages,
    Restangular, // this reference to Restangular is used to call the Virtuous API.
    notyService
  ) {
    //Service calls
    let imageKey, keySplit, parentKey;
    $scope.userInfo = currentUserInfo.contact;
    $scope.currentOrganization = organizationService.getCurrentOrganization();
    $scope.organizationData = fullOrgData.result;

    $scope.states = [
      'AK',
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'GU',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MH',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'PR',
      'PW',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VI',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ];

    $scope.imageData = {};

    $scope.categories = [
      {
        id: 51,
        title: 'Adoption',
      },
      {
        id: 52,
        title: 'Adult Care',
      },
      {
        id: 53,
        title: 'Arts/Worship',
      },
      {
        id: 54,
        title: 'Bible Translation',
      },
      {
        id: 55,
        title: 'Church',
      },
      {
        id: 56,
        title: 'Counseling',
      },
      {
        id: 57,
        title: 'Discipleship',
      },
      {
        id: 58,
        title: 'Education',
      },
      {
        id: 59,
        title: 'Evangelism',
      },
      {
        id: 60,
        title: 'Family',
      },
      {
        id: 61,
        title: 'Homeless',
      },
      {
        id: 62,
        title: 'Hunger',
      },
      {
        id: 63,
        title: 'International',
      },
      {
        id: 64,
        title: 'Life Affirmation',
      },
      {
        id: 65,
        title: 'Marriage',
      },
      {
        id: 66,
        title: 'Media',
      },
      {
        id: 67,
        title: 'Medical/Healthcare',
      },
      {
        id: 68,
        title: 'Missions',
      },
      {
        id: 69,
        title: 'Needy',
      },
      {
        id: 70,
        title: 'Orphan',
      },
      {
        id: 71,
        title: 'Poor',
      },
      {
        id: 72,
        title: 'Prayer',
      },
      {
        id: 73,
        title: 'Urban',
      },
      {
        id: 74,
        title: 'Youth',
      },
    ];

    $scope.stockProcessors = [
      'Adoption',
      'Charity',
      'NCF',
      'Schwab',
      'Fidelity',
    ];

    //Initial Vars
    $scope.tabs = [
      {
        title: 'Info',
        tab: 'info',
      },
      {
        title: 'Contact',
        tab: 'contact',
      },
      {
        title: 'Logos',
        tab: 'logos',
      },
    ];

    // Add sections based on roles -- these tabs only contain superuser-visible features.
    // TODO? : concentrate all superuser features into their own tabs and clearly label them!
    if ($rootScope.allowRole(['SuperAdmin'])) {
      $scope.tabs.push(
        {
          title: 'Redirects',
          tab: 'redirects',
        },
        {
          title: 'Two Factor Authentication',
          tab: '2fa',
        },
        {
          title: 'Other',
          tab: 'other',
        }
      );
    }

    // Add sections based on features
    if ($rootScope.allowFeature('ems')) {
      $scope.tabs.push({
        title: 'Email Marketing Integration',
        tab: 'ems',
      });
    }

    if (
      $rootScope.allowFeature('salesforce') ||
      $rootScope.allowFeature('virtuous_integration')
    ) {
      $scope.tabs.push({
        title: 'CRM Integrations',
        tab: 'crm',
      });
    }

    if ($rootScope.allowFeature('standard_policies')) {
      $scope.tabs.push({
        title: 'Legal',
        tab: 'legal',
      });
    }

    if ($rootScope.allowFeature('branded_dss')) {
      $scope.tabs.push({
        title: 'Donor Self Serve',
        tab: 'dss',
      });
    }

    $scope.setActiveTab = function (tabName, tab) {
      $scope.activeTab = tab;
      return ($scope.activeTab.name = tabName);
    };

    // added extra security so people don't stumble upon ems section via url param
    if (
      $routeParams['tab'] === 'email-marketing-integration' &&
      $rootScope.allowFeature('ems')
    ) {
      $scope.setActiveTab('ems', _.filter($scope.tabs, { tab: 'ems' }));
    } else {
      $scope.setActiveTab('info', _.filter($scope.tabs, { tab: 'info' }));
    }

    $scope.notificationEmails = [];

    if ($scope.organizationData.attributes.config.notification_emails) {
      const _tempEmailArray =
        $scope.organizationData.attributes.config.notification_emails.split(
          ','
        );
      angular.forEach(_tempEmailArray, (val, key) =>
        $scope.notificationEmails.push({
          id: key,
          value: val,
        })
      );
    }

    //Local scopes
    $scope.disableSaveBtn = [];
    $scope.disabledChange = [];
    $scope.disabledChange['saveOrganizationInformationWidget'] = true;
    $scope.disabledChange['contact'] = true;
    $scope.disabledChange['saveEmailsReceiptWidget'] = true;
    $scope.disabledChange['saveExternalRedirectsWidget'] = true;
    $scope.disabledChange['saveStockProcessorWidget'] = true;
    $scope.disabledChange['saveSalesforceWidget_1'] = true;
    $scope.disabledChange['saveSalesforceWidget_2'] = true;

    //Watch for changes on basic info
    $scope.$watch('organizationData.address.street', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.address.street2', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.address.city', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.address.state', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.address.zip', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.categories[0]', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.attributes.config.nonprofit_type', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.attributes.config.website', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.attributes.config.social.rss_url', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch(
      'organizationData.attributes.config.social.twitter_username',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange[
              'saveOrganizationInformationWidget'
            ] = false);
          }
        })
    );

    $scope.$watch(
      'organizationData.attributes.config.social.facebook_username',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange[
              'saveOrganizationInformationWidget'
            ] = false);
          }
        })
    );

    $scope.$watch('organizationData.attributes.config.mobile.enabled', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange[
            'saveOrganizationInformationWidget'
          ] = false);
        }
      })
    );

    $scope.$watch('organizationData.primary_contact.title', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['contact'] = false);
        }
      })
    );

    $scope.$watch('organizationData.primary_contact.firstname', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['contact'] = false);
        }
      })
    );

    $scope.$watch('organizationData.primary_contact.lastname', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['contact'] = false);
        }
      })
    );

    $scope.$watch('organizationData.primary_contact.email', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['contact'] = false);
        }
      })
    );

    $scope.$watch('organizationData.primary_contact.phone', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          return ($scope.disabledChange['contact'] = false);
        }
      })
    );

    $scope.$watch(
      'organizationData.attributes.config.external_handlers.cash',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange[
              'saveExternalRedirectsWidget'
            ] = false);
          }
        })
    );

    $scope.$watch(
      'organizationData.attributes.config.external_handlers.stock',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange[
              'saveExternalRedirectsWidget'
            ] = false);
          }
        })
    );

    $scope.$watch(
      'organizationData.attributes.config.external_handlers.realestate',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange[
              'saveExternalRedirectsWidget'
            ] = false);
          }
        })
    );

    $scope.$watch(
      'organizationData.attributes.config.external_handlers.businessinterest',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange[
              'saveExternalRedirectsWidget'
            ] = false);
          }
        })
    );

    $scope.$watch(
      'organizationData.attributes.config.stock.processor',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange['saveStockProcessorWidget'] = false);
          }
        })
    );

    $scope.$watch(
      'organizationData.attributes.config.salesforce_2.id',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange['saveSalesforceWidget_2'] = false);
          }
        })
    );

    $scope.$watch(
      'organizationData.attributes.config.salesforce_2.access_token',
      (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return ($scope.disabledChange['saveSalesforceWidget_2'] = false);
          }
        })
    );

    $scope.$watch(
      'notificationEmails',
      function (newVal, oldVal) {
        if (_.filter($scope.notificationEmails, { value: '' }).length > 0) {
          $scope.disabledChange['saveEmailsReceiptWidget'] = true;
        } else {
          $scope.disabledChange['saveEmailsReceiptWidget'] = newVal === oldVal;
        }
        if (newVal === '') {
          return ($scope.disabledChange['saveEmailsReceiptWidget'] = true);
        }
      },
      true
    );

    const changeCallback = (changed) =>
      ($scope.disabledChange['saveEmailsReceiptWidget'] = !changed);

    //Functionality to validate fields
    const validate = function (requiredFields) {
      const validateInputs = requiredFields || [];
      const validation = [];
      let validationResult = true;

      //Empty fields Validation
      angular.forEach(validateInputs, function (obj, $i) {
        validation[$i] = true; //Set the input as true by default
        $scope[obj.errorClassModel] = false;

        //Check if the object is required
        if (obj.required === true) {
          const localString = String(obj.content);
          if (
            localString === undefined ||
            localString === null ||
            localString.trim().length === 0
          ) {
            validation[$i] = false;
          }
        }

        //Check if the object has some special validation
        if (obj.type) {
          switch (obj.type) {
            case 'email':
              var pattern =
                /^[a-zA-Z0-9\-]{1}([\.\_\-]{0,}[a-zA-Z0-9\-]{1}){0,}@[a-zA-Z0-9]{1,}([\-]{0,}[a-zA-Z0-9]{1,}){0,}([\.]{1}[a-zA-Z0-9]{1,}){1,}$/;
              if (obj.content && pattern.test(obj.content) === false) {
                return (validation[$i] = false);
              }
              break;
            case 'url':
              pattern =
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
              if (obj.content && pattern.test(obj.content) === false) {
                return (validation[$i] = false);
              }
              break;
            case 'number':
              pattern = /^[0-9]{1,}$/;
              if (obj.content && pattern.test(obj.content) === false) {
                return (validation[$i] = false);
              }
              break;
          }
        }
      });

      //Seek in the array for errors
      let counter = 0;
      while (counter < validation.length) {
        if (validation[counter] === false) {
          validationResult = false;
          //console.log validateInputs[counter].errorClassModel
          $scope[validateInputs[counter].errorClassModel] = true;
        }
        counter++;
      }
      return validationResult;
    };

    $scope.detectChangeEmail = () =>
      ($scope.disabledChange['saveEmailsReceiptWidget'] = false);

    $scope.addNewEmail = function () {
      $scope.notificationEmails.push({
        id: $scope.notificationEmails.length + 1,
        value: '',
        isNew: true,
      });
    };

    //Save functionality for Organization Information widget
    $scope.saveOrganizationInformationWidget = function () {
      $scope.disableSaveBtn['saveOrganizationInformationWidget'] = true;
      $scope.disabledChange['saveOrganizationInformationWidget'] = true;
      const postValues = {
        address_street:
          $scope.organizationData.address !== undefined
            ? $scope.organizationData.address.street
            : undefined,
        address_street2:
          $scope.organizationData.address !== undefined
            ? $scope.organizationData.address.street2
            : undefined,
        address_city:
          $scope.organizationData.address !== undefined
            ? $scope.organizationData.address.city
            : undefined,
        address_state:
          $scope.organizationData.address !== undefined
            ? $scope.organizationData.address.state
            : undefined,
        address_zip:
          $scope.organizationData.address !== undefined
            ? $scope.organizationData.address.zip
            : undefined,
        categories:
          $scope.organizationData.categories[0] !== undefined
            ? $scope.organizationData.categories[0]
            : undefined,
      };
      const postValues2 = {
        config: {
          website:
            $scope.organizationData.attributes.config.website !== undefined
              ? $scope.organizationData.attributes.config.website
              : undefined,
          social: {
            rss_url:
              $scope.organizationData.attributes.config.social.rss_url !==
              undefined
                ? $scope.organizationData.attributes.config.social.rss_url
                : undefined,
            twitter_username:
              $scope.organizationData.attributes.config.social
                .twitter_username !== undefined
                ? $scope.organizationData.attributes.config.social
                    .twitter_username
                : undefined,
            facebook_username:
              $scope.organizationData.attributes.config.social
                .facebook_username !== undefined
                ? $scope.organizationData.attributes.config.social
                    .facebook_username
                : undefined,
          },
          mobile: {
            enabled:
              $scope.organizationData.attributes.config.mobile.enabled !==
              undefined
                ? $scope.organizationData.attributes.config.mobile.enabled
                : undefined,
          },
        },
      };
      organizationService.updateOrganization(postValues).then(function (data) {
        organizationService.updateAttributes(postValues2).then(function (data) {
          $scope.disableSaveBtn['saveOrganizationInformationWidget'] = false;
        });
      });
    };

    //Save functionality for contact widget
    $scope.saveContactWidget = function () {
      const itemsToValidate = [
        {
          content:
            $scope.organizationData.primary_contact !== undefined
              ? $scope.organizationData.primary_contact.email
              : undefined,
          type: 'email',
          required: true,
          errorClassModel: 'validationEmailResult',
        },
      ];
      $scope.validation = validate(itemsToValidate);
      if ($scope.validation === true) {
        $scope.disableSaveBtn['contact'] = true;
        $scope.disabledChange['contact'] = true;
        const postValues = {
          organization_id: $scope.currentOrganization.id,
          contact_firstname:
            $scope.organizationData.primary_contact.firstname !== undefined
              ? $scope.organizationData.primary_contact.firstname
              : undefined,
          contact_lastname:
            $scope.organizationData.primary_contact.lastname !== undefined
              ? $scope.organizationData.primary_contact.lastname
              : undefined,
          contact_email:
            $scope.organizationData.primary_contact.email !== undefined
              ? $scope.organizationData.primary_contact.email
              : undefined,
          contact_phone:
            $scope.organizationData.primary_contact.phone !== undefined
              ? $scope.organizationData.primary_contact.phone
              : undefined,
          contact_title:
            $scope.organizationData.primary_contact.title !== undefined
              ? $scope.organizationData.primary_contact.title
              : undefined,
        };
        return organizationService
          .updateOrganization(postValues)
          .then(function (data) {
            $scope.disableSaveBtn['contact'] = false;
            $scope.disabledChange['contact'] = false;
          });
      }
    };

    //Save functionality for notification emails
    $scope.updateNotificationEmails = function () {
      $scope.disableSaveBtn['saveEmailsReceiptWidget'] = false;
      $scope.disabledChange['saveEmailsReceiptWidget'] = false;

      const _tempEmailNotificationsToSend = [];

      angular.forEach($scope.notificationEmails, function (val, key) {
        if (val.value !== '') {
          _tempEmailNotificationsToSend.push(val.value);
          return (val.isNew = false);
        }
      });

      const postValues = {
        config: {
          notification_emails: _tempEmailNotificationsToSend.join(),
        },
      };

      organizationService.updateAttributes(postValues).then(
        function (data) {
          $scope.disableSaveBtn['saveEmailsReceiptWidget'] = false;
          return ($scope.disabledChange['saveEmailsReceiptWidget'] = true);
        },
        function (error) {
          $scope.disableSaveBtn['saveEmailsReceiptWidget'] = false;
          return ($scope.disabledChange['saveEmailsReceiptWidget'] = true);
        }
      );
    };

    //Save functionality for imagery widget
    $scope.saveImageryAndMessage = function () {
      $scope.disableSaveBtn['imageryMessages'] = true;
      const postValues = {
        config: {
          receipt_text:
            $scope.organizationData.attributes.config !== undefined
              ? $scope.organizationData.attributes.config.receipt_text
              : undefined,
        },
      };
      organizationService
        .updateAttributes(postValues)
        .then((data) => ($scope.disableSaveBtn['imageryMessages'] = false));
    };

    //Save functionality for external redirects widget
    $scope.saveExternalRedirectsWidget = function () {
      const itemsToValidate = [
        {
          content:
            $scope.organizationData.attributes.config.external_handlers.cash,
          type: 'url',
          required: false,
          errorClassModel: 'validationCashRedirect',
        },
        {
          content:
            $scope.organizationData.attributes.config.external_handlers.stock,
          type: 'url',
          required: false,
          errorClassModel: 'validationStockRedirect',
        },
        {
          content:
            $scope.organizationData.attributes.config.external_handlers
              .realestate,
          type: 'url',
          required: false,
          errorClassModel: 'validationStateRedirect',
        },
        {
          content:
            $scope.organizationData.attributes.config.external_handlers
              .businessinterest,
          type: 'url',
          required: false,
          errorClassModel: 'validationBusinessRedirect',
        },
      ];
      $scope.validation = validate(itemsToValidate);

      if ($scope.validation === true) {
        $scope.disableSaveBtn['saveExternalRedirectsWidget'] = true;
        $scope.disabledChange['saveExternalRedirectsWidget'] = true;
        const postValues = {
          config: {
            external_handlers: {
              businessinterest:
                $scope.organizationData.attributes.config.external_handlers !==
                undefined
                  ? $scope.organizationData.attributes.config.external_handlers
                      .businessinterest
                  : undefined,
              cash:
                $scope.organizationData.attributes.config.external_handlers !==
                undefined
                  ? $scope.organizationData.attributes.config.external_handlers
                      .cash
                  : undefined,
              stock:
                $scope.organizationData.attributes.config.external_handlers !==
                undefined
                  ? $scope.organizationData.attributes.config.external_handlers
                      .stock
                  : undefined,
              realestate:
                $scope.organizationData.attributes.config.external_handlers !==
                undefined
                  ? $scope.organizationData.attributes.config.external_handlers
                      .realestate
                  : undefined,
            },
          },
        };
        organizationService
          .updateAttributes(postValues)
          .then(
            (data) =>
              ($scope.disableSaveBtn['saveExternalRedirectsWidget'] = false)
          );
      }
    };

    //Save functionality for stock procesor
    $scope.saveStockProcesor = function () {
      $scope.disableSaveBtn['saveStockProcessorWidget'] = true;
      $scope.disabledChange['saveStockProcessorWidget'] = true;
      const postValues = {
        config: {
          stock: {
            processor:
              $scope.organizationData.attributes.config.stock !== undefined
                ? $scope.organizationData.attributes.config.stock.processor
                : undefined,
          },
        },
      };
      organizationService
        .updateAttributes(postValues)
        .then(
          (data) => ($scope.disableSaveBtn['saveStockProcessorWidget'] = false)
        );
    };

    organizationService.getSalesForceLoginUrl().then((res) => {
      $scope.salesForceLink = res.result.url;
    });
    $scope.showSalesForceBtn = $scope.organizationData.salesforce_connect
      ? false
      : true;

    $scope.checkForSalesForceAuth = function () {
      $scope.chekingForSalesForce = true;
      let temp_attempts = 0;
      var reCheckForSalesForceAuth = () =>
        $timeout(
          () =>
            organizationService
              .loadFullOrganizationInformation()
              .then(function (data) {
                if (data.result.salesforce_connect) {
                  $scope.showSalesForceBtn = false;
                  return ($scope.chekingForSalesForce = false);
                } else if (temp_attempts < 5) {
                  temp_attempts = temp_attempts + 1;
                  return reCheckForSalesForceAuth();
                } else {
                  $scope.showSalesForceBtn = true;
                  return ($scope.chekingForSalesForce = false);
                }
              }),

          7000
        );
      return reCheckForSalesForceAuth();
    };

    $scope.changeTfa = function () {
      $scope.disableSaveBtn['saveTfaWidget'] = true;
      $scope.disabledChange['saveTfaWidget'] = true;
      return organizationService
        .updateAttributeWithPath(
          'require_tfa',
          $scope.organizationData.attributes.require_tfa
        )
        .then(function (result) {
          $scope.disableSaveBtn['saveTfaWidget'] = false;
          return ($scope.disabledChange['saveTfaWidget'] = false);
        });
    };

    $scope.updateDSS = function () {
      if (!$scope.organizationDSSColorField.value) {
        // since it's a required field (from the endpoint)
        // let's take care of blank values before it's being submitted
        notyService.info('There must be a value for DSS color');
        return;
      }
      $scope.disableSaveBtn['updateDSS'] = true;
      $scope.disabledChange['updateDSS'] = true;
      return organizationService
        .updateAttributes({
          'dss.color': $scope.organizationDSSColorField.value,
          'dss.custom_notice.content': $scope.dss.custom_notice_content.value,
          'dss.edit_schedule_designations':
            $scope.dss.edit_schedule_designations.value,
        })
        .then(function (result) {
          $scope.disableSaveBtn['updateDSS'] = false;
          return ($scope.disabledChange['updateDSS'] = false);
        });
    };

    $scope.removeImage = (key) =>
      organizationService.deleteOrganizationImage(key).then(function (data) {});

    $scope.$watch('organizationLogoField.value', function (a, b) {
      if (a === '') {
        return $scope.removeImage(imageKey);
      }
    });
    $scope.$watch('organizationParentField.value', function (a, b) {
      if (a === '') {
        return $scope.removeImage(parentKey);
      }
    });

    if (organizationImages.result.images['parent_logo']) {
      keySplit = organizationImages.result.images['parent_logo'].key.split('/');
      parentKey = keySplit[keySplit.length - 1];
    } else {
      parentKey = null;
    }
    if (organizationImages.result.images['theme_logo']) {
      keySplit = organizationImages.result.images['theme_logo'].key.split('/');
      imageKey = keySplit[keySplit.length - 1];
    } else {
      imageKey = null;
    }

    $scope.organizationLogoField = {
      value: organizationImages.result.images['theme_logo']
        ? organizationImages.result.images['theme_logo'].url +
          '?ts=' +
          new Date().getTime()
        : null,
      name: 'theme_logo',
    };
    $scope.organizationParentField = {
      value: organizationImages.result.images['parent_logo']
        ? organizationImages.result.images['parent_logo'].url +
          '?ts=' +
          new Date().getTime()
        : null,
      name: 'parent_logo',
    };

    // Organization Legal Policies

    $scope.savingLegal = false;
    const legalData = $scope.organizationData.attributes.legal;
    $scope.legal = {
      privacy_policy: {
        value: legalData.privacy_policy || '',
        label: 'Privacy Policy',
        disabled: false,
      },
      refund_policy: {
        value: legalData.refund_policy || '',
        label: 'Refund Policy',
        disabled: false,
      },
      terms_and_conditions: {
        value: legalData.terms_and_conditions || '',
        label: 'Terms and Conditions',
        disabled: false,
      },
    };
    $scope.saveLegalChanges = function (data) {
      $scope.savingLegal = true;
      const _post = {};
      angular.forEach(
        data,
        (item, key) => (_post[`legal.${key}`] = item.value)
      );
      return organizationService.updateAttributes(_post).then(
        (data) => ($scope.savingLegal = false),
        (error) => ($scope.savingLegal = false)
      );
    };

    // DSS Color Field
    $scope.organizationDSSColorField = {
      value: $scope.organizationData.attributes.dss.color,
      label: 'Color',
    };

    let editScheduleDesignations =
      $scope.organizationData.attributes.dss.edit_schedule_designations ||
      false;
    $scope.dss = {
      custom_notice_content: {
        value: $scope.organizationData.attributes.dss.custom_notice.content,
        label: 'Custom Notice Content',
      },
      edit_schedule_designations: {
        value: editScheduleDesignations,
        label: 'Allow Donors to Edit Designations on Recurring Schedules',
      },
    };

    organizationService
      .getVirtuousToken()
      .then(
        (response) =>
          ($scope.virtuousAuthComplete = response.result.refresh_token != null)
      );

    $scope.virtuous = {};
    $scope.connectVirtuous = () =>
      Restangular.withConfig((RestangularConfigurer) =>
        RestangularConfigurer.setBaseUrl(window.iDonateConfig.virtuousApi)
      )
        .one('Token')
        .customPOST(
          $httpParamSerializer({
            grant_type: 'password',
            username: $scope.virtuous.username,
            password: $scope.virtuous.password,
          })
        )
        .then(
          (response) =>
            organizationService
              .postVirtuousToken(response.refresh_token, response.access_token)
              .then(function (response) {
                notyService.success('Successfully Authenticated Virtuous');
                return ($scope.virtuousAuthComplete = true);
              }),

          (error) => notyService.error(error.data.error_description)
        );

    // load tab if requested
    if ($routeParams.tab) {
      $scope.setActiveTab(
        $routeParams.tab,
        _.filter($scope.tabs, { tab: $routeParams.tab })
      );
    }

    return ($scope.deleteVirtuous = () =>
      organizationService.deleteVirtuousToken().then(
        function (response) {
          notyService.success('Successfully Removed Virtuous Token');
          return ($scope.virtuousAuthComplete = false);
        },
        (error) => notyService.error(error.data.error_description)
      ));
  }
);
