/*
 * Objects returned from Transform methods must contain at least the following keys:
 * * data_type: a string value corresponding to gms-dynamic-field types (which are derived from Property Graph instead of db types)
 * * value: the value may be transformed/replaced depending on the needs of the dynamic field
 * * untransform: a function that returns the untransformed value for sending into the backend.
 */
const dynamicFieldTransforms = {
  MultiCheckboxField(meta, value) {
    let choices = meta.choices.reduce((acc, v) => {
      acc[v[0]] = v[1];
      return acc;
    }, {});

    // prep to be used as a model in templates
    let values = meta.choices.reduce((acc, v) => {
      acc[v[0]] = false;
      return acc;
    }, {});

    if (value) {
      // apply 'on' states
      value.forEach((x) => {
        values[x] = true;
      });
    }

    return {
      data_type: 'multiple_checkbox',
      choices: choices,
      value: values,
      untransform() {
        // return array of keys where value is true
        return Object.keys(this.value).filter((k) => this.value[k]);
      },
    };
  },
  BooleanField(meta, value) {
    return {
      data_type: 'boolean',
      value: value,
      untransform() {
        return this.value;
      },
    };
  },

  // null is used as fallback for all other field types
  null(meta, value) {
    return {
      data_type: 'text',
      value: value,
      untransform() {
        return this.value;
      },
    };
  },
};

function transformDynamicField(meta, value) {
  let func = dynamicFieldTransforms[meta.type] || dynamicFieldTransforms[null];

  return func(meta, value);
}

function formFromConfigMeta(webhook, hide_private) {
  let rv = [];
  const privateTypes = ['PasswordField', 'EncryptedStringField'];

  for (const [key, meta] of Object.entries(webhook.adapter.config_meta)) {
    // emit password fields for masked private data
    if (hide_private && privateTypes.indexOf(meta.type) != -1) {
      rv.push({
        name: key,
        label: meta.label,
        disabled: true,
        data_type: 'password',
        value: 'masked data not shown',
      });
    } else {
      rv.push({
        name: key,
        label: meta.label,
        disabled: false,
        ...transformDynamicField(meta, webhook.config[key]),
      });
    }
  }

  return rv;
}

angular
  .module('idonate.gms')
  .factory('webhookService', function (SessionService, organizationService) {
    return {
      listAvailableAdapters() {
        return SessionService.restangularGmsApi
          .all('webhook')
          .customGET()
          .then((result) => result.result.adapters);
      },
      prepareNewWebhook(adapterName) {
        return SessionService.restangularGmsApi
          .one('webhook')
          .customGET(adapterName)
          .then((result) => {
            let webhook = {
              config: {},
              adapter: result.result,
              enabled: true,
            };

            // populate defaults
            for (const [key, meta] of Object.entries(
              webhook.adapter.config_meta
            )) {
              webhook.config[key] = meta.default;
            }

            webhook.form = formFromConfigMeta(webhook, false);

            return webhook;
          });
      },
      listInstalled() {
        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .all('webhooks')
          .customGET()
          .then((result) => result.result.webhooks);
      },
      listEvents() {
        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .all('events')
          .customGET()
          .then((result) => result.result.items);
      },
      getWebhookDetails(webhookId) {
        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .one('webhooks', webhookId)
          .get()
          .then((result) => {
            let webhook = result.result.webhook;
            webhook.form = formFromConfigMeta(webhook, true);

            webhook.exceptions = result.result.exceptions;

            return webhook;
          });
      },
      putConfiguration(webhook) {
        // TODO : validate data before sending?
        let data = {
          enabled: webhook.enabled,
          terminal_only: webhook.terminal_only,
          target_url: webhook.target_url,
          headers: webhook.headers,
        };

        for (const field of webhook.form) {
          if (!field.disabled) {
            data[field.name] = field.untransform();
          }
        }

        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .one('webhooks', webhook.id)
          .customPUT(data)
          .then((result) => result.result);
      },
      testEventConfiguration(webhook, eventType) {
        let data = {
          enabled: webhook.enabled,
          terminal_only: webhook.terminal_only,
          target_url: webhook.target_url,
          headers: webhook.headers,
          event_type: eventType,
        };

        for (const [key, meta] of Object.entries(webhook.adapter.config_meta)) {
          data[key] = webhook.config[key];
        }

        for (const field of webhook.form) {
          if (!field.disabled) {
            data[field.name] = field.untransform();
          }
        }

        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .one('webhooks')
          .customPOST(data, 'event-test')
          .then((result) => result.result);
      },
      testTransactionConfiguration(webhook) {
        let data = {
          adapter: webhook.adapter.key,
          webhook_id: webhook.id || null,
        };

        for (const [key, meta] of Object.entries(webhook.adapter.config_meta)) {
          data[key] = webhook.config[key];
        }

        for (const field of webhook.form) {
          if (!field.disabled) {
            data[field.name] = field.untransform();
          }
        }

        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .one('webhooks')
          .customPOST(data, 'transaction-test')
          .then((result) => result.result);
      },
      installConfiguration(webhook) {
        let data = {
          adapter: webhook.adapter.key,
          enabled: webhook.enabled,
          terminal_only: webhook.terminal_only,
          target_url: webhook.target_url || '',
          headers: webhook.headers || {},
        };

        // ensure defaults are set
        for (const [key, meta] of Object.entries(webhook.adapter.config_meta)) {
          data[key] = webhook.config[key];
        }

        // override with form
        for (const field of webhook.form) {
          if (!field.disabled) {
            data[field.name] = field.untransform();
          }
        }

        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .customPOST(data, 'webhooks')
          .then((result) => result.result);
      },
      getEvents(webhookId, params) {
        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .one('webhooks', webhookId)
          .all('events')
          .customGET('', params || {})
          .then((result) => result.result);
      },
      getEventDetails(eventId) {
        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .one('events', eventId)
          .get()
          .then((result) => result.result);
      },
      pushEvent(webhookId, eventId) {
        return SessionService.restangularGmsApi
          .one('organization', organizationService.getCurrentOrganization().id)
          .one('events', eventId)
          .customPOST(
            {
              webhook_id: webhookId,
            },
            'push'
          )
          .then((result) => result.result);
      },
    };
  });
