// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.legacy-p2p')
  .factory('p2pService', (SessionService, organizationService) => ({
    publishP2P(id) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(`organization/${_org}/legacy-p2p/advocacy_program/${id}/publish`)
        .post();
    },
    getAllTemplates() {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .one('legacy-p2p/advocacy_template')
        .get();
    },
    createNewProgram(org_info) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(`organization/${_org}/legacy-p2p/advocacy_program`)
        .post(org_info);
    },
    expireAdvocate(team_id, advocate_id) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(
          `organization/${_org}/legacy-p2p/advocacy_team/${team_id}/advocate/${advocate_id}/membership/expire`
        )
        .post();
    },
    expireProgram(programID) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(
          `organization/${_org}/legacy-p2p/advocacy_program/${programID}/expire`
        )
        .post();
    },
    archiveProgram(programID) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(
          `organization/${_org}/legacy-p2p/advocacy_program/${programID}/archive`
        )
        .post();
    },
    deleteProgram(programID) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(`organization/${_org}/legacy-p2p/advocacy_program/${programID}`)
        .customDELETE();
    },
    saveP2PForm(program) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .one(`organization/${_org}/legacy-p2p/advocacy_program/${program.id}`)
        .customOperation('put', null, null, null, program);
    },
    getP2PFormData(id) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .one(`organization/${_org}/legacy-p2p/advocacy_program/${id}`)
        .get();
    },
    getAllAdvocacyPrograms() {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .one(`organization/${_org}/legacy-p2p/advocacy_program`)
        .get();
    },
    getP2PProgram(interval) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .one(`organization/${_org}/legacy-p2p/summary`)
        .get(interval);
    },
    getAnchorDetails() {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .one(`organization/${_org}/attributes`)
        .get();
    },
    saveAnchorDetails(details) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(`organization/${_org}/attributes`)
        .post(details);
    },
    addAdvocateToTeam(teamId, advocateId) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(
          `organization/${_org}/legacy-p2p/advocacy_team/${teamId}/advocate/${advocateId}/membership`
        )
        .post();
    },
    kickAdvocateFromTeam(teamId, advocateId) {
      const _org = organizationService.getCurrentOrganization().id;
      return SessionService.restangularGmsApi
        .all(
          `organization/${_org}/legacy-p2p/advocacy_team/${teamId}/advocate/${advocateId}/membership`
        )
        .customDELETE();
    },
    setDonorAdvocacy(advocacy) {
      return SessionService.restangularGmsApi
        .one(`transaction/${advocacy.transactionId}`)
        .patch({
          advocacy_team_id: !!advocacy.teamId ? advocacy.teamId : null,
          advocacy_program_id: !!advocacy.programId ? advocacy.programId : null,
          advocate_id: !!advocacy.advocateId ? advocacy.advocateId : null,
        });
    },
  }));
