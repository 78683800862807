// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.website')
  .directive('newPageModal', function (Modals) {
    Modals.registerModal('NewPageModal', function (resolve) {
      return {
        size: 'md',
        windowClass: 'advocate-modal',
        templateUrl: '/scripts/website/new-page-modal.html',
        controllerAs: '$ctrl',
        resolve,
        controller($uibModalInstance, bases, websiteService, $location) {
          this.bases = bases;
          const clearBases = () => {
            for (let base of Array.from(this.bases)) {
              base.selected = false;
            }
          };
          const validate = (embed) => {
            let valid = true;
            this.invalid = [];
            if (!this.page.title || this.page.title === '') {
              valid = false;
              this.invalid.push('title');
            }
            if (!this.page.template) {
              valid = false;
              this.invalid.push('template');
            }
            return valid;
          };
          this.cancel = () => $uibModalInstance.dismiss('cancel');
          this.selectTemplate = (template) => {
            this.page.template = template.id;
            clearBases();
            return (template.selected = true);
          };
          this.save = () => {
            this.loading = true;
            if (!validate(this.page)) {
              return (this.loading = false);
            } else {
              return websiteService
                .addOrganizationPage({
                  slug: this.page.title,
                  page_id: this.page.template,
                })
                .then((data) => {
                  $location.path(`pages/edit/${data.result.page.id}`);
                  $uibModalInstance.close();
                  return (this.loading = false);
                });
            }
          };
          this.page = {
            title: '',
            template: '',
          };
        },
      };
    });
    return { restrict: 'A' };
  });
