angular.module('idonate.gms').directive('multipleChoiceField', () => ({
  templateUrl: '/scripts/input/multiple-choice-field.html',
  scope: { field: '=' },
  restrict: 'E',
  controller($scope) {
    if (_.isObject($scope.field.choices)) {
      $scope.choice_options = [];
      return angular.forEach($scope.field.choices, (v, k) =>
        $scope.choice_options.push({ id: k, text: v })
      );
    }
  },
}));
