angular.module('idonate.gms').component('dashboard', {
  template: `
    <div class="page-heading">
      <h1>Dashboard</h1>
    </div>

    <div class="page-full-container summary-dashboard">
      <selector-bar date="$ctrl.dateRange" dash="$ctrl.dash" options="$ctrl.options"></selector-bar>
      <div class="panel" ng-switch="$ctrl.dash.id">
        <dashboard-indicators ng-switch-when="indicators" date-range="$ctrl.dateRange"></dashboard-indicators>
        <dashboard-campaign ng-switch-when="campaign" date-range="$ctrl.dateRange"></dashboard-campaign>
        <dashboard-designation ng-switch-when="designation" date-range="$ctrl.dateRange"></dashboard-designation>
        <dashboard-channel ng-switch-when="channel" date-range="$ctrl.dateRange"></dashboard-channel>
        <dashboard-wisdom ng-switch-when="wisdom"></dashboard-wisdom>
      </div>
    </div>
  `,
  controller() {
    this.options = [
      {
        id: 'indicators',
        title: 'Key Giving Indicators',
      },
      {
        id: 'campaign',
        title: 'Giving by Campaign',
      },
      {
        id: 'designation',
        title: 'Giving by Designation',
      },
      {
        id: 'channel',
        title: 'Giving by Channel',
      },
      {
        id: 'wisdom',
        title: 'Giving Wisdom',
      },
    ];
  },
});
