angular.module('idonate.gms').directive('gmsChangemark', function ($parse) {
  return {
    restrict: 'E',
    template: `\
<div class="float-right" uib-tooltip="% change from previous matching time period">
    <div class="summary-percentages label">
      {{percent | percentage:0}}
    </div>
</div>`,
    replace: true,
    scope: {
      percent: '=',
    },
    /* @ngInject */
    link(scope, element, attrs) {
      scope.$watch('percent', function (a, b) {
        const children = element.children();
        if (children) {
          children.toggleClass('label-success', a > 0);
          children.toggleClass('label-default', a === 0);
          children.toggleClass('label-danger', a < 0);
          children.children().remove();

          if (a !== undefined) {
            if (a > 0) {
              children.prepend('<span class="icon icon-up-arrow"></span>');
            } else if (a < 0) {
              children.prepend('<span class="icon icon-down-arrow"></span>');
            }
          } else {
            children.prepend('<span>n/a</span>');
          }
        }
      });
    },
  };
});
