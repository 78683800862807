// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class ReceiptService {
  /* @ngInject */
  constructor(SessionService, organizationService, $rootScope) {
    this.getCashReceipt = this.getCashReceipt.bind(this);
    this.saveCashReceipt = this.saveCashReceipt.bind(this);
    this.getCampaignReceipt = this.getCampaignReceipt.bind(this);
    this.getProgramReceipt = this.getProgramReceipt.bind(this);
    this.saveCampaignReceipt = this.saveCampaignReceipt.bind(this);
    this.getEmailTemplates = this.getEmailTemplates.bind(this);
    this.SessionService = SessionService;
    this.organizationService = organizationService;
    this._org = this.organizationService.getCurrentOrganization().id;
    $rootScope.$on('changeOrganization', (evt, newId) => {
      return (this._org = newId);
    });
  }
  getCashReceipt() {
    return this.SessionService.restangularGmsApi
      .one(`organization/${this._org}/preview_email/d_payment_complete`)
      .get()
      .then((data) => data.result);
  }
  saveCashReceipt(data) {
    return this.SessionService.restangularGmsApi
      .all(`organization/${this._org}`)
      .customPUT(data)
      .then((data) => data.result);
  }
  getCampaignReceipt(campaignId) {
    return this.SessionService.restangularGmsApi
      .one(`organization/${this._org}/preview_email/d_payment_complete`)
      .get({ campaign_id: campaignId })
      .then((data) => data.result);
  }
  getProgramReceipt(template, programId) {
    return this.SessionService.restangularGmsApi
      .one(`organization/${this._org}/preview_email/${template}`)
      .get({ advocacy_program_id: programId })
      .then((data) => data.result);
  }
  saveCampaignReceipt(campaignId, data) {
    const attribute = 'custom_email_content';
    return this.SessionService.restangularGmsApi
      .all(`organization/${this._org}/campaigns/${campaignId}`)
      .customPUT(data)
      .then((data) => data.result);
  }
  getEmailTemplates() {
    return this.SessionService.restangularGmsApi
      .one('email/template/')
      .get()
      .then((data) => data.result);
  }
}

angular.module('idonate.gms').service('ReceiptService', ReceiptService);
