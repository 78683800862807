/**
 * GMS API helpers for Organization-related User manipulation.
 */

angular
  .module('idonate.gms')
  .controller(
    'OrganizationUsersCtrl',
    function (
      $scope,
      organizationUsers,
      SessionService,
      orgUserService,
      $location
    ) {
      $scope.users = organizationUsers.result.users;
      let currentUser = {};

      $scope.setCurrent = function (userId) {
        $scope.currentUserId = userId;
        return (currentUser = _.find($scope.users, { id: parseInt(userId) }));
      };

      $scope.editUser = function (userId) {
        $location.path(`/organization/users/edit/${userId}`);
      };

      $scope.tableOptions = {
        outsideSearchId: 'outside-search',
        clickableRowAction: $scope.editUser,
      };

      $scope.deleteUser = () => {
        orgUserService
          .deleteUserFromOrganization(currentUser.id, currentUser.email)
          .then(function () {
            angular.forEach($scope.users, function (val, $i) {
              if (val.id === currentUser.id) {
                return $scope.users.splice($i, 1);
              }
            });

            return $scope.$broadcast('reloadTable');
          });
      };
    }
  );
