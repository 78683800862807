class EmbedSection {
  /* @ngInject */
  constructor(
    organizationService,
    DashboardAPI,
    websiteService,
    $uibModal,
    $scope,
    $location,
    $filter,
    $timeout,
    $rootScope
  ) {
    this.$onInit = this.$onInit.bind(this);
    this.$onChanges = this.$onChanges.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
    this.selectedCampaignChanges = this.selectedCampaignChanges.bind(this);
    this.setAnalytics = this.setAnalytics.bind(this);
    this.currency = this.currency.bind(this);
    this.generateSummary = this.generateSummary.bind(this);
    this.setSummary = this.setSummary.bind(this);
    this.redirectToEmbed = this.redirectToEmbed.bind(this);
    this.redirectToViewPage = this.redirectToViewPage.bind(this);
    this.createEmbed = this.createEmbed.bind(this);
    this.cloneEmbed = this.cloneEmbed.bind(this);
    this.bindDataToTable = this.bindDataToTable.bind(this);
    this.showDisabledFilter = this.showDisabledFilter.bind(this);
    this.enableDisabledEmbed = this.enableDisabledEmbed.bind(this);
    this.copySnippet = this.copySnippet.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
    this.DashboardAPI = DashboardAPI;
    this.websiteService = websiteService;
    this.$uibModal = $uibModal;
    this.$scope = $scope;
    this.$location = $location;
    this.$filter = $filter;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.orgId = organizationService.getCurrentOrganization().id;
    this.showDisabledItems = false;
    this.rowAction = this.redirectToViewPage;
    this.tableOptions = {
      sortByColumn: 1,
      sortOrder: 'desc',
      outsideSearchId: 'outside-search',
      clickableRowAction: (id) => this.rowAction(id),
    };
  }
  $onInit() {
    const rowAction = {};
    if (this.type === 'goal_meter') {
      this.rowAction = this.redirectToEmbed;
    } else {
    }
    this.$scope.$watch('$ctrl.showDisabledItems', (a, b) => {
      return this.$timeout(() => {
        if (a !== b) {
          return this.bindDataToTable();
        }
      });
    });
    this.$scope.$watch('$ctrl.selectedCampaign', this.selectedCampaignChanges);
    if (this.type === 'goal_meter') {
      return this.generateSummary();
    }
  }
  $onChanges(changes) {
    this.selectedCampaign = changes.selectedCampaign.currentValue;
    return this.selectedCampaignChanges();
  }
  setCurrent(id) {
    return (this.current = _.find(this.embeds, { id }));
  }
  selectedCampaignChanges() {
    if (this.selectedCampaign) {
      this.$location.search({ campaign_id: this.selectedCampaign.id });
    } else {
      this.$location.search({});
    }
    return this.generateSummary();
  }
  setAnalytics(summary) {
    return (this.analytics = [
      {
        percent: this.percent(
          summary.current_total_value,
          summary.prev_total_value
        ),
        figureC: summary.current_total_value,
        subfigure: summary.current_total_count,
        label: 'All Donations',
        sublabelB: 'Total Donations',
      },
      {
        percent:
          this.percent(
            summary.current_total_value,
            summary.current_total_count
          ) / this.percent(summary.prev_total_value, summary.pev_total_count),
        figureC: summary.current_total_value / summary.current_total_count,
        label: 'Average Donations',
        sublabelF:
          `Range: ${this.currency(summary.min_value)}` +
          `-${this.currency(summary.max_value)}`,
      },
      {
        percent: this.percent(
          summary.current_total_sessions,
          summary.prev_total_sessions
        ),
        figure: summary.current_total_sessions,
        subfigure: summary.avg_daily_sessions,
        label: 'Total Views',
        sublabelF: 'Daily Avg Views:',
      },
    ]);
  }
  currency(val) {
    return this.$filter('currency')(val, this.$rootScope.currencyCode, 0);
  }
  percent(first, second) {
    if (second !== 0) {
      return first / second;
    } else {
      return 0;
    }
  }
  generateSummary(current, previous) {
    if (current) {
      this.current = current;
    }
    if (previous) {
      this.previous = previous;
    }
    let queryParams = {};
    if (this.current) {
      queryParams = {
        start_date: this.current.start.toISOString(),
        end_date: this.current.end.toISOString(),
        previous_start_date: this.previous.start.toISOString(),
        previous_end_date: this.previous.end.toISOString(),
      };
    }
    if (this.selectedCampaign) {
      queryParams.campaign_id = this.selectedCampaign.id;
    }
    if (this.type !== 'goal_meter' && this.current) {
      return this.DashboardAPI.getEmbeds(queryParams).then(this.setSummary);
    } else if (this.type === 'goal_meter') {
      return this.websiteService
        .getGoalInstances('goal_meter', queryParams.campaign_id)
        .then((result) => {
          this.embeds = this.filterForTypes(result.result.embed_instances, [
            'goal_meter',
          ]);
          return this.bindDataToTable();
        });
    }
  }
  setSummary(summaryResult) {
    this.setAnalytics(summaryResult.summary);
    this.embeds = this.filterForTypes(summaryResult.embeds, [
      'unified_giving',
      'unified_giving_v2',
      'single_page',
    ]);
    return this.bindDataToTable();
  }
  filterForTypes(embeds, types) {
    return _.filter(embeds, (item) => types.includes(item.type));
  }
  redirectToEmbed(id) {
    if (this.type === 'goal_meter') {
      return this.$location.path(`goal-meters/edit/${id}`);
    } else {
      return this.$location.path(`embed/edit/${id}`);
    }
  }
  redirectToViewPage(id) {
    if (this.campaignId) {
      return this.$location.path(`/embed/view/${id}/campaign`);
    } else {
      return this.$location.path(`/embed/view/${id}`);
    }
  }
  createEmbed(embedId, embedName) {
    const embedObj = {
      name: embedName + '_' + Date.now(),
      parent_definition_id: embedId,
    };
    this.websiteService.createEmbedInstance(embedObj).then((data) => {
      return this.redirectToEmbed(data.result.embed_instance.id);
    });
  }
  cloneEmbed(embedId) {
    return this.websiteService.cloneEmbedInstance(embedId).then((data) => {
      return this.redirectToEmbed(data.result.embed_instance.id);
    });
  }
  bindDataToTable() {
    return this.$scope.$broadcast('reloadTable');
  }
  showDisabledFilter(item) {
    if (!this.showDisabledItems) {
      return item.is_enabled === true;
    } else {
      return item;
    }
  }
  getEmbedInfo(embedId) {
    return (
      `<div data-idonate-embed='${embedId}'></div>\n<script src='https://` +
      `${window.iDonateConfig.idonateiFrame.url}/idonate.js'></script>`
    );
  }
  enableDisabledEmbed(embedId) {
    const _embed = _.find(this.embeds, { id: embedId });
    if (!_embed) {
      return;
    }
    _embed.is_enabled = _embed.is_enabled ? false : true;
    if (_embed.is_enabled) {
    }
    return this.websiteService.updateEmbedInstance(_embed).then((data) => {
      return this.bindDataToTable();
    });
  }
  copySnippet(id) {
    const forceInitialColumns = false;
    const modalInstance = this.$uibModal.open({
      size: 'md',
      scope: this.$scope,
      windowClass: 'copy-code-modal',
      templateUrl: '/scripts/website/copy-code-modal.html',
      controller: [
        '$scope',
        '$uibModalInstance',
        function (scope, $uibModalInstance) {
          const ua = navigator.userAgent.toLowerCase();
          scope.safari = false;
          if (ua.indexOf('safari' !== -1)) {
            if (ua.indexOf('chrome' <= -1)) {
              scope.safari = true;
            }
          }
          scope.current_id = id;
          return (scope.cancel = () => $uibModalInstance.dismiss('cancel'));
        },
      ],
    });
    return modalInstance.result.then(angular.noop, angular.noop);
  }
  setCurrent(id) {
    return (this.current = _.find(this.embeds, { id }));
  }
}
angular.module('idonate.gms.website').component('embedSection', {
  templateUrl: '/scripts/website/embed-section.html',
  controller: EmbedSection,
  bindings: {
    type: '<',
    campaignId: '<',
    selectedCampaign: '<',
  },
});
