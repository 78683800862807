import './ajaxsortabletables.html';
// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

angular
  .module('idonate.gms.donations')
  .directive('ajaxSortableTable', ($timeout, $filter, $rootScope) => ({
    restrict: 'E',
    templateUrl: '/scripts/donations/ajaxsortabletables.html',
    controller(
      $scope,
      $element,
      $attrs,
      $timeout,
      $uibModal,
      $filter,
      transactionColumns,
      SessionService,
      appState,
      $location
    ) {
      const init = function () {
        $scope.openColumnSelector = openColumnSelector;
        $scope.reload = reload;
        $scope.export = exportTable;
        $scope.$watch('reportData.cascade', cascadeWatch);
        $scope.$watch('reportData.filter_by', cascadeWatch);
        $scope.paymentsColumns = [
          'funding.id',
          'funding.card_connect_id',
          'funding.date',
          'designation.title',
          'designation_id',
          'designation.code',
        ];
        setColumns();
        return activate();
      };

      var activate = function () {
        $scope.uniqueId = `GMS-ST${Math.floor(
          Math.random() * 10000000 + 1000
        )}`;

        const urlParams = $location.search();

        if (urlParams.cascade === 'true') {
          urlParams.cascade = true;
        } else if (urlParams.cascade === 'false') {
          urlParams.cascade = false;
        }

        // Set the base reportData object to be consumed by DataTable()
        $scope.reportData = {
          // $scope.reportData.(start_date/end_date) overridden in IntervalContainer()
          cascade: false,
          format: 'json',
          organization_id: urlParams.organization_id || $scope.orgId,
          filter_by: 'donation_date',
        };

        // Default Order By Column
        $scope.order = [[0, 'desc']];
        // Picks up the pre-built search-term if available in the URL
        $scope.search = urlParams.search_term;
        // Picks up the pre-set page number from the URL parameters
        $scope.pageNum = urlParams.pageNum;

        $scope.customTime = {};
        $scope.firstGenerate = true;

        $scope.columns = gmsStorage.gmscolumns
          ? JSON.parse(gmsStorage.gmscolumns)
          : angular.copy($scope.newColumns);

        const columnDiff = _.intersectionBy(
          _.differenceBy($scope.newColumns, $scope.columns, 'name'),
          $scope.newColumns,
          'name'
        );

        $scope.columns = _.intersectionBy(
          $scope.columns,
          $scope.newColumns,
          'name'
        );
        $scope.columns = $scope.columns.concat(columnDiff);

        if (urlParams.columns) {
          const urlColumns = new Set(urlParams.columns.split(','));

          urlColumns.add('id'); // Add base ID by default instead of popping up alerts

          $scope.columns = $scope.columns.map((clmn) => ({
            ...clmn,
            active: urlColumns.has(clmn.name), // Pre-sets the columns that are supposed to be active
          }));

          if (urlParams.order_by && urlParams.sort_direction) {
            $scope.order[0] = [
              $scope.columns
                .filter((clmn) => clmn.active === true)
                .findIndex((clmn) => clmn.name === urlParams.order_by),
              urlParams.sort_direction,
            ];
          }
        }

        $scope.pageLimit = gmsStorage.gmsTablePageLimit
          ? parseInt(gmsStorage.gmsTablePageLimit)
          : 10;

        angular.forEach($scope.columns, function (val, key) {
          if (
            val.name === 'created' ||
            val.name === 'schedule.start_date' ||
            val.name === 'schedule.expires' ||
            val.name === 'schedule.first_transaction' ||
            val.name === 'schedule.created' ||
            val.name === 'schedule.latest_transaction'
          ) {
            return ($scope.columns[key].render = (data, type, row) =>
              $filter('gmsDate')(data));
          } else if (
            val.name === 'detail.sale_price' ||
            val.name === 'net_proceeds' ||
            val.name === 'client_proceeds'
          ) {
            return ($scope.columns[key].render = (data, type, row) =>
              $filter('currency')(data, $rootScope.currencyCode));
          } else if (val.name === 'detail.email_opt_in') {
            return ($scope.columns[key].render = function (data, type, row) {
              if (data) {
                if (data === 'true') {
                  return 'Yes';
                } else {
                  return 'No';
                }
              } else {
                return data;
              }
            });
          } else if (val.name === 'detail.final_date') {
            return ($scope.columns[key].render = function (data, type, row) {
              if (data) {
                //return $filter('gmsDate')(data)
                return moment(data).format('MM-DD-YYYY');
              } else {
                return data;
              }
            });
          } else if (val.name === 'funding.id') {
            return ($scope.columns[key].render = function (data, type, row) {
              if (data) {
                return (
                  '<a href="/merchant/funding/' + data + '">View Funding</a>'
                );
              } else {
                return '';
              }
            });
          } else {
            return ($scope.columns[key].render = $.fn.dataTable.render.text());
          }
        });

        $scope.columnsActiveDesactive = {};

        return angular.forEach(
          $scope.columns,
          (val, $key) => ($scope.columnsActiveDesactive[val.name] = val.active)
        );
      };

      var setColumns = function () {
        $scope.newColumns = transactionColumns.defaults;
        if ($rootScope.allowFeature('anonymous_option')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.anonymousOption
          );
        }
        if ($rootScope.allowFeature('custom_fields')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.customFields
          );
        }
        if ($rootScope.allowFeature('sms-giving')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.smsGiving
          );
        }
        if ($rootScope.allowFeature('campaign')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.campaign
          );
        }
        if ($rootScope.allowFeature('pledge')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.pledge
          );
        }
        if ($rootScope.allowFeature('thank_you')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.thankYou
          );
        }
        if ($rootScope.allowFeature('tribute')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.tribute
          );
        }
        if ($rootScope.allowFeature('utm')) {
          $scope.newColumns = $scope.newColumns.concat(transactionColumns.utm);
        }
        if ($rootScope.allowFeature('reference_code')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.referenceCode
          );
        }
        if ($rootScope.allowFeature('payment_reconciliation')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.paymentReconciliation
          );
        }
        if ($rootScope.allowFeature('payments')) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.funding
          );
        }
        let activeP2P = $rootScope.allowFeature('p2p-2019');
        if (activeP2P) {
          $scope.newColumns = $scope.newColumns.concat(
            transactionColumns.p2p_2019
          );
        }
        if ($rootScope.allowFeature('p2p')) {
          let p2pColumns = transactionColumns.p2p.map(function (item) {
            if (!activeP2P) {
              return item;
            } else {
              return { ...item, text: '~(Legacy) ' + item.text };
            }
          });
          $scope.newColumns = $scope.newColumns.concat(p2pColumns);
        }
        if ($rootScope.allowFeature('external_service_mapping')) {
          $scope.newColumns = $scope.newColumns.concat(
            $scope.mappings.map((item) => {
              return {
                active: false,
                name: `external_mapping.${item.key}`,
                text: `${item.name} Mapping`,
              };
            })
          );
        }
      };

      var openColumnSelector = function () {
        let forceInitialColumns = false;
        const modalInstance = $uibModal.open({
          size: 'lg',
          scope: $scope,
          windowClass: 'column-selector-modal',
          templateUrl: 'columnSelectorModal.html',
          controller($scope, $uibModalInstance, $route) {
            $scope.changeColumns = function (checkName, value) {
              if (checkName === 'all') {
                if (value) {
                  angular.forEach($scope.columns, function (val, $i) {
                    $scope.columns[$i].active = true;
                    return ($scope.columnsActiveDesactive[val.name] = true);
                  });
                  return (forceInitialColumns = false);
                } else {
                  gmsStorage.gmscolumns = '';
                  forceInitialColumns = true;
                  $scope.columns = angular.copy($scope.newColumns);
                  return angular.forEach(
                    $scope.columns,
                    (val, $i) =>
                      ($scope.columnsActiveDesactive[val.name] = val.active)
                  );
                }
              } else {
                let _counter = 0;
                forceInitialColumns = false;
                angular.forEach($scope.columns, function (val, $i) {
                  $scope.columns[$i].active =
                    $scope.columnsActiveDesactive[val.name];
                  if (!$scope.columns[$i].active) {
                    return ($scope.columnsActiveDesactive['all'] = false);
                  } else {
                    return _counter++;
                  }
                });
                if (_counter === $scope.columns.length) {
                  return ($scope.columnsActiveDesactive['all'] = true);
                }
              }
            };

            // checks if the user has clicked to
            // navigate away from the page
            // -> close the modal automatically
            $scope.$on('$routeChangeStart', (next, current) =>
              $uibModalInstance.dismiss('cancel')
            );

            return ($scope.closeModal = () =>
              $uibModalInstance.dismiss('cancel'));
          },
        });

        modalInstance.result.then(null, function () {
          if (forceInitialColumns) {
            $scope.columns = angular.copy($scope.newColumns);
            forceInitialColumns = false;
          }
          saveTableLocally();
          return $scope.reload();
        });
      };

      $scope.$watch('date', function (a) {
        if (a) {
          $scope.reportData.start_date = a.start.toISOString();
          $scope.reportData.end_date = a.end.toISOString();
          return $scope.reload();
        }
      });

      var cascadeWatch = function (a, b) {
        if (!$scope.firstGenerate) {
          return $scope.reload();
        }
      };

      var saveTableLocally = function () {
        gmsStorage.gmscolumns = JSON.stringify($scope.columns);
      };

      const removeTable = function () {
        if ($scope.table) {
          $scope.table.destroy();
          return $(`#${$scope.uniqueId} table`).remove();
        }
      };

      const buildTable = function () {
        const buildColumns = ($scope.buildColumns = _.filter($scope.columns, {
          active: true,
        }));
        return $timeout(function () {
          $(`#${$scope.uniqueId}_temp .table`)
            .clone()
            .appendTo(`#${$scope.uniqueId}`);
          $scope.firstGenerate = false;

          $scope.url = `timezone=${moment.tz.guess()}&organization_id=${
            $scope.reportData.organization_id
          }&start_date=${$scope.reportData.start_date}&end_date=${
            $scope.reportData.end_date
          }&cascade=${$scope.reportData.cascade}&filter_by=${
            $scope.reportData.filter_by
          }`;

          $scope.table = $(`#${$scope.uniqueId} table`).DataTable({
            createdRow(row, data, dataIndex) {
              const column = $scope.columns[dataIndex];
              if ((column != null ? column.name : undefined) === 'created') {
                const cell = $('td', row).eq(dataIndex);
                return cell.attr('data-order', data[dataIndex]);
              }
            },
            search: {
              search: $scope.search,
            },
            serverSide: true,
            processing: true,
            pagingType: 'simple',
            sDom: "<'row'<'col-6'T><'col-6'f>r><'panel panel-table table-responsive't><'row'<'col-sm-3'l><'col-sm-6'p>>",
            oLanguage: {
              sLengthMenu: 'Show _MENU_ per page',
              oPaginate: {
                sNext: '<i class="icon-right"></i>',
                sPrevious: '<i class="icon-left"></i>',
              },
            },
            deferLoading: true,
            pageLength: $scope.pageLimit,
            order: $scope.order,
            //positioning elements - https://datatables.net/examples/basic_init/dom.html
            dom: '<tilp>',
            ajax: {
              url: `${window.iDonateConfig.baseUrl}transaction-table?${$scope.url}&export=${$scope.reportData.format}`,
              dataType: 'json',
              type: 'post',
              headers: SessionService.getAuthHeaders(),
            },
            columns: buildColumns,
            rowCallback(row, data) {
              row.className = 'clickable-row';
              let getIconNumber = null;
              angular.forEach(buildColumns, function (val, $key) {
                if (val.name === 'icon') {
                  return (getIconNumber = $key);
                }
              });
              if (getIconNumber && data[getIconNumber]) {
                const _icon = $filter('typeToGlyph')(data[getIconNumber]);
                return $(`td:eq(${getIconNumber})`, row).html(
                  `<i class="glyphicons ${_icon}"></i>`
                );
              }
            },
          });
          $scope.table
            .columns()
            .iterator('column', (ctx, idx) =>
              $($scope.table.column(idx).header()).append(
                '<span class="sort-icon"/>'
              )
            );

          // Sets the initial page number on load
          if (typeof $scope.pageNum === 'number' && $scope.pageNum >= 0) {
            $timeout(() => {
              $scope.table.page($scope.pageNum).draw(false);
            }, 500);
          }

          $('#gifts-search').keyup(function () {
            const _self = this;
            if ($scope.searchingTable) {
              $timeout.cancel($scope.searchingTable);
            }
            return ($scope.searchingTable = $timeout(function () {
              $scope.search = $(_self).val();
              return $scope.table.search($(_self).val()).draw();
            }, 800));
          });
          return $(`#${$scope.uniqueId} table`)
            .off()
            .on('xhr.dt', function () {
              window.table = $scope.table;
              const params = $scope.table.ajax.params();

              $scope.pageLimit = params.length;
              gmsStorage.gmsTablePageLimit = params.length;

              let getIdNumber = _.filter(params.columns, { name: 'id' });

              if (getIdNumber.length > 0) {
                let idIdx = getIdNumber[0].data;

                return $(`#${$scope.uniqueId} tbody`)
                  .off()
                  .on('click', 'tr', function (e) {
                    let rowPosOnCurrentPage = $scope.table.row(this)[0][0];

                    let offset =
                      $scope.pageLimit * $scope.table.page() +
                      rowPosOnCurrentPage;

                    let [clmnOrderedByIdx, sort_direction] =
                      $scope.table.order()[0];

                    let orderByClmn =
                      $scope.table.context[0].aoColumns[clmnOrderedByIdx].name;

                    const params = {
                      // timezone: moment.tz.guess(),
                      organization_id: $scope.reportData.organization_id,
                      start_date: $scope.reportData.start_date,
                      end_date: $scope.reportData.end_date,
                      cascade: $scope.reportData.cascade,
                      filter_by: $scope.reportData.filter_by,
                      search_term: $scope.search || '',
                      order_by: orderByClmn,
                      sort_direction,
                      offset,
                      columns: $scope.table.context[0].aoColumns
                        .map((clmn) => clmn.name)
                        .toString(),
                      pageNum: $scope.table.page(),
                      returnTo: 'legacy', // 'legacy' or 'beta'
                    };

                    const reportId = $scope.table.row(this).data()[idIdx];

                    return $scope.clickOnRow(reportId, params);
                  });
              } else {
                return $(`#${$scope.uniqueId} tbody`)
                  .off()
                  .on('click', 'tr', (e) => alert('Please Activate ID column'));
              }
            });
        });
      };

      var reload = function () {
        removeTable();
        $('.dataTables_filter').parent().remove();
        buildTable().then(() => $timeout(() => $scope.tableReloaded()));
      };

      var exportTable = function (format) {
        const params = {
          export: format,
          timezone: moment.tz.guess(),
          start_date: $scope.reportData.start_date,
          end_date: $scope.reportData.end_date,
          cascade: $scope.reportData.cascade,
          organization_id: $scope.reportData.organization_id,
          filter_by: $scope.reportData.filter_by,

          // previously stuffed into an invisible form:
          'order[0][column]': '0',
          'order[0][dir]': 'asc',
        };

        if ($scope.search) {
          params['search[value]'] = $scope.search;
          params['search[regex]'] = false;
        }

        const preBuildColumns = _.filter($scope.columns, { active: true });

        for (let idx = 0; idx < preBuildColumns.length; idx++) {
          const c = preBuildColumns[idx];

          params[`columns[${idx}][name]`] = c.name;

          if ($scope.search && c.searchable) {
            params[`columns[${idx}][searchable]`] = c.searchable;
          }
        }

        SessionService.downloadGmsApiResult(
          'transaction-table',
          params,
          `Donations.${format}`,
          'formdata'
        );
      };

      return init();
    },
  }));

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
