'use strict';

import './gmssidebar.html';
// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export const gmsSidebar = {
  name: 'gmsSidebar',
  templateUrl: '/scripts/base/gmssidebar.html',
  controller: class GmsSidebarCtrl {
    static $inject = ['$location', '$route', '$rootScope', '$timeout'];
    constructor($location, $route, $rootScope, $timeout) {
      this.$route = $route;
      this.$location = $location;

      $(window).bind('resize', () => {
        addCollapseClass();
        $timeout(() => addCollapseClass());
      });

      let _parent;
      $rootScope.toggleSidebar = () => {
        $('.row-offcanvas').toggleClass('active');
      };

      this.topLevelMenu = _.filter($route.routes, { menu: { level: 1 } });
      this.routes = $route.routes;

      let menu;

      if (
        $route.current &&
        $route.current.$$route &&
        $route.current.$$route.menu
      ) {
        menu = $route.current.$$route.menu;
      } else {
        menu = {};
      }

      if (menu.level === 1) {
        this.parentRoute = $route.current.$$route.originalPath;
        this.itemRoute = null;
      } else if (menu.level === 2) {
        _parent = _.filter($route.routes, {
          menu: {
            name: menu.parent,
            level: 1,
          },
        });

        this.parentRoute = _parent[0].originalPath;
        this.itemRoute = $route.current.$$route.originalPath;
      } else {
        _parent = _.filter($route.routes, {
          menu: {
            name: menu.parent,
            level: 1,
          },
        });

        if (_parent.length > 0) {
          this.parentRoute = _parent[0].originalPath;
          this.itemRoute = null;
        } else {
          this.parentRoute = null;
          this.itemRoute = null;
        }
      }

      // this listens for a message emitted from routeChangeSuccess in app.run.js
      $rootScope.$on('gmsRouteChangeSuccess', (event, elements) => {
        if (elements[0]) {
          this.parentRoute = elements[0];
          this.itemRoute = elements[1];
        }
      });
    }

    getSubMenu(parent) {
      const subMenu = _.filter(this.routes, {
        menu: { parent: parent.menu.name, level: 2 },
      });

      _.forEach(
        subMenu,
        (itemLevelTwo) =>
          (itemLevelTwo.subElements = _.filter(this.routes, {
            menu: { parent: itemLevelTwo.menu.name, level: 3 },
          }))
      );
      return subMenu;
    }
  },
};

function addCollapseClass() {
  const width = window.innerWidth > 0 ? window.innerWidth : screen.width;

  if (width < 768) {
    return $('div.navbar-collapse').addClass('collapse');
  } else {
    return $('div.navbar-collapse').removeClass('collapse');
  }
}
