import { CampaignMetrics } from './campaign-metrics';
import { CampaignService } from './campaign-service';
import { CampaignsCtrl } from './campaigns';
import { NewCampaignModal } from './new-campaign-modal';
import { ThankYouItemCtrl } from './thank-you-item';
import { ThankYouCtrl } from './thank-you';
import { TributesCtrl } from './tributes';
import { TributeItemCtrl } from './tribute-item';

angular
  .module('idonate.gms.campaign', [])
  .component('campaignMetrics', CampaignMetrics)
  .service('CampaignService', CampaignService)
  .controller('CampaignsCtrl', CampaignsCtrl)
  .controller('ThankYouItemCtrl', ThankYouItemCtrl)
  .controller('ThankYouCtrl', ThankYouCtrl)
  .controller('TributeItemCtrl', TributeItemCtrl)
  .controller('TributesCtrl', TributesCtrl)
  .directive('newCampaignModal', NewCampaignModal.init)
  .config(($routeProvider) => {
    $routeProvider
      //Campaign - List
      .when('/campaigns', {
        templateUrl: CampaignsCtrl.templateUrl,
        controller: 'CampaignsCtrl',
        menu: {
          style: 'icon-flag',
          name: 'Campaigns',
          level: 1,
          position: 4,
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'campaign',
        },
        resolve: {
          organizationCampaigns(CampaignService) {
            'ngInject';
            return CampaignService.getCampaigns(true);
          },
          organizationAttributes(organizationService) {
            'ngInject';
            return organizationService.getOrganizationAttributes();
          },
        },
      })
      .when('/campaigns/:new', {
        templateUrl: CampaignsCtrl.templateUrl,
        controller: 'CampaignsCtrl',
        menu: {
          style: 'icon-flag',
          name: 'CampaignNew',
          parent: 'Campaigns',
          level: 3,
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'campaign',
        },
        resolve: {
          organizationCampaigns(CampaignService) {
            'ngInject';
            return CampaignService.getCampaigns(true);
          },
          organizationAttributes(organizationService) {
            'ngInject';
            return organizationService.getOrganizationAttributes();
          },
        },
      })
      .when('/campaign/:campaignId', {
        templateUrl: '/scripts/campaign/campaign-item/campaign-item.html',
        controller: 'CampaignItemCtrl',
        menu: {
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'campaign',
          parent: 'Campaigns',
          level: 3,
        },
        resolve: {
          campaign($q, CampaignService, $route) {
            'ngInject';
            return CampaignService.getCampaign(
              $route.current.params.campaignId
            );
          },
          goalInstances(websiteService, $route) {
            'ngInject';
            return websiteService.getGoalInstances(
              'goal_meter',
              $route.current.params.campaignId
            );
          },
          embedBases(websiteService) {
            'ngInject';
            return websiteService.getEmbedBases();
          },
          fullOrgData(organizationService) {
            'ngInject';
            return organizationService.loadFullOrganizationInformation();
          },
          allTemplates(websiteService) {
            'ngInject';
            return websiteService.getTemplates();
          },
        },
      })
      .when('/campaign/:campaignId/edit', {
        templateUrl: '/scripts/campaign/campaign-item/campaign-item-edit.html',
        controller: 'CampaignEditCtrl',
        menu: {
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'campaign',
          parent: 'Campaigns',
          level: 3,
        },
      })
      .when('/campaign/:campaignId/edit/:path*?', {
        templateUrl: '/scripts/campaign/campaign-item/campaign-item-edit.html',
        controller: 'CampaignEditCtrl',
        menu: {
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'campaign',
          parent: 'Campaigns',
          level: 3,
        },
      })
      .when('/thank-you-gifts', {
        templateUrl: '/scripts/campaign/thank-you.html',
        controller: 'ThankYouCtrl',
        menu: {
          parent: 'Campaigns',
          name: 'Thank You Gifts',
          level: 2,
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'thank_you',
        },
        resolve: {
          organizationGifts(organizationService) {
            'ngInject';
            return organizationService.getOrganizationGifts();
          },
        },
      })
      .when('/thank-you-gift/:thankYouGiftId', {
        templateUrl: '/scripts/campaign/thank-you-item.html',
        controller: 'ThankYouItemCtrl',
        menu: {
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'campaign',
          parent: 'Campaigns',
          level: 3,
        },
        resolve: {
          thankyouGift($q, organizationService, $route) {
            'ngInject';
            const defer = $q.defer();
            if ($route.current.params.thankYouGiftId === 'new') {
              defer.resolve({
                result: {
                  gift: {},
                },
              });
            } else {
              organizationService
                .getOrganizationGiftData($route.current.params.thankYouGiftId)
                .then(
                  (data) => defer.resolve(data),
                  (error) => defer.reject(error)
                );
            }
            return defer.promise;
          },
        },
      })
      .when('/tributes', {
        templateUrl: '/scripts/campaign/tributes.html',
        controller: 'TributesCtrl',
        menu: {
          parent: 'Campaigns',
          name: 'Tributes',
          level: 2,
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'tribute',
        },
        resolve: {
          organizationTributeDefinitions(organizationService) {
            'ngInject';
            return organizationService.getOrganizationTributeDefinitions();
          },
        },
      })
      .when('/tribute/:tributeId', {
        templateUrl: '/scripts/campaign/tribute-item.html',
        controller: 'TributeItemCtrl',
        menu: {
          parent: 'Campaigns',
          name: 'Tributes',
          level: 3,
          allowFor: ['SuperAdmin', 'Admin', 'Editor'],
          allowFeatureFor: 'tribute',
        },
        resolve: {
          organizationTributeDefinitions(organizationService) {
            'ngInject';
            return organizationService.getOrganizationTributeDefinitions();
          },
        },
      });
  });
