// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular
  .module('idonate.gms')
  .controller(
    'OrganizationDesignatedFundsDetailsCtrl',
    function (
      $scope,
      organizationDesignations,
      designationService,
      $route,
      organizationAttributes,
      $location,
      notyService
    ) {
      $scope.organizationAttributes = organizationAttributes.result.attributes;
      $scope.loading = false;
      $scope.designation =
        $route.current.params.designationId &&
        $route.current.params.designationId !== 'new' &&
        _.find(organizationDesignations.result.designations, {
          id: $route.current.params.designationId,
        })
          ? _.find(organizationDesignations.result.designations, {
              id: $route.current.params.designationId,
            })
          : {};
      if (!$scope.designation.id) {
      }
      $scope.editPage = Boolean($scope.designation.id);
      $scope.vancoUser = Boolean(
        __guard__(
          __guard__(
            $scope.organizationAttributes.config != null
              ? $scope.organizationAttributes.config.cash
              : undefined,
            (x1) => x1.vanco
          ),
          (x) => x.client_id
        )
      );
      $scope.filterOptions = !$scope.vancoUser
        ? ['allow-space', 'allow-punctuation']
        : [];

      $scope.saveDesignatedFund = function () {
        $scope.loading = true;
        if ($scope.designation.id) {
          return designationService
            .editOrganizationDesignations($scope.designation)
            .then(function (data) {
              $scope.loading = false;
              return $location.path('/organization/designated-funds');
            });
        } else {
          return designationService
            .addOrganizationDesignations($scope.designation)
            .then((data) => $location.path('/organization/designated-funds'));
        }
      };

      $scope.makeDefault = function () {
        $scope.designation.make_default = $scope.designation.is_default;
        return designationService.editOrganizationDesignations(
          $scope.designation
        );
      };

      return ($scope.activeDesactive = function () {
        if ($scope.designation.is_active) {
          return designationService
            .enableOrganizationDesignation($scope.designation.id)
            .then(
              function () {},
              () =>
                ($scope.designation.is_active = $scope.designation.is_active
                  ? false
                  : true)
            );
        } else {
          return designationService
            .disableOrganizationDesignation($scope.designation.id)
            .then(
              function () {},
              () =>
                ($scope.designation.is_active = $scope.designation.is_active
                  ? false
                  : true)
            );
        }
      });
    }
  );

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
