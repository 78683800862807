import './recurring-item-update.html';
import { roundHalfEven } from '../../util/rounding';

angular.module('idonate.gms.donations').component('recurringItemUpdate', {
  templateUrl: '/scripts/donations/recurring/recurring-item-update.html',
  bindings: {
    recurringSchedule: '<',
  },
  controller: function (
    designationService,
    recurringService,
    $location,
    $scope,
    notyService
  ) {
    this.designationLink = '<i class="icon-link"></i>';
    this.donorPaysFeeAmount = 0;
    this.calculatedTotal = 0;
    this.$onInit = () => {
      this.addDonorPaysFee = this.recurringSchedule.donor_paid_fee !== null;
      this.selectedDesignations = [];
      this.selectedDesignationsAmount = 0;
      designationService
        .getOrganizationDesignations()
        .then(this._buildDesignationList);
      this.dpfInfo =
        this.recurringSchedule.organization.fees[
          this.recurringSchedule.payment_method.transaction_subtype
        ];
      this._setupForm();
      this.validate();
      this.schedules = [
        'Weekly',
        'Every 2 Weeks',
        '1st and 15th',
        'Monthly',
        'Quarterly',
        'Semi-Annually',
        'Annually',
      ];
      this.clearAmountField = function (id) {
        angular
          .element(document.querySelector(`#gms-designation-amount-${id}`))
          .focus();
        angular.forEach(this.selectedDesignations, function (designation, idx) {
          if (idx === id) {
            return (designation.amount = '');
          }
        });
      };
    };
    this._buildDesignationList = (result) => {
      this.designations = [];
      angular.forEach(result.result.designations, (val, $i) => {
        val.text = val.title;
        if (val.is_active === true) {
          this.designations.push(val);
        }
        if (val.id === this.form.designation_id && this.state === 'expired') {
          return (this.form.designationTitle = val.title);
        }
      });
    };
    this._setupForm = () => {
      const kvpList = [];
      this.form = {
        schedule_type:
          (this.recurringSchedule != null
            ? this.recurringSchedule.schedule_type.charAt(0).toUpperCase()
            : undefined) +
          (this.recurringSchedule != null
            ? this.recurringSchedule.schedule_type.slice(1)
            : undefined),
        amount:
          this.recurringSchedule.amount -
          (Number(this.recurringSchedule.donor_paid_fee) || 0),
        designations: this.selectedDesignations,
        next_payment: this.recurringSchedule.next_payment,
        final_date: this.recurringSchedule.final_date,
        id: this.recurringSchedule.id,
        expires: this.recurringSchedule.expires,
      };
      this.form.schedule_type = this.form.schedule_type.replace(
        'Semiannually',
        'Semi-Annually'
      );
      this.form.schedule_type = this.form.schedule_type.replace(
        'Twicemonthly',
        '1st and 15th'
      );
      this.form.schedule_type = this.form.schedule_type.replace(
        'Biweekly',
        'Every 2 Weeks'
      );
      if (
        this.recurringSchedule.designations != null
          ? this.recurringSchedule.designations.length(!0)
          : undefined
      ) {
        kvpList.push({
          id:
            this.recurringSchedule != null
              ? this.recurringSchedule.designation_id
              : undefined,
          amount: this.recurringSchedule.amount,
        });
      } else {
        angular.forEach(this.recurringSchedule.designation_ids, (value, key) =>
          kvpList.push({ id: key, amount: value })
        );
      }
      kvpList.push({ id: '', amount: '' });
      this.selectedDesignations = kvpList;

      this.removeZero = () =>
        (this.selectedDesignations = this.selectedDesignations.filter(
          (item) => item.amount > 0 && item.id !== ''
        ));

      // watch designations for changes and recalculate form.amount
      $scope.$watch(
        () => this.selectedDesignations,
        (nv, ov) => {
          if (nv || ov) {
            let _amount = 0;
            const _items = nv || ov;
            angular.forEach(_items, (item) => {
              _amount += parseFloat(item.amount) || 0;
            });

            this.selectedDesignationsAmount = _amount;

            if (_amount > 0) {
              // only reassign amount if > 0 (fixes 'single-designation' forms resetting to 0 on load)
              this.form.amount = _amount;
            }

            // extend designations with a single blank
            if (_items[_items.length - 1].amount > 0) {
              _items.push({ id: '', amount: '' });
            }

            this.selectedDesignations = _items;
            this.recalculate();
          }
        },
        true
      ); // end $watch selectDesignations

      $scope.$watch(
        () => this.addDonorPaysFee,
        () => this.recalculate()
      );
      $scope.$watch(
        () => this.form.amount,
        () => this.recalculate()
      );

      if (this.recurringSchedule.expires != null) {
        this.recurringSchedule.expires = moment(this.recurringSchedule.expires);
      }
      if (this.recurringSchedule.next_payment != null) {
        this.recurringSchedule.nextPayment = moment(
          this.recurringSchedule.next_payment
        );
      }

      this.expiresOptions = {
        minDate:
          moment(this.recurringSchedule.expires).diff() < 0
            ? null
            : moment().subtract(1, 'seconds'),
      };
      this.state = this._getState();
    };
    this._getState = () => {
      if (moment(this.recurringSchedule.expires).diff() < 0) {
        return 'expired';
      } else {
        return 'disabled';
      }
    };
    this.nextBlur = () => {
      return (this.form.next_payment = moment(
        this.recurringSchedule.nextPayment
      ).toISOString());
    };
    this.expiresBlur = () => {
      if (
        this.recurringSchedule.expires &&
        moment(this.recurringSchedule.expires).isValid()
      ) {
        this.form.expires = moment(
          this.recurringSchedule.expires
        ).toISOString();
      }
    };
    this.invalidAmount = () => {
      return this.form.amount < 5 || this.form.amount > 250000;
    };
    this.saveChanges = () => {
      if (this.validate()) {
        if (this.form.amount < 0.01) {
          notyService.info('Minimum amount should be greater than $0.00');
          return;
        }

        let toPost = this._removeEmpties(this.form);
        toPost = angular.extend(
          {},
          toPost,
          formatDesignationList(this.selectedDesignations)
        );
        toPost.designation_id =
          toPost.designation_id != null ? toPost.designation_id.id : undefined;
        toPost.schedule_type = toPost.schedule_type
          .toString()
          .toLowerCase()
          .replace(/\W/g, '');

        if (toPost.schedule_type == 'every2weeks') {
          toPost.schedule_type = 'biweekly';
        } else if (toPost.schedule_type == '1stand15th') {
          toPost.schedule_type = 'twicemonthly';
        }

        if (this.addDonorPaysFee) {
          toPost.donor_paid_fee = parseFloat(this.donorPaysFeeAmount);
          toPost.amount = parseFloat(toPost.amount) + toPost.donor_paid_fee;
        }
        if (this.recurringSchedule.company_name) {
          toPost.is_company = true;
          toPost.company_name = this.recurringSchedule.company_name;
        }

        this.state = 'loading';
        return recurringService.updateRecurringSchedule(toPost).then(
          () => window.history.back() ?? $location.path('/reporting'),
          () => (this.state = 'enabled')
        );
      }
    };
    this.validate = () => {
      if (this.state === 'expired') {
        return false;
      }
      if ((this.form.amount != null) > 0) {
        this.state = 'enabled';
        return true;
      }
      this.state = 'disabled';
      return false;
    };
    this._removeEmpties = (form) => {
      return angular.forEach(form, function (value, key) {
        if (typeof value === 'undefined' || value === null) {
          return delete form[key];
        }
      });
    };
    this.recalculate = () => {
      const feeAmount = calculateFee(
        this.form.amount,
        this.dpfInfo.ratio,
        this.dpfInfo.fixed
      );
      this.donorPaysFeeAmount = feeAmount;
      this.calculatedTotal = this.form.amount;
      if (this.addDonorPaysFee) {
        this.calculatedTotal += feeAmount;
      }
    };
    this.cancel = () => {
      window.history.back() ?? $location.path('/reporting');
    };
  },
});

function formatDesignationList(designations) {
  const _formattedList = [];
  angular.forEach(designations, (designation) => {
    if (designation && designation.id !== '' && designation.amount > 0) {
      // ignoring the empty select field
      const obj = {
        designation_id: designation.id,
        amount: designation.amount !== '' ? designation.amount : 0,
      };
      return _formattedList.push(obj);
    }
  });
  return { designations: _formattedList };
}

function calculateFee(amount, ratio, fixed) {
  return roundHalfEven((amount * ratio + fixed) * 100.0) / 100.0;
}
