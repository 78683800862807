'use strict';

angular
  .module('idonate.gms')
  .controller(
    'integrationsUiCtrl',
    ($scope, $sce, organizationService, SessionService) => {
      function handlePostMessage(integrations) {
        let data = {};

        try {
          // only interested in JSON-parseable payloads
          data = JSON.parse(integrations.data);
        } catch (e) {
          return;
        }

        if (data.source === 'integrations-ui' && data.status === 'ready') {
          integrations.source.postMessage(
            JSON.stringify({
              source: 'gms',
              method: 'setConfiguration',
              params: {
                organizationId: organizationService.getCurrentOrganization().id,
                apiAuthorization: `Bearer ${SessionService.getCurrentSessionKey()}`,
                baseUrl: window.iDonateConfig.baseUrl.replace(/\/$/, ''), // remove the slash
              },
            }),
            '*'
          );
        }
      }

      window.addEventListener('message', handlePostMessage);

      $scope.$on('$destroy', function () {
        window.removeEventListener('message', handlePostMessage);
      });

      const current_org_id = organizationService.getCurrentOrganization().id;
      let iframeSrc = new URL(
        window.iDonateConfig.integrationsUiBaseUrl +
          '?organization_id=' +
          current_org_id
      );
      $scope.iframeUrl = $sce.trustAsResourceUrl(iframeSrc.href);
      $scope.minHeight = window != window.parent ? '800px' : 0;
    }
  );
