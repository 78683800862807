import './campaign-goal-meters.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const goals = () => ({
  templateUrl: '/scripts/campaign/campaign-item/campaign-goal-meters.html',
  restrict: 'E',
  scope: {
    instances: '=',
    bases: '=',
    currentCampaign: '=current',
  },
  controller: goalsController,
  controllerAs: '$ctrl',
  bindToController: true,
});
angular.module('idonate.gms.campaign').directive('campaignGoalMeters', goals);

function goalsController(
  $scope,
  $location,
  $uibModal,
  $timeout,
  websiteService,
  notyService
) {
  const ctrl = this;
  const init = function () {
    ctrl.getEmbedInfo = getEmbedInfo;
    ctrl.copySnippet = copySnippet;
    ctrl.enableDisabledEmbed = enableDisabledEmbed;
    ctrl.createGoalMeter = createGoalMeter;
    ctrl.cloneEmbed = cloneEmbed;

    ctrl.tableOptions = {
      sortByColumn: 2,
      sortOrder: 'desc',
      clickableRowAction: ctrl.redirectToEmbed,
    };

    $timeout(function () {
      if ((ctrl.instances != null ? ctrl.instances.length : undefined) > 0) {
        return $scope.$broadcast('reloadTable');
      }
    }, 1000);
  };
  ctrl.setCurrent = (id) => (ctrl.current = _.find(ctrl.instances, { id }));

  ctrl.redirectToEmbed = (id) =>
    $location
      .path(`embed/edit/${id}`)
      .search({ campaign_id: ctrl.currentCampaign.id });

  var copySnippet = function (id) {
    let modalInstance;
    const forceInitialColumns = false;
    return (modalInstance = $uibModal.open({
      size: 'md',
      scope: $scope,
      windowClass: 'copy-code-modal',
      templateUrl: '/scripts/website/copy-code-modal.html',
      controller($scope, $uibModalInstance) {
        const ua = navigator.userAgent.toLowerCase();
        $scope.safari = false;
        if (ua.indexOf('safari') !== -1) {
          if (ua.indexOf('chrome') > -1) {
            // chrome should take care of it automatically
          } else {
            $scope.safari = true;
          }
        }

        $scope.current_id = id;

        return ($scope.cancel = () => $uibModalInstance.dismiss('cancel'));
      },
    }));
  };

  var getEmbedInfo = (embedId) =>
    `<div data-idonate-embed='${embedId}'></div>\n<script src='https://${window.iDonateConfig.idonateiFrame.url}/idonate.js'></script>`;

  var enableDisabledEmbed = function (embedId) {
    const _embed = _.find(ctrl.instances, { id: embedId });
    _embed.is_enabled = _embed.is_enabled ? false : true;
    websiteService
      .updateEmbedInstance(_embed)
      .then((data) => $scope.$broadcast('reloadTable'));
  };

  var createGoalMeter = function () {
    const _goalmeterBase = _.find(
      ctrl.bases,
      (base) => base.type === 'goal_meter'
    );
    if (_goalmeterBase) {
      const embedObj = {
        name: `_goal_meter${Date.now()}`,
        parent_definition_id: _goalmeterBase.id,
      };
      return websiteService
        .createEmbedInstance(embedObj)
        .then((data) =>
          $location
            .path(`embed/edit/${data.result.embed_instance.id}`)
            .search({ campaign_id: ctrl.currentCampaign.id })
        );
    } else {
      return notyService.info(
        'There is no goal meter embed available to create.'
      );
    }
  };

  var cloneEmbed = function (embedId) {
    websiteService
      .cloneEmbedInstance(embedId)
      .then((data) =>
        $location
          .path(`embed/edit/${data.result.embed_instance.id}`)
          .search({ campaign_id: ctrl.currentCampaign.id })
      );
  };
  return init();
}
