angular.module('idonate.gms').service('Platform', function ($http, $location) {
  // update environment if env_vars are ever moved to a saner place
  const environment = { ...window.iDonateConfig };

  let platformLoaded = false;
  const platformConfig = {
    images: {},
    text: {
      name: 'iDonate',
    },
    environment,
  };

  function configurePlatformStyles() {
    // set styles on root element
    for (const item in platformConfig.theme) {
      document.body.style.setProperty(
        `--gms-${item}`,
        platformConfig.theme[item]
      );
    }

    for (const item in platformConfig.images) {
      document.body.style.setProperty(
        `--gms-${item}`,
        `url(${platformConfig.images[item]})`
      );
    }
  }

  async function loadPlatform() {
    const url =
      $location.host().indexOf('loc-idonate') >= 0
        ? 'https://api.loc-idonate.com/platform/api.loc-idonate.com/theme.json'
        : `https://${$location.host()}/platforms/${$location.host()}/theme.json`;

    return $http({
      method: 'GET',
      url: url,
    }).then(function (response) {
      Object.assign(platformConfig, response.data);
      Object.assign(platformConfig.environment, platformConfig.override);

      configurePlatformStyles();

      platformLoaded = true;

      return platformConfig;
    });
  }

  return {
    getImg(name) {
      return platformConfig.images[name];
    },
    getText(name) {
      return platformConfig.text[name];
    },

    async getConfig() {
      if (platformLoaded) {
        return platformConfig;
      }

      return await loadPlatform();
    },
  };
});
