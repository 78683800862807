// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let SelectorCtrl;
angular.module('idonate.gms').component('selector', {
  transclude: true,
  template: `\
<label>
  <input type="radio" name="options"><ng-transclude></ng-transclude>
</label>\
`,
  bindings: {
    name: '@',
    onSelect: '&?',
  },
  require: {
    selectorRowCtrl: '^^selectorRow',
  },
  controller: (SelectorCtrl = class SelectorCtrl {
    /* @ngInject */
    constructor($attrs, $element) {
      this._checkForSelected = this._checkForSelected.bind(this);
      this.select = this.select.bind(this);
      this.activate = this.activate.bind(this);
      this.deactivate = this.deactivate.bind(this);
      this.toggle = this.toggle.bind(this);
      this.$element = $element;
      this.$element.on('click', this.select);
      this.$element.addClass('btn');
      this._checkForSelected($attrs);
    }
    _checkForSelected($attrs) {
      this.active = false;
      if ($attrs.hasOwnProperty('selected')) {
        this.active = true;
        this.$element.removeClass('btn-gray');
        return this.$element.addClass('btn-clear');
      } else {
        this.$element.removeClass('btn-clear');
        return this.$element.addClass('btn-gray');
      }
    }
    select() {
      return this.selectorRowCtrl.select(this.name);
    }
    activate() {
      this.active = true;
      this.$element.removeClass('btn-gray');
      return this.$element.addClass('btn-clear');
    }
    deactivate() {
      this.active = false;
      this.$element.removeClass('btn-clear');
      return this.$element.addClass('btn-gray');
    }
    toggle() {
      if (this.active) {
        return this.deactivate();
      } else {
        return this.activate();
      }
    }
    $onInit() {
      return this.selectorRowCtrl.register(this.name, this);
    }
  }),
});
