// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('kvpField', ($compile) => ({
  templateUrl: '/scripts/input/kvp-field.html',
  scope: {
    field: '=field',
    onRemove: '=onRemove',
  },
  link(scope, elem, attrs) {
    return scope.$watch('field', function (nv, ov) {
      if (nv !== ov) {
        return $compile(elem.contents())(scope);
      }
    });
  },
  restrict: 'E',
}));
