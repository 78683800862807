// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms')
  .controller(
    'OrganizationDesignatedFundsCtrl',
    function (
      $scope,
      organizationDesignations,
      organizationService,
      designationService,
      $timeout,
      organizationCampaigns,
      organizationAttributes,
      $location
    ) {
      let c;

      $scope.organizationDesignations =
        organizationDesignations.result.designations;
      $scope.currentOrganization = organizationService.getCurrentOrganization();
      $scope.advancedDesignationsEnabled =
        $scope.currentOrganization.features.includes('advanced_designations');
      $scope.organizationAttributes = organizationAttributes.result.attributes;
      $scope.loading = false;
      $scope.showDisabledItems = false;

      //Hide Archived items by default
      $scope.organizationDesignations = (() => {
        const result = [];
        for (c of Array.from($scope.organizationDesignations)) {
          if (c.is_active) {
            result.push(c);
          }
        }
        return result;
      })();

      //Filter Designations
      const filterDesignations = function () {
        let _getDesignations = [];
        const designationScope = $scope.organizationDesignations;
        if (designationScope.length > 0) {
          angular.forEach(designationScope, function (val, $i) {
            val.text = val.title;
            if (val.is_active === true) {
              return _getDesignations.push(val);
            }
          });
        } else {
          _getDesignations = [];
        }
        return ($scope.organizationDesignationSelector = _getDesignations);
      };

      const updateDesignationAttribute = (designationId, attributeValue) =>
        angular.forEach(
          $scope.organizationDesignations,
          function (designation, key) {
            if (designation.id === designationId) {
              return ($scope.organizationDesignations[key][
                Object.keys(attributeValue)[0]
              ] = attributeValue[Object.keys(attributeValue)[0]]);
            }
          }
        );
      $scope.editDesignation = function (designationId) {
        if ($scope.advancedDesignationsEnabled) {
          const advDesUrl = `${window.iDonateConfig.gmsReactBaseUrl}settings/designated-funds/${designationId}/edit`;
          window.location = advDesUrl;
        } else {
          $location.path(`organization/designated-fund/${designationId}`);
        }
      };

      $scope.tableConf = {
        outsideSearchId: 'outside-search',

        rowReorder: {
          selector: 'td.movable-column',
        },
        columnDefs: [
          {
            orderable: false,
            targets: '_all',
          },
        ],
        bAutoWidth: false,
      };

      $scope.getGroups = function (designationGroups) {
        return designationGroups.length ?
        designationGroups.reduce((acc, val) => {
          return acc ? `${acc}, ${val.name}` : val.name
        }, '')
        : '';
      };

      $scope.formatDate = function (designationDate) {
        const date = moment.utc(designationDate);
        return date.isValid() ? date.format('MM/DD/YYYY') : '';
      };

      $scope.newDesignationLink = function () {
        //determine if we support advanced designations
        // if so, generate this link to the new gms2 advanced designation create URL
        // otherwise, we just want to show the old
        if ($scope.advancedDesignationsEnabled) {
          return `${window.iDonateConfig.gmsReactBaseUrl}settings/designated-funds/new`;
        }

        return "/organization/designated-fund/new";
      }

      function sortWeight(a, b) {
        if (a.weight < b.weight) return -1;
        if (a.weight > b.weight) return 1;
        return 0;
      }

      function getDesignationIndex(id) {
        let index = null;
        angular.forEach($scope.organizationDesignations, (designation, key) => {
          if (designation.id == id) {
            index = key;
          }
        });
        return index;
      }

      $scope.$on('designationReordered', ($event, newOrder) => {
        $scope.loading = true;
        designationService.getOrganizationDesignations().then((data) => {
          $scope.organizationDesignations = [];
          $timeout(() => {
            $scope.loading = false;
            $scope.organizationDesignations = data.result.designations;
            if (!$scope.showDisabledItems) {
              $scope.organizationDesignations = (() => {
                const result1 = [];
                for (c of Array.from($scope.organizationDesignations)) {
                  if (c.is_active) {
                    result1.push(c);
                  }
                }
                return result1;
              })();
            }
            return $scope.$broadcast('reloadTable');
          });
        });
      });

      // Update weight of the designation, on blur/enter for order input
      $scope.updateOrder = (evt) => {
        $scope.loading = true;
        const designation_id = evt.target.attributes['designation-id'].value;
        const newOrder = Number(evt.target.value);
        const currentOrder = getDesignationIndex(designation_id) + 1;

        if (!newOrder || newOrder < 0 || currentOrder === newOrder) return;

        const _newOrder = {
          organization_id: organizationService.getCurrentOrganization().id,
          id: designation_id,
          fromPosition: currentOrder - 1,
          toPosition: newOrder - 1,
        };

        designationService
          .reorderOrganizationDesignation(_newOrder)
          .then(function (res) {
            $scope.$broadcast('designationReordered');
            return $scope.$broadcast('reloadTable');
          });
      };

      $scope.setCurrent = (id, is_active, is_default) =>
        ($scope.currentDesignation = {
          id,
          // since ng converts the booleans to strings, we revert them to their original states
          is_active: is_active === 'true',
          is_default: is_default === 'true',
        });

      $scope.setDefaultDesignation = function (designationId) {
        let designation = _.filter($scope.organizationDesignations, {
          id: designationId,
        });
        designation = designation.length > 0 ? designation[0] : null;
        angular.forEach($scope.organizationDesignations, function (val, key) {
          if (val.id === designationId) {
            return ($scope.organizationDesignations[key].is_default = true);
          } else {
            return ($scope.organizationDesignations[key].is_default = false);
          }
        });
        if (designation) {
          return designationService
            .editOrganizationDesignations({
              id: designation.id,
              title: designation.title,
              code: designation.code,
              make_default: true,
            })
            .then(() => $scope.$broadcast('reloadTable'));
        }
      };

      $scope.enableDesignation = (designationId) =>
        designationService
          .enableOrganizationDesignation(designationId)
          .then(function (data) {
            updateDesignationAttribute(designationId, { is_active: true });
            filterDesignations();
            return $scope.$broadcast('reloadTable');
          });

      $scope.disableDesignation = (designationId) =>
        designationService.disableOrganizationDesignation(designationId).then(
          function (data) {
            updateDesignationAttribute(designationId, { is_active: false });
            filterDesignations();
            return $scope.$broadcast('reloadTable');
          },
          function (response) {}
        );

      $scope.$watch('showDisabledItems', (a, b) =>
        $timeout(function () {
          if (a !== b) {
            return designationService
              .getOrganizationDesignations()
              .then(function (res) {
                // getting the updated order
                $scope.organizationDesignations = res.result.designations;
                if (!$scope.showDisabledItems) {
                  $scope.organizationDesignations = (() => {
                    const result1 = [];
                    for (c of Array.from($scope.organizationDesignations)) {
                      if (c.is_active) {
                        result1.push(c);
                      }
                    }
                    return result1;
                  })();
                }
                return $scope.$broadcast('reloadTable');
              });
          }
        })
      );

      return filterDesignations();
    }
  );
