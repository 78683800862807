angular
  .module('idonate.gms')
  .controller(
    'reconciliationUiCtrl',
    ($scope, $routeParams, $sce, organizationService, SessionService) => {
      let iframeSrc = new URL(
        `${window.iDonateConfig.reconciliationUiBaseUrl}/${
          organizationService.getCurrentOrganization().id
        }/gms-ui`
      );
      const { batchId } = $routeParams;

      function handlePostMessage(msg) {
        let data = {};

        try {
          // only interested in JSON-parseable payloads
          data = JSON.parse(msg.data);
        } catch (e) {
          return;
        }

        if (data.source === 'reconciliation' && data.status === 'ready') {
          let configObj = {
            source: 'gms',
            method: 'setConfiguration',
            params: {
              organizationId: organizationService.getCurrentOrganization().id,
              apiAuthorization: `Bearer ${SessionService.getCurrentSessionKey()}`,
              baseUrl: window.iDonateConfig.baseUrl.replace(/\/$/, ''), // remove the slash
            },
          };

          if (batchId) {
            configObj.params.navigate = `/funding-batches/${batchId}`;
          }

          msg.source.postMessage(JSON.stringify(configObj), iframeSrc.href);
        }
      }

      window.addEventListener('message', handlePostMessage);

      $scope.$on('$destroy', function () {
        window.removeEventListener('message', handlePostMessage);
      });

      $scope.iframeUrl = $sce.trustAsResourceUrl(iframeSrc.href);
    }
  );
