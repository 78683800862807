export class DashboardAPI {
  /* @ngInject */
  constructor(RestAPI, appState) {
    this.restAPI = RestAPI;

    this.baseUrl = () => {
      return `organization/${appState.currentOrganization.id}/dashboard`;
    };

    this.chapterBaseUrl = () => {
      return `organization/${appState.currentOrganization.id}/chapter`;
    };

    this.orgId = () => {
      return appState.currentOrganization.id;
    };
  }

  getSummary(data) {
    return this.restAPI.get(`${this.baseUrl()}/summary`, data);
  }

  getCampaignSummary(data) {
    return this.restAPI.get(`${this.baseUrl()}/campaigns_summary`, data);
  }

  getChannelSummary(data) {
    return this.restAPI.get(`${this.baseUrl()}/channels_summary`, data);
  }

  getDesignationSummary(data) {
    return this.restAPI.get(`${this.baseUrl()}/designations_summary`, data);
  }

  getCampaign(campaignId, data) {
    return this.restAPI.get(`${this.baseUrl()}/campaign/${campaignId}`, data);
  }

  getEmbeds(data) {
    return this.restAPI.get(`${this.baseUrl()}/embeds`, data);
  }

  getEmbed(embedId, data) {
    return this.restAPI.get(`${this.baseUrl()}/embed/${embedId}`, data);
  }

  getGoalmeters(data) {
    return this.restAPI.get(`${this.baseUrl()}/goal_meters`, data);
  }

  getTransactionChart(data) {
    return this.restAPI.get('transaction/dashboard_data', {
      ...data,
      organization_id: this.orgId(),
    });
  }

  getChapterIndicators(data) {
    return this.restAPI.get(`${this.chapterBaseUrl()}/summary`, data);
  }

  getChapterChannels(data) {
    return this.restAPI.get(`${this.chapterBaseUrl()}/channels`, data);
  }
}
