// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('idcChartName', () => ({
  restrict: 'A',
  require: '^^chartContainer',
  link($scope, $element, $attrs, $ctrl) {
    const name = $attrs['idcChartName'];
    const chart = {
      activate() {
        return $element.removeClass('ng-hide');
      },
      deactivate() {
        return $element.addClass('ng-hide');
      },
    };
    return $ctrl.registerChart(name, chart);
  },
}));
