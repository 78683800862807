// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular
  .module('idonate.gms')
  .controller(
    'OrganizationEmailMarketingIntegrationCtrl',
    function (
      $scope,
      $rootScope,
      SessionService,
      $compile,
      organizationService,
      emailMarketingService,
      GlobalService
    ) {
      $scope.currentOrganization = organizationService.getCurrentOrganization();
      $scope.selectedRuleCode = 'NONE';
      $scope.selectedMailingListId = '';
      $scope.ruleSelected = '';
      $scope.listSelected = '';
      $scope.ruleSupplementalData = '';
      $scope.showAmountEditor = false;
      $scope.showEmailRulesSection = false;
      $scope.mailingLists = [];
      $scope.allMailingListRules = [];
      $scope.organizationMailingListRules = [];
      $scope.emailRulesConfigurationLoaded = false;

      $scope.select2MailingListRulesOptions = { data: [] };

      $scope.select2MailingListsOptions = { data: [] };

      $scope.mailchimpLinkingDisabled = false;
      $scope.organizationData.mailchimp_account_status =
        'Your Mailchimp settings have not been configured';
      if ($scope.organizationData.mailchimp_account_name !== null) {
        $scope.organizationData.mailchimp_account_status =
          'Your Mailchimp account is connected';
      }

      $scope.linkMailchimpAccount = function () {
        organizationService.getMailchimpiFrameUrl().then((res) => {
          $('#mailchimpOAuthModal-iFrame').attr('src', res.result.url);
          $('#mailchimpOAuthModal').modal('show');
        });
      };

      $scope.removeMailchimpAccount = () =>
        GlobalService.alertModal(
          'Are you sure you want to do this?',
          'Disconnecting your Mailchimp account will remove all rules and mapped campaigns analytics.',
          'Disconnect Mailchimp account',
          function (closeModal) {
            const myData = {
              mailchimp_account_name:
                $scope.organizationData.mailchimp_account_name,
            };

            return organizationService
              .removeMailchimpAccountAssociation(myData)
              .then(() =>
                organizationService
                  .loadFullOrganizationInformation()
                  .then(function (data) {
                    closeModal();
                    $scope.mailchimpLinkingDisabled = false;
                    $scope.organizationData.mailchimp_account_name = null;
                    $scope.organizationData.mailchimp_account_status =
                      'Your Mailchimp settings have not been configured';
                    $scope.emailRulesConfigurationLoaded = false;
                    return ($scope.showEmailRulesSection = false);
                  })
              );
          }
        );

      $scope.onMailchimpAccountLinkingCompleted = function () {
        $('#mailchimpOAuthModal').modal('hide');
        $scope.organizationData.mailchimp_account_status =
          'Connecting to your Mailchimp account. Please wait...';
        $scope.mailchimpLinkingDisabled = true;
        return organizationService
          .loadFullOrganizationInformation()
          .then(function (data) {
            $scope.organizationData = data.result;
            $scope.mailchimpLinkingDisabled = false;
            $scope.organizationData.mailchimp_account_status =
              'Your Mailchimp account is connected';
            $scope.showEmailRulesSection = true;
            $scope.mailingLists = [];
            $scope.allMailingListRules = [];
            $scope.organizationMailingListRules = [];
            $scope.loadMailingLists();
            return $scope.loadMailingListRules();
          });
      };

      window.addEventListener('message', (event) => {
        if (
          event.data !== null &&
          event.data === 'MailchimpAuthorizationCompleted'
        ) {
          const element_scope = angular
            .element(document.getElementById('mailchimpOAuthModal'))
            .scope();
          return element_scope.$apply(() =>
            element_scope.onMailchimpAccountLinkingCompleted()
          );
        }
      });

      $scope.addMailingListRule = function () {
        let i;
        const rule = (() => {
          const result = [];
          for (i of Array.from($scope.allMailingListRules)) {
            if (i.code === $scope.selectedRuleCode) {
              result.push(i);
            }
          }
          return result;
        })()[0];
        const mailingList = (() => {
          const result1 = [];
          for (i of Array.from($scope.mailingLists)) {
            if (i.id === $scope.selectedMailingListId) {
              result1.push(i);
            }
          }
          return result1;
        })()[0];
        if (rule && mailingList) {
          const myData = {
            rule_id: rule.id,
            mailing_list_id: mailingList.id,
            supplemental_data: $scope.ruleSupplementalData,
          };
          return emailMarketingService
            .addOrganizationMailingListRule(
              organizationService.getCurrentOrganization().id,
              myData
            )
            .then(function (data) {
              const organizationMailingList = {
                id: data.result.id,
                rule,
                mailingList,
                supplemental_data: $scope.ruleSupplementalData,
              };
              $scope.organizationMailingListRules.push(organizationMailingList);
              $scope.ruleSelected = '';
              $scope.listSelected = '';
              return ($scope.ruleSupplementalData = '');
            });
        }
      };

      $scope.removeMailingListRule = function (ruleId) {
        angular.forEach(
          $scope.organizationMailingListRules,
          function (val, $i) {
            if (val.id === ruleId && ruleId !== 'TEMP') {
              $scope.organizationMailingListRules.splice($i, 1);
              return emailMarketingService.removeOrganizationMailingListRule(
                organizationService.getCurrentOrganization().id,
                val.id
              );
            }
          }
        );
      };

      $scope.$watch('ruleSelected', function (rule) {
        if (rule && _.isObject(rule)) {
          $scope.selectedRuleCode = rule.id;
          $scope.showAmountEditor = false;
          if (rule.id === 'DONATION-OVER-AMOUNT') {
            $scope.showAmountEditor = true;
          }
        }
      });

      $scope.$watch('listSelected', function (mailingList) {
        if (mailingList && _.isObject(mailingList)) {
          $scope.selectedMailingListId = mailingList.id;
        }
      });

      $scope.loadMailingLists = () =>
        emailMarketingService
          .getMailingLists(organizationService.getCurrentOrganization().id)
          .then(function (data) {
            for (let mailingList of Array.from(data.result.lists.data)) {
              $scope.select2MailingListsOptions.data.push({
                id: mailingList.id,
                text: mailingList.name,
              });
              $scope.mailingLists.push(mailingList);
            }
            $scope.loadMailingListRules();
            if ($('#listSelector').data('select2')) {
              return $('#listSelector').data('select2').setPlaceholder();
            }
          });

      $scope.loadMailingListRules = () =>
        emailMarketingService.getMailingListRules().then(function (data) {
          for (let rule of Array.from(data.result.rules)) {
            $scope.select2MailingListRulesOptions.data.push({
              id: rule.code,
              text: rule.description,
            });
            $scope.allMailingListRules.push(rule);
          }
          $scope.loadOrganizationMailingListRules();
          if ($('#ruleSelector').data('select2')) {
            return $('#ruleSelector').data('select2').setPlaceholder();
          }
        });

      $scope.loadOrganizationMailingListRules = () =>
        emailMarketingService
          .getOrganizationMailingListRules(
            organizationService.getCurrentOrganization().id
          )
          .then(function (data) {
            let i;
            $scope.organizationMailingListRules = [];
            for (var organizationMailingListRule of Array.from(
              data.result.rules
            )) {
              const rule = (() => {
                const result = [];
                for (i of Array.from($scope.allMailingListRules)) {
                  if (i.id === organizationMailingListRule.rule_id) {
                    result.push(i);
                  }
                }
                return result;
              })()[0];
              const mailingList = (() => {
                const result1 = [];
                for (i of Array.from($scope.mailingLists)) {
                  if (i.id === organizationMailingListRule.mailing_list_id) {
                    result1.push(i);
                  }
                }
                return result1;
              })()[0];
              organizationMailingListRule.rule = rule;
              organizationMailingListRule.mailingList = mailingList;
              $scope.organizationMailingListRules.push(
                organizationMailingListRule
              );
            }
            return ($scope.emailRulesConfigurationLoaded = true);
          });

      if ($scope.organizationData.mailchimp_account_name !== null) {
        $scope.showEmailRulesSection = true;
        $scope.loadMailingLists();
      }
    }
  );
