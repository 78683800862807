import getCurrencySymbol from 'currency-symbol-map';

import { toUtc } from '../util/date-functions';
import './funding-item.html';
('use strict');

angular
  .module('idonate.gms.donations')
  .controller(
    'FundingDetailSpecificCtrl',
    function ($scope, fundingSummary, $rootScope, $location) {
      $scope.activeFunding = fundingSummary.result.funding;
      //funding records come in with their own currency set, not sure if it's worth doing this.
      $scope.fundingCurrencyCode =
        getCurrencySymbol(fundingSummary.result.funding.currency_code) ||
        $rootScope.CurrencyCode;
      $scope.tableOptions = {
        sortByColumn: 1,
        sortOrder: 'desc',
      };

      $scope.toUtc = function (date) {
        return toUtc(date);
      };

      $scope.done = function () {
        return $location.path('donations');
      };
    }
  );
