// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').filter(
  'phonenumber',
  () =>
    function (number) {
      if (!number) {
        return '';
      }
      number = String(number);
      // Will return formattedNumber.
      // If phonenumber isn't longer than an area code, just show number
      let formattedNumber = number;
      // if the first character is '1', strip it out and add it back
      const c = number[0] === '1' ? '1 ' : '';
      number = number[0] === '1' ? number.slice(1) : number;
      // # (###) ###-#### as c (area) front-end
      const area = number.substring(0, 3);
      const front = number.substring(3, 6);
      const end = number.substring(6, 10);
      if (front) {
        formattedNumber = c + '(' + area + ') ' + front;
      }
      if (end) {
        formattedNumber += `-${end}`;
      }
      return formattedNumber;
    }
);
