import './offline-bulk-import.html';

angular
  .module('idonate.gms.donations')
  .controller(
    'offlineBulkImport',
    function (
      SessionService,
      notyService,
      transactionService,
      organizationService
    ) {
      this.save = () => {
        this.loading = true;
        if (!this.donationFile) {
          this.loading = false;
        } else {
          const orgId = organizationService.getCurrentOrganization().id;
          SessionService.uploadToGmsApi(
            `/import/transaction?organization_id=${orgId}`,
            { data: { file: this.donationFile } }
          ).then(
            (success) => {
              notyService.success('Imported Donations');
              this.loading = false;
            },
            (error) => {
              notyService.error(error.data.message);
              this.loading = false;
            },
            (evt) => {
              this.progress = parseInt((100.0 * evt.loaded) / evt.total);
            }
          );
        }
      };
      this.download = () => transactionService.downloadOfflineTemplate();
      this.donationFile = null;
      this.progress = 0;
    }
  );
