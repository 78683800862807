angular
  .module('idonate.gms.donors')
  .factory('donorService', (SessionService, organizationService, $window) => ({
    getConfig() {
      return SessionService.restangularGmsApi.withConfig((rc) =>
        rc.setBaseUrl(`${$window.iDonateConfig.baseUrl}/donor`)
      );
    },
    getDonor(id) {
      const org_id = organizationService.getCurrentOrganization().id;
      return this.getConfig()
        .one(id, 'detail')
        .get({ organization_id: org_id });
    },
    updateDonor(obj) {
      return this.getConfig().all(obj.donor_id).customPUT(obj);
    },
    mergeDonors(keepDonor, assimilateDonor) {
      const org_id = organizationService.getCurrentOrganization().id;
      return this.getConfig().all('merge').customPUT({
        organization_id: org_id,
        merge_donor_id: keepDonor,
        assimilate_donor_id: assimilateDonor,
      });
    },
    getExternalDonorMapping(externalService) {
      const org_id = organizationService.getCurrentOrganization().id;

      SessionService.downloadGmsApiResult(
        `organization/${org_id}/external-mapping/donor/${externalService}`,
        {},
        `unmatched-donors.${externalService}.csv`
      );
    },
    updateDonorMapping(arr) {
      arr.map((item) =>
        this.getConfig()
          .one(`${item.donor_id}/external-mapping`)
          .customPUT(item)
      );
    },
  }));
