import { getStateName } from '../util/get-states';
// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './transactions-item.html';
import { formatSingleLineAddress } from '../util/format';
('use strict');

angular
  .module('idonate.gms.donations')
  .controller(
    'DonationsDetailSpecificCtrl',
    function (
      $scope,
      transaction,
      $rootScope,
      $location,
      transactionService,
      SessionService,
      appState
    ) {
      $scope.transactionService = transactionService;
      $scope.activeTransaction = transaction.result.transaction;
      $scope.showRefund = function (trans) {
        const refundLimit = moment().subtract(180, 'days');

        //logic check for showing the button
        return (
          moment(trans.created)._d > refundLimit._d &&
          !trans.details.refund_date &&
          trans.payment_transaction_id !== null &&
          trans.subtype !== 'echeck' &&
          trans.status.id === 'complete'
        );
      };

      $scope.showCancel = (trans) => trans.status.id !== 'cancelled';

      const acceptedAttrs =
        appState.currentOrganization.attributes.widget.accept;
      if (
        acceptedAttrs.pledge_cash ||
        acceptedAttrs.pledge_hours ||
        acceptedAttrs.pledge_payroll ||
        acceptedAttrs.pledge_vacation
      ) {
        if ($scope.activeTransaction.contact.employee_id) {
          $scope.showEmployeeId = true;
        } else {
          $scope.showEmployeeId = false;
        }
      } else {
        $scope.showEmployeeId = false;
      }

      $scope.getAddress = () => {
        return formatSingleLineAddress($scope.activeTransaction.contact.address);
      };

      $scope.resendReceipt = () => {
        transactionService.postTransactionResendReceipt(
          $scope.activeTransaction.id
        );
      };

      $scope.getUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const reqParams = Object.fromEntries(urlParams);

        if (urlParams.get('columns')) {
          reqParams.columns = (urlParams.get('columns') || '').split(',');

          if (reqParams.columns[0] == '') {
            reqParams.columns = [];
          }
        }

        return reqParams;
      };

      $scope.allowChangeTransaction = () => {
        return !!Object.keys($scope.getUrlParams()).length;
      };

      $scope.changeTransaction = (dir) => {
        const reqParams = $scope.getUrlParams();

        reqParams.offset_direction = dir;

        transactionService
          .getTransactionWithOffset(reqParams)
          .then((response) => {
            const params = $scope.getUrlParams();
            const trsId = response.result.data.id;

            params.offset = response.result.data.offset;
            params.columns = params.columns.toString();

            $location.path(`/donations/detail/${trsId}`).search(params);
            $scope.$apply();
          });
      };

      $scope.done = function () {
        if ($location.search().return === 'batch') {
          return $location
            .path(`reconciliation/batches/${$location.search().batch}`)
            .search('return', null)
            .search('batch', null);
        } else if ($location.search().return === 'statement-item') {
          return $location
            .path(`reconciliation/statements/${$location.search().statement}`)
            .search('return', null)
            .search('statement', null);
        } else if ($location.search().return === 'transactions') {
          return $location
            .path('reconciliation/transactions')
            .search('return', null);
        } else if ($scope.getUrlParams().returnTo === 'legacy') {
          return $location.path('donations');
        } else {
          return $location.path('reporting');
        }
      };

      $scope.processRefund = () => {
        transactionService
          .postTransactionRefund($scope.activeTransaction.id)
          .then((response) =>
            transactionService
              .getTransaction($scope.activeTransaction.id)
              .then(
                (response) =>
                  ($scope.activeTransaction = response.result.transaction)
              )
          );
      };

      $scope.detailUpdate = () => {
        $scope.$broadcast('showDetailUpdateModal', {
          id: $scope.activeTransaction.id,
          details: $scope.activeTransaction.details,
          edittable: $scope.activeTransaction.allowed_detail_edits,
        });
      };

      $scope.transactionUpdate = () => {
        $scope.$broadcast('showTransactionUpdateModal', {
          activeTransaction: { ...$scope.activeTransaction },
        });
      };

      return ($scope.processCancel = () => {
        // TODO: Change to cancel endpoint

        transactionService
          .postTransactionCancel($scope.activeTransaction.id)
          .then((response) =>
            transactionService
              .getTransaction($scope.activeTransaction.id)
              .then(
                (response) =>
                  ($scope.activeTransaction = response.result.transaction)
              )
          );
      });
    }
  );
