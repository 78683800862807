// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.text')
  .controller(
    'GiveKeywordsCtrl',
    function (
      $scope,
      organizationService,
      $location,
      keyword,
      organizationCampaigns,
      organizationDesignations,
      notyService,
      $timeout,
      $routeParams
    ) {
      $scope.filteredDesignations = _.filter(
        organizationDesignations.result.designations,
        { is_active: true }
      );
      $scope.campaigns = organizationCampaigns.campaigns;
      $scope.keyword = keyword.result.sms_keyword;
      $scope.campaignId = $routeParams.campaign_id;

      if ($scope.campaignId) {
        $scope.keyword.campaign_id = $scope.campaignId;
      }
      $scope.loading = false;

      $scope.$watch('keyword.campaign_id', function (value) {
        if (value) {
          const _campaign = _.find($scope.campaigns, { id: value });
          if (_campaign) {
            $scope.filteredDesignations = _.filter(_campaign.designations, {
              is_active: true,
            });
          }
        } else {
          $scope.filteredDesignations = _.filter(
            organizationDesignations.result.designations,
            { is_active: true }
          );
        }
      });

      $scope.enableDisableKeyword = function () {
        const _postObject = {
          keyword: $scope.keyword.keyword,
          id: $scope.keyword.id,
          is_active: $scope.keyword.is_active,
          designation_id: $scope.keyword.designation_id,
          campaign_id: $scope.keyword.campaign_id,
        };
        return organizationService
          .updateOrganizationKeyword(_postObject)
          .then(function (data) {});
      };

      $timeout(() => $scope.keywordForm.$setPristine(), 100);

      return ($scope.saveKeyword = function () {
        let apiCall;
        $scope.loading = true;
        const _postObject = {
          keyword: $scope.keyword.keyword,
          id: $scope.keyword.id,
          is_active: $scope.keyword.is_active,
          designation_id: $scope.keyword.designation_id
            ? $scope.keyword.designation_id
            : undefined,
          campaign_id: $scope.keyword.campaign_id
            ? $scope.keyword.campaign_id
            : undefined,
        };
        if ($scope.keyword.id) {
          apiCall = organizationService.updateOrganizationKeyword(_postObject);
        } else {
          apiCall = organizationService.addOrganizationKeyword(_postObject);
        }
        return apiCall.then(function (data) {
          if ($scope.keyword.id) {
            $scope.keyword = data.result.sms_keyword;
            $scope.loading = false;
            notyService.success(
              'A giving-keyword has been updated successfully.'
            );
            return $location.path('/text');
          } else {
            $scope.loading = true;
            notyService.success(
              'A giving-keyword has been created successfully.'
            );
            return $location.path('/text');
          }
        });
      });
    }
  );
