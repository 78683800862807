// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms')
  .directive(
    'sortableTable',
    (
      $rootScope,
      $timeout,
      $compile,
      organizationService,
      designationService
    ) => ({
      transclude: true,
      restrict: 'E',
      template:
        '<div class="idonate-dataTable" id={{uniqueId}}><div class="temp-table-container"></div><div class="perm-table" ng-transclude ng-show="false"></div></div>',
      scope: {
        conf: '=',
        reorder: '=',
        preventCompile: '@',
      },
      controller($scope, $element, $attrs) {
        return ($scope.uniqueId = `GMS-ST${Math.floor(
          Math.random() * 10000000 + 1000
        )}`);
      },
      link(scope, element, attrs) {
        scope.sortByColumn = 0;
        scope.sortOrder = 'asc';
        const columnDefs =
          scope.conf && scope.conf.columnDefs ? scope.conf.columnDefs : [];
        if (
          (scope.conf !== undefined && scope.conf.sortByColumn) ||
          (scope.conf !== undefined && scope.conf.sortOrder)
        ) {
          scope.sortByColumn = scope.conf.sortByColumn;
          scope.sortOrder = scope.conf.sortOrder;
        }
        const _perPage = (scope.conf != null ? scope.conf.perPage : undefined)
          ? scope.conf != null
            ? scope.conf.perPage
            : undefined
          : 10;
        const bindClickRow = function () {
          if (
            _.isFunction(
              scope.conf != null ? scope.conf.clickableRowAction : undefined
            ) ||
            $('.clickable-row td').not('.actions-column').length > 0
          ) {
            $timeout(
              () =>
                $(`#${scope.uniqueId}`)
                  .find('.clickable-row td')
                  .not('.actions-column')
                  .off('click')
                  .on('click', function () {
                    if (scope.conf != null) {
                      scope.conf.clickableRowAction(
                        $(this).parent('.clickable-row').data('action-id')
                      );
                    }
                    scope.$apply();
                  }),

              500
            );
          }
        };
        const buildTable = () =>
          $timeout(function () {
            const _tempTable = $(
              `#${scope.uniqueId} .perm-table .table`
            ).clone();
            $(`#${scope.uniqueId} .temp-table-container`).html(_tempTable);
            const toCompile = $(
              `#${scope.uniqueId} .temp-table-container .table td, th.actions-column`
            );
            $compile(toCompile)(scope.$parent);
            return $timeout(function () {
              let destroy = false;
              if (
                $.fn.dataTable.isDataTable(
                  `#${scope.uniqueId} .temp-table-container .table`
                )
              ) {
                destroy = true;
              }
              const _table = $(
                `#${scope.uniqueId} .temp-table-container .table`
              ).dataTable({
                sDom: "<'row'<'col-6'T><'col-6'f>r><'panel panel-table table-responsive't><'row'<'col-sm-3'l><'col-sm-6'p>>",
                iDisplayLength: _perPage,
                destroy,
                oLanguage: {
                  sLengthMenu:
                    'Show <select class="form-control input-sm">' +
                    '<option value="10">10</option>' +
                    '<option value="25">25</option>' +
                    '<option value="50">50</option>' +
                    '<option value="100">100</option>' +
                    '<option value="-1">All</option>' +
                    '</select> per page',
                  oPaginate: {
                    sNext: '<i class="icon-right"></i>',
                    sPrevious: '<i class="icon-left"></i>',
                  },
                },
                aaSorting: [[scope.sortByColumn, scope.sortOrder]],
                columnDefs,
                paging:
                  (scope.conf != null ? scope.conf.paging : undefined) != null
                    ? scope.conf.paging
                    : true,
                rowReorder: (
                  scope.conf != null ? scope.conf.rowReorder : undefined
                )
                  ? scope.conf.rowReorder
                  : null,
              });
              if (scope.conf != null ? scope.conf.outsideSearchId : undefined) {
                $(`#${scope.conf.outsideSearchId}`).keyup(function () {
                  return _table.fnFilter($(this).val());
                });
              }
              _table.on('draw.dt', () => bindClickRow());

              scope.reOrderInProgress = false;
              if (scope.conf != null ? scope.conf.rowReorder : undefined) {
                _table.api().on('row-reorder', function (e, diff, edit) {
                  const _rowChangedID = edit.triggerRow.id();
                  let _fromPosition = null;
                  let _toPosition = null;

                  let _diffMatch = false;

                  return _.forEach(diff, function (item) {
                    if (item.node.id === _rowChangedID && !_diffMatch) {
                      _diffMatch = true;
                      const _currentPage = _table.api().page.info().page;
                      const _displayPerPage = _table.api().page.info().length;

                      const _calculatedPosition =
                        _currentPage * _displayPerPage;

                      _fromPosition = _calculatedPosition + item.oldPosition;
                      _toPosition = _calculatedPosition + item.newPosition;

                      if (
                        _fromPosition !== null &&
                        _toPosition !== null &&
                        scope.reOrderInProgress === false
                      ) {
                        scope.reOrderInProgress = true;

                        // disable the row reordering during server side process
                        _table.api().rowReorder.disable();

                        const _newOrder = {
                          organization_id:
                            organizationService.getCurrentOrganization().id,
                          id: _rowChangedID,
                          fromPosition: _fromPosition,
                          toPosition: _toPosition,
                        };

                        return designationService
                          .reorderOrganizationDesignation(_newOrder)
                          .then(
                            function () {
                              scope.reOrderInProgress = false;
                              $rootScope.$broadcast(
                                'designationReordered',
                                _newOrder
                              );
                              // re-enable reordering
                              return _table.api().rowReorder.enable();
                            },
                            function (err) {
                              scope.reOrderInProgress = false;
                              return _table.api().rowReorder.enable();
                            }
                          );
                      }
                    }
                  });
                });
              }

              _table
                .api()
                .columns()
                .iterator('column', function (ctx, idx) {
                  const column = $(_table.api().column(idx).header());
                  if (column[0].innerText !== '') {
                    const icons = $(_table.api().column(idx).header()).find(
                      '.sort-icon'
                    );
                    if (!icons.length > 0) {
                      return $(_table.api().column(idx).header()).append(
                        '<span class="sort-icon"/>'
                      );
                    }
                  }
                });
              return bindClickRow();
            });
          });
        $timeout(() => buildTable());
        return scope.$on('reloadTable', buildTable);
      },
    })
  );
