'use strict';

angular.module('idonate.gms').directive('gmsListField', ($timeout) => ({
  restrict: 'E',
  templateUrl: '/scripts/input/list-field.html',
  scope: {
    field: '=',
  },

  controller($scope, $element, $attrs) {
    $scope.items = [];
    $scope.newValue = '';

    const unpackData = function () {
      if ($scope.field.value) {
        if (Array.isArray($scope.field.value)) {
          $scope.items = Array.from($scope.field.value);
        } else {
          $scope.items = $scope.field.value.split('|');
        }
      } else {
        $scope.items = [];
      }
    };

    const packData = function () {
      $scope.field.value = $scope.items.join('|');
    };

    $scope.onDrop = function (item, index) {
      $scope.$apply(() => {
        $scope.items.splice(index, 1, item);
        packData();
      });
    };

    $scope.addNewItem = function () {
      $scope.newValue = $scope.newValue.replace(new RegExp('\\|', 'g'), ' ');
      if (!$scope.items.includes($scope.newValue)) {
        $scope.items.push($scope.newValue);
        packData();
      }
      $scope.newValue = '';
    };

    $scope.removeItem = function ($index) {
      $scope.items.splice($index, 1);
      packData();
    };

    unpackData();

    return $scope.$watch('field', function (ov, nv) {
      if (nv !== ov) {
        return unpackData();
      }
    });
  },
}));
