// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const WebsiteService = function (SessionService, organizationService) {
  const init = () => ({
    getTemplates,
    updateEmbedInstance,
    publishEmbed,
    updateOrganizationEmbedInstanceProperties,
    editOrganizationPage,
    deletePage,
    enablePage,
    addOrganizationPage,
    getOrganizationPages,
    getOrganizationPage,
    cloneEmbedInstance,
    createEmbedInstance,
    getEmbedBases,
    getEmbedInstances,
    getEmbedInstancesSelect,
    getGoalInstances,
    getEmbedInstance,
  });
  var getTemplates = () => SessionService.restangularGmsApi.one('page').get();

  var updateEmbedInstance = function (obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/embed/instances/${obj.id}`)
      .customOperation('put', null, null, null, obj);
  };

  var publishEmbed = function (id) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/embed/instances/${id}/publish`)
      .post(null);
  };

  var updateOrganizationEmbedInstanceProperties = function (id, obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/embed/instances/${id}/properties`)
      .customOperation('put', null, null, null, obj);
  };

  var editOrganizationPage = function (pageId, obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/pages/${pageId}`)
      .post(obj);
  };

  var deletePage = function (pageId) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/pages/${pageId}`)
      .customDELETE();
  };

  var enablePage = function (pageId) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/pages/${pageId}`)
      .post();
  };

  var addOrganizationPage = function (obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/pages`)
      .post(obj);
  };

  var getOrganizationPages = function (campaignId) {
    const _org = organizationService.getCurrentOrganization();
    if (campaignId != null) {
      return SessionService.restangularGmsApi
        .one(`organization/${_org.id}/pages`)
        .get({
          campaign_id: campaignId,
        });
    } else {
      return SessionService.restangularGmsApi
        .one(`organization/${_org.id}/pages`)
        .get();
    }
  };

  var getOrganizationPage = function (pageId) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/pages/${pageId}`)
      .get();
  };

  var cloneEmbedInstance = function (embId) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/embed/instances/${embId}/copy`)
      .post(null);
  };

  var createEmbedInstance = function (obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/embed/instances`)
      .post(obj);
  };

  var getEmbedBases = function () {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/embed/bases`)
      .get();
  };

  var getEmbedInstances = function (campaign_id) {
    const _org = organizationService.getCurrentOrganization();
    const query = SessionService.restangularGmsApi.one(
      `organization/${_org.id}/embed/instances`
    );
    if (campaign_id != null) {
      return query.get({ campaign_id });
    } else {
      return query.get();
    }
  };

  var getEmbedInstancesSelect = function () {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/embed/instances-select2`)
      .get();
  };

  var getGoalInstances = function (type, campaignId) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/embed/instances`)
      .get({
        types: type,
        campaign_id: campaignId,
      });
  };

  var getEmbedInstance = function (embedId) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/embed/instances/${embedId}`)
      .get();
  };

  return init();
};

angular.module('idonate.gms.website').factory('websiteService', WebsiteService);
