import './thank-you-item.html';

export class ThankYouItemCtrl {
  static $inject = [
    '$scope',
    'thankyouGift',
    'organizationService',
    'SessionService',
    '$location',
    'notyService',
  ];
  constructor(
    $scope,
    thankyouGift,
    organizationService,
    SessionService,
    $location,
    notyService
  ) {
    $scope.gift = thankyouGift.result.gift;
    if (!$scope.gift.id) {
    }
    $scope.gift.is_enabled = !$scope.gift.is_disabled;
    //Adding ts param to image url prevent load the img from cache
    $scope.gift.image_url = $scope.gift.image_url
      ? `${$scope.gift.image_url}?ts=` + new Date().getTime()
      : $scope.gift.image_url;
    let file = null;
    $scope.uploadProgress = 0;
    $scope.loading = false; //Show the spinner

    if (
      ($scope.gift.extra_placeholder != null
        ? $scope.gift.extra_placeholder.length
        : undefined) > 0
    ) {
      $scope.customDetailsCheck = true;
    }

    $scope.saveThankyouGift = function (removeImage) {
      $scope.loading = true; //Show the spinner
      if ($scope.gift.id) {
        $scope.gift.gift_id = $scope.gift.id;
        if (removeImage) {
          $scope.gift.remove_image = true;
        }
        return organizationService
          .updateOrganizationGift($scope.gift)
          .then(function (data) {
            if (removeImage) {
              $scope.gift.image_url = null;
              $scope.gift.remove_image = false;
            }
            $scope.loading = false;
            notyService.success(
              'A thank you gift has been updated successfully.'
            );
            return $location.path('/thank-you-gifts');
          });
      } else {
        return organizationService
          .postOrganizationGift($scope.gift)
          .then(function (data) {
            $scope.gift = data.result.gift;
            if (file) {
              return uploadFile(true);
            } else {
              $scope.loading = false;
              notyService.success(
                'A thank you gift has been created successfully.'
              );
              return $location.path('/thank-you-gifts');
            }
          });
      }
    };

    $scope.removeImage = function () {
      $scope.files = [];
      file = null;
      if ($scope.gift.id && $scope.gift.image_url) {
        return $scope.saveThankyouGift(true);
      }
    };

    $scope.$watch('files', (files) => $scope.upload(files));

    var uploadFile = function (redirectAtEnd) {
      const data = {};
      angular.extend(data, $scope.gift, {
        image: file,
      });

      return SessionService.uploadToGmsApi(
        `/organization/${
          organizationService.getCurrentOrganization().id
        }/gifts/${$scope.gift.id}`,
        {
          data,
          fileFormDataName: 'image',
          method: 'PUT',
        }
      )
        .progress(
          (evt) =>
            ($scope.uploadProgress = parseInt((100.0 * evt.loaded) / evt.total))
        )
        .success(function (data, status, headers, config) {
          $scope.uploadProgress = 0;
          $scope.gift.image_url =
            `${data.result.gift.image_url}?ts=` + new Date().getTime();
          $scope.file = null;
          $scope.loading = false;
          if (redirectAtEnd) {
            notyService.success(
              'A thank you gift has been created successfully.'
            );
            return $location.path('/thank-you-gifts');
          }
        });
    };

    $scope.upload = function (files) {
      if (files && files.size > 0) {
        file = files;
        if ($scope.gift.id) {
          return uploadFile(false);
        }
      }
    };

    return ($scope.activeDesactive = function () {
      $scope.gift.gift_id = $scope.gift.id;
      $scope.gift.is_disabled = !$scope.gift.is_enabled;
      return organizationService.updateOrganizationGift($scope.gift).then(
        function () {},
        function () {
          $scope.gift.is_enabled = !$scope.gift.is_enabled;
          return ($scope.gift.is_disabled = !$scope.gift.is_enabled);
        }
      );
    });
  }
}
