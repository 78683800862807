let uniqueId = 0;
angular.module('idonate.gms.donations').component('tableFilterToggle', {
  template: `
    <div class="btn-group" data-toggle="buttons">
      <label class="btn {{ $ctrl.value == false ? 'btn-clear' : 'btn-gray'}}" ng-click="$ctrl.setShow(false)">
        <input type="radio" name="options">Hide {{$ctrl.text}}
      </label>
      <label class="btn {{ $ctrl.value == true ? 'btn-clear' : 'btn-gray'}}" ng-click="$ctrl.setShow(true)">
        <input type="radio" name="options">Show {{$ctrl.text}}
      </label>
    </div>`,
  bindings: {
    value: '=?model',
    itemChanged: '<?changed',
    text: '@',
  },
  controller: class {
    constructor($timeout) {
      this.$timeout = $timeout;
    }
    $onInit() {
      this.value = this.value || false;
      this.uniqueId = `showArchivedSwitch${uniqueId++}`;
      this.setShow = (showArchived) =>
        this.$timeout(() => {
          this.value = showArchived;
          if (this.itemChanged) {
            this.itemChanged(this.value);
          }
        });
    }
  },
});
