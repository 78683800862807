// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').component('dashboardCampaign', {
  templateUrl: '/scripts/dashboard/dashboard-campaign.html',
  bindings: {
    dateRange: '<?',
  },
  controller($location, CampaignAPI, DashboardAPI) {
    const getPercent = function (first, second) {
      if (second !== 0) {
        return (first - second) / second;
      } else {
        return undefined;
      }
    };
    this.onInit = function () {
      this.choices = [
        { id: 'all', title: 'All Campaigns' },
        { id: '00000000-0000-0000-0000-000000000000', title: 'Unassigned' },
      ];
      const search = $location.search();
      CampaignAPI.getOrganizationCampaigns({ showDisabled: false }).then(
        (result) => {
          for (let item of Array.from(result.campaigns)) {
            this.choices.push(item);
          }
          if (search.selected) {
            this.selected = _.find(
              this.choices,
              (item) => item.id === search.selected
            );
            if (typeof this.selected === 'undefined')
              this.selected = this.choices[0];
          }
          return this.getCampaignSummary();
        }
      );
      if (!search.selected) {
        this.selected = this.choices[0];
      }
    };
    this.$onChanges = () => {
      return this.onInit();
    };
    this.campaignSelected = ($model) => {
      const search = $location.search();
      search.selected = $model.id;
      $location.search(search);
      return this.onInit();
    };
    this.setCampaignSummary = (result) => {
      this.summary = result;
      this.summary.donations = {
        figure: result.current.total_value,
        percent: getPercent(
          result.current.total_value,
          result.previous.total_value
        ),
      };
      this.summary.avgDonations = {
        figure: result.current.avg_value,
        percent: getPercent(
          result.current.avg_value,
          result.previous.avg_value
        ),
      };
      this.summary.gifts = {
        figure: result.current.total_count,
        percent: getPercent(
          result.current.total_count,
          result.previous.total_count
        ),
      };
      return (this.summary.donors = {
        figure: result.current.new_donor_count,
        percent: getPercent(
          result.current.new_donor_count,
          result.previous.new_donor_count
        ),
      });
    };
    this.setTransactionChart = (result) => {
      this.chart = { donation: angular.extend({}, result.chart) };
      this.chart.donation.series.cash.data['fill'] = false;
      this.chart.donation.series.noncash.data['fill'] = false;
      this.chart.donation.series.average.data['fill'] = false;
      this.chart.donation.data = [
        this.chart.donation.series.cash.data,
        this.chart.donation.series.noncash.data,
        this.chart.donation.series.average.data,
      ];
      this.chart.donation.labels = [];
      angular.forEach(this.chart.donation.categories, (val, key) => {
        return this.chart.donation.labels.push(
          moment(val).format(this.dateFmt)
        );
      });
      this.chart.donation.types = ['Cash', 'Noncash', 'Average'];
      this.chart.donation.colors = ['#F16B82', '#F6D879', '#16ABD3'];
      this.chart.cash = angular.extend({}, this.chart.donation);
      this.chart.cash.data = [this.chart.cash.series.cash.data];
      this.chart.cash.types = ['Cash'];
      this.chart.cash.colors = ['#F16B82'];
      this.chart.noncash = angular.extend({}, this.chart.donation);
      this.chart.noncash.data = [this.chart.noncash.series.noncash.data];
      this.chart.noncash.types = ['Noncash'];
      return (this.chart.noncash.colors = ['#F6D879']);
    };
    this.getCampaignSummary = () => {
      const query = {
        start_date: this.dateRange.current.start.toISOString(),
        end_date: this.dateRange.current.end.toISOString(),
        previous_start_date: this.dateRange.previous.start.toISOString(),
        previous_end_date: this.dateRange.previous.end.toISOString(),
        timezone: moment.tz.guess(),
      };
      if ((this.selected != null ? this.selected.id : undefined) !== 'all') {
        if (this.selected.id !== '00000000-0000-0000-0000-000000000000') {
          query.campaign_id = this.selected.id;
        } else {
          query.op = '^';
        }
      }
      DashboardAPI.getCampaignSummary(query).then(this.setCampaignSummary);
      angular.extend(query, {
        transaction_status: 'complete',
        use_finalized: true,
        plot_by: 'day',
      });
      const dateDiff = this.dateRange.current.end.diff(
        this.dateRange.current.start,
        'days'
      );
      this.dateFmt = 'MM-DD-YYYY';
      if (dateDiff > 45) {
        query['plot_by'] = 'month';
        this.dateFmt = "MMM 'YY";
      }
      if ('op' in query) {
        delete query.op;
        query.campaign_id = '^';
      }
      return DashboardAPI.getTransactionChart(query).then(
        this.setTransactionChart
      );
    };
  },
});
