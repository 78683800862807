// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class ManageTeamModal {
  /* @ngInject */
  constructor(team, baseLink, $uibModalInstance) {
    this.cancel = this.cancel.bind(this);
    this.team = team;
    this.$uibModalInstance = $uibModalInstance;
    this.viewPage = `${baseLink}/a/${this.team.captain.advocate_id}`;
  }
  cancel() {
    return this.$uibModalInstance.dismiss('cancel');
  }
}

angular
  .module('idonate.gms.legacy-p2p')
  .directive('manageTeamModal', (Modals) => {
    Modals.registerModal('ManageTeamModal', (resolve) => {
      return {
        size: 'md',
        windowClass: 'advocate-modal',
        templateUrl: '/scripts/legacy-p2p/manage-team-modal.html',
        controllerAs: '$ctrl',
        resolve,
        controller: ManageTeamModal,
      };
    });
    return { restrict: 'A' };
  });
