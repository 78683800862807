// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').component('dashboardWisdom', {
  templateUrl: '/scripts/dashboard/dashboard-wisdom.html',
  controller(Restangular) {
    this.limit = 9;
    const page = 1;

    Restangular.withConfig((r) =>
      r.setBaseUrl('https://idonatecontent.wpengine.com/')
    )
      .one('wp-json/wp/v2/posts?_embed=wp:featuredmedia')
      .get()
      .then((result) => {
        this.wisdom = result;
      });
  },
});
