import { getStateName } from '../util/get-states';

import './donorstable.html';

angular
  .module('idonate.gms.donors')
  .directive(
    'donorsTable',
    ($timeout, $filter, $rootScope, SessionService) => ({
      restrict: 'E',
      templateUrl: '/scripts/donors/donorstable.html',
      controller(
        $scope,
        $element,
        $attrs,
        $timeout,
        $uibModal,
        $filter,
        donorColumns
      ) {
        const init = function () {
          $scope.openColumnSelector = openColumnSelector;
          $scope.reload = reload;
          setColumns();
          activate();
          reload();
        };

        //############################

        var activate = function () {
          $scope.uniqueId = `GMS-ST${Math.floor(
            Math.random() * 10000000 + 1000
          )}`;

          const table = null;
          const resizeTO = null;
          $scope.firstGenerate = true;
          $scope.colums = $scope.newColumns;
          $scope.pageLimit = gmsStorage.gmsTablePageLimit
            ? parseInt(gmsStorage.gmsTablePageLimit)
            : 10;
          const columnDiff = _.intersectionBy(
            _.differenceBy($scope.columns, $scope.newColumns, 'name'),
            $scope.newColumns,
            'name'
          );
          $scope.colums = _.intersectionBy(
            $scope.colums,
            $scope.newColumns,
            'name'
          );
          $scope.colums.concat(columnDiff);
          $scope.columnsActiveDesactive = {};

          return angular.forEach($scope.colums, (val, $key) => {
            $scope.columnsActiveDesactive[val.name] = val.active;
            if (val.name === 'totalgiving') {
              $scope.colums[$key].render = (data, type, row) =>
                $filter('currency')(
                  `${data}`.replace('$', ''),
                  $rootScope.currencyCode
                );
            } else if (val.name === 'state') {
              $scope.colums[$key].render = (data, type, row) => {
                const address = row.contact.address;
                return getStateName(address.country, address.state);
              };
            } else {
              $scope.colums[$key].render = $.fn.dataTable.render.text();
            }
          });
        };

        var setColumns = function () {
          $scope.newColumns = donorColumns.defaults;
          return $scope.newColumns;
        };

        var openColumnSelector = function () {};

        const removeTable = function () {
          if ($scope.table) {
            $scope.table.destroy();
            return $(`#${$scope.uniqueId} table`).remove();
          }
        };

        const buildTable = function () {
          const buildColumns = _.filter($scope.colums, { active: true });
          return $timeout(function () {
            $(`#${$scope.uniqueId}_temp .table`)
              .clone()
              .appendTo(`#${$scope.uniqueId}`);
            $scope.firstGenerate = false;
            $scope.table = $(`#${$scope.uniqueId} table`).DataTable({
              createdRow(row, data, dataIndex) {
                const column = $scope.colums[dataIndex];
                if ((column != null ? column.name : undefined) === 'created') {
                  const cell = $('td', row).eq(dataIndex);
                  return cell.attr('data-order', data[dataIndex]);
                }
              },

              serverSide: true,
              processing: true,
              sDom: "<'row'<'col-6'T><'col-6'f>r><'panel panel-table table-responsive't><'row'<'col-sm-3'l><'col-sm-6'p>>",
              oLanguage: {
                sLengthMenu: 'Show _MENU_ per page',
                oPaginate: {
                  sNext: '<i class="icon-right"></i>',
                  sPrevious: '<i class="icon-left"></i>',
                },
              },
              pageLength: $scope.pageLimit,
              order: [[0, 'desc']],
              //positioning elements - https://datatables.net/examples/basic_init/dom.html
              dom: '<tilp>',
              ajax: {
                url: `${window.iDonateConfig.baseUrl}donor?organization_id=${$scope.orgId}`,
                dataType: 'json',
                type: 'get',
                dataSrc: 'result',
                beforeSend: (request) => {
                  // grab authentication headers from API client.
                  const headers = SessionService.getAuthHeaders();

                  Object.keys(headers).forEach((key) => {
                    request.setRequestHeader(key, headers[key]);
                  });
                },
              },
              columns: buildColumns,
              rowCallback(row, data) {
                row.className = 'clickable-row';
                let getIconNumber = null;
                angular.forEach(buildColumns, function (val, $key) {
                  if (val.name === 'icon') {
                    return (getIconNumber = $key);
                  }
                });
                if (getIconNumber && data[getIconNumber]) {
                  const _icon = $filter('typeToGlyph')(data[getIconNumber]);
                  return $(`td:eq(${getIconNumber})`, row).html(
                    `<i class="glyphicons ${_icon}"></i>`
                  );
                }
              },
            });
            $scope.table
              .columns()
              .iterator('column', (ctx, idx) =>
                $($scope.table.column(idx).header()).append(
                  '<span class="sort-icon"/>'
                )
              );
            $('#donor-search').keyup(function () {
              const _self = this;
              if ($scope.searchingTable) {
                $timeout.cancel($scope.searchingTable);
              }
              return ($scope.searchingTable = $timeout(function () {
                $scope.search = $(_self).val();
                return $scope.table.search($(_self).val()).draw();
              }, 800));
            });
            return $(`#${$scope.uniqueId} table`)
              .off()
              .on('xhr.dt', function () {
                const params = $scope.table.ajax.params();

                $scope.pageLimit = params.length;
                gmsStorage.gmsTablePageLimit = params.length;

                return $(`#${$scope.uniqueId} tbody`)
                  .off()
                  .on('click', 'tr', function (e) {
                    const donorId = $scope.table.row(this).data().donor_id;
                    return $scope.clickOnRow(donorId);
                  });
              });
          });
        };

        var reload = function () {
          removeTable();
          buildTable();
          return $('.dataTables_filter').parent().remove();
        };

        return init();
      },
    })
  );

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
