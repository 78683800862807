import countryRegionData from 'country-region-data/data';

const ALL_COUNTRIES = countryRegionData.map((country) => {
  const states = country.regions.map((region) => ({
    text: region.name,
    val: region.shortCode,
  }));
  return { [country.countryShortCode]: states };
});
export const STATE_CHOICES = Object.assign({}, ...ALL_COUNTRIES);

export const getStateName = (countryCode, stateShortCode) => {
  if (!countryCode || !stateShortCode) {
    return stateShortCode;
  }

  if (!(countryCode in STATE_CHOICES)) {
    return stateShortCode;
  }

  const state_obj = STATE_CHOICES[countryCode].find(
    (state) => state.val == stateShortCode
  );
  return state_obj ? state_obj.text : stateShortCode;
};
