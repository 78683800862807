import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { Angular as AngularIntegration } from '@sentry/integrations';

import jquery from 'jquery/dist/jquery';
import Popper from 'popper.js/dist/umd/popper';
import moment from 'moment/moment';
import Noty from 'noty/lib/noty';
import dt from 'datatables.net/js/jquery.dataTables';
import intlTelInput from 'intl-tel-input/build/js/intlTelInput';

import 'datatables.net-bs/js/dataTables.bootstrap';
import 'datatables.net-rowreorder/js/dataTables.rowReorder.min';
import 'intl-tel-input/build/js/utils';

window.$ = jquery;
window.jQuery = jquery;
window.jquery = jquery;
window.Popper = Popper;
window.moment = moment;
window.Noty = Noty;
window.intlTelInput = intlTelInput;

try {
  localStorage.setItem('localStorage', 1);
  localStorage.removeItem('localStorage');
  window.gmsStorage = localStorage;
} catch (e) {
  window.gmsStorage = {
    clear: function () {},
  };
  alert(
    'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.'
  );
}
document.createElement('carousel');
document.createElement('slide');

// provide noop console methods if console is not defined to prevent logging from erroring out.
(function () {
  var method;
  var noop = function () {};
  var methods = [
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profileEnd',
    'table',
    'time',
    'timeEnd',
    'timeStamp',
    'trace',
    'warn',
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();
(function (w, d, s, g, js, fs) {
  g = w.gapi || (w.gapi = {});
  g.analytics = {
    q: [],
    ready: function (f) {
      this.q.push(f);
    },
  };
  js = d.createElement(s);
  fs = d.getElementsByTagName(s)[0];
  js.src = 'https://apis.google.com/js/platform.js';
  fs.parentNode.insertBefore(js, fs);
  js.onload = function () {
    g.load('analytics');
  };
})(window, document, 'script');
(function (i, s, o, g, r, a, m) {
  i['GoogleAnalyticsObject'] = r;
  (i[r] =
    i[r] ||
    function () {
      (i[r].q = i[r].q || []).push(arguments);
    }),
    (i[r].l = 1 * new Date());
  (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m);
})(
  window,
  document,
  'script',
  'https://www.google-analytics.com/analytics.js',
  'ga'
);

ga('create', 'UA-38269447-3');
ga('send', 'pageview');
var s = document.createElement('script');
s.src = '//' + window.iDonateConfig.idonateiFrame.url + '/idonate.js';
document.documentElement.appendChild(s);

Sentry.init({
  dsn: window.iDonateConfig?.sentry?.dsn,
  integrations: [new AngularIntegration(), new BrowserTracing()],
  tracesSampleRate:
    parseFloat(window.iDonateConfig?.sentry?.tracesSampleRate) || 0.0,
  environment: window.iDonateConfig?.sentry?.environment || 'local',
});

$.fn.dataTable.ext.order['dom-checkbox'] = function (settings, col) {
  return this.api()
    .column(col, { order: 'index' })
    .nodes()
    .map(function (td, i) {
      let left;
      return (left = $('input', td).prop('checked')) != null
        ? left
        : { 1: '0' };
    });
};
