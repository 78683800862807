import getCurrencySymbol from 'currency-symbol-map';

angular
  .module('idonate.gms')
  .factory(
    'organizationService',
    function (SessionService, $rootScope, $q, $route, appState) {
      // cache for org list
      let cachedOrganizationList = {};

      // cache for full org info, for current org
      let fullOrgInfo = null;

      // TODO: should be able to simplify and get rid of 'tempOrgsAndRoles'
      let tempOrgsAndRoles = [];

      return {
        //Begin of organization calls

        // TODO refactor away getCurrentOrganization().
        getCurrentOrganization() {
          if (!appState.currentOrganization) {
            console.error(
              'currentOrganization accessed before load',
              appState.currentOrganization
            );
            throw new Error('Invalid currentOrganization');
          }

          return appState.currentOrganization;
        },

        getOrganizationRole() {
          let orgWithRole = _.find(tempOrgsAndRoles, {
            id: appState.currentOrganization.id,
          });

          if (orgWithRole) {
            return orgWithRole;
          } else {
            // try to fetch from cache?
            orgWithRole = _.find(cachedOrganizationList['flat:'], {
              id: appState.currentOrganization.id,
            });

            if (orgWithRole) {
              tempOrgsAndRoles.push(orgWithRole);
              return orgWithRole;
            } else {
              return false;
            }
          }
        },

        // deprecated shim to pass appState.currentOrganization around as before TODO CLEAN THIS
        loadFullOrganizationInformation() {
          // rewrap in `result` until this can be refactored
          return Promise.resolve({
            result: appState.currentOrganization,
          });
        },

        updateOrganization(obj) {
          return SessionService.restangularGmsApi
            .all(`organization/${appState.currentOrganization.id}`)
            .customOperation('put', null, null, null, obj);
        },
        //End of organization calls

        //Begin of call for organization images
        loadOrganizationImages() {
          return SessionService.restangularGmsApi
            .one(`organization/${appState.currentOrganization.id}/images`)
            .get();
        },

        deleteOrganizationImage(key) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/images/` + key
            )
            .remove();
        },
        //End of call for organization images

        //Begin of organizations attributes calls
        getOrganizationAttributes() {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}`,
              'attributes'
            )
            .get();
        },

        // TODO: Keep attributes loaded to avoid repeated expensive calls
        updateAttributeWithPath(path, val) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/attributes/${path}`
            )
            .post({ value: val });
        },

        updateAttributeWithPathGlobal(path, val) {
          return SessionService.restangularGmsApi
            .all(`organization/${appState.currentOrganization.id}/${path}`)
            .post({ value: val });
        },

        updateAttributes(obj) {
          return SessionService.restangularGmsApi
            .all(`organization/${appState.currentOrganization.id}/attributes`)
            .post(obj);
        },
        //End of organizations attributes edit

        //Begin of organization tribute definitions Calls
        getOrganizationTributeDefinitions() {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}`,
              'tribute_definitions'
            )
            .get();
        },

        getOrganizatioCampaignTributeDefinitions(campaign_id) {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/campaigns/${campaign_id}`,
              'tribute_definitions'
            )
            .get();
        },

        postOrganizationCampaignTributeDefinitions(
          campaign_id,
          tribute_definition_id
        ) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/campaigns/${campaign_id}/tribute_definitions`
            )
            .post({ tribute_definition_id });
        },

        deleteCampaignTribute(campaignId, tributeId) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/campaigns/${campaignId}/tribute_definitions/${tributeId}`
            )
            .customDELETE();
        },

        postOrganizationTributeDefinition(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/tribute_definitions`
            )
            .post(obj);
        },

        deleteTributeDefinition(tributeId) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/tribute_definitions/${tributeId}`
            )
            .customDELETE();
        },

        updateTributeDefinition(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/tribute_definitions/${obj.tribute_definition_id}`
            )
            .customOperation('put', null, null, null, obj);
        },
        //End of organizations tribute definitions edit

        //Begin of Organization and Campaigns Gifts
        getOrganizationGifts() {
          return SessionService.restangularGmsApi
            .one(`organization/${appState.currentOrganization.id}/gifts`)
            .get();
        },

        deleteOrganizationGift(giftId) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/gifts/${giftId}`
            )
            .customDELETE();
        },

        getOrganizationGiftData(giftId) {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/gifts/${giftId}`
            )
            .get();
        },

        postOrganizationGift(obj) {
          return SessionService.restangularGmsApi
            .all(`organization/${appState.currentOrganization.id}/gifts`)
            .post(obj);
        },

        updateOrganizationGift(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/gifts/${obj.gift_id}`
            )
            .customOperation('put', null, null, null, obj);
        },

        deleteCampaignGift(campaignId, giftId) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/campaigns/${campaignId}/gifts/${giftId}`
            )
            .customDELETE();
        },

        getCampaignGifts(campaignId) {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/campaigns/${campaignId}/gifts`
            )
            .get();
        },

        postCampaignGift(campaignId, obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/campaigns/${campaignId}/gifts`
            )
            .post(obj);
        },
        //End of Organization Gifts

        //Begin Association of Email Campaigns with iDonate campaigns
        deleteCampaignEmailCampaign(campaignId, emailCampaignId) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/campaigns/${campaignId}/email_campaigns/${emailCampaignId}`
            )
            .customDELETE();
        },

        getCampaignEmailCampaigns(campaignId) {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/campaigns/${campaignId}/email_campaigns`
            )
            .get();
        },

        postCampaignEmailCampaign(campaignId, obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/campaigns/${campaignId}/email_campaigns`
            )
            .post(obj);
        },

        removeMailchimpAccountAssociation(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/remove_mailchimp_association`
            )
            .post(obj);
        },

        getMailchimpiFrameUrl() {
          return SessionService.restangularGmsApi
            .one(`email_marketing/authorize/${appState.currentOrganization.id}`)
            .get();
        },

        getSalesForceLoginUrl() {
          return SessionService.restangularGmsApi
            .one(`salesforce/connect/${appState.currentOrganization.id}`)
            .get();
        },

        getCampaignEmailAnalytics(campaignId) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/campaigns/${campaignId}/email_campaigns/summary`
            )
            .customGET();
        },

        getOrganizationEmailCampaigns() {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/campaigns/email_campaigns`
            )
            .customGET();
        },

        //Begin Push Notifications
        sendOrganizationPushNotification(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/push-notification`
            )
            .post(obj);
        },

        getOrganizationPushNotificationHistory() {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/push-notification`
            )
            .get();
        },
        //End Push Notifications

        //Begin Contacts
        getOrganizationContacts(obj) {
          return SessionService.restangularGmsApi
            .one(`organization/${appState.currentOrganization.id}/contacts`)
            .get(obj);
        },
        //End Contacts

        //Begin Payment Gateways
        getOrganizationPaymentGateways() {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/payment/available-gateways`
            )
            .get();
        },

        getOrganizationInstalledGateways() {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/payment/gateways`
            )
            .get();
        },

        updateOrganizationInstalledGateway(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/payment/gateways/${obj.gateway_id}`
            )
            .customOperation('put', null, null, null, obj);
        },

        installPaymentGateway(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/payment/gateways`
            )
            .post(obj);
        },

        getInstalledPaymentGateway(gatewayId) {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/payment/gateways/${gatewayId}`
            )
            .get();
        },
        //End Payment Gateways

        //Begin SMS Keywords
        getOrganizationKeyword(keywordId) {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/sms-keywords/${keywordId}`
            )
            .get();
        },

        getOrganizationKeywords() {
          return SessionService.restangularGmsApi
            .one(`organization/${appState.currentOrganization.id}/sms-keywords`)
            .get();
        },

        addOrganizationKeyword(obj) {
          return SessionService.restangularGmsApi
            .all(`organization/${appState.currentOrganization.id}/sms-keywords`)
            .post(obj);
        },

        updateOrganizationKeyword(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/sms-keywords/${obj.id}`
            )
            .customOperation('put', null, null, null, obj);
        },
        //End SMS Keywords

        //Beging SMS numbers
        getOrganizationSmsNumbers() {
          return SessionService.restangularGmsApi
            .one(`organization/${appState.currentOrganization.id}/sms/numbers`)
            .get();
        },

        buyOrganizationNumber(obj) {
          return SessionService.restangularGmsApi
            .all(
              `organization/${appState.currentOrganization.id}/sms/available-numbers`
            )
            .post(obj);
        },
        //End SMS numbers

        //Get campaign chapter
        getCampaignChart(interval) {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/chapter/chart`
            )
            .get(interval);
        },

        getCampaignChapter(interval) {
          return SessionService.restangularGmsApi
            .one(`organization/${appState.currentOrganization.id}/chapter`)
            .get(interval);
        },
        //End campaign chapter

        //Begin URLs
        getOrganizationShortUrls() {
          return SessionService.restangularGmsApi
            .one(`organization/${appState.currentOrganization.id}/shortening`)
            .get();
        },

        postOrganizationShortUrl(url) {
          return SessionService.restangularGmsApi
            .all(`organization/${appState.currentOrganization.id}/shortening`)
            .post({ original_url: url });
        },
        //End URLS

        getOrganizationEvents() {
          return SessionService.restangularGmsApi
            .one(`organization/${appState.currentOrganization.id}/events`)
            .get();
        },

        getOrganizationGoalMeters() {
          return SessionService.restangularGmsApi
            .one(
              `organization/${appState.currentOrganization.id}/dashboard/goal_meters`
            )
            .get();
        },

        getVirtuousToken() {
          return SessionService.restangularGmsApi
            .one(
              `virtuous/organization/${appState.currentOrganization.id}/token`
            )
            .get();
        },

        postVirtuousToken(refresh, access) {
          return SessionService.restangularGmsApi
            .one(`virtuous/organization/${appState.currentOrganization.id}`)
            .post('token', { refresh_token: refresh, access_token: access });
        },

        deleteVirtuousToken() {
          return SessionService.restangularGmsApi
            .one(`virtuous/organization/${appState.currentOrganization.id}`)
            .customDELETE('token');
        },
      };
    }
  );
