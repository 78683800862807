// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('newEmbedModal', function (Modals) {
  Modals.registerModal('NewEmbedModal', function (resolve) {
    return {
      size: 'md',
      windowClass: 'advocate-modal',
      templateUrl: '/scripts/embed/new-embed-modal.html',
      controllerAs: '$ctrl',
      resolve,
      controller($uibModalInstance, bases, websiteService, $location, type) {
        this.bases = bases;
        this.type = type;
        const clearBases = () => {
          for (let base of Array.from(this.bases)) {
            base.selected = false;
          }
        };
        const validate = (embed) => {
          let valid = true;
          this.invalid = [];
          if (!this.embed.title || this.embed.title === '') {
            valid = false;
            this.invalid.push('title');
          }
          if (!this.embed.template) {
            valid = false;
            this.invalid.push('template');
          }
          return valid;
        };
        this.cancel = () => $uibModalInstance.dismiss('cancel');
        this.selectTemplate = (template) => {
          this.embed.template = template.id;
          clearBases();
          return (template.selected = true);
        };
        this.save = () => {
          this.loading = true;
          if (!validate(this.embed)) {
            return (this.loading = false);
          } else {
            return websiteService
              .createEmbedInstance({
                name: this.embed.title,
                parent_definition_id: this.embed.template,
              })
              .then((data) => {
                $location.path(`embed/edit/${data.result.embed_instance.id}`);
                $uibModalInstance.close();
                return (this.loading = false);
              });
          }
        };
        this.embed = {
          title: '',
          template: '',
        };
        if (this.bases.length === 1) {
          this.embed.template = this.bases[0].id;
          this.bases[0].selected = true;
        }
      },
    };
  });
  return { restrict: 'A' };
});
