// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import './summarySection.html';
angular.module('idonate.gms.donations').directive('summarySection', () => ({
  templateUrl: '/scripts/donations/summarySection.html',
  controller(
    $scope,
    $element,
    $timeout,
    $filter,
    $window,
    organizationService,
    DashboardAPI,
    transactionService,
    $rootScope
  ) {
    $scope.currentOrganization = organizationService.getCurrentOrganization();
    $scope.utcToggle = false;
    $scope.activeChart = 'total';

    const updateSummaries = (data) => ({
      Total: Math.round(data.result.aggregate.total.sum),
      TotalPercent: Math.round(data.result.aggregate_delta.total.sum * 100),
      Count: data.result.aggregate.total.count,
      Average: data.result.aggregate.total.average,
      AveragePercent: Math.round(
        data.result.aggregate_delta.total.average * 100
      ),
      range: {
        low: data.result.aggregate.total.minimum,
        high: data.result.aggregate.total.maximum,
      },
    });
    const generateSummary = function () {
      const organizationID = $scope.currentOrganization.id;
      const start_date = $scope.current.start.toISOString();
      const end_date = $scope.current.end.toISOString();
      const previous_start_date = $scope.previous.start.toISOString();
      const previous_end_date = $scope.previous.end.toISOString();

      if ($scope.utcToggle) {
        console.log('UTC MODE');
      }

      const obj = {
        organization_id: organizationID,
        //campaign_id:          (if ($scope.selectedCampaign?) then $scope.selectedCampaign.id else null)
        start_date,
        end_date,
        previous_start_date,
        previous_end_date,
        transaction_status: 'complete',
        use_finalized: true,
        timezone: $scope.utcToggle ? 'UTC' : moment.tz.guess(),
      };

      const dateDiff = $scope.current.end.diff($scope.current.start, 'days');
      let dateFmt = 'MM-DD-YYYY';

      if (dateDiff > 45) {
        obj['plot_by'] = 'month';
        dateFmt = "MMM 'YY";
      } else {
        obj['plot_by'] = 'day';
      }

      DashboardAPI.getSummary(organizationID, obj).then(
        (response) => ($scope.visits = response),
        () => {}
      );

      return transactionService
        .getTransactionReportSummary(obj)
        .then(function (data) {
          $scope.All = updateSummaries(data);
          $scope.donationChart = angular.extend({}, data.result.chart);
          $scope.donationChart.series.cash.data['fill'] = false;
          $scope.donationChart.series.noncash.data['fill'] = false;
          $scope.donationChart.series.average.data['fill'] = false;
          $scope.donationChart.data = [
            $scope.donationChart.series.cash.data,
            $scope.donationChart.series.noncash.data,
            $scope.donationChart.series.average.data,
          ];
          $scope.donationChart.labels = [];
          angular.forEach($scope.donationChart.categories, (val, key) =>
            $scope.donationChart.labels.push(moment(val).format(dateFmt))
          );

          $scope.donationChart.types = ['Cash', 'Noncash', 'Average'];
          $scope.donationChart.colors = ['#F16B82', '#F6D879', '#16ABD3'];
          $scope.cashChart = angular.extend({}, $scope.donationChart);
          $scope.cashChart.data = [$scope.cashChart.series.cash.data];
          $scope.cashChart.types = ['Cash'];
          $scope.cashChart.colors = ['#F16B82'];
          $scope.noncashChart = angular.extend({}, $scope.donationChart);
          $scope.noncashChart.data = [$scope.noncashChart.series.noncash.data];
          $scope.noncashChart.types = ['Noncash'];
          return ($scope.noncashChart.colors = ['#F6D879']);
        });
    };

    $window.addCommas = (value) =>
      $filter('currency')(value, $rootScope.currencyCode, '0');

    $scope.setChart = function (type) {
      if (type === 'total') {
        $scope.chart.data = [
          $scope.chart.series.cash.data,
          $scope.chart.series.noncash.data,
          $scope.chart.series.average.data,
        ];
        $scope.chart.types = ['Cash', 'Noncash', 'Average'];
        $scope.chart.colors = ['#F16B82', '#F6D879', '#16ABD3'];
      } else if (type === 'cash') {
        $scope.chart.data = [$scope.chart.series.cash.data];
        $scope.chart.types = ['Cash'];
        $scope.chart.colors = ['#F16B82'];
      } else if (type === 'noncash') {
        $scope.chart.data = [$scope.chart.series.noncash.data];
        $scope.chart.types = ['Noncash'];
        $scope.chart.colors = ['#F6D879'];
      }
      //set the active chart
      return ($scope.activeChart = type);
    };

    $scope.reloadSummary = function () {
      $scope.utcToggle = !$scope.utcToggle;
      if ($scope.current) {
        return generateSummary();
      }
    };

    $scope.intervalCallback = function (current, previous) {
      $scope.current = current;
      $scope.previous = previous;
      generateSummary();
    };

    return ($scope.radioRowOptions = [
      {
        selectors: [
          {
            name: 'total',
            title: 'Total Donations',
            active: true,
          },
          {
            name: 'cash',
            title: 'Cash Donations',
          },
          {
            name: 'noncash',
            title: 'Noncash Donations',
          },
        ],
        onSelect: $scope.setChart,
      },
      {
        selectors: [
          {
            name: 'utc',
            title: 'UTC',
            perms: ['SuperAdmin'],
          },
        ],
        onSelect: $scope.reloadSummary,
      },
    ]);
  },
}));
