angular.module('idonate.gms').component('fileUploader', {
  template: `
    <div class="form-group">
      <label for="file">{{$ctrl.label || 'Upload donation file'}}</label>
      <div class="file-uploader"
            ng-class="{'file-present': $ctrl.model > 0}"
            ngf-drop
            ng-model="$ctrl.model"
            ngf-drag-over-class="dragover"
            ngf-multiple="false"
            ngf-accept="'.csv'">
        <a href=""
            ngf-select
            ngf-accept="'.csv'"
            ng-model="$ctrl.model">
          <i class="icon-photos-2"></i>
          Drop a csv file or click to
          <span ng-if="$ctrl.model">
            replace
          </span>
          <span ng-if="!$ctrl.model">
            upload
          </span>
          <br>
          <span>{{$ctrl.model.name}}</span>
        </a>
      </div>
    </div>`,
  controller: class {
    // @ngInject
    constructor() {}
  },
  bindings: {
    label: '@',
    model: '=',
    fileType: '@?',
  },
});
