// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const WebsiteSection = function () {
  const WebsiteSectionCtrl = function ($window) {
    const ctrl = this;

    const init = function () {
      ctrl.intervalCallback = intervalCallback;
      ctrl.logout = logout;
      ctrl.isAuthorized = false;

      return prepare();
    };

    var prepare = function () {
      // Chart Setup
      ctrl.chart = {};
      ctrl.chart.data = [[]];
      ctrl.chart.options = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                maxTicksLimit: 10,
                beginAtZero: true,
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                zeroLineColor: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
        tooltips: {
          titleFontSize: 14,
          bodyFontSize: 14,
          bodySpacing: 5,
        },
      };
      ctrl.chart.types = ['visits'];
      ctrl.chart.colors = ['#F16B82'];

      // Data Setup
      ctrl.data = {};
      ctrl.data.sessionDuration = {};
      ctrl.data.devices = {};
      ctrl.data.cities = {};
      ctrl.data.countries = {};

      gapi.analytics.ready(function () {
        gapi.analytics.auth.on('signIn', () => (ctrl.isAuthorized = true));
        gapi.analytics.auth.on('signOut', () => (ctrl.isAuthorized = false));

        if (!gapi.analytics.auth.isAuthorized()) {
          const response = gapi.analytics.auth.getAuthResponse();
          if (typeof response !== 'undefined') {
            return $window.location.reload();
          } else {
            return gapi.analytics.auth.authorize(
              {
                container: 'embed-api-auth-container',
                clientid: window.iDonateConfig.analyticsId,
                immediate: false,
              },
              function (authResult) {
                if (authResult && !authResult.error) {
                  return (ctrl.isAuthorized =
                    gapi.analytics.auth.isAuthorized());
                } else {
                  return (ctrl.isAuthorized = false);
                }
              }
            );
          }
        } else {
          return (ctrl.isAuthorized = true);
        }
      });

      // GA View Selector
      const viewSelector = new gapi.analytics.ViewSelector({
        container: 'view-selector',
      });

      viewSelector.on('change', function (ids) {
        ctrl.chart.ids = ids;
        changeChartReport();
        changeSessionReport();
        changeCityReport();
        changeDeviceReport();
        return changeCountryReport();
      });

      return viewSelector.execute();
    };

    var changeChartReport = function () {
      if (ctrl.chart.ids) {
        const chartReport = new gapi.analytics.report.Data({});

        chartReport.set({
          query: {
            ids: ctrl.chart.ids,
            metrics: 'ga:sessions',
            dimensions: 'ga:date',
            'start-date': ctrl.chart.start ? ctrl.chart.start : '21daysAgo',
            'end-date': ctrl.chart.end ? ctrl.chart.end : 'yesterday',
          },
        });
        chartReport.execute();

        return chartReport.on('success', function (response) {
          const data = [];
          ctrl.chart.data[0] = [];
          ctrl.chart.labels = [];
          for (let item of Array.from(response.rows)) {
            data.push(parseInt(item[1]));
            const year = item[0].substr(0, 4);
            const month = item[0].substr(4, 2);
            const day = item[0].substr(6, 2);
            const date = `${month}-${day}-${year}`;
            ctrl.chart.labels.push(date);
          }
          return (ctrl.chart.data[0] = data);
        });
      }
    };

    var changeSessionReport = function () {
      if (ctrl.chart.ids) {
        const sessionReport = new gapi.analytics.report.Data({});

        ctrl.data.sessionDuration.minutes = 0;
        ctrl.data.sessionDuration.seconds = 0;
        ctrl.data.new = 0;

        sessionReport.set({
          query: {
            ids: ctrl.chart.ids,
            metrics: 'ga:percentNewSessions,ga:avgSessionDuration',
            'start-date': ctrl.chart.start ? ctrl.chart.start : '21daysAgo',
            'end-date': ctrl.chart.end ? ctrl.chart.end : 'yesterday',
          },
        });
        sessionReport.execute();

        return sessionReport.on('success', function (response) {
          if (response.rows && response.rows[0]) {
            ctrl.data.sessionDuration.minutes = Math.floor(
              response.rows[0][1] / 60
            );
            ctrl.data.sessionDuration.seconds =
              response.rows[0][1] - ctrl.data.sessionDuration.minutes * 60;
            return (ctrl.data.new = response.rows[0][0]);
          }
        });
      }
    };

    var changeCityReport = function () {
      if (ctrl.chart.ids) {
        const cityReport = new gapi.analytics.report.Data({});

        cityReport.set({
          query: {
            ids: ctrl.chart.ids,
            metrics: 'ga:sessions',
            dimensions: 'ga:city',
            'start-date': ctrl.chart.start ? ctrl.chart.start : '21daysAgo',
            'end-date': ctrl.chart.end ? ctrl.chart.end : 'yesterday',
            sort: '-ga:sessions,ga:city',
            'max-results': 5,
          },
        });
        cityReport.execute();

        return cityReport.on('success', function (response) {
          if (response.rows) {
            ctrl.data.cities.total = parseInt(
              response.totalsForAllResults['ga:sessions']
            );
            ctrl.data.cities.data = response.rows;
            return Array.from(ctrl.data.cities.data).map(
              (item) => (item[1] = parseInt(item[1]) / ctrl.data.cities.total)
            );
          }
        });
      }
    };

    var changeDeviceReport = function () {
      if (ctrl.chart.ids) {
        const deviceReport = new gapi.analytics.report.Data({});

        deviceReport.set({
          query: {
            ids: ctrl.chart.ids,
            metrics: 'ga:sessions',
            dimensions: 'ga:deviceCategory',
            'start-date': ctrl.chart.start ? ctrl.chart.start : '21daysAgo',
            'end-date': ctrl.chart.end ? ctrl.chart.end : 'yesterday',
          },
        });
        deviceReport.execute();

        return deviceReport.on('success', function (response) {
          if (response.rows) {
            ctrl.data.devices.total = parseInt(
              response.totalsForAllResults['ga:sessions']
            );
            ctrl.data.devices.data = response.rows;
            return Array.from(ctrl.data.devices.data).map(
              (item) => (item[1] = parseInt(item[1]) / ctrl.data.devices.total)
            );
          }
        });
      }
    };

    var changeCountryReport = function () {
      if (ctrl.chart.ids) {
        const countryReport = new gapi.analytics.report.Data({});

        countryReport.set({
          query: {
            ids: ctrl.chart.ids,
            metrics: 'ga:sessions',
            dimensions: 'ga:country',
            'start-date': ctrl.chart.start ? ctrl.chart.start : '21daysAgo',
            'end-date': ctrl.chart.end ? ctrl.chart.end : 'yesterday',
            sort: '-ga:sessions,ga:country',
            'max-results': 5,
          },
        });
        countryReport.execute();

        return countryReport.on('success', function (response) {
          if (response.rows) {
            ctrl.data.countries.total = parseInt(
              response.totalsForAllResults['ga:sessions']
            );
            ctrl.data.countries.data = response.rows;
            return Array.from(ctrl.data.countries.data).map(
              (item) =>
                (item[1] = parseInt(item[1]) / ctrl.data.countries.total)
            );
          }
        });
      }
    };

    var intervalCallback = function (current, previous) {
      ctrl.chart.start = moment(current.start, 'YYYYMMDD').format('YYYY-MM-DD');
      ctrl.chart.end = moment(current.end, 'YYYYMMDD').format('YYYY-MM-DD');
      changeChartReport();
      changeSessionReport();
      changeCityReport();
      changeDeviceReport();
      return changeCountryReport();
    };

    var logout = function () {
      ctrl.isAuthorized = false;
      return gapi.analytics.auth.signOut();
    };

    init();
  };

  return {
    templateUrl: '/scripts/website/website-section.html',
    restrict: 'E',
    scope: {},
    controllerAs: 'ctrl',
    bindToController: true,
    replace: true,
    controller: WebsiteSectionCtrl,
  };
};

angular
  .module('idonate.gms.website')
  .directive('websiteSection', WebsiteSection);
