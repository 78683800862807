// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('idonate.gms').filter(
  'typeToGlyph',
  () =>
    function (input) {
      let icon;
      return (icon = (() => {
        switch (false) {
          case input !== `pledge_${input.slice(7)}`:
            return 'idonate-pledge';
          case input !== 'auto':
            return 'idonate-car';
          case input !== 'elec':
            return 'idonate-ipad';
          case input !== 'cash':
            return 'idonate-money';
          case input !== 'paypal':
            return 'idonate-paypal';
          case input !== 'giftcard':
            return 'idonate-credit-card';
          case input !== 'atv':
            return 'idonate-atv';
          case input !== 'boat':
            return 'idonate-boat';
          case input !== 'rv':
            return 'idonate-rv';
          case input !== 'motorcycle':
            return 'idonate-motorcycle';
          case input !== 'other':
            return 'idonate-gift';
          case input !== 'stock':
            return 'idonate-stocks';
          default:
            return 'idonate-gift';
        }
      })());
    }
);
