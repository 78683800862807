import './campaigns.html';
import './campaign-item/confirm-disable-campaign-modal.html';
import './campaign-item/campaign-new-item-modal.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export class CampaignsCtrl {
  static templateUrl = '/scripts/campaign/campaigns.html';
  /* @ngInject */
  constructor(
    $scope,
    $timeout,
    CampaignService,
    Modals,
    organizationCampaigns,
    organizationAttributes,
    $location,
    notyService,
    $uibModal,
    $route
  ) {
    let c;

    $scope.organizationAttributes = organizationAttributes.result.attributes;
    $scope.organizationCampaigns = organizationCampaigns.campaigns;

    // by default, hide Disabled campaigns
    $scope.organizationCampaigns = (() => {
      const result = [];
      for (c of Array.from($scope.organizationCampaigns)) {
        if (c.is_active) {
          result.push(c);
        }
      }
      return result;
    })();

    $scope.showDisabledCampaigns = false;

    $scope.setCurrent = (id) =>
      ($scope.current = _.find($scope.organizationCampaigns, { id }));

    $scope.editCampaign = function (campaignId) {
      $location.path(`/campaign/${campaignId}/edit`);
    };

    $scope.detailCampaign = function (campaignId) {
      $location.path(`/campaign/${campaignId}`);
    };

    $scope.newCampaign = function () {
      Modals.open('NewCampaignModal').then(angular.noop, angular.noop);
    };

    $scope.tableOptions = {
      sortByColumn: 2,
      sortOrder: 'asc',
      outsideSearchId: 'outside-search',
      clickableRowAction: $scope.detailCampaign,
    };

    $scope.duplicateCampaign = (campaignId) => {
      CampaignService.duplicateCampaign(campaignId).then(function (data) {
        notyService.success('Campaign has been duplicated');
        return $location.path(`/campaign/${data.new_campaign_id}`);
      });
    };

    const updateCampaignAttribute = (campaignId, attributeValue) =>
      angular.forEach($scope.organizationCampaigns, function (campaign, key) {
        if (campaign.id === campaignId) {
          return ($scope.organizationCampaigns[key][
            Object.keys(attributeValue)[0]
          ] = attributeValue[Object.keys(attributeValue)[0]]);
        }
      });

    $scope.enableDisableCampaign = function (campaignId) {
      const _campaign = _.find($scope.organizationCampaigns, {
        id: campaignId,
      });

      if (!_campaign) {
        return;
      }

      const status = !_campaign.is_active;
      // check if the campaign has any active tools so we can present a warning to the user before disabling the campaign
      $scope.hideDisableWarning = true;

      return CampaignService.getCampaignActiveTools(_campaign.id).then(
        function (result) {
          let activeTools = result;

          angular.forEach(activeTools, function (tool) {
            if ($scope.hideDisableWarning && Object.keys(tool).length > 0) {
              return ($scope.hideDisableWarning = false);
            }
          });

          if (_campaign && status === false) {
            // if deactivate
            if (!$scope.hideDisableWarning) {
              return $uibModal
                .open({
                  size: 'md',
                  scope: $scope,
                  windowClass: 'advocate-modal',
                  templateUrl:
                    '/scripts/campaign/campaign-item/confirm-disable-campaign-modal.html',
                  controller($scope, $uibModalInstance) {
                    $scope.modalHeader = 'Warning: Campaign has active tools';
                    return ($scope.cancel = () =>
                      $uibModalInstance.dismiss('cancel'));
                  },
                })
                .result.then(angular.noop, angular.noop);
            } else {
              // allow campaign to be disabled if there are no active tools
              return updateCampaignStatus(_campaign, status, function () {
                notyService.success('Campaign has been disabled');
                return $location.path('/campaigns');
              });
            }
          } else {
            // if active
            return updateCampaignStatus(_campaign, status, () =>
              notyService.success('Campaign has been enabled')
            );
          }
        }
      );
    };

    var updateCampaignStatus = function (_campaign, status, cb) {
      const objToPost = {
        id: _campaign.id,
        title: _campaign.title,
        is_active: status,
      };
      return CampaignService.editCampaign(objToPost).then(function () {
        if (cb) {
          cb();
        }
        updateCampaignAttribute(_campaign.id, { is_active: status });
        return $scope.$broadcast('reloadTable');
      });
    };

    $scope.toUtc = function (date) {
      date = moment.utc(date);
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      }
      return '-';
    };

    $scope.$watch('showDisabledCampaigns', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          if (a) {
            $scope.organizationCampaigns = organizationCampaigns.campaigns;
          } else {
            $scope.organizationCampaigns = (() => {
              const result1 = [];
              for (c of Array.from($scope.organizationCampaigns)) {
                if (c.is_active) {
                  result1.push(c);
                }
              }
              return result1;
            })();
          }
          return $scope.$broadcast('reloadTable');
        }
      })
    );

    // opening up the new campaign modal
    // if you are creating a campaign from
    // a different page
    if ($route.current.params.new) {
      return $scope.newCampaign();
    }
  }
}
