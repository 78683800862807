import getCurrencySymbol from 'currency-symbol-map';
import { GlobalService } from './base/global.service';

/**
 * utility func to flatten org tree into tree-sorted list with level decorations
 */
const flattenOrgTree = function (orgs) {
  orgs = _.map(orgs, function (item) {
    item.level = 0;
    return item;
  });

  const childFlatten = function (item) {
    let children = _.map(item.children, function (child) {
      child.level = item.level + 1;
      return child;
    });

    delete item.children;
    children = _.flatMapDeep(children, childFlatten);
    return [item, children];
  };

  orgs = _.flatMapDeep(orgs, childFlatten);
  return orgs;
};

/**
 * begin actual appState factory
 */
angular
  .module('idonate.gms')
  .factory(
    'appState',
    function (
      SessionService,
      GlobalService,
      $route,
      Platform,
      // NOTE: avoid $rootScope for new features, it is only present here to retain compatibility with
      //       some really ugly legacy code.
      $rootScope,
      $location
    ) {
      return {
        isAuthenticated: false,
        currentUser: null,
        currentOrganization: null,
        currentPlatform: null,

        // org list is loaded once and reformatted for various usages
        organizationList: null,
        organizationTree: null,
        organizationTreeFlat: null,
        organizationRoles: null,

        organizationDataCache: {},

        async initializeSessionAsync(token) {
          this.currentPlatform = await Platform.getConfig();

          SessionService.setNewSessionKey(token);
          this.isAuthenticated = true;

          // load all required data before returning from initialization.
          await Promise.all([
            this.loadUser(),
            this.loadAvailableOrganizations().then(() => {
              if (
                localStorage.lastOrganizationId &&
                this.organizationList.find(
                  (el) => el.id === localStorage.lastOrganizationId
                )
              ) {
                // switch to last org if it's still valid.
                return this.switchOrganization(localStorage.lastOrganizationId);
              } else {
                // otherwise load the first org available.
                if (this.organizationList && this.organizationList.length) {
                  return this.switchOrganization(this.organizationList[0].id);
                } else {
                  // no orgs available, throw the old error from actualOrganizationService:
                  GlobalService.forceLogOutError(
                    'Error: No Organizations',
                    '<p>The user you are attempting to login with is not attached to any organizations.</p>' +
                      '<p>Please contact ' +
                      Platform.getText('name') +
                      ' at (877) 410-4431 for more information.</p>'
                  );

                  throw new Error('No Organization to switch to!');
                }
              }
            }),
          ]);
        },

        async initializeUiPluginAsync() {
          this.currentPlatform = await Platform.getConfig();

          return new Promise((resolve, reject) => {
            // define message handler in-line to give access to resolve/reject
            const handlePluginMessage = (event) => {
              let data;

              try {
                data = JSON.parse(event.data);
              } catch (e) {
                // ignore bad messages silently.
                return;
              }

              switch (data.method) {
                case 'setConfiguration':
                  // apiAuthorization comes as a complete Authorization header but the rest of the code just wants the token:
                  const apiToken = data.params.apiAuthorization.replace(
                    'Bearer ',
                    ''
                  );
                  const organizationId = data.params.organizationId;

                  localStorage.lastOrganizationId = organizationId;
                  this.initializeSessionAsync(apiToken)
                    .then(resolve)
                    .catch(reject);
                  break;
              }
            };

            window.addEventListener('message', handlePluginMessage);
            parent.postMessage(
              JSON.stringify({
                source: 'legacy-ui',
                status: 'ready',
              }),
              '*'
            );
          });
        },

        async loadUser() {
          return SessionService.getCurrentUserInfo()
            .then((result) => {
              const contact = result.contact || {};

              this.currentUser = {
                id: result.id,
                email: result.email,
                displayName: `${contact.firstname} ${contact.lastname}`,
                contact: contact,
              };

              return true;
            })
            .catch((err) => {
              this.currentUser = null;

              // user is authenticated but ran into an error while trying to fetch user info
              GlobalService.forceLogOutError(
                'Unknown Error',
                "<p>An unknown error has occurred, please Log Out and try again.</p><p>If the error persists, please contact {{Platform.getText('name')}} for more information.</p>"
              );

              throw err;
            });
        },

        async loadAvailableOrganizations() {
          return SessionService.restangularGmsApi
            .all('organization')
            .customGET('', { tree: true })
            .then((data) => {
              const organizationTree = data.result.organizations;

              this.organizationTree = organizationTree;

              // TODO : we could actually build the flat tree at the same as the list above, I think.
              this.organizationTreeFlat = this.organizationList =
                flattenOrgTree(organizationTree);
              this.organizationRoles = {};

              for (const entry of this.organizationTreeFlat) {
                this.organizationRoles[entry.id] = entry.role;
              }

              return true;
            });
        },

        async loadOrganization(organizationId) {
          if (!this.organizationDataCache[organizationId]) {
            this.organizationDataCache[organizationId] =
              await SessionService.restangularGmsApi
                .all(`organization/${organizationId}`)
                .customGET()
                .then((result) => result.result);
          }

          return this.organizationDataCache[organizationId];
        },

        async switchOrganization(organizationId) {
          this.currentOrganization =
            await this.loadOrganization(organizationId);

          // store selected organization id in the browser so it can be pre-selected when the user comes back.
          localStorage.lastOrganizationId = organizationId;

          // TODO WTF: convert references for $rootScope.currencyCode to use appState instead!
          $rootScope.currencyCode = getCurrencySymbol(
            this.currentOrganization.currency_code || 'USD'
          );

          window.pendo.updateOptions(this.resolvePendoConfig());

          // all non-auth routes rely on the current org, reload the current route for new info.
          $route.reload();
        },

        /**
         * Auth0 id passed in from the authentication path.
         * @param {string} userId
         */
        setUserId(userId) {
          // hard assume we have a user
          this.currentUser.id = userId;
        },

        resolvePendoConfig() {
          let visitorId = this.currentUser.id;

          if (this.currentPlatform.environment.env !== 'production') {
            visitorId = `${this.currentPlatform.environment.env}|${visitorId}`;
          }

          return {
            visitor: {
              id: visitorId,
              email: this.currentUser.contact.email,
              full_name:
                this.currentUser.displayName.trim() ||
                this.currentUser.contact.email,
            },
            account: {
              id: this.currentOrganization.id,
              name: this.currentOrganization.name,
            },
          };
        },
      };
    }
  )
  .run(function (appState, $rootScope) {
    // mount appState globally on $rootScope for templates access
    // - this is a terrible code smell but replaces multiple other globals.
    // TODO : actually might be able to get rid of this with a little more work!
    $rootScope.appState = appState;
  });
