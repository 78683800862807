// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms')
  .controller(
    'OrganizationMobileFundsCtrl',
    function (
      $scope,
      organizationDesignations,
      organizationService,
      designationService,
      $timeout,
      organizationCampaigns,
      organizationAttributes,
      $location
    ) {
      let c;
      $scope.organizationDesignations =
        organizationDesignations.result.designations;
      $scope.currentOrganization = organizationService.getCurrentOrganization();
      $scope.organizationAttributes = organizationAttributes.result.attributes;
      $scope.loading = false;
      $scope.showDisabledItems = false;
      $scope.addingDesignation = false;
      $scope.reOrderInProgress = false;
      $scope.newMobileDesignation = { selected: null };
      $scope.parseInt = parseInt;
      //Hide Archived items by default
      $scope.designationSelection = (() => {
        const result = [];
        for (c of Array.from($scope.organizationDesignations)) {
          if (c.mobile_weight === null) {
            result.push(c);
          }
        }
        return result;
      })();
      $scope.organizationDesignations = (() => {
        const result1 = [];
        for (c of Array.from($scope.organizationDesignations)) {
          if (c.is_active && typeof c.mobile_weight === 'number') {
            result1.push(c);
          }
        }
        return result1;
      })();

      // Get Latest Designations + Weight
      const getFreshDesignations = function () {
        $scope.organizationDesignations = [];
        return designationService
          .getOrganizationDesignations()
          .then(function (res) {
            // getting the updated order
            let c;
            // reorder the designation list by weight
            $scope.organizationDesignations =
              res.result.designations.sort(sortWeight);
            $scope.designationSelection = (() => {
              const result2 = [];
              for (c of Array.from($scope.organizationDesignations)) {
                if (c.mobile_weight === null) {
                  result2.push(c);
                }
              }
              return result2;
            })();
            $scope.organizationDesignations = (() => {
              const result3 = [];
              for (c of Array.from($scope.organizationDesignations)) {
                if (typeof c.mobile_weight === 'number') {
                  result3.push(c);
                }
              }
              return result3;
            })();
            if (!$scope.showDisabledItems) {
              $scope.organizationDesignations = (() => {
                const result4 = [];
                for (c of Array.from($scope.organizationDesignations)) {
                  if (c.is_active) {
                    result4.push(c);
                  }
                }
                return result4;
              })();
            }
            return $scope.$broadcast('reloadTable');
          });
      };

      const updateDesignationAttribute = (designationId, attributeValue) =>
        angular.forEach(
          $scope.organizationDesignations,
          function (designation, key) {
            if (designation.id === designationId) {
              return ($scope.organizationDesignations[key][
                Object.keys(attributeValue)[0]
              ] = attributeValue[Object.keys(attributeValue)[0]]);
            }
          }
        );
      $scope.editDesignation = function (designationId) {
        $location.path(`organization/designated-fund/${designationId}`);
      };

      $scope.disableDesignation = function (designation) {
        // setting it to null should remove the mobile designation from the list
        const _designation = angular.extend({}, designation, {
          mobile_weight: -1,
        });
        return designationService
          .editOrganizationDesignations(_designation)
          .then(
            () => getFreshDesignations(),
            function (response) {}
          );
      };

      let table = undefined;

      const buildTable = function (rb) {
        if (rb && table) {
          table.clear().destroy();
        }

        return $timeout(function () {
          table = $('#designation-table').DataTable({
            dom: "<'row'<'col-6'T><'col-6'f>r><'panel panel-table table-responsive't><'row'<'col-3'l><'col-6'p>>",
            rowReorder: { selector: 'td.movable-column' },
            order: [[0, 'asc']],
            columnDefs: [
              {
                orderable: false,
                targets: '_all',
              },
            ],
            bAutoWidth: false,
            oLanguage: {
              sLengthMenu: 'Show _MENU_ per page',
              oPaginate: {
                sNext: '<i class="icon-right"></i>',
                sPrevious: '<i class="icon-left"></i>',
              },
            },
          });

          table.on('row-reorder', function (e, diff, edit) {
            const _rowChangedID = edit.triggerRow.id();
            let _fromPosition = null;
            let _toPosition = null;

            let _diffMatch = false;

            return _.forEach(diff, function (item) {
              if (item.node.id === _rowChangedID && !_diffMatch) {
                _diffMatch = true;
                const _currentPage = table.page.info().page;
                const _displayPerPage = table.page.info().length;

                const _calculatedPosition = _currentPage * _displayPerPage;

                _fromPosition = _calculatedPosition + item.oldPosition;
                _toPosition = _calculatedPosition + item.newPosition;

                if (
                  _fromPosition !== null &&
                  _toPosition !== null &&
                  $scope.reOrderInProgress === false
                ) {
                  $scope.reOrderInProgress = true;

                  // disable the row reordering during server side process
                  table.rowReorder.disable();

                  const _newOrder = {
                    organization_id: $scope.currentOrganization.id,
                    id: _rowChangedID,
                    fromPosition: _fromPosition,
                    toPosition: _toPosition,
                    mobile: true,
                  };

                  return designationService
                    .reorderOrganizationDesignation(_newOrder)
                    .then(
                      function () {
                        getFreshDesignations();
                        $scope.reOrderInProgress = false;
                        // re-enable reordering
                        return table.rowReorder.enable();
                      },
                      function (err) {
                        // on error reset the positions
                        getFreshDesignations();
                        $scope.reOrderInProgress = false;
                        return table.rowReorder.enable();
                      }
                    );
                }
              }
            });
          });

          return $('#outside-search').keyup(function () {
            return table.search($(this).val()).draw();
          });
        });
      };

      buildTable();

      $scope.addDesignation = function (designation) {
        $scope.addingDesignation = true;
        const _designation = angular.extend({}, designation, {
          mobile_weight: 0,
        });
        return designationService
          .editOrganizationDesignations(_designation)
          .then(
            function () {
              $scope.newMobileDesignation.selected = null;
              getFreshDesignations();
              return ($scope.addingDesignation = false);
            },

            function () {
              $scope.newMobileDesignation.selected = null;
              getFreshDesignations();
              return ($scope.addingDesignation = false);
            }
          );
      };

      // Sort the designation table by weight
      function sortWeight(a, b) {
        if (a.mobile_weight < b.mobile_weight) return -1;
        if (a.mobile_weight > b.mobile_weight) return 1;
        return 0;
      }

      // Find the designation from the list
      function getDesignationIndex(id) {
        let index = null;
        const organizationDesignations = (() => {
          const result1 = [];
          for (c of Array.from($scope.organizationDesignations)) {
            if (c.is_active && typeof c.mobile_weight === 'number') {
              result1.push(c);
            }
          }
          return result1;
        })();
        angular.forEach(organizationDesignations, (designation, key) => {
          console.log(designation, key);
          if (designation.id == id) {
            index = key;
          }
        });
        return index;
      }

      // Update weight of the designation, on blur/enter for weight input
      $scope.updateOrder = (evt) => {
        $scope.loading = true;
        const designation_id = evt.target.attributes['designation-id'].value;
        const newOrder = Number(evt.target.value);
        const currentOrder = getDesignationIndex(designation_id) + 1;

        if (!newOrder || newOrder < 0 || currentOrder === newOrder) return;

        const _newOrder = {
          organization_id: organizationService.getCurrentOrganization().id,
          id: designation_id,
          fromPosition: currentOrder - 1,
          toPosition: newOrder - 1,
          mobile: true,
        };

        designationService
          .reorderOrganizationDesignation(_newOrder)
          .then(function (res) {
            $scope.loading = false;
            getFreshDesignations();
          });
      };

      return $scope.$on('reloadTable', () => buildTable(true));
    }
  );
