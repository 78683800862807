// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').component('dashboardChannel', {
  templateUrl: '/scripts/dashboard/dashboard-channel.html',
  bindings: {
    dateRange: '<?',
  },
  controller($location, CampaignAPI, DashboardAPI) {
    const getPercent = function (first, second) {
      if (second !== 0) {
        return first / second;
      } else {
        return 0;
      }
    };
    this.onInit = function () {
      const query = {
        start_date: this.dateRange.current.start.toISOString(),
        end_date: this.dateRange.current.end.toISOString(),
        previous_start_date: this.dateRange.previous.start.toISOString(),
        previous_end_date: this.dateRange.previous.end.toISOString(),
        plot_by: 'day',
        timezone: moment.tz.guess(),
      };
      const dateDiff = this.dateRange.current.end.diff(
        this.dateRange.current.start,
        'days'
      );
      this.dateFmt = 'MM-DD-YYYY';
      if (dateDiff > 45) {
        query['plot_by'] = 'month';
        this.dateFmt = "MMM 'YY";
      }
      DashboardAPI.getChannelSummary(query)
        .then(this.setChannelSummary)
        .then(this.setChannelChart);
    };
    this.$onChanges = () => {
      return this.onInit();
    };
    this.view = (link) => $location.path(link).search({});
    this.setChannelSummary = (result) => {
      let val;
      this.analytics = result.tool_summary;
      let total = 0;
      for (var key in this.analytics) {
        val = this.analytics[key];
        total += val.total_amount;
      }
      for (key in this.analytics) {
        val = this.analytics[key];
        this.analytics[key].contribution = getPercent(val.total_amount, total);
      }
      return result;
    };
    this.setChannelChart = (result) => {
      this.chart = { total: angular.extend({}, result.chart) };
      this.chart.total.series.embed.data['fill'] = false;
      this.chart.total.series.p2p.data['fill'] = false;
      this.chart.total.series.sms.data['fill'] = false;
      this.chart.total.series.organization_event.data['fill'] = false;
      this.chart.total.series.page.data['fill'] = false;
      this.chart.total.data = [
        this.chart.total.series.embed.data,
        this.chart.total.series.p2p.data,
        this.chart.total.series.sms.data,
        this.chart.total.series.organization_event.data,
        this.chart.total.series.page.data,
      ];
      this.chart.total.labels = [];
      angular.forEach(this.chart.total.categories, (val, key) => {
        return this.chart.total.labels.push(moment(val).format(this.dateFmt));
      });
      this.chart.total.types = [
        'Total',
        'Embed',
        'Peer-to-Peer',
        'Text-to-Give',
        'Events',
        'Landing Pages',
      ];
      this.chart.total.colors = [
        '#F16B82',
        '#F6D879',
        '#16ABD3',
        '#000000',
        '#AA0000',
      ];
      //Embed
      this.chart.embed = angular.extend({}, this.chart.total);
      this.chart.embed.data = [this.chart.embed.series.embed.data];
      this.chart.embed.types = ['Embed'];
      this.chart.embed.colors = ['#F16B82'];
      //P2P
      this.chart.p2p = angular.extend({}, this.chart.total);
      this.chart.p2p.data = [this.chart.p2p.series.p2p.data];
      this.chart.p2p.types = ['Peer-to-Peer'];
      this.chart.p2p.colors = ['#F6D879'];
      //SMS
      this.chart.sms = angular.extend({}, this.chart.total);
      this.chart.sms.data = [this.chart.sms.series.sms.data];
      this.chart.sms.types = ['Text-to-Give'];
      this.chart.sms.colors = ['#16ABD3'];
      //Event
      this.chart.organization_event = angular.extend({}, this.chart.total);
      this.chart.organization_event.data = [
        this.chart.organization_event.series.organization_event.data,
      ];
      this.chart.organization_event.types = ['Events'];
      this.chart.organization_event.colors = ['#000000'];
      //Page
      this.chart.page = angular.extend({}, this.chart.total);
      this.chart.page.data = [this.chart.page.series.page.data];
      this.chart.page.types = ['Landing Pages'];
      return (this.chart.page.colors = ['#AA0000']);
    };
  },
});
