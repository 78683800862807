angular.module('idonate.gms').component('chapterIndicators', {
  template: `
      <sortable-table conf="tableOptions">
        <table class="table bootstrap-datatable">
          <thead>
            <tr>
              <th class="hidden"></th>
              <th>Chapter</th>
              <th>Total Giving</th>
              <th>Average Gift</th>
              <th>Recurring Total</th>
              <th>Recurring Average</th>
              <th>New Donors</th>
              <th>Visits</th>
              <th>Conversion</th>
            </tr>
          </thead>
          <tbody>
            <tr ng-repeat="chapter in $ctrl.chapterList">
              <td class="hidden">{{chapter.path}}</td>
              <td>{{chapter.name}}</td>
              <td>{{chapter.total_value || 0 | currency:$root.currencyCode:2}}</td>
              <td>{{chapter.avg_value || 0 | currency:$root.currencyCode:2}}</td>
              <td>{{chapter.recurring.total_value || 0 | currency:$root.currencyCode:2}}</td>
              <td>{{chapter.recurring.avg_value || 0 | currency:$root.currencyCode:2}}</td>
              <td>{{chapter.new_donor_count || 0}}</td>
              <td>{{chapter.total_sessions || 0}}</td>
              <td>{{$ctrl.percent(chapter.initial_count || 0, chapter.total_sessions) | percentage:0}}</td>
            </tr>
          </tbody>
        </table>
      </sortable-table>
    `,
  bindings: {
    dateRange: '<?',
  },
  controller(DashboardAPI, $scope) {
    this.chapterList = [];
    this.onInit = () =>
      this.dateRange &&
      DashboardAPI.getChapterIndicators({
        start_date: this.dateRange.current.start.toISOString(),
        end_date: this.dateRange.current.end.toISOString(),
        previous_start_date: this.dateRange.previous.start.toISOString(),
        previous_end_date: this.dateRange.previous.end.toISOString(),
      })
        .then((result) => (this.chapterList = result))
        .then(() => $scope.$broadcast('reloadTable'));
    this.$onChanges = () => this.onInit();
    this.percent = (val1, val2) => (val2 ? val1 / val2 : 0);
  },
});
