import './account-ems';
import './account';
import './webhooks';
import './cashoptions';
import './contacts';
import './customize-receipt';
import './designated-funds-item';
import './designated-funds-import';
import './designated-funds';
import './designation-cart';
import './designationService';
import './emailMarketingService';
import './chapter';
import './gateway-settings';
import './gmsiconpicker';
import './mobile-funds';
import './mobile-payment-configuration';
import './mobile-payment-configuration.html';
import './org-user-service';
import './organization';
import './organizationservice';
import './receipt-preview';
import './receipt-service';
import './users-item';
import './users';
import './contacts.html';
import './customize-receipt.html';
import './designated-funds-item.html';
import './designated-funds.html';
import './designation-cart.html';
import './gateway-settings.html';
import './mobile-funds.html';
import './organization.html';
import './users-item.html';
import './users.html';

import './event-management';
import './event-management.html';

import './peer-to-peer';
import './peer-to-peer.html';

import './anchor-sites';
import './anchor-sites.html';

import './integrations';
import './integrations.html';

import './reconciliation';
import './reconciliation.html';

import './chapter-dashboard/chapter-channels';
import './chapter-dashboard/chapter-indicators';
import './chapter-dashboard/chapter-hierarchy';
import './chapter-dashboard/chapter-chart';
