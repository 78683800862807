// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

angular.module('idonate.gms.website', []).config(($routeProvider) =>
  $routeProvider
    .when('/embeds', {
      templateUrl: '/scripts/website/embed.html',
      controller: 'OrganizationEmbedCtrl',
      menu: {
        style: 'icon-puzzle',
        name: 'Embeds',
        level: 1,
        position: 6,
        allowFor: 'allRoles',
        allowFeatureFor: 'embed_id',
      },
      reloadOnSearch: false,
    })
    .when('/goal-meters', {
      templateUrl: '/scripts/website/embed.html',
      controller: 'OrganizationEmbedCtrl',
      menu: {
        style: 'icon-target',
        name: 'Goal Meters',
        level: 1,
        position: 7,
        allowFor: 'allRoles',
        allowFeatureFor: 'embed_id',
      },
      reloadOnSearch: false,
    })
    .when('/goal-meters/edit/:embedId', {
      templateUrl: '/scripts/website/embed-item.html',
      controller: 'OrganizationEmbedIdCtrl',
      menu: {
        parent: 'Goal Meters',
        name: 'Goal Meter Builder',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'embed_id',
      },
      resolve: {
        embedData(websiteService, $route) {
          'ngInject';
          return websiteService.getEmbedInstance($route.current.params.embedId);
        },
        organizationEmbedInstances(websiteService) {
          'ngInject';
          return websiteService.getEmbedInstances();
        },
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
        organizationDesignationAttributes(GmsAPI) {
          'ngInject';
          return GmsAPI.get('designation_attributes').then(
            (result) => result.items
          );
        },
      },
    })
    .when('/embed/view/:embedId/:backToCampaign?', {
      templateUrl: '/scripts/website/embed-view.html',
      controller: 'EmbedViewCtrl',
      menu: {
        parent: 'Embeds',
        name: 'Embed Builder',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'embed_id',
      },
      resolve: {
        embedData(websiteService, $route) {
          'ngInject';
          return websiteService.getEmbedInstance($route.current.params.embedId);
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
      },
    })
    .when('/embed/edit/:embedId', {
      templateUrl: '/scripts/website/embed-item.html',
      controller: 'OrganizationEmbedIdCtrl',
      menu: {
        parent: 'Embeds',
        name: 'Embed Builder',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'embed_id',
      },
      resolve: {
        embedData(websiteService, $route) {
          'ngInject';
          return websiteService.getEmbedInstance($route.current.params.embedId);
        },
        organizationEmbedInstances(websiteService) {
          'ngInject';
          return websiteService.getEmbedInstances();
        },
        organizationDesignations(designationService, $route) {
          'ngInject';
          return designationService.getOrganizationDesignations(
            $route.current.params.embedId
          );
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
        organizationDesignationAttributes(GmsAPI) {
          'ngInject';
          return GmsAPI.get('designation_attributes').then(
            (result) => result.items
          );
        },
      },
    })
    .when('/pages', {
      templateUrl: '/scripts/website/pages.html',
      controller: 'PagesCtrl',
      menu: {
        style: 'icon-website',
        name: 'Landing Pages',
        level: 1,
        position: 8,
        allowFor: 'allRoles',
        allowFeatureFor: 'pages',
      },
      reloadOnSearch: false,
      resolve: {
        fullOrgData(organizationService) {
          'ngInject';
          return organizationService.loadFullOrganizationInformation();
        },
      },
    })
    .when('/pages/edit/:pageId', {
      templateUrl: '/scripts/website/pages-item.html',
      controller: 'OrganizationEditpageCtrl',
      menu: {
        parent: 'Landing Pages',
        name: 'Page',
        level: 3,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'pages',
      },
      resolve: {
        pageData(websiteService, $route) {
          'ngInject';
          return websiteService.getOrganizationPage(
            $route.current.params.pageId
          );
        },
        organizationEmbedInstances(websiteService) {
          'ngInject';
          return websiteService.getEmbedInstances();
        },
      },
    })
);
