import angular from 'angular';

import { RestAPI } from './rest.api';
import { DashboardAPI } from './dashboard.api';
import { UserMessagesAPI } from './user-messages.api';
import { CampaignAPI } from './campaign.api';
import { GmsAPI } from './gms.api';

angular
  .module('idonate.gms.api', ['restangular'])
  .service('RestAPI', RestAPI)
  .service('DashboardAPI', DashboardAPI)
  .service('UserMessagesAPI', UserMessagesAPI)
  .service('CampaignAPI', CampaignAPI)
  .service('GmsAPI', GmsAPI);
