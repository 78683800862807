// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const newP2PProgramButton = () => ({
  restrict: 'E',
  templateUrl: '/scripts/legacy-p2p/program-button.html',
  scope: true,
  controller($scope, p2pService, $location, Modals) {
    $scope.createNewProgramText = 'New P2P Program';

    return ($scope.createNewProgram = function () {
      $scope.createNewProgramText = 'CREATING...';
      $scope.createNewProgramDisable = true;
      return p2pService.getAllTemplates().then(function (templates) {
        const allAvailableTemplates = templates.result;
        if (allAvailableTemplates.length === 0) {
          $scope.createNewProgramText = 'NO TEMPLATES AVAILABLE';
          return ($scope.createNewProgramDisable = false);
        } else {
          const template_1 = allAvailableTemplates[0];
          return p2pService
            .createNewProgram({
              status: 'draft',
              template_id: template_1.id,
            })
            .then(function (data) {
              const { id } = data.result;
              if (id) {
                return $location.path(`legacy-p2p/program/edit/${id}`);
              }
            });
        }
      });
    });
  },
});
angular
  .module('idonate.gms.legacy-p2p')
  .directive('p2pProgramButton', newP2PProgramButton);
