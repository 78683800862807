// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
field:
  upload_path: string
  value: image
  name: string
  label: string (optional)
  description: string (optional)
  disabled: boolean (optional)
*/

class ImageField {
  /* @ngInject */
  constructor($scope, Upload, SessionService, organizationService) {
    this.$onInit = this.$onInit.bind(this);
    this._setUploadPath = this._setUploadPath.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this._inProgress = this._inProgress.bind(this);
    this._onSuccess = this._onSuccess.bind(this);
    this.upload = this.upload.bind(this);
    this.$scope = $scope;
    this.Upload = Upload;
    this.baseUrl = SessionService.restangularGmsApi.configuration.baseUrl;
    this.orgId = organizationService.getCurrentOrganization().id;
    this.SessionService = SessionService;
  }
  $onInit() {
    this.uploaderImage = this.field.value
      ? this._getImageUrl(this.field.value)
      : null;
    this._file = null;
    this.files = [];
    this.$scope.$watch('$ctrl.files', this.upload);
    return this._setUploadPath();
  }
  _getImageUrl(value) {
    return `${value.url || value}?ts=${new Date().getTime()}`;
  }
  _setUploadPath() {
    if (!this.field.upload_path) {
      this.field.upload_path = `/organization/${this.orgId}/images`;
      return (this.field._setFields = true);
    }
  }
  removeImage() {
    this.files = null;
    this._file = null;
    this.uploaderImage = null;
    return (this.field.value = '');
  }
  _inProgress(evt) {
    return (this.uploadProgress = parseInt((100.0 * evt.loaded) / evt.total));
  }
  _onSuccess(data) {
    this.uploadProgress = 0;
    this._file = null;
    if (!data.result.page) {
      this.uploaderImage = this._getImageUrl(data.result);
      this.field.value = data.result.url || data.result;
      return;
    }
    return Array.from(data.result.page.fieldsets).map((_fieldset) =>
      (() => {
        const result = [];
        for (let _field of Array.from(_fieldset.fields)) {
          if (_field.name === this.field.name) {
            this.uploaderImage = this._getImageUrl(_field.value);
            result.push((this.field.value = _field.value.url || _field.value));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })()
    );
  }
  upload(file) {
    // could probably replace this with a call to SessionService.uploadToGmsApi
    if (!file || (file && file.length === 0)) {
      return;
    }
    const uploadData = {
      url: `${this.baseUrl}${this.field.upload_path}`,
      data: {
        [this.field.name]: file,
      },

      // fileFormDataName should be ignored, but is not..
      // https://github.com/danialfarid/ng-file-upload/releases/tag/7.2.0
      fileFormDataName: this.field._setFields ? null : this.field.name,

      // API client headers needed for authentication
      headers: this.SessionService.getAuthHeaders(),
    };
    if (this.field._setFields) {
      uploadData.data.tag = this.field.name;
      uploadData.data.overwrite = 'true';
      uploadData.data.file = file;
    }

    return this.Upload.upload(uploadData)
      .progress(this._inProgress)
      .success(this._onSuccess);
  }
}

angular.module('idonate.gms').component('imageField', {
  templateUrl: '/scripts/input/image-field.html',
  bindings: {
    field: '=',
  },
  controller: ImageField,
});
