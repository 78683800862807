// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
angular.module('idonate.gms').component('dashboardMetric', {
  templateUrl: '/scripts/dashboard/dashboard-metric.html',
  bindings: {
    figure: '<?',
    figureC: '<?',
    figureCFull: '<?',
    figureP: '<?',
    label: '<?',
    percent: '<?',
    sublabelF: '<?',
    sublabelB: '<?',
    subfigure: '<?',
    subfigureC: '<?',
    tooltip: '<?',
    tooltipIcon: '<?',
    tooltipPosition: '<?',
  },
});
