export class UserService {
  static $inject = ['RestAPI'];

  constructor(RestAPI) {
    this.restAPI = RestAPI;
  }

  getUserInfo(id) {
    return this.restAPI.get(`user/${id}`);
  }

  postNewUser(obj) {
    return this.restAPI.post('user', obj);
  }

  updateUserContactCurrent(obj) {
    return this.restAPI.put(`user/current/contact`, obj);
  }

  postApi(id) {
    return this.restAPI.post(`user/${id}/api`);
  }

  getApi(id) {
    return this.restAPI.get(`user/${id}/api`);
  }
}
