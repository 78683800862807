angular.module('idonate.gms').directive('linkGenerator', function ($location) {
  return {
    restrict: 'E',
    template: `<a ng-href="/{{ctrl.go()}}" ng-bind-html="ctrl.text"></a>`,
    scope: {
      type: '@',
      id: '=',
      text: '=',
      class: '@',
    },
    bindToController: true,
    controllerAs: 'ctrl',
    controller($scope) {
      const ctrl = this;
      ctrl.go = () => {
        switch (ctrl.type) {
          case 'campaign':
            return `campaign/${ctrl.id}`;
            break;
          case 'designation':
            return `organization/designated-fund/${ctrl.id}`;
            break;
          case 'schedule':
            return `donations/recurring/${ctrl.id}`;
            break;
          case 'donor':
            return `donors/detail/${ctrl.id}`;
            break;
          case 'embed':
            return `embed/view/${ctrl.id}`;
            break;
          case 'donation':
          case 'transaction':
            return `donations/detail/${ctrl.id}`;
            break;
          case 'webhook':
            return `organization/webhooks/${ctrl.id}`;
            break;
        }
      };
    },
  };
});
