angular.module('idonate.gms').directive('gmsTabContainer', function () {
  let TabContainerCtrl;
  return {
    template: `\
      <div class="page-heading">
        <ol class="breadcrumb">
          <li ng-repeat="crumb in $ctrl.breadcrumbs">
            <a ng-href="/{{crumb.finalUrl}}">{{crumb.title}}</a>
          </li>
        </ol>
      </div>
      <div class="row">
        <nav class="tab-menu">
          <ul>
            <li ng-repeat="tab in $ctrl.tabs" class="menu-item"
                ng-class="{selected: tab.active}">
              <a ng-href="/{{tab.finalUrl}}">{{tab.title}}</a>
            </li>
          </ul>
        </nav>
      </div>
      <div gms-tab-content-slot></div>
    `,
    priority: 10,
    transclude: {
      tabs: 'tabs',
      content: 'content',
      urlBase: 'urlBase',
    },
    controllerAs: '$ctrl',
    compile(element, attrs, transclude) {
      return { pre(scope, elem, attr, ctrl) {} };
    },
    controller: (TabContainerCtrl = class TabContainerCtrl {
      /* @ngInject */
      constructor(
        $scope,
        $location,
        $transclude,
        $interpolate,
        $element,
        $compile
      ) {
        this._baseTransclusion = this._baseTransclusion.bind(this);
        this._tabsTransclusion = this._tabsTransclusion.bind(this);
        this._recurTabsTransclusion = this._recurTabsTransclusion.bind(this);
        this._contentTransclusion = this._contentTransclusion.bind(this);
        this._buildMenu = this._buildMenu.bind(this);
        this._recurTabs = this._recurTabs.bind(this);
        this._selectComponent = this._selectComponent.bind(this);
        this._buildUrls = this._buildUrls.bind(this);
        this._recurTabsUrls = this._recurTabsUrls.bind(this);
        this.$scope = $scope;
        this.$location = $location;
        this.$interpolate = $interpolate;
        this.$element = $element;
        this.$compile = $compile;
        this.tabs = [];
        this.path = this.$location.path().split('/').splice(1);
        this.base = this.path[0];
        this.breadcrumbs = [];
        $transclude(
          this.$scope.$parent,
          this._baseTransclusion,
          this.$element.parent(),
          'urlBase'
        );
        $transclude(
          this.$scope.$parent,
          this._tabsTransclusion,
          this.$element.parent(),
          'tabs'
        );
        $transclude(
          this.$scope.$parent,
          this._contentTransclusion,
          this.$element.parent(),
          'content'
        );
        this._buildMenu();
        this._buildUrls();
        this._selectComponent();
      }
      _baseTransclusion(base) {
        this.bases = [];
        const interpolated = {};
        const baseItems = $(base).children('gms-base');
        for (let baseItem of baseItems) {
          var titleWatchVal, urlWatchVal;
          const length = this.bases.push({
            url: this.$interpolate(baseItem.attributes['url'].value)(
              this.$scope
            ),
            title: this.$interpolate(baseItem.attributes['title'].value)(
              this.$scope
            ),
          });
          if (baseItem.attributes['url'].value.substring(0, 2) === '{{') {
            urlWatchVal = baseItem.attributes['url'].value
              .replace('{{', '')
              .replace('}}', '');
            interpolated[urlWatchVal] = length - 1;
          }
          if (baseItem.attributes['title'].value.substring(0, 2) === '{{') {
            titleWatchVal = baseItem.attributes['title'].value
              .replace('{{', '')
              .replace('}}', '');
            interpolated[titleWatchVal] = length - 1;
          }
          this.$scope.$watchGroup([titleWatchVal, urlWatchVal], (nv, ov) => {
            if (nv) {
              if (nv[0]) {
                this.bases[interpolated[titleWatchVal]].title = nv[0];
              }
              if (nv[1]) {
                this.bases[interpolated[urlWatchVal]].url = nv[1];
              }
              this._buildMenu();
              this._buildUrls();
              this._selectComponent();
            }
          });
        }
      }
      _tabsTransclusion(tabs) {
        this.tabs = this._recurTabsTransclusion(tabs);
      }
      _recurTabsTransclusion(tabElements) {
        const tabs = [];
        const tabItems = $(tabElements).children('gms-tab');
        for (let tabItem of tabItems) {
          var showItem;
          if (tabItem.attributes['if']) {
            showItem =
              this.$interpolate(tabItem.attributes['if'].value)(this.$scope) ===
              'true';
          } else {
            showItem = true;
          }
          if (showItem) {
            const subTabs = this._recurTabsTransclusion(tabItem);
            tabs.push({
              url: tabItem.attributes['url'].value,
              title: tabItem.attributes['title'].value,
              default: tabItem.attributes['default'] ? true : false,
              subTabs,
            });
          }
        }
        return tabs;
      }
      _contentTransclusion(content) {
        this.tabViews = [];
        const contentItems = $(content).children('[url]');
        for (let contentItem of contentItems) {
          this.tabViews.push({
            url: contentItem.attributes['url'].value,
            component: contentItem,
          });
        }
      }
      _buildMenu() {
        this.breadcrumbs = [];
        this.path = this.$location.path().split('/').splice(1);
        for (let base of this.bases) {
          if (this.path[0] === base.url) {
            this.breadcrumbs.push(base);
            this.path = this.path.splice(1);
          }
        }
        if (this.path.length > 0) {
          this.breadcrumbs = this._recurTabs(
            this.tabs,
            this.path,
            this.breadcrumbs
          );
        } else {
          for (let tab of this.tabs) {
            if (tab.default) {
              this.breadcrumbs.push(tab);
              tab.active = true;
            }
          }
        }
        return this.breadcrumbs;
      }
      _recurTabs(tabs, path, breadcrumbs) {
        for (let tab of tabs) {
          if (tab.url === path[0] || tab.url === '*') {
            tab.active = true;
            if (tab.url === '*') {
              tab.title = path[0];
            }
            breadcrumbs.push(tab);
            path = path.splice(1);
            if (path.length > 0 && tab.subTabs) {
              breadcrumbs = this._recurTabs(tab.subTabs, path, breadcrumbs);
            }
          }
        }
        return breadcrumbs;
      }
      _selectComponent() {
        for (let view of this.tabViews) {
          const viewUrl = view.url.split('/');
          let valid = true;
          for (let index = 0; index < viewUrl.length; index++) {
            const url = viewUrl[index];
            const content =
              this.breadcrumbs[
                this.breadcrumbs.length - viewUrl.length + index
              ];
            if (content == null || (content.url !== url && url !== '*')) {
              valid = false;
            }
          }
          if (valid && this._currentView !== view) {
            this._currentView = view;
            $(this.$element)
              .find('[gms-tab-content-slot]')
              .empty()
              .append(view.component);
            this.$compile($(this.$element).find('[gms-tab-content-slot]'))(
              this.$scope
            );
            break;
          }
        }
      }
      _buildUrls() {
        let baseUrl = '#';
        for (let base of this.bases) {
          baseUrl += `/${base.url}`;
        }
        this.tabs = this._recurTabsUrls(this.tabs, baseUrl);
        return (this.breadcrumbs = this._buildBreadcrumbsUrls(
          this.breadcrumbs,
          '#'
        ));
      }
      _recurTabsUrls(tabs, baseUrl) {
        for (let tab of tabs) {
          tab.finalUrl = `${baseUrl}/${tab.url}`;
          if (tab.subTabs) {
            tab.subTabs = this._recurTabsUrls(tab.subTabs, baseUrl);
          }
        }
        return tabs;
      }
      _buildBreadcrumbsUrls(breadcrumbs, baseUrl) {
        for (let crumb of breadcrumbs) {
          const url = crumb.url === '*' ? crumb.title : crumb.url;
          crumb.finalUrl = `${baseUrl}/${url}`;
          baseUrl = crumb.finalUrl;
        }
        return breadcrumbs;
      }
    }),
  };
});
