// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('idonate.gms').filter(
  'gmsDate',
  () =>
    function (input) {
      if (input && moment(input).isValid()) {
        return moment(input)
          .tz(moment.tz.guess())
          .local()
          .format('MM-DD-YYYY hh:mm A z');
      } else {
        return input;
      }
    }
);

angular.module('idonate.gms').filter(
  'gmsDateNoConversion',
  () =>
    function (input) {
      if (input && input.indexOf('T') > 0) {
        input = input.substring(0, input.indexOf('T'));
      }
      if (input && moment(input).isValid()) {
        return moment(input).format('MM-DD-YYYY');
      } else {
        return input;
      }
    }
);
