'use strict';

angular
  .module('idonate.gms')
  .controller(
    'anchorSiteCtrl',
    ($scope, $sce, organizationService, SessionService) => {
      function handlePostMessage(p2p) {
        let data = {};

        try {
          data = JSON.parse(p2p.data);
        } catch (err) {
          console.error(err);
          return;
        }

        if (data.source === 'p2p-ui' && data.status === 'ready') {
          p2p.source.postMessage(
            JSON.stringify({
              source: 'gms',
              method: 'setConfiguration',
              params: {
                organizationId: organizationService.getCurrentOrganization().id,
                apiAuthorization: `Bearer ${SessionService.getCurrentSessionKey()}`,
                baseUrl: window.iDonateConfig.baseUrl.replace(/\/$/, ''), // remove the slash
                p2pPublicBaseUrl: window.iDonateConfig.p2pBaseUrl, // keep the slash on this one
              },
            }),
            '*'
          ); // TODO : we have p2pUiBaseUrl here so we could tighten up the origin easily
        }
      }

      window.addEventListener('message', handlePostMessage);

      $scope.$on('$destroy', function () {
        window.removeEventListener('message', handlePostMessage);
      });

      let iframeSrc = new URL(
        `${window.iDonateConfig.p2pUiBaseUrl}anchor-sites`
      );
      $scope.iframeUrl = $sce.trustAsResourceUrl(iframeSrc.href);
    }
  );
