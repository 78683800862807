// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular
  .module('idonate.gms')
  .directive('gmsIconPicker', (app4Iconset, app2Iconset, $timeout) => ({
    template: '<button class="btn btn-default btn-icon-picker"></button>',
    replace: true,
    scope: {
      icon: '=',
      type: '@',
    },
    restrict: 'E',
    link(scope, element, attrs) {
      let iconset = app4Iconset;

      if (scope.type === 'unified_giving') {
        iconset = app2Iconset;
      }

      element.iconpicker({
        iconset,
        icon:
          scope.type === 'unified_giving'
            ? `idonate-${scope.icon}`
            : `icon-${scope.icon}`,
        placement: 'bottom',
        arrowPrevIconClass: 'icon-left-caret',
        arrowNextIconClass: 'icon-right-caret',
        search: 'false',
      });

      return $(element).on('change', function (e) {
        scope.icon = e.icon.replace('icon-', '');
        //remove the class for app2Iconset
        scope.icon = scope.icon.replace('idonate-', '');
        return scope.$apply();
      });
    },
  }));
