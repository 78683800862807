function _filterTextarea(fieldset) {
  angular.forEach(fieldset.fields, function (field, index) {
    if (field.data_type === 'textarea') {
      field['optionsSummerNote'] = {
        disableDragAndDrop: true,
        toolbar: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link', 'picture']],
        ],
      };
      return (fieldset.fields[index] = field);
    }
  });
  return fieldset;
}
class CustomizeReceipt {
  blurContent = true;
  /* @ngInject */
  constructor($scope, organizationService, ReceiptService) {
    this._setupFieldsets = this._setupFieldsets.bind(this);
    this._initializeFieldset = this._initializeFieldset.bind(this);
    this._onCustomizeContentChange = this._onCustomizeContentChange.bind(this);
    this._setupPreview = this._setupPreview.bind(this);
    this.publish = this.publish.bind(this);
    this.$scope = $scope;
    this.ReceiptService = ReceiptService;
    organizationService
      .loadFullOrganizationInformation()
      .then(this._setupFieldsets);
    this.ReceiptService.getCashReceipt().then(this._setupPreview);
  }
  _setupFieldsets(data) {
    const { fieldsets } = data.result;
    for (let fieldset of Array.from(fieldsets)) {
      if (fieldset.title === 'Customize Receipt') {
        this.fieldset = _filterTextarea(fieldset);
      }
    }
    return this._initializeFieldset();
  }
  _initializeFieldset() {
    let disableFields = false;
    let index = 0;
    for (let field of this.fieldset.fields) {
      if (field.label === 'Customize Content') {
        disableFields = !field.value;
        this.blurContent = disableFields;
        var customizeIndex = index;
        this.$scope.$watch(() => {
          return this.fieldset.fields[customizeIndex].value;
        }, this._onCustomizeContentChange);
      } else {
        field.disabled = disableFields;
      }
      index++;
    }
  }
  _onCustomizeContentChange(val) {
    for (let field of this.fieldset.fields) {
      var disableFields;
      if (field.label === 'Customize Content') {
        disableFields = !field.value;
        this.blurContent = disableFields;
      } else {
        field.disabled = disableFields;
      }
    }
  }
  _setupPreview(result) {
    return (this.preview = result.html);
  }
  publish() {
    this.loading = true;
    const data = {};
    for (let field of this.fieldset.fields) {
      data[field.name] = field.value;
    }
    return this.ReceiptService.saveCashReceipt(data).then((result) => {
      this.loading = false;
      return this.ReceiptService.getCashReceipt().then(this._setupPreview);
    });
  }
}

angular.module('idonate.gms').component('customizeReceipt', {
  templateUrl: '/scripts/organization/customize-receipt.html',
  controller: CustomizeReceipt,
});
