// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ChartSeriesCtrl;
angular.module('idonate.gms').component('chartSeries', {
  transclude: true,
  require: {
    chartContainerCtrl: '^^chartContainer',
  },
  bindings: {
    tooltip: '@tooltipText',
    title: '@',
    color: '@',
    when: '=',
  },
  template: `\
<li uib-tooltip="{{ $ctrl.tooltip }}" tooltip-append-to-body="true">
  <span style="background-color:{{ $ctrl.color }}"></span>
  {{$ctrl.title}}
</li>\
`,
  controller: (ChartSeriesCtrl = class ChartSeriesCtrl {
    /* @ngInject */
    constructor($element) {
      this.$onInit = this.$onInit.bind(this);
      this.onSelect = this.onSelect.bind(this);
      this.$element = $element;
    }
    $onInit() {
      if (this.when) {
        return this.chartContainerCtrl.registerSeries(this);
      }
    }
    onSelect(name) {
      if (!Array.from(this.when).includes(name)) {
        return this.$element.addClass('ng-hide');
      } else {
        return this.$element.removeClass('ng-hide');
      }
    }
  }),
});
