// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.website')
  .controller(
    'EmbedViewCtrl',
    function (
      $rootScope,
      $scope,
      $location,
      $routeParams,
      $filter,
      embedData,
      organizationService,
      DashboardAPI,
      organizationCampaigns
    ) {
      $scope.backToCampaign = $routeParams.backToCampaign === 'campaign';
      $scope.embedId = $routeParams.embedId;
      $scope.embed = embedData.result.embed_instance;
      if ($scope.embed.type === 'goal_meter') {
        $location.path('/goal-meters');
        return;
      }
      $scope.campaignId =
        $scope.embed.published_property_fieldsets[1]['fields'][1].value;
      angular.forEach(organizationCampaigns.campaigns, function (campaign) {
        if (campaign.id === $scope.campaignId) {
          return ($scope.campaignName = campaign.title);
        }
      });

      $scope.editButtonText =
        window.parent == window ? 'Edit Embed' : 'Edit Giving Form';

      const currency = (val) =>
        $filter('currency')(val, $rootScope.currencyCode, 0);
      const percent = function (first, second) {
        if (second !== 0) {
          return first / second;
        } else {
          return 0;
        }
      };
      $scope.intervalCallback = function (current, previous) {
        const days = Math.abs(current.start.diff(current.end, 'days'));
        const params = {
          start_date: current.start.toISOString(),
          end_date: current.end.toISOString(),
          previous_start_date: previous.start.toISOString(),
          previous_end_date: previous.end.toISOString(),
          plot_by: days > 60 ? 'month' : 'day',
        };
        return DashboardAPI.getEmbed($scope.embedId, params).then(
          function (result) {
            const { summary } = result;
            $scope.embed.analytics = result;
            $scope.chart.data = [];

            const categories = [];

            for (let category of Array.from(
              $scope.embed.analytics.chart.categories
            )) {
              categories.push(moment(category).format('MM-DD-YYYY'));
            }

            $scope.chart.labels = categories;
            $scope.chart.data.push(
              $scope.embed.analytics.chart.series.sessions.data
            );
            $scope.chart.data.push(
              $scope.embed.analytics.chart.series.page_activities.data
            );
            $scope.chart.data.push(
              $scope.embed.analytics.chart.series.conversions.data
            );

            return ($scope.analytics = [
              {
                figureC: summary.total_value,
                subfigure: summary.total_value / days,
                label: 'Total Donations',
                sublabelF: 'Avg Daily Rev.: ',
              },
              {
                figureP: summary.conversion_rate,
                subfigure: summary.total_count,
                label: 'Conversion Rate',
                sublabelF: 'Total Conv.: ',
              },
              {
                figureC: summary.average_value,
                label: 'Average Donations',
                sublabelF:
                  `Range: ${currency(summary.min_value)}` +
                  `-${currency(summary.max_value)}`,
              },
              {
                figure: summary.total_sessions,
                subfigure: summary.total_sessions / days,
                label: 'Total Embed Views',
                sublabelF: 'Daily Avg Views: ',
              },
            ]);
          }
        );
      };

      //Chart Setup
      $scope.chart = {};
      $scope.chart.data = [];
      $scope.chart.options = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                maxTicksLimit: 10,
                beginAtZero: true,
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                zeroLineColor: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
        tooltips: {
          titleFontSize: 14,
          bodyFontSize: 14,
          bodySpacing: 5,
        },
      };
      $scope.chart.types = ['Embed Views', 'Embed Interactions', 'Conversions'];
      $scope.chart.colors = ['#F16B82', '#F6D879', '#16ABD3'];

      //Buttons
      $scope.editEmbed =
        window !== window.parent && $scope.embed.type === 'giving_form'
          ? () =>
              window['eventHub'].emit(
                'navigation',
                location.origin + `/embed/edit-new/${$scope.embed.id}`
              )
          : () => $location.path(`/embed/edit/${$scope.embed.id}`);

      return ($scope.returnCampaign = () => $location.path('/embeds'));
    }
  );
