import { getStateName } from './get-states';

export const formatSingleLineAddress = (address) => {
  // return a single-line string representation of the current address object
  const { street, street2, city, state, zip, country } = address;

  if (!street && !city && !state && !zip && !country) {
    return '';
  }

  let result = '';

  if (street) {
    result += street;
  }

  if (street2) {
    result += ` ${street2}`;
  }

  if (city) {
    result += ` ${city},`;
  }

  const stateName = getStateName(country, state);
  if (stateName) {
    result += ` ${stateName}`;
  }

  if (zip) {
    result += ` ${zip}`;
  }

  // only show country if it is not US
  if (country && !['US', 'USA', 'United States'].includes(country)) {
    result += ` ${country}`;
  }

  return result;
};
