// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.legacy-p2p')
  .controller(
    'P2PAllController',
    function ($scope, $location, $timeout, $rootScope, p2pService) {
      $scope.showDisabledItems = false;
      $scope.intervalCallback = function (current, previous) {
        const interval = {
          start_date: current.start.toISOString(),
          end_date: current.end.toISOString(),
          previous_start_date: previous.start.toISOString(),
          previous_end_date: previous.end.toISOString(),
          campaign_id: $scope.campaignId || null,
          timezone: moment.tz.guess(),
        };
        return p2pService.getP2PProgram(interval).then(function (data) {
          const { result } = data;
          $scope.programs = result.programs;
          return $scope.$broadcast('reloadTable');
        });
      };

      $scope.archiveFilter = (item) =>
        $scope.showDisabledItems || item.status !== 'archived';

      $scope.$watch('showDisabledItems', (a, b) =>
        $timeout(function () {
          if (a !== b) {
            $rootScope.$broadcast(
              'onShowDisabledItemsChanged',
              $scope.showDisabledItems
            );
            return $scope.$broadcast('reloadTable');
          }
        })
      );

      const viewP2PSingleProgram = (id) =>
        $location.path(`/legacy-p2p/program/${id}`);

      return ($scope.tableOptions = {
        sortByColumn: 0,
        sortOrder: 'asc',
        outsideSearchId: 'search-all-programs',
        clickableRowAction: viewP2PSingleProgram,
      });
    }
  );
