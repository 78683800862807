// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const OrgUserService = function (SessionService, organizationService) {
  const init = () => ({
    getOrganizationUsers,
    newUserOrganization,
    updateUserOrganization,
    deleteUserFromOrganization,
  });
  var getOrganizationUsers = function () {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}`, 'users')
      .get();
  };

  var newUserOrganization = function (user) {
    const _org = organizationService.getCurrentOrganization();
    const _post = { organization_id: _org.id };
    angular.extend(user, _post);
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/users`)
      .post(user);
  };

  var updateUserOrganization = function (user) {
    const _org = organizationService.getCurrentOrganization();
    const _post = { organization_id: _org.id };
    angular.extend(user, _post);
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/users`)
      .customOperation('put', null, null, null, user);
  };

  var deleteUserFromOrganization = function (id, email) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/users`)
      .customDELETE(null, {
        user_id: id,
        email,
      });
  };

  return init();
};

angular.module('idonate.gms').factory('orgUserService', OrgUserService);
