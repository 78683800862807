import './thank-you.html';

export class ThankYouCtrl {
  /* @ngInject */
  constructor(
    $scope,
    $location,
    $timeout,
    organizationGifts,
    organizationService
  ) {
    //List of gifts returned by the API
    let c;
    $scope.giftsList = organizationGifts.result.gifts;
    // By default hide Archived items
    $scope.giftsList = (() => {
      const result = [];
      for (c of Array.from($scope.giftsList)) {
        if (!c.is_disabled) {
          result.push(c);
        }
      }
      return result;
    })();
    $scope.showDisabledItems = false;
    $scope.setCurrent = (id) =>
      ($scope.current = _.find($scope.giftsList, { id }));
    $scope.editGift = function (giftId) {
      $location.path(`/thank-you-gift/${giftId}`);
    };
    $scope.tableOptions = {
      outsideSearchId: 'outside-search',
      clickableRowAction: $scope.editGift,
    };
    const updateGiftAttribute = (giftId, attributeValue) =>
      angular.forEach($scope.giftsList, function (gift, key) {
        if (gift.id === giftId) {
          return ($scope.giftsList[key][Object.keys(attributeValue)[0]] =
            attributeValue[Object.keys(attributeValue)[0]]);
        }
      });
    $scope.$watch('showDisabledItems', (a, b) =>
      $timeout(function () {
        if (a !== b) {
          $scope.giftsList = organizationGifts.result.gifts;
          if (!$scope.showDisabledItems) {
            $scope.giftsList = (() => {
              const result1 = [];
              for (c of Array.from($scope.giftsList)) {
                if (!c.is_disabled) {
                  result1.push(c);
                }
              }
              return result1;
            })();
          }
          return $scope.$broadcast('reloadTable');
        }
      })
    );
    //Enable a gift after being deleted.
    $scope.enableOrganizationGift = function (giftId) {
      const _gift = _.find($scope.giftsList, { id: giftId });
      if (_gift) {
        _gift.gift_id = giftId;
        _gift.is_disabled = false;
        return organizationService
          .updateOrganizationGift(_gift)
          .then(function (data) {
            updateGiftAttribute(giftId, { is_disabled: false });
            $scope.$broadcast('reloadTable');
          });
      }
    };
    $scope.disableGift = (giftId) =>
      organizationService.deleteOrganizationGift(giftId).then(function (data) {
        updateGiftAttribute(giftId, { is_disabled: true });
        $scope.$broadcast('reloadTable');
      });
  }
}
