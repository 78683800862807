// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('idonate.gms').factory('validationService', function () {
  const service = {};
  service.validate = function (fieldsData, requiredFields, opts) {
    fieldsData = fieldsData || [];
    const validateInputs = requiredFields || [];
    opts = opts || {};

    const validation = [];
    validation.ok = true;

    //Empty fields Validation
    angular.forEach(validateInputs, function (val, $i) {
      if (!fieldsData[val]) {
        validation[val] = true;
        return (validation.ok = false);
      }
    });

    //Confirm if two fields have the same data
    if (opts.confirm) {
      if (fieldsData[opts.confirm[0]] !== fieldsData[opts.confirm[1]]) {
        validation[opts.confirm[0]] = true;
        validation[opts.confirm[1]] = true;
        validation.ok = false;
      }
    }

    //Check if some field has email pattern
    if (opts.checkEmailPattern) {
      const patternEmail =
        /^[a-zA-Z0-9\-]{1}([\.\_\-]{0,}[a-zA-Z0-9\-]{1}){0,}@[a-zA-Z0-9]{1,}([\-]{0,}[a-zA-Z0-9]{1,}){0,}([\.]{1}[a-zA-Z0-9]{1,}){1,}$/;
      if (!patternEmail.test(fieldsData[opts.checkEmailPattern])) {
        validation[opts.checkEmailPattern] = true;
        validation.ok = false;
      }
    }

    if (opts.min) {
      if (fieldsData[opts.min[0]] < opts.min[1]) {
        validation[opts.min[0]] = true;
        validation.msg = [opts.min[0], 'min', opts.min[2]];
        validation.ok = false;
      }
    }

    if (opts.max) {
      if (fieldsData[opts.max[0]] > opts.max[1]) {
        validation[opts.max[0]] = true;
        validation.msg = [opts.max[0], 'max', opts.max[2]];
        validation.ok = false;
      }
    }

    return validation;
  };

  return service;
});
