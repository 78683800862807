// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('choiceField', function () {
  let uniqueId = 1;
  return {
    templateUrl: '/scripts/input/choice-field.html',
    scope: {
      field: '=field',
    },
    restrict: 'E',
    controller($scope) {
      console.log($scope.field.blockClear);
      $scope.uniqueId = `selectField${uniqueId++}`;
      if (_.isObject($scope.field.choices)) {
        $scope.choice_options = [];
        angular.forEach($scope.field.choices, (v, k) =>
          $scope.choice_options.push({ id: k, text: v })
        );
        if ($scope.field.sort) {
          $scope.choice_options = _.sortBy(
            $scope.choice_options,
            (choice) => choice.text
          );
        }
      }
      $scope.placeholder = $scope.field.placeholder;
      if (!$scope.field.placeholder) {
        $scope.placeholder = 'Select an option';
      }
      return $scope.$watch('field.choices', function (nv) {
        $scope.choice_options = [];
        angular.forEach($scope.field.choices, (v, k) =>
          $scope.choice_options.push({ id: k, text: v })
        );
        if ($scope.field.sort) {
          return ($scope.choice_options = _.sortBy(
            $scope.choice_options,
            (choice) => choice.text
          ));
        }
      });
    },
  };
});
