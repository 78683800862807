// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

angular.module('idonate.gms.donations', []).config(($routeProvider) => {
  let provider = $routeProvider
    //Summary Page/Dashboard
    .when('/dashboard', {
      template: '<dashboard></dashboard>',
      menu: {
        style: 'icon-dashboard',
        name: 'Dashboard',
        level: 1,
        position: 1,
        allowFor: 'allRoles',
        allowFeatureFor: 'alwaysAllow',
      },
      reloadOnSearch: false,
    })
    .when('/chapter', {
      template: '<chapter></chapter>',
      menu: {
        parent: 'Dashboard',
        name: 'Chapter',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'chapter',
      },
    })
    .when('/chapter/hierarchy', {
      template: '<chapter-hierarchy></chapter-hierarchy>',
      menu: {
        parent: 'Dashboard',
        name: 'Chapter',
        level: 3,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'chapter',
      },
    })
    .when('/donations', {
      templateUrl: '/scripts/donations/transactions.html',
      controller: 'DonationsReportsCtrl',
      menu: {
        style: 'icon-gift',
        name: 'Donations',
        level: 1,
        position: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/donations/offline', {
      templateUrl: '/scripts/donations/offline.html',
      controller: 'OfflineCtrl',
      menu: {
        parent: 'Donations',
        name: 'Offline',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'offline',
      },
    })
    .when('/donations/import-offline', {
      controller: 'offlineBulkImport',
      templateUrl: '/scripts/donations/offline-bulk-import.html',
      controllerAs: '$ctrl',
      menu: {
        parent: 'Donations',
        name: 'Import Offline Donations',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'offline',
      },
    })
    .when('/donations/detail/:transactionId', {
      templateUrl: '/scripts/donations/transactions-item.html',
      controller: 'DonationsDetailSpecificCtrl',
      menu: {
        parent: 'Donations',
        name: 'Gifts',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        transaction($route, transactionService) {
          'ngInject';
          return transactionService.getTransaction(
            $route.current.params.transactionId
          );
        },
      },
    })
    .when('/donations/recurring', {
      templateUrl: '/scripts/donations/recurring/recurring.html',
      controller: 'DonationsRecurringCtrl',
      menu: {
        parent: 'Donations',
        name: 'Recurring',
        level: 2,
        allowFor: 'allRoles',
        allowFeatureFor: 'edit_recurring',
      },
    })
    .when('/donations/recurring/:itemId', {
      templateUrl: '/scripts/donations/recurring/recurring-item.html',
      controller: 'DonationsRecurringItemCtrl',
      menu: {
        parent: 'Donations',
        name: 'Recurring',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'edit_recurring',
      },
      resolve: {
        recurringSchedule($route, recurringService) {
          'ngInject';
          return recurringService.getRecurringSchedule(
            $route.current.params.itemId
          );
        },
      },
    })
    .when('/merchant/funding', {
      templateUrl: '/scripts/donations/funding-list.html',
      controller: 'FundingListCtrl',
      menu: {
        parent: 'Donations',
        name: 'Funding List',
        level: 2,
        allowFor: 'allRoles',
        allowFeatureFor: 'payments',
      },
    })
    .when('/merchant/exception', {
      templateUrl: '/scripts/donations/exception-list.html',
      controller: 'ExceptionListCtrl',
      menu: {
        parent: 'Donations',
        name: 'Exception List',
        level: 2,
        allowFor: 'allRoles',
        allowFeatureFor: 'no-show-payments',
      },
    })
    .when('/merchant/funding/:fundingId', {
      templateUrl: '/scripts/donations/funding-item.html',
      controller: 'FundingDetailSpecificCtrl',
      menu: {
        parent: 'Donations',
        name: 'Fundings',
        level: 3,
        allowFor: 'allRoles',
        allowFeatureFor: 'payments',
      },
      resolve: {
        fundingSummary($route, transactionService) {
          'ngInject';
          return transactionService.getFunding($route.current.params.fundingId);
        },
      },
    });

  if (window === parent) {
    provider = provider.otherwise('/dashboard');
  }

  return provider;
});
