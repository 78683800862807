export class CampaignService {
  /* @ngInject */
  constructor(
    SessionService,
    $q,
    CampaignAPI,
    designationService,
    organizationService
  ) {
    this.$q = $q;
    this.SessionService = SessionService;
    this.CampaignAPI = CampaignAPI;
    this.designationService = designationService;
    this.organizationService = organizationService;
  }
  getCampaigns(showDisabled) {
    if (showDisabled == null) {
      showDisabled = false;
    }
    return this.CampaignAPI.getOrganizationCampaigns({
      show_disabled: showDisabled,
    });
  }
  getCampaign(campaignId) {
    const defer = this.$q.defer();
    this.campaign = this.campaign || {};
    if (campaignId === this.campaign.id) {
      defer.resolve(angular.extend({}, this.campaign));
    } else {
      if (campaignId === 'new') {
        this.campaign = {
          designations: [],
          gifts: [],
          tribute_definitions: [],
          email_campaigns: [],
          title: 'New',
          id: 'new',
        };
        defer.resolve(this.campaign);
      } else {
        this.CampaignAPI.getCampaignDetails(campaignId).then(
          (data) => {
            this.campaign = data.campaign;
            return defer.resolve(this.campaign);
          },
          (error) => defer.reject(error)
        );
      }
    }
    return defer.promise;
  }
  getDesignations() {
    return this.designationService.getOrganizationDesignations();
  }
  getGifts() {
    return this.organizationService.getOrganizationGifts();
  }
  getCampaignActiveTools(campaignId) {
    return this.CampaignAPI.getCampaignActiveTools(campaignId);
  }
  getOrganizationAttributes() {
    return this.organizationService.getOrganizationAttributes();
  }
  getTributeDefinitions() {
    return this.organizationService.getOrganizationTributeDefinitions();
  }
  editCampaign(campaign) {
    return this.CampaignAPI.putCampaign(campaign).then((result) => {
      if (
        result.campaign.id ===
        (this.campaign != null ? this.campaign.id : undefined)
      ) {
        return (this.campaign = angular.extend(this.campaign, result.campaign));
      }
    });
  }
  duplicateCampaign(campaignId) {
    return this.CampaignAPI.postCampaignDuplicate(campaignId);
  }
  getCampaignChannelAnalytics(id) {
    return this.CampaignAPI.getCampaignChannels(id);
  }
  getAllCampaignsChannelAnalytics() {
    return this.CampaignAPI.getAllCampaignsChannels();
  }
  addOrganizationCampaign(campaign) {
    return this.CampaignAPI.postOrganizationCampaign(campaign);
  }
  addDesignationToCampaign(id, des) {
    return this.CampaignAPI.postCampaignDesignation(id, des).then((result) => {
      return (this.campaign.designations = result.designations);
    });
  }
  removeDesignationFromCampaign(campaignId, designationId) {
    return this.CampaignAPI.deleteCampaignDesignation(
      campaignId,
      designationId
    ).then((result) => {
      return (this.campaign.designations = this.campaign.designations.filter(
        (des) => des.id !== designationId
      ));
    });
  }
  reorderDesignations(id, obj) {
    return this.CampaignAPI.postDesignationOrder(id, obj);
  }
  downloadImportTemplate() {
    this.SessionService.downloadGmsApiResult(
      'import/designation',
      {},
      'Designation-Importer-Template.csv'
    );
  }
}
