// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*

options:
  ng-model: 'variable'
  placeholder: 'string'
  ng-disabled: 'expression'
  label: 'string'
  options: 'variable'

*/

let GmsDatetimeCtrl;
angular.module('idonate.gms').component('gmsDatetime', {
  templateUrl: '/scripts/input/gms-datetime.html',
  bindings: {
    ngModel: '=',
    placeholder: '@',
    ngDisabled: '<',
    label: '@',
    userOptions: '<options',
    description: '@',
    frequency: '=',
  },
  controller: (GmsDatetimeCtrl = class GmsDatetimeCtrl {
    /* @ngInject */
    constructor($scope, $parse, $attrs, $timeout) {
      this.$onInit = this.$onInit.bind(this);
      this._setDefaults = this._setDefaults.bind(this);
      this._setWatches = this._setWatches.bind(this);
      this._setupBlur = this._setupBlur.bind(this);
      this.clear = this.clear.bind(this);
      this.$scope = $scope;
      this.$parse = $parse;
      this.$attrs = $attrs;
      this.$timeout = $timeout;
      this.displayModel = '';
      this.frequency = $scope.frequency;
      this.hideDate = false;
      this.selectable = null;
    }
    $onInit() {
      this._setDefaults();
      this._setWatches();
      this.setDates(!(this.frequency === '1st and 15th'));
      return this._setupBlur();
    }
    _setDefaults() {
      if (this.options == null) {
        this.options = {};
      }
      angular.extend(
        this.options,
        {
          format: 'MM-DD-YYYY hh:mm A z',
          startView: 'month',
          today: 'true',
          minView: 'year',
          maxView: 'hour',
          clearable: false,
          startDate: this.ngModel ? this.ngModel : moment(),
        },
        this.userOptions
      );
      if (this.ngModel) {
        return (this.displayModel = this.ngModel.format(
          'MM-DD-YYYY hh:mm A z'
        ));
      }
    }
    _setWatches() {
      this.$scope.$watch(
        '$ctrl.ngModel',
        () => {
          return this.$timeout(() => {
            return this.onBlur(this.$scope.$parent);
          });
        },
        true
      );
      this.$scope.$watch(
        '$ctrl.frequency',
        (nv) => {
          if (nv) {
            this.hideDate = true;
            // if 1st and 15th selected filter the dates
            this.setDates(!(nv == '1st and 15th'));
            setTimeout(() => {
              this.hideDate = false;
            }, 1);
          }
        },
        true
      );
      return this.$scope.$watch('$ctrl.userOptions', () => {
        return this._setDefaults;
      });
    }
    _setupBlur() {
      return (this.onBlur = this.$parse(this.$attrs['ngBlur']));
    }
    clear() {
      return (this.ngModel = '');
    }
    setDates(reset = false) {
      this.selectable = (date, type) => {
        const day = date.format('D');
        return reset ? true : day == 1 || day == 15;
      };
    }
  }),
});
