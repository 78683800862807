// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class DateField {
  /* @ngInject */
  constructor($scope) {
    this.$onInit = this.$onInit.bind(this);
    this.modelWatch = this.modelWatch.bind(this);
    this.setDateOptions = this.setDateOptions.bind(this);
    this.$scope = $scope;
  }
  $onInit() {
    this.options = {
      minDate: this.extra.min_date ? moment(this.extra.min_date) : undefined,
      maxDate: this.extra.max_date ? moment(this.extra.max_date) : undefined,
    };
    if (this.field.value) {
      this.model = moment(this.field.value);
    } else {
      this.model = null;
    }
    this.$scope.$watch('$ctrl.model', this.modelWatch, true);
    return this.$scope.$watch('$ctrl.extra', this.setDateOptions, true);
  }
  modelWatch(nv, ov) {
    if (nv) {
      return (this.field.value = nv.format());
    }
  }
  setDateOptions(nv, ov) {
    this.options.minDate = this.extra.min_date
      ? moment(this.extra.min_date)
      : undefined;
    return (this.options.maxDate = this.extra.max_date
      ? moment(this.extra.max_date)
      : undefined);
  }
}

angular.module('idonate.gms').component('dateField', {
  templateUrl: '/scripts/input/date-field.html',
  bindings: {
    field: '=',
    extra: '=',
  },
  controller: DateField,
});
