import { createHttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';

export class SessionService {
  /* @ngInject */
  constructor(Restangular, $location, Upload, GlobalService) {
    // bind injected junk to this object, mostly for `userLogged` at the end...
    this.Restangular = Restangular;
    this.$location = $location;
    this.Upload = Upload;
    this.GlobalService = GlobalService;

    this.bearerToken = null;

    // configure authenticated clients stored values
    this.configureClients();
  }

  /**
   * Configure (or reconfigure) API clients with credentials - should be called
   * whenever credentials are refreshed. API credentials should NOT be configured
   * anywhere else in GMS.
   */
  configureClients() {
    // TODO consider splitting these into more Services (RestangularGMS, RestangularAuth?) for cleaner downstream code.

    this.graphql = new ApolloClient({
      cache: new InMemoryCache(),
      // link will be replaced (or reconfigured?) in SessionService
      link: createHttpLink({
        uri: `${window.iDonateConfig.baseUrl}graphql`,
        headers: {
          Authorization: `Bearer ${this.bearerToken}`,
        },
      }),
    });

    this.restangularGmsApi = this.Restangular.withConfig((r) => {
      return r.setBaseUrl(window.iDonateConfig.baseUrl).setDefaultHeaders({
        Authorization: `Bearer ${this.bearerToken}`,
      });
    });

    this.restangularAuthApi = this.Restangular.withConfig((r) => {
      const defaultHeaders = {};

      return r
        .setBaseUrl(window.iDonateConfig.authApiBaseUrl)
        .setDefaultHeaders(defaultHeaders);
    });
  }

  hasSession() {
    return this.bearerToken !== null;
  }

  // return a fresh new object representing current authentication headers
  getAuthHeaders() {
    return {
      Authorization: `Bearer ${this.bearerToken}`,
    };
  }

  logOut(callback) {
    return this.restangularAuthApi
      .all('logout')
      .post({ preventShowError: true })
      .then(
        () => callback(),
        () => callback() // ignore errors and still callback...
      );
  }

  setNewSessionKey(SessionKey) {
    this.bearerToken = SessionKey;
    this.configureClients();
  }

  // TODO AUTH : this is referenced by the 'plugins':
  //  designation-cart, events-ui, p2p-ui,  integrations-exchange
  getCurrentSessionKey() {
    return this.bearerToken;
  }

  getCurrentUserInfo(contactOnly) {
    return this.restangularGmsApi
      .one('user/current')
      .get()
      .then((result) => {
        if (contactOnly) {
          return result.result.user.contact;
        }

        return result.result.user;
      })
      .catch((err) => {
        // error fetching user -- show modal allowing user to logout
        // support number omitted on purpose, this will always be an engineering issue.
        this.GlobalService.forceLogOutError(
          'Unknown Error',
          "<p>An unknown error has occurred, please Log Out and try again.</p><p>If the error persists, please contact {{Platform.getText('name')}} for more information.</p>"
        );
        // TODO : log to sentry!
        console.error('Unknown Error', err);

        throw err;
      });
  }

  downloadGmsApiResult(endpoint, params, filename, method) {
    let resultPromise;

    switch (method) {
      case undefined: // default to get
      case 'get':
        resultPromise = this.restangularGmsApi
          .one(endpoint) // one vs all for get vs post is important.
          .withHttpConfig({ responseType: 'blob' })
          .get(params);
        break;
      case 'post':
        // post as JSON
        resultPromise = this.restangularGmsApi
          .all(endpoint)
          .withHttpConfig({ responseType: 'blob' })
          .post(params);
        break;
      case 'formdata':
        // post as formdata - for DataTables endpoints
        {
          const formData = new FormData();

          for (const key in params) {
            if (params.hasOwnProperty(key)) {
              formData.append(key, params[key]);
            }
          }

          resultPromise = this.restangularGmsApi
            .all(endpoint)
            .withHttpConfig({
              transformRequest: angular.identity, // identity is f(x) { return x }
              responseType: 'blob',
            })
            .customPOST(formData, undefined, undefined, {
              'Content-Type': undefined,
            });
        }
        break;
      default:
        throw new Error(`Unhandled Method: ${method}`);
    }

    resultPromise.then((result) => {
      // need to create an anchor to provide a filename at all, otherwise this would work:
      // window.open(URL.createObjectURL(result));
      // would LOVE to be able to use filename sent from server.

      const fileAnchor = document.createElement('a');

      fileAnchor.href = URL.createObjectURL(result);
      fileAnchor.download = filename;

      fileAnchor.click();

      URL.revokeObjectURL(fileAnchor.href);
    });
  }

  // returns an 'Upload' promise
  uploadToGmsApi(endpoint, params) {
    const opts = {
      url: `${this.restangularGmsApi.configuration.baseUrl}${endpoint}`,

      // `Upload` _modifies the headers object_, ensure it gets its own copy!
      headers: this.getAuthHeaders(),
    };

    // allow opts to overwrite everything else, just in case.
    Object.assign(opts, params || {});

    return this.Upload.upload(opts);
  }
}
