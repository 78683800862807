/*
RestAPI is used in MANY places - mostly to utilize the unwrapping of result.result

RestAPI cannot access non-GMS APIs.
 */

export class RestAPI {
  static $inject = ['SessionService'];

  constructor(SessionService) {
    this.SessionService = SessionService;
  }

  _masterMethod(method, url, data) {
    let rest = this.SessionService.restangularGmsApi;

    rest = rest.one(url);

    return rest[method](data).then((result) => {
      // the unwrapping of result.result is the main benefit of RestAPI
      if (result && result.result) {
        return result.result;
      } else {
        return result;
      }
    });
  }

  // httpConfig was never used, config was only used in two GET calls that have
  // been deleted or replaced with SessionService calls.

  get(url, data) {
    return this._masterMethod('get', url, data);
  }

  post(url, data) {
    return this._masterMethod('customPOST', url, data);
  }

  put(url, data) {
    return this._masterMethod('customPUT', url, data);
  }

  delete(url, data) {
    return this._masterMethod('customDELETE', url, data);
  }
}
