// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ChartContainerCtrl;
angular.module('idonate.gms').component('chartContainer', {
  template: `\
    <div ng-transclude ng-transclude-slot="header">
    </div>
    <div id="chartjs-tooltip"></div>
    <div class="row">
      <div class="col-12" ng-transclude ng-transclude-slot="charts">
      </div>
    </div>\
  `,
  transclude: {
    header: 'chartHeader',
    charts: 'chartCharts',
  },
  controller: (ChartContainerCtrl = class ChartContainerCtrl {
    /* @ngInject */
    constructor($scope, $timeout) {
      this.registerChart = this.registerChart.bind(this);
      this.registerSeries = this.registerSeries.bind(this);
      this.selectChartThen = this.selectChartThen.bind(this);
      this.selectChart = this.selectChart.bind(this);
      this.$scope = $scope;
      this.$timeout = $timeout;
      this.charts = {};
      this.series = [];
    }
    registerChart(key, chart) {
      this.charts[key] = chart;
      return chart.deactivate();
    }
    registerSeries(series) {
      return this.series.push(series);
    }
    selectChartThen(key) {
      if (this.selectedChart != null) {
        this.selectedChart.deactivate();
      }
      this.selectedChart = this.charts[key];
      if (this.selectedChart != null) {
        this.selectedChart.activate();
      }
      this.selectedChartName = key;
      for (let series of Array.from(this.series)) {
        series.onSelect(key);
      }
      return this.$scope.$emit('selectedChanged', this.selectedChartName);
    }
    selectChart(key) {
      return this.$timeout(() => {
        return this.selectChartThen(key);
      });
    }
  }),
});
