// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular
  .module('idonate.gms.text')
  .controller(
    'AutoResponderKeywordsCtrl',
    function (
      $scope,
      organizationService,
      $location,
      keyword,
      notyService,
      $timeout,
      $uibModal
    ) {
      $scope.loading = false;
      $scope.select2opts = { allowClear: true };

      $scope.keyword = keyword.result.sms_keyword;
      $scope.keyword.response_template = $scope.keyword.template_auto_responder
        .response_template
        ? $scope.keyword.template_auto_responder.response_template
        : '';

      $scope.enableDisableKeyword = function () {
        const _postObject = {
          keyword: $scope.keyword.keyword,
          id: $scope.keyword.id,
          response_template: $scope.keyword.response_template,
          is_active: $scope.keyword.is_active,
        };
        return organizationService
          .updateOrganizationKeyword(_postObject)
          .then(function (data) {});
      };

      $timeout(() => $scope.keywordForm.$setPristine(), 100);

      $scope.saveKeyword = function () {
        let apiCall;
        $scope.loading = true;
        const _postObject = {
          keyword: $scope.keyword.keyword,
          id: $scope.keyword.id,
          response_template: $scope.keyword.response_template,
          is_active: $scope.keyword.is_active,
        };
        if ($scope.keyword.id) {
          apiCall = organizationService.updateOrganizationKeyword(_postObject);
        } else {
          apiCall = organizationService.addOrganizationKeyword(_postObject);
        }
        return apiCall.then(function (data) {
          if ($scope.keyword.id) {
            $scope.keyword = data.result.sms_keyword;
            $scope.keyword.response_template = $scope.keyword
              .template_auto_responder.response_template
              ? $scope.keyword.template_auto_responder.response_template
              : '';
            $scope.loading = false;
            notyService.success(
              'A auto-responder has been updated successfully.'
            );
            return $location.path('/text/autoresponder-keywords');
          } else {
            notyService.success(
              'A auto-responder has been created successfully.'
            );
            $scope.loading = true;
            return $location.path('/text/autoresponder-keywords');
          }
        });
      };

      return ($scope.openUrlShortening = function (url) {
        const modalInstance = $uibModal.open({
          templateUrl: '/scripts/modals/urlShorteningModal.html',
          windowClass: 'url-shortening-modal',
          resolve: {
            urls(organizationService) {
              'ngInject';
              return organizationService.getOrganizationShortUrls();
            },
          },
          controller($scope, $uibModalInstance, urls, organizationService) {
            $scope.urls = urls.result.urls;
            $scope.url = url || '';
            $scope.btnLoading = false;
            $scope.shortUrlReady = false;
            $scope.showMsg = false;

            $scope.closeModal = () => $uibModalInstance.dismiss('cancel');

            const ua = navigator.userAgent.toLowerCase();
            $scope.safari = false;
            if (ua.indexOf('safari') !== -1) {
              if (ua.indexOf('chrome') > -1) {
                // chrome should take care of it automatically
              } else {
                $scope.safari = true;
              }
            }

            const showMsg = () =>
              new Noty({
                animation: {
                  open: 'animated fadeIn',
                  close: 'animated fadeOut',
                  easing: 'swing',
                  speed: 200,
                },
                text: 'Link has been copied to clipboard',
                layout: 'topRight',
                theme: 'idonate',
                type: 'alert',
                timeout: 4000,
              }).show();
            $scope.showCopyMsg = () => showMsg();

            $scope.urlCopy = () => showMsg();

            $scope.shortlinkTableConf = {
              sortOrder: 'desc',
              sortByColumn: 0,
            };

            return ($scope.getShortUrl = function () {
              $scope.btnLoading = true;
              return organizationService
                .postOrganizationShortUrl($scope.url)
                .then(
                  function (data) {
                    $scope.shortUrlReady = data.result.short_url.short_url;
                    $scope.urls.push(data.result.short_url);
                    $scope.btnLoading = false;
                    return $scope.$broadcast('reloadTable');
                  },
                  (error) => ($scope.btnLoading = false)
                );
            });
          },
        });
        modalInstance.result.catch(angular.noop);
      });
    }
  );
