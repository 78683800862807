import './donor-merge.html';

angular
  .module('idonate.gms.donors')
  .controller(
    'DonorsMergeCtrl',
    ($scope, $location, donorService, organizationService, notyService) => {
      $scope.selectedDonor = undefined;
      $scope.assimilatingDonor = undefined;
      if (gmsStorage.mergeKeeper) {
        $scope.selectedDonor = JSON.parse(gmsStorage.mergeKeeper);
      }
      if (gmsStorage.mergeAssimilate) {
        $scope.assimilatingDonor = JSON.parse(gmsStorage.mergeAssimilate);
      }
      $scope.readyToMerge = function () {
        return (
          $scope.selectedDonor &&
          $scope.assimilatingDonor &&
          $scope.selectedDonor.donor_id != $scope.assimilatingDonor.donor_id
        );
      };
      $scope.clearKeep = function () {
        $scope.selectedDonor = undefined;
        gmsStorage.mergeKeeper = '';
      };
      $scope.clearAssimilate = function () {
        $scope.assimilatingDonor = undefined;
        gmsStorage.mergeAssimilate = '';
      };

      $scope.gotoDonorDetail = (id) => {
        $location.path(`donors/detail/${id}`);
      };

      $scope.mergeDonors = () => {
        donorService
          .mergeDonors(
            $scope.selectedDonor.donor_id,
            $scope.assimilatingDonor.donor_id
          )
          .then((results) => {
            if (results.result.error) {
              notyService.error(results.result.error_message);
            } else {
              gmsStorage.mergeAssimilate = '';
              gmsStorage.mergeKeeper = '';
              notyService.success('Merge Complete.');
              $location.path(`donors`);
            }
          });
      };
    }
  );
