var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PaginationReportTable", {
        attrs: {
          focus: _vm.currentReport.dataSrc,
          canDelete: _vm.currentReport.canDelete,
          currentPage: _vm.currentPage,
          defaultFormat: _vm.defaultFormat,
          defaultRowsPerPage: 25,
          downloadFormats: _vm.sendMessageProps.fileTypeConfig.options,
          headers: _vm.headers,
          initialFilters: _vm.currentFilters,
          isDefaultReport: _vm.defaultReportId == _vm.reportId,
          isLoading: _vm.isLoading,
          newReport: _vm.newReport,
          reportId: _vm.reportId,
          reports: _vm.reports,
          rollUpAllLocations: _vm.isRollup,
          rows: _vm.rows,
          rowsPerPageOptions: _vm.rowsPerPageOptions,
          saveCustomViewProps: _vm.saveCustomViewProps,
          searchValue: _vm.searchTerm,
          sendMessageProps: _vm.sendMessageProps,
          totalPageNumber: 0
        },
        on: {
          rowClicked: _vm.handleRowClicked,
          configChange: _vm.customizeReport,
          deleteReport: _vm.deleteReport,
          download: _vm.downloadReport,
          focusChange: _vm.changeFocus,
          onFiltersChanged: _vm.handleFiltersChanged,
          goToCampaigns: _vm.goToCampaigns,
          makeDefault: _vm.setDefaultReport,
          refresh: _vm.reloadTable,
          reportChange: _vm.changeReport,
          request: _vm.reloadTable,
          saveCustomView: _vm.saveCustomView,
          saveNewReport: _vm.createReport,
          saveOverCurrentReport: _vm.updateCurrentReport,
          showSendMessage: _vm.showSendMessageModal,
          closeSendMessage: _vm.closeSendMessageModal,
          addEmail: _vm.addSubscriber,
          removeEmail: _vm.removeSubscriber,
          deleteSchedule: _vm.deleteReportSchedule,
          send: _vm.createSchedule,
          sendOnce: _vm.sendOnce
        }
      }),
      _vm.showDialog
        ? _c(
            "Modal",
            _vm._b(
              {
                on: {
                  close: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              "Modal",
              _vm.dialogConfig,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }