import './campaign-item-channels.html';

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const channels = () => ({
  templateUrl: '/scripts/campaign/campaign-item/campaign-item-channels.html',
  restrict: 'E',
  scope: {
    current: '=',
    embedBases: '=bases',
    themeLimits: '=',
    templates: '=',
  },
  controller: metricsController,
});
angular
  .module('idonate.gms.campaign')
  .directive('campaignItemChannels', channels);

var metricsController = function (
  $scope,
  $uibModal,
  $route,
  $timeout,
  $location,
  CampaignService,
  p2pService,
  websiteService
) {
  $scope.campaignId = {};
  $scope.campaignId = $route.current.params.campaignId;

  $scope.availableBases = _.filter(
    $scope.embedBases,
    (base) => base.type !== 'goal_meter'
  );

  $scope.showing_all_campaigns = true;

  if ($scope.campaignId) {
    $scope.showing_all_campaigns = false;
    CampaignService.getCampaignChannelAnalytics($scope.campaignId).then(
      function (data) {
        $scope.analytics = data;
      },
      () => {}
    );
  } else {
    CampaignService.getAllCampaignsChannelAnalytics().then(function (data) {
      $scope.analytics = data;
    });
  }

  const themeCounter = function (pages) {
    $scope.themeCounter = {}; // reset counter every time a theme is edited/deleted or when the page is loaded as new instance
    return angular.forEach(pages, function (page) {
      if (angular.isDefined($scope.themeCounter[page.template])) {
        return ($scope.themeCounter[page.template] += 1);
      } else {
        return ($scope.themeCounter[page.template] = 1);
      }
    });
  };

  const openModal = function (title, type) {
    // if there is only one base available, create an embed off of that base
    let embedObj;
    if (type === 'embeds' && $scope.availableBases.length === 1) {
      embedObj = {
        name: $scope.availableBases[0].name + '_' + Date.now(),
        parent_definition_id: $scope.availableBases[0].id,
      };

      return websiteService
        .createEmbedInstance(embedObj)
        .then((data) =>
          $timeout(
            () => $location.path(`embed/edit/${data.result.embed_instance.id}`),
            1000
          )
        );
    } else {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/scripts/campaign/campaign-item/campaign-new-item-modal.html',
        scope: $scope,
        controller($scope, $uibModalInstance) {
          $scope.title = title;

          $scope.onSelectTemplate = (template) =>
            $uibModalInstance.close(template);

          $scope.cancel = () => $uibModalInstance.dismiss('cancel');
          if (type === 'pages') {
            return ($scope.checkLimit = function (tmp) {
              // check if the current template is over the limit
              // and hides it
              if (angular.isDefined($scope.themeLimits[tmp.template])) {
                // check if limit is set
                let curCount = 0;
                if (angular.isDefined(themeCounter[tmp.template])) {
                  curCount = themeCounter[tmp.template];
                }
                return $scope.themeLimits[tmp.template] > curCount;
              } else {
                return true;
              }
            });
          } else {
            return ($scope.displayEmbeds = true);
          }
        },
      });
      return modalInstance.result.then(function (template) {
        if (type === 'pages' && template) {
          const _page = {
            page_id: template.id,
            slug: Date.now(),
          };
          websiteService
            .addOrganizationPage(_page)
            .then((data) =>
              $timeout(
                () => $location.path(`/pages/edit/${data.result.page.id}`),
                1000
              )
            );
        }
        if (type === 'embeds' && template) {
          embedObj = {
            name: template.name + '_' + Date.now(),
            parent_definition_id: template.id,
          };
          return websiteService
            .createEmbedInstance(embedObj)
            .then((data) =>
              $timeout(
                () =>
                  $location.path(`embed/edit/${data.result.embed_instance.id}`),
                1000
              )
            );
        }
      });
    }
  };

  return ($scope.channels = [
    {
      title: 'Embeds',
      icon: 'icon-puzzle',
      data: 'embed',
      view() {
        if ($scope.current) {
          //$location.path("embeds")
          //.search {campaign_id: $scope.current}
          return (location.href = `/embeds?campaign_id=${$scope.current}`);
        } else {
          return $location.path('embeds');
        }
      },
      new() {
        return openModal('Select Embed Type', 'embeds');
      },
    },
    {
      title: 'Peer-to-Peer',
      icon: 'icon-peer',
      data: 'p2p',
      view() {
        if ($scope.current) {
          return $location.path('p2p').search({ campaign_id: $scope.current });
        } else {
          return $location.path('p2p');
        }
      },
      new() {
        return p2pService.getAllTemplates().then((templates) =>
          p2pService
            .createNewProgram({
              status: 'draft',
              template_id: templates.result[0].id,
            })
            .then(function (data) {
              const { id } = data.result;
              if (id) {
                return $location.path(`legacy-p2p/program/edit/${id}`);
              }
            })
        );
      },
    },
    {
      title: 'Text-to-Give',
      icon: 'icon-phone',
      data: 'sms',
      view() {
        if ($scope.current) {
          return $location.path('text').search({ campaign_id: $scope.current });
        } else {
          return $location.path('text');
        }
      },
      new() {
        return $location
          .path('text/giving-keyword/new')
          .search({ campaign_id: $scope.current });
      },
    },
    {
      title: 'Events',
      icon: 'icon-ticket',
      data: 'organization_event',
      view() {
        if ($scope.current) {
          return $location
            .path('organization_event')
            .search({ campaign_id: $scope.current });
        } else {
          return $location.path('events');
        }
      },
      new() {
        return openModal('Select Landing Page Template', 'events');
      },
    },
    {
      title: 'Landing Pages',
      icon: 'icon-website',
      data: 'page',
      view() {
        if ($scope.current) {
          return $location
            .path('pages')
            .search({ campaign_id: $scope.current });
        } else {
          return $location.path('pages');
        }
      },
      new() {
        return openModal('Select Landing Page Template', 'pages');
      },
    },
  ]);
};
