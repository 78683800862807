// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').filter(
  'percentage',
  ($filter) =>
    function (input, decimals) {
      const filtered = $filter('number')(input * 100, decimals);
      if (filtered) {
        return filtered + '%';
      } else {
        return null;
      }
    }
);
