export class GmsAPI {
  static $inject = ['RestAPI', 'appState'];

  constructor(RestAPI, appState) {
    this.RestAPI = RestAPI;

    this.organizationId = () => {
      return appState.currentOrganization.id;
    };
  }

  get(path, data) {
    return this.RestAPI.get(
      `organization/${this.organizationId()}/${path}`,
      data
    );
  }

  post(path, data) {
    return this.RestAPI.post(
      `organization/${this.organizationId()}/${path}`,
      data
    );
  }
}
