// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
angular.module('idonate.gms').component('dashboardChannelMetric', {
  templateUrl: '/scripts/dashboard/dashboard-channel-metric.html',
  bindings: {
    title: '<?',
    icon: '<?',
    view: '&?',
    analytics: '<?',
  },
});
