// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('idonate.gms').factory('notyService', function () {
  const globalOpts = {
    animation: {
      open: 'animated fadeIn',
      close: 'animated fadeOut',
      easing: 'swing',
      speed: 200,
    },
    theme: 'idonate',
    layout: 'topRight',
    timeout: 4000,
  };
  return {
    closeAll() {
      return Noty.closeAll();
    },
    success(msg, opts) {
      opts = opts || {};
      opts = angular.extend(angular.copy(globalOpts), opts);
      opts.text = msg;
      opts.type = 'success';
      return new Noty(opts).show();
    },
    info(msg, opts) {
      opts = opts || {};
      opts = angular.extend(angular.copy(globalOpts), opts);
      opts.text = msg;
      opts.type = 'info';
      return new Noty(opts).show();
    },
    error(msg, opts) {
      opts = opts || {};
      opts = angular.extend(angular.copy(globalOpts), opts);
      opts.text = msg;
      opts.type = 'error';
      return new Noty(opts).show();
    },
    confirm(msg, successBtn, cancelBtn, opts) {
      opts = opts || {};
      opts = angular.extend(angular.copy(globalOpts), opts);
      opts.text = msg;
      opts.type = 'confirm';
      opts.buttons = [
        Noty.button(successBtn.label, 'btn btn-primary', successBtn.action),
        Noty.button(cancelBtn.label, 'btn btn-primary', cancelBtn.action),
      ];
      return new Noty(opts).show();
    },
  };
});
