angular.module('idonate.gms').component('chapter', {
  template: `
    <div class="page-heading">
      <ol class="breadcrumb">
        <li>Chapter</li>
      </ol>
    </div>

    <div class="row">
      <nav class="tab-menu">
        <ul>
          <li class="menu-item selected"><a>Performance</a></li>
          <li class="menu-item"><a ng-href="/chapter/hierarchy">Hierarchy</a></li>
        </ul>
      </nav>
    </div>
    <div class="page-full-container summary-dashboard">
      <selector-bar date="$ctrl.dateRange" dash="$ctrl.dash" options="$ctrl.options"></selector-bar>
      <div class="panel" ng-switch="$ctrl.dash.id">
        <chapter-chart date-range="$ctrl.dateRange"></chapter-chart>
        <chapter-indicators ng-switch-when="indicators" date-range="$ctrl.dateRange"></chapter-indicators>
        <chapter-channels ng-switch-when="channel" date-range="$ctrl.dateRange"></chapter-channels>
      </div>
    </div>
  `,
  controller() {
    this.options = [
      {
        id: 'indicators',
        title: 'Key Giving Indicators',
      },
      {
        id: 'channel',
        title: 'Giving by Channel',
      },
    ];
  },
});
