// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

angular.module('idonate.gms').directive('gmsKvpList', ($timeout) => ({
  restrict: 'E',
  templateUrl: '/scripts/input/kvp-list.html',
  scope: {
    value: '=',
    field: '=',
    onRemove: '=',
  },

  controller($scope, $element, $attrs) {
    $scope.formatedData = [];
    $scope.imageList = [];
    $scope.newItem = {};
    $scope.newImage = null;

    if ($scope.field && $scope.field['limit']) {
      $scope.limit =
        parseInt($scope.field.limit) > 0 ? parseInt($scope.field.limit) : false;
    }

    const addImageField = (url) =>
      $scope.imageList.push({
        upload_path: $scope.field.value_upload_path,
        name: $scope.field.value_name,
        value: url,
        label: `#${($scope.imageList.length + 1).toString()}`,
      });
    const unzipData = function () {
      if ($scope.field) {
        if (typeof $scope.value === 'string') {
          $scope.value = JSON.parse($scope.value);
        }

        if ($scope.field.value_data_type === 'image') {
          angular.forEach($scope.value, (item) => addImageField(item[1]));

          return (() => {
            const result = [];
            while (
              $scope.imageList.length < ($scope.field.placeholder_count || 1)
            ) {
              result.push($scope.addNewImage());
            }
            return result;
          })();
        } else {
          return angular.forEach($scope.value, (item) =>
            $scope.formatedData.push({
              title: item[0],
              value: item[1],
            })
          );
        }
      }
    };

    const zipData = function () {
      if ($scope.formatedData.length >= 0 || $scope.imageList.length >= 0) {
        $scope.value = [];
        if ($scope.field.value_data_type === 'image') {
          return angular.forEach($scope.imageList, function (image, index) {
            if (image.value) {
              return $scope.value.push([index, image.value]);
            }
          });
        } else {
          return angular.forEach($scope.formatedData, (item) =>
            $scope.value.push([item.title, item.value])
          );
        }
      }
    };

    $scope.$watchCollection('formatedData', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        return zipData();
      }
    });

    $scope.$watch(
      'imageList',
      function (newVal, oldVal) {
        if (newVal !== oldVal) {
          return zipData();
        }
      },
      true
    );

    $scope.addNewItem = function () {
      if (
        ($scope.limit && $scope.formatedData.length < $scope.limit) ||
        !$scope.limit
      ) {
        $scope.formatedData.push($scope.newItem);
        return ($scope.newItem = {});
      }
    };

    $scope.removeItem = function ($index, item) {
      if (item && typeof $scope.onRemove === 'function') {
        $scope.onRemove(item);
      }
      return $scope.formatedData.splice($index, 1);
    };

    $scope.addNewImage = function () {
      if (
        ($scope.limit && $scope.imageList.length < $scope.limit) ||
        !$scope.limit
      ) {
        return addImageField('');
      }
    };

    unzipData();

    return $scope.$watch('field', function (ov, nv) {
      if (nv !== ov) {
        $scope.formatedData = [];
        $scope.imageList = [];
        $scope.newItem = {};
        $scope.newImage = null;
        if ($scope.field['limit']) {
          $scope.limit =
            parseInt($scope.field.limit) > 0
              ? parseInt($scope.field.limit)
              : false;
        }
        return unzipData();
      }
    });
  },
}));
