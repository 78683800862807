const DesignationService = function (SessionService, organizationService) {
  const getEmbedDesignations = function (embedId, _org) {
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/embed/instances/${embedId}`, 'designations')
      .get();
  };

  const getOrganizationDesignations = function (embedId = null) {
    const _org = organizationService.getCurrentOrganization();
    if (embedId) {
      return getEmbedDesignations(embedId, _org);
    } else {
      return SessionService.restangularGmsApi
        .one(`organization/${_org.id}`, 'designations')
        .get();
    }
  };

  const addOrganizationDesignations = function (obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/designations`)
      .post(obj);
  };

  const reorderOrganizationDesignation = function (obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/designations/reorder`)
      .post(obj);
  };

  const editOrganizationDesignations = function (obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/designations/${obj.id}`)
      .customOperation('put', null, null, null, obj);
  };

  const disableOrganizationDesignation = function (designation_id) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/designations/${designation_id}/is_active`)
      .customOperation('put', null, null, null, { is_active: 'false' });
  };

  const enableOrganizationDesignation = function (designation_id) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .all(`organization/${_org.id}/designations/${designation_id}/is_active`)
      .customOperation('put', null, null, null, { is_active: 'true' });
  };

  return {
    getOrganizationDesignations,
    getEmbedDesignations,
    addOrganizationDesignations,
    editOrganizationDesignations,
    disableOrganizationDesignation,
    enableOrganizationDesignation,
    reorderOrganizationDesignation,
  };
};

angular.module('idonate.gms').factory('designationService', DesignationService);
