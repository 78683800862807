// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const AnalyticsService = function (SessionService, organizationService) {
  const init = () => ({
    getAnalyticsSummary,
    getOrganizationEmbeds,
    getEmbedVisits,
  });
  var getAnalyticsSummary = function (id, obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/embed/instances/${id}/analytics_summary`)
      .get(obj);
  };

  var getOrganizationEmbeds = function (obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/dashboard/embeds`)
      .get(obj);
  };

  var getEmbedVisits = function (obj) {
    const _org = organizationService.getCurrentOrganization();
    return SessionService.restangularGmsApi
      .one(`organization/${_org.id}/visits`)
      .get(obj);
  };

  return init();
};

angular
  .module('idonate.gms.analytics')
  .factory('analyticsService', AnalyticsService);
