// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ChartLegendCtrl;
angular.module('idonate.gms').component('chartLegend', {
  transclude: true,
  template: `\
<ul class="line-legend" ng-transclude>
</ul>\
`,
  bindings: {
    colors: '=',
  },
  controller: (ChartLegendCtrl = class ChartLegendCtrl {
    constructor() {
      this.register = this.register.bind(this);
      this.deregister = this.deregister.bind(this);
      this._fixColors = this._fixColors.bind(this);
      this.chartSeries = [];
    }
    $onInit() {}
    register(series) {
      this.chartSeries.push(series);
      if (this.chartSeries.length <= this.colors.length) {
        return series.changeColor(this.colors[this.chartSeries.length - 1]);
      }
    }
    deregister(series) {
      _.remove(this.chartSeries, (value, index, array) => series === value);
      return this._fixColors();
    }
    _fixColors() {
      for (let index = 0; index < this.chartSeries.length; index++) {
        const series = this.chartSeries[index];
        series.changeColor(this.colors[index]);
      }
    }
  }),
});
