// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').filter(
  'elipses',
  ($sce, $filter) =>
    function (text, limit) {
      let newText = text;
      if ((text != null ? text.length : undefined) > limit) {
        newText = $filter('limitTo')(text, limit - 3) + '...';
      }
      return newText;
    }
);
