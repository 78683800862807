angular
  .module('idonate.gms')
  .directive('campaignSelector', function (CampaignService) {
    return {
      restrict: 'E',
      template: `\
      <ui-select class="idonate-select sm-select2" ng-model="ctrl.selectedCampaign" >
        <ui-select-match placeholder="Filter by Campaign" allow-clear="true">
          <span ng-bind="$select.selected.title"></span>
        </ui-select-match>
        <ui-select-choices repeat="item in (ctrl.campaigns | filter: $select.search) track by item.id">
          <span ng-bind="item.title"></span>
        </ui-select-choices>
      </ui-select>`,
      scope: {
        default: '=?',
        selectedCampaign: '=campaign',
      },
      bindToController: true,
      controllerAs: 'ctrl',
      controller($scope) {
        const ctrl = this;
        CampaignService.getCampaigns().then(function (result) {
          ctrl.campaigns = result.campaigns;
          if (ctrl.default) {
            const selected = _.find(
              ctrl.campaigns,
              (item) => item.id === ctrl.default
            );
            if (selected != null) {
              ctrl.selectedCampaign = selected;
            }
          }
        });
      },
    };
  });
