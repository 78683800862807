// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
'use strict';

angular
  .module('idonate.gms')
  .filter(
    'trustAsResourceUrl',
    ($sce) => (val) => $sce.trustAsResourceUrl(val)
  );
