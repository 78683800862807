angular.module('idonate.gms').directive('onClickSelect', () => ({
  restrict: 'A',
  link(scope, elem, attrs) {
    elem.bind('click', function () {
      let el = angular.element(
        document.querySelector(`#${attrs.onClickSelect}`)
      );
      if (el) {
        el = el[0];
        setTimeout(function () {
          el.focus();
          el.select();
        }, 0);
      }
    });
  },
}));
