// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

const AnchorSiteSetup = function (
  $scope,
  $uibModal,
  $sce,
  p2pService,
  fullOrgData,
  $timeout
) {
  const ANCHORURL =
    window.iDonateConfig.legacyP2PBaseUrl + fullOrgData.result.slug;

  // make this resource trusted url

  $scope.saveButtonText = 'Save';

  const getIframeSource = function () {
    $scope.loginUrl = ANCHORURL + '/login';
    return $sce.trustAsResourceUrl(ANCHORURL);
  };
  $scope.iframeSrc = getIframeSource();

  p2pService.getAnchorDetails().then(function (data) {
    const anchor = data.result.attributes.p2p.anchor_page;
    $scope.faq_section_header = data.result.attributes.p2p.faq_section_header;
    $scope.faqs = data.result.attributes.p2p.faqs;
    if ($scope.faqs.length < 1) {
      // by default have 2 faq
      $scope.faqs.push(['', '']);
      $scope.faqs.push(['', '']);
    }

    $scope.anchorFields = [
      {
        name: 'title',
        data_type: 'text',
        label: 'Page Title',
        placeholder: '[Org] Fundraiser Programs',
        value: anchor.title || '',
      },
      {
        name: 'description',
        data_type: 'textarea',
        label: 'About Your Programs',
        placeholder: 'Type or paste information here',
        value: anchor.description || '',
        displayFlat: false,
        optionsSummerNote: {
          disableDragAndDrop: true,
          toolbar: [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['insert', ['link']],
          ],
        },
      },
      {
        name: 'faq.section_header',
        data_type: 'text',
        label: 'FAQ section heading',
        placeholder: 'Type section heading here...',
        value: $scope.faq_section_header || '',
      },
    ];
    // populate anchor fields with available faqs
    return angular.forEach($scope.faqs, function (faq, key) {
      const nextFaqNumber = key + 1;
      return $scope.anchorFields.push(
        {
          name: 'faq.question',
          data_type: 'textarea',
          label: `FAQ: Question ${nextFaqNumber}`,
          placeholder: 'Type or paste question here',
          value:
            ($scope.faqs[key] != null ? $scope.faqs[key][0] : undefined) || '',
          displayFlat: true,
        },
        {
          name: 'faq.answer',
          data_type: 'textarea',
          label: `FAQ: Answer ${nextFaqNumber}`,
          placeholder: 'Type or paste answer here',
          value:
            ($scope.faqs[key] != null ? $scope.faqs[key][1] : undefined) || '',
          displayFlat: false,
          optionsSummerNote: {
            disableDragAndDrop: true,
            toolbar: [
              ['style', ['bold', 'italic', 'underline', 'clear']],
              ['insert', ['link']],
            ],
          },
        }
      );
    });
  });

  // add other faq questions if the data is already available in the backend
  // else keep them hidden until the user clicks on add new faq button
  $scope.addNewFaqButton = 'Add new FAQ';
  $scope.addNewFaq = function () {
    const currentLength = $scope.faqs.length;
    const nextFaqNumber = currentLength + 1;
    const maxFaq = 4;
    if (currentLength < maxFaq) {
      $scope.faqs.push(['', '']);
      return $scope.anchorFields.push(
        {
          name: 'faq.question',
          data_type: 'textarea',
          label: `FAQ: Question ${nextFaqNumber}`,
          placeholder: 'Type or paste question here',
          value: '',
          displayFlat: true,
        },
        {
          name: 'faq.answer',
          data_type: 'textarea',
          label: `FAQ: Answer ${nextFaqNumber}`,
          placeholder: 'Type or paste answer here',
          value: '',
          displayFlat: false,
          optionsSummerNote: {
            disableDragAndDrop: true,
            toolbar: [
              ['style', ['bold', 'italic', 'underline', 'clear']],
              ['insert', ['link']],
            ],
          },
        }
      );
    }
  };

  $scope.saveAnchor = function () {
    $scope.saveButtonText = 'Saving...';

    const _toPost = {};
    const _faqs = [];
    let _faq_section_header = $scope.faq_section_header;

    angular.forEach($scope.anchorFields, function (field) {
      if (field.name === 'faq.section_header') {
        return (_faq_section_header = field.value);
      } else if (field.name === 'faq.question') {
        return _faqs.push([field.value, '']);
      } else if (field.name === 'faq.answer') {
        return (_faqs[_faqs.length - 1][1] = field.value);
      } else {
        return (_toPost[field.name] = field.value);
      }
    });

    const details = {
      p2p: {
        faqs: _faqs,
        faq_section_header: _faq_section_header,
        anchor_page: _toPost,
      },
    };

    return p2pService.saveAnchorDetails(details).then(function (res) {
      $scope.saveButtonText = 'Saved';
      $scope.iframeSrc = null;
      $timeout(() => ($scope.iframeSrc = getIframeSource()), 100);
      return $timeout(() => ($scope.saveButtonText = 'Save'), 1500);
    });
  };

  $scope.openModal = (text) =>
    $uibModal.open({
      size: 'md',
      scope: $scope,
      windowClass: 'copy-code-modal',
      templateUrl: '/scripts/website/copy-snippet-modal.html',
      controller($scope, $uibModalInstance) {
        const ua = navigator.userAgent.toLowerCase();
        $scope.safari = false;
        if (ua.indexOf('safari') !== -1) {
          if (ua.indexOf('chrome') > -1) {
            // chrome should take care of it automatically
          } else {
            $scope.safari = true;
          }
        }

        $scope.text = text;

        return ($scope.close = () => $uibModalInstance.close());
      },
    });
};

angular
  .module('idonate.gms.legacy-p2p')
  .controller('AnchorSiteSetup', AnchorSiteSetup);
