const authModule = angular.module('idonate.gms.auth', []);

authModule.run(function ($rootScope, auth, appState) {
  $rootScope.auth = auth;

  // alias a few things to $rootScope for legacy code..
  $rootScope.allowRole = auth.allowRole;
  $rootScope.allowFeature = auth.allowFeature;

  // handle route-based auth.
  $rootScope.$on('$routeChangeStart', function (event) {
    if (!appState.isAuthenticated || !appState.currentUser) {
      // auth0.factory (currently) handles redirecting away and back, no other
      // route manipulation needed here - just prevent controllers from being loaded.
      event.preventDefault();
    }
  });
});

authModule.config(($routeProvider) => {
  return $routeProvider.when('/logout', {
    controller(auth) {
      auth.logout();
    },

    // a template is required for the controller to function
    template: '',

    // allow anyone to log out :D
    menu: {
      allowFor: 'allRoles',
      allowFeatureFor: 'allFeatures',
    },
  });
});

export default authModule;
