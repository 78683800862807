// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class GMSDynamicField {
  /* @ngInject */
  constructor($rootScope, $scope) {
    this.showField = this.showField.bind(this);
    this.$rootScope = $rootScope;

    // GMS2: populate 'campaign' properties from URL and hide inputs when wrapped in iframe
    if (window != window.parent) {
      if ($scope.$parent.field.data_type == 'campaign') {
        const campaignIdMatch = location.search.match(/campaign_id\=([^&]*)/);

        if (campaignIdMatch) {
          $scope.$parent.field.value = campaignIdMatch[1];
          $scope.$parent.field.hidden = true;
        }
      }
    }
  }

  showField(field, types) {
    if (!field) {
      return undefined;
    }
    if (!Array.isArray(types)) {
      types = [types];
    }
    for (let type of Array.from(types)) {
      if (
        field.data_type === type &&
        (!field.require_feature ||
          (field.require_feature &&
            this.$rootScope.allowFeature(field.require_feature)))
      ) {
        return true;
      }
    }
    return false;
  }
  // use if you want hidden to support a function object
  isHidden() {
    if (typeof this.field.hidden == 'function') {
      return this.field.hidden();
    } else {
      return this.field.hidden;
    }
  }
}

angular.module('idonate.gms.website').component('gmsDynamicField', {
  templateUrl: '/scripts/website/gmsdynamicfields.html',
  bindings: {
    field: '=fieldData',
    page: '=pageData',
    completeFieldSet: '=fieldSet',
    callbacks: '=callbacks',
    extraClass: '@',
    extraData: '=',
    type: '@',
    extraIndex: '=',
  },
  controller: GMSDynamicField,
});
