import { ProfileCtrl } from './profile';

angular.module('idonate.gms.user', []).config(($routeProvider) =>
  $routeProvider
    //Profile
    .when('/profile', {
      templateUrl: '/scripts/user/profile.html',
      controller: ProfileCtrl,

      // profile does not actually show on the main menu but must be defined for auth reasons.
      menu: {
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/help', {
      menu: {
        style: 'icon-question-mark',
        name: 'Help',
        level: 1,
        position: 1300,
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
      template: '',
      controller(Platform) {
        Platform.getConfig().then((config) => {
          window.location.replace(config.environment.helpUrl);
        });
      },
    })
);
