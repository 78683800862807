import './transactions.html';

angular
  .module('idonate.gms.donations')
  .controller(
    'DonationsReportsCtrl',
    function ($scope, organizationService, $location, $timeout) {
      const currentOrganization = organizationService.getCurrentOrganization();
      $scope.orgId = currentOrganization.id;
      $scope.mappings =
        currentOrganization.external_service_mapping_configurations.map(
          (item) => {
            return { ...item, show: false, filterVal: true };
          }
        );

      $scope.filterOptions = [
        { id: 'donation_date', name: 'Donated Date' },
        { id: 'funding_date', name: 'Funded Date' },
      ];
      $scope.filter = {};
      $scope.filter.selected = $scope.filterOptions[0];

      $scope.clickOnRow = function (id, params) {
        $location.path(`/donations/detail/${id}`).search(params);
        $scope.$apply();
      };

      let drawTimeout = null;
      $scope.$watch(
        'buildColumns',
        (newVal) =>
          ($scope.mappings = $scope.mappings.map((mapping) => {
            return {
              ...mapping,
              show:
                newVal &&
                newVal.find(
                  (item) => item.name == `external_mapping.${mapping.key}`
                )
                  ? true
                  : false,
            };
          }))
      );
      $scope.intervalCallback = (current, previous) => ($scope.date = current);
      $scope.onFilterTypeChange = function (newVal) {
        $scope.reportData.filter_by = newVal.id;
        $scope.onFilterChange(newVal);
      };
      $scope.onFilterChange = (newVal) => {
        $timeout(() => {
          if (!$scope.table) {
            return;
          }
          drawTimeout && $timeout.cancel(drawTimeout);
          $scope.mappings.map((mapping) =>
            $scope.table
              .columns(
                $scope.buildColumns.findIndex(
                  (item) => item.name == `external_mapping.${mapping.key}`
                )
              )
              .search(mapping.filterVal ? '' : '!null')
          );
          drawTimeout = $timeout(() => $scope.table.draw());
        });
      };
      $scope.tableReloaded = () => $scope.onFilterChange($scope.filterVal);
    }
  );
