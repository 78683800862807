angular
  .module('idonate.gms.donations')
  .factory('transactionService', (SessionService, organizationService) => ({
    getTransactionList(cascade) {
      const _org = organizationService.getCurrentOrganization();
      return SessionService.restangularGmsApi.all('transaction').customGET('', {
        organization_id: _org.id,
        cascade,
      });
    },
    getTransactionReportSummary(obj) {
      return SessionService.restangularGmsApi
        .one('transaction/dashboard_data')
        .get(obj);
    },
    getTransaction(id) {
      return SessionService.restangularGmsApi.one('transaction', id).get();
    },
    getTransactionWithOffset(obj) {
      return SessionService.restangularGmsApi
        .all(`transaction/offset`)
        .post(obj);
    },
    postTransactionResendReceipt(id) {
      return SessionService.restangularGmsApi
        .one(`transaction/${id}/resend_receipt`)
        .post();
    },
    postTransactionRefund(id) {
      return SessionService.restangularGmsApi
        .one(`transaction/${id}/refund`)
        .post();
    },
    postTransactionCancel(id) {
      return SessionService.restangularGmsApi
        .one(`transaction/${id}/cancel`)
        .post();
    },
    postTransaction(transaction) {
      return SessionService.restangularGmsApi
        .all('transaction')
        .post(transaction);
    },
    downloadOfflineTemplate() {
      SessionService.downloadGmsApiResult(
        'import/transaction',
        {},
        'Offline-Transaction-Importer-Template.csv'
      );
    },
    getFundingList(params) {
      const _org = organizationService.getCurrentOrganization();
      return SessionService.restangularGmsApi
        .all(`organization/${_org.id}/merchant/funding/list`)
        .customGET('', params);
    },
    getFunding(id) {
      const _org = organizationService.getCurrentOrganization();
      return SessionService.restangularGmsApi
        .one(`organization/${_org.id}/merchant/funding`, id)
        .get();
    },
    getExceptionList(params) {
      const _org = organizationService.getCurrentOrganization();
      return SessionService.restangularGmsApi
        .all(`organization/${_org.id}/merchant/exception/list`)
        .customGET('', params);
    },
    updateTransactionDetail(obj) {
      return SessionService.restangularGmsApi
        .one(`transaction/${obj.parent_id}/details`)
        .customPOST(obj);
    },
    deleteTransactionDetail(obj) {
      return SessionService.restangularGmsApi
        .one(`transaction/${obj.parent_id}/details?key=${obj.key}`)
        .customDELETE(null);
    },
    updateTransactionCampaign(transactionObj) {
      console.log(transactionObj);
      return SessionService.restangularGmsApi
        .one(`transaction/${transactionObj.transactionId}`)
        .patch({
          campaign_id: transactionObj.campaign_id,
          designation_id: transactionObj.designation_id,
          p2p_fundraiser_id: transactionObj.fundraiser_id,
        });
    },
  }));
