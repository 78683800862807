angular.module('idonate.gms').component('chapterChannels', {
  template: `
    <sortable-table conf="tableOptions">
      <table class="table bootstrap-datatable">
        <thead>
          <tr>
            <th class="hidden">Path</th>
            <th>Chapter</th>
            <th>Embed Total</th>
            <th>P2P Total</th>
            <th>SMS Total</th>
            <th>Events Total</th>
            <th>Landing Pages Total</th>
          </tr>
        </thead>
        <tbody>
          <tr ng-repeat="chapter in $ctrl.chapterList">
            <td class="hidden">{{chapter.path}}</td>
            <td>{{chapter.name}}</td>
            <td>{{chapter.embed.donation_total || 0 | currency:$root.currencyCode:2}}</td>
            <td>{{chapter.p2p.donation_total || 0 | currency:$root.currencyCode:2}}</td>
            <td>{{chapter.sms.donation_total || 0 | currency:$root.currencyCode:2}}</td>
            <td>{{chapter.organization_event.donation_total || 0 | currency:$root.currencyCode:2}}</td>
            <td>{{chapter.page.donation_total || 0 | currency:$root.currencyCode:2}}</td>
          </tr>
        </tbody>
      </table>
    </sortable-table>
  `,
  bindings: {
    dateRange: '<?',
  },
  controller(DashboardAPI, $scope, $rootScope, $location) {
    this.chapterList = [];
    this.onInit = () =>
      this.dateRange &&
      DashboardAPI.getChapterChannels({
        start_date: this.dateRange.current.start.toISOString(),
        end_date: this.dateRange.current.end.toISOString(),
        previous_start_date: this.dateRange.previous.start.toISOString(),
        previous_end_date: this.dateRange.previous.end.toISOString(),
        timezone: moment.tz.guess(),
      })
        .then((result) => (this.chapterList = result))
        .then(() => $scope.$broadcast('reloadTable'));
    this.$onChanges = () => this.onInit();
  },
});
