// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms.legacy-p2p').directive('p2pMetrics', () => ({
  templateUrl: '/scripts/legacy-p2p/p2p-metrics.html',
  restrict: 'E',
  scope: {
    current: '=',
    previous: '=',
    programs: '=',
    campaignId: '=',
    campaign: '=',
  },
  controller($scope, $routeParams, $location, $timeout, p2pService) {
    $scope.showDisabledItems = false;
    $scope.campaignId = $routeParams.campaign_id;
    $scope.pageTabSelected = {
      all: true,
      program: false,
    };
    $scope.metricsTooltips = {
      top: 'Programs identified in green are in the top 1/3 of all programs in total donations',
      middle:
        'Programs identified in yellow are in the middle 1/3 of all programs in total donations',
      low: 'Programs identified in red are in the bottom 1/3 of all programs in total donations',
    };

    const filterByCampaign = function () {
      if ($scope.campaign != null) {
        $scope.programs = _.filter(
          $scope.allPrograms,
          (item) => item.campaign_title === $scope.campaign.title
        );
      } else {
        $scope.programs = $scope.allPrograms;
      }

      if ($scope.programs && !$scope.showDisabledItems) {
        return ($scope.programs = Array.from($scope.programs).filter(
          (p) => p.status !== 'archived'
        ));
      }
    };

    $scope.$watch('campaign', function (a, b) {
      if (a != null && a !== b) {
        $location.search({ campaign_id: a.id });
      } else if (!a && b != null) {
        $location.search({});
      }
      return filterByCampaign();
    });

    $scope.$on(
      'onShowDisabledItemsChanged',
      function (event, isShowDisabledItems) {
        $scope.showDisabledItems = isShowDisabledItems;
        return filterByCampaign();
      }
    );

    return ($scope.intervalCallback = function (current, previous) {
      const interval = {
        start_date: current.start.toISOString(),
        end_date: current.end.toISOString(),
        previous_start_date: previous.start.toISOString(),
        previous_end_date: previous.end.toISOString(),
        campaign_id: $scope.campaignId || null,
        timezone: moment.tz.guess(),
      };
      p2pService.getP2PProgram(interval).then(function (data) {
        const { result } = data;
        $scope.allPrograms = result.programs;
        filterByCampaign();
        $scope.totalRaised = result.amount_raised;
        $scope.raisedPercentage = result.amount_raised_delta_percent;
        $scope.totalAdvocates = result.advocate_count;
        $scope.advocatePercentage = result.advocate_count_delta_percent;
        $scope.totalDonations = result.donation_count;
        return ($scope.chart = result.chart);
      });
    });
  },
}));
