// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import './offline.html';

const QUERIES = require('../../graphql/queries.gql');

angular
  .module('idonate.gms.donations')
  .controller(
    'OfflineCtrl',
    function (
      $rootScope,
      $scope,
      $q,
      organizationService,
      p2pService,
      $location,
      transactionService,
      Modals,
      SessionService
    ) {
      let allPrograms = [];

      $scope.loadingPrograms = true;
      $scope.validPrograms = [];
      $scope.legacyP2P = true;
      $scope.honorees = [{ firstname: '', lastname: '', message: '' }];
      let allDesignationList = [];
      $scope.states = [
        'AK',
        'AL',
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'DC',
        'DE',
        'FL',
        'GA',
        'GU',
        'HI',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MH',
        'MI',
        'MN',
        'MO',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'PR',
        'PW',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VA',
        'VI',
        'VT',
        'WA',
        'WI',
        'WV',
        'WY',
      ];
      $scope.types = [
        {
          id: 'credit',
          title: 'Credit Card',
        },
        {
          id: 'debit',
          title: 'Debit Card',
        },
        {
          id: 'echeck',
          title: 'eCheck',
        },
        {
          id: 'cash',
          title: 'Cash',
        },
        {
          id: 'check',
          title: 'Check',
        },
      ];
      $scope.provinces = [
        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'ON',
        'PE',
        'QC',
        'SK',
      ];
      $scope.errorMessages = [];
      $scope.showValidation = false;

      organizationService
        .loadFullOrganizationInformation()
        .then(function (result) {
          $scope.org = result.result;
          allDesignationList = result.result.designations;
          $scope.designationList = allDesignationList;
          $scope.campaigns = result.result.campaigns;

          const newDeferred = $q.defer();
          const legacyDeferred = $q.defer();

          // New P2P Program Lookup - requires organization
          SessionService.graphql
            .query({
              query: QUERIES.getPrograms,
              variables: { organizationId: $scope.org.id },
            })
            .then((result) => {
              console.log(
                'Fetching of new p2p programs complete, raw results',
                result
              );
              const newPrograms = result.data.programs;
              newPrograms.forEach((program) => {
                if (program.status.toLowerCase() != 'draft') {
                  allPrograms.push(normalizeProgram(program, false));
                }
              });
              newDeferred.resolve();
            });

          // Legacy P2P Program lookup
          p2pService.getAllAdvocacyPrograms().then((result) => {
            console.log(
              'Fetching of legacy p2p programs complete, raw results',
              result
            );
            const legacyPrograms = result.result;
            legacyPrograms.forEach((program) => {
              if (program.status.toLowerCase() != 'draft') {
                allPrograms.push(normalizeProgram(program, true));
              }
            });
            legacyDeferred.resolve();
          });

          $q.all([newDeferred.promise, legacyDeferred.promise]).then(() => {
            $scope.loadingPrograms = false;
            allPrograms.sort((a, b) => {
              // sort legacy to the bottom of the list
              if (a.isLegacy && !b.isLegacy) {
                return 1;
              } else if (b.isLegacy && !a.isLegacy) {
                return -1;
              }

              // otherwise they're both legacy or both not. Now sort by name
              return (a.name || '').localeCompare(b.name || '');
            });

            $scope.validPrograms = allPrograms.map((program) =>
              Object.assign({}, program)
            );

            if ($scope.selectedCampaign) {
              $scope.validPrograms = $scope.validPrograms.filter((program) => {
                return program.campaign_title === $scope.selectedCampaign.title;
              });
            }
          });
        });

      function normalizeProgram(program, isLegacy) {
        program.isLegacy = isLegacy;
        program.displayName = `${program.isLegacy ? 'Legacy: ' : ''}${
          program.name
        }${
          program.status.toLowerCase() !== 'active'
            ? ` [${program.status.toLowerCase()}]`
            : ''
        }`;

        // normalize campaign title
        if (!isLegacy) {
          program.campaign_title = program.campaign
            ? program.campaign.title
            : null;
        }

        return program;
      }

      function getSelectedCampaign(campaignId) {
        if (campaignId && $scope.campaigns) {
          return $scope.campaigns.find(
            (campaign) => campaign.id === campaignId
          );
        }
      }

      function clearPrograms() {
        $scope.validPrograms.length = 0;
        $scope.program = null;
      }

      function clearFundraisers() {
        if ($scope.validAdvocates) {
          $scope.validAdvocates.length = 0;
        }
        $scope.advocate = null;
      }

      $scope.$watch('campaign', function (campaignId) {
        const selectedCampaign = getSelectedCampaign(campaignId);
        if (selectedCampaign) {
          $scope.selectedCampaign = selectedCampaign;

          // clear existing and selected programs/fundraisers
          clearPrograms();
          clearFundraisers();

          // filter by campaign if legacy program
          $scope.validPrograms = allPrograms.filter((program) => {
            return (
              program.status.toLowerCase() != 'draft' &&
              program.campaign_title === selectedCampaign.title
            );
          });
        }

        if (campaignId) {
          $scope.designationList = $scope.selectedCampaign.designations;
        } else {
          $scope.designationList = allDesignationList;
        }
      });

      function refreshValidation() {
        const form = $scope.offlineForm;
        let errMessages = [];

        if (form.$invalid) {
          angular.forEach(form.$error, (errors, errorGroup) => {
            errMessages = errMessages.concat(
              errors.map((err) => err.$name + ': ' + errorGroup)
            );
          });
        }

        // address-field workarounds.
        if ($scope.address.value && !$scope.address.details) {
          // a value was entered but it did not provide a valid address
          errMessages.push(
            `"${$scope.address.value}" is not a recognized address.  An unrecognized address may still be entered manually with "Edit Address".`
          );
        }

        $scope.errorMessages = errMessages;

        return errMessages.length == 0;
      }

      $scope.$watch('program', function (programId) {
        if (programId) {
          // Are we dealing with new or legacy p2p?
          const selectedProgram = $scope.validPrograms.find(
            (program) => program.id === programId
          );
          $scope.legacyP2P = selectedProgram.isLegacy;

          // Clear previously selected fundraiser
          clearFundraisers();

          if ($scope.legacyP2P) {
            const time = moment().format();
            p2pService
              .getP2PProgram({
                program_id: programId,
                end_date: time,
                previous_end_date: time,
                start_date: time,
                previous_start_date: time,
              })
              .then(function (result) {
                $scope.validAdvocates = result.result.individuals;
              });
            return;
          } else {
            SessionService.graphql
              .query({
                query: QUERIES.getProgram,
                variables: { id: programId },
              })
              .then((result) => {
                const programFundraisers =
                  result.data.program.fundraisers.fundraiser;
                $scope.validAdvocates = programFundraisers.map((fundraiser) => {
                  fundraiser.advocate_name =
                    getFundraiserDisplayName(fundraiser);
                  return fundraiser;
                });
              });
          }
        }
      });

      function getFundraiserDisplayName(fundraiser) {
        const creator = fundraiser.creator || {};
        const user = creator.user || {};

        if (fundraiser.title) {
          return fundraiser.title;
        }

        // created by
        const createdByName =
          fundraiser.name ||
          creator.name ||
          creator.title ||
          user.name ||
          user.username ||
          user.email;

        return `Fundraiser created by ${createdByName || 'user'}`;
      }

      $scope.designations = [
        {
          id: '',
          val: '',
        },
      ];

      if ($rootScope.allowFeature('multi_designation')) {
        $scope.multiDesignation = true;
      }

      $scope.address = { label: 'Address' };

      $scope.tributeAddress = { label: 'Address' };

      $scope.customNotes = [
        {
          id: '',
          val: '',
        },
      ];

      $scope.dateField = {
        label: 'Date',
        value: moment().format(),
      };
      $scope.extraData = {};

      $scope.cancel = () => $location.path('/donations');

      $scope.addHonoree = () =>
        validateHonorees($scope.honorees) &&
        $scope.honorees.push({ firstname: '', lastname: '', message: '' });

      $scope.save = function (valid) {
        valid = refreshValidation() && valid;
        $scope.showValidation = !valid;

        if (!valid) return;

        let transactionData = {
          organization_id: $scope.org.id,
          subtype: $scope.subtype.id,
          description: 'Offline Donation',
          campaign_id:
            $scope.selectedCampaign != null
              ? $scope.selectedCampaign.id
              : undefined,
          designation_note: $scope.customDesignation,
          contact_firstname: $scope.firstName,
          contact_lastname: $scope.lastName,
          contact_phone: $scope.phone,
          contact_email: $scope.email,
          honorees: $scope.honorees,
          gift_id: $scope.gift,
          gift_extra: $scope.giftExtra,
          sync_with_dms: $scope.syncDms,
          reference_code: $scope.referenceCode,
          company_name: $scope.companyName,
          hide_name: $scope.anonymous,
        };

        angular.forEach(
          $scope.customNotes,
          (val, k) => (transactionData[`custom_note_${k + 1}`] = val.val)
        );

        // New P2P program validation is not required, program id can be
        // determined using fundraiser id.
        if ($scope.program !== '' && $scope.legacyP2P) {
          transactionData.advocacy_program_id = $scope.program;
        }

        if ($scope.advocate) {
          if ($scope.legacyP2P) {
            transactionData.advocate_id = $scope.advocate.advocate_id;
            transactionData.advocacy_team_id = $scope.advocate.team_id;
          } else {
            transactionData.p2p_fundraiser_id = $scope.advocate.id;
            transactionData.hide_name = false;
          }
        }

        let amount = 0;
        transactionData.designations = [];
        for (let des of Array.from($scope.designations)) {
          if (des.id) {
            transactionData.designations.push({ id: des.id, amount: des.val });
          }
          amount += parseFloat(des.val) || 0;
        }
        transactionData.value = amount;
        transactionData.currency_code = $scope.org.currency_code;

        if (
          (!$scope.address1 || $scope.address1 === '') &&
          $scope.address.details
        ) {
          const address = parseAddress(
            $scope.address.details.address_components
          );
          transactionData.contact_address_street = address.address_street;
          transactionData.contact_address_street2 = address.address_street2;
          transactionData.contact_address_country = address.address_country;
          transactionData.contact_address_state = address.address_state;
          transactionData.contact_address_zip = address.address_zip;
          transactionData.contact_address_city = address.address_city;
        } else if ($scope.address1 && $scope.address1 !== '') {
          transactionData.contact_address_street = $scope.address1;
          transactionData.contact_address_street2 = $scope.address2;
          transactionData.contact_address_country = $scope.country;
          transactionData.contact_address_state = $scope.state;
          transactionData.contact_address_zip = $scope.zip;
          transactionData.contact_address_city = $scope.city;
        }

        if ($scope.tribute !== '') {
          transactionData = angular.extend(transactionData, {
            tribute_definition_id: $scope.tribute,
            tribute_from_name: $scope.tributeFrom,
            tribute_recipient_email: $scope.tributeEmail,
          });
          if (
            (!$scope.tributeAddress1 || $scope.tributeAddress1 === '') &&
            $scope.tributeAddress.details
          ) {
            const contactAddress = {};
            for (let det of Array.from(
              $scope.tributeAddress.details.address_components
            )) {
              for (let type of Array.from(det.types)) {
                if (type === 'street_number') {
                  contactAddress.tribute_address1 =
                    det.short_name + ' ' + contactAddress.tribute_address1;
                } else if (type === 'administrative_area_level_1') {
                  contactAddress.tribute_state = det.short_name;
                } else if (type === 'country') {
                  contactAddress.tribute_country = det.short_name;
                } else if (type === 'route') {
                  contactAddress.tribute_address1 =
                    contactAddress.tribute_address1 + ' ' + det.short_name;
                } else if (type === 'locality') {
                  contactAddress.tribute_city = det.short_name;
                } else if (type === 'postal_code') {
                  contactAddress.tribute_zip = det.short_name;
                }
              }
            }
            transactionData = angular.extend(transactionData, contactAddress);
          } else if ($scope.tributeAddress1 && $scope.tributeAddress1 !== '') {
            transactionData.tribute_address1 = $scope.tributeAddress1;
            transactionData.tribute_address2 = $scope.tributeAddress2;
            transactionData.tribute_country = $scope.tributeCountry;
            transactionData.tribute_state = $scope.tributeState;
            transactionData.tribute_zip = $scope.tributeZip;
            transactionData.tribute_city = $scope.tributeCity;
          }
        }

        transactionData.created = moment($scope.dateField.value).toISOString();

        return transactionService
          .postTransaction(transactionData)
          .then((result) => $location.path('/donations'));
      };

      $scope.addDesignation = function () {
        const len = $scope.designations.length;
        if (len < 4) {
          const des = $scope.designations[len - 1];
          if (des.id !== '' && des.val !== '') {
            return $scope.designations.push({
              id: '',
              val: '',
            });
          }
        }
      };

      $scope.addCustomNote = function () {
        const len = $scope.customNotes.length;
        if (len < 5) {
          const des = $scope.customNotes[len - 1];
          if (des.val !== '') {
            return $scope.customNotes.push({
              id: '',
              val: '',
            });
          }
        }
      };

      $scope.showAddress = function () {
        $scope.editDonorAddress = true;

        if ($scope.address.details) {
          const address = parseAddress(
            $scope.address.details.address_components
          );
          $scope.address1 = address.address_street;
          $scope.address2 = address.address_street2;
          $scope.country = address.address_country;
          $scope.state = address.address_state;
          $scope.zip = address.address_zip;
          $scope.city = address.address_city;
        }

        // erase textbox with unparsed address
        $scope.address.value = null;
      };

      $scope.showTributeAddress = function () {
        $scope.editTributeRecipientAddress = true;
        if (!$scope.tributeAddress.details) {
          return;
        }
        const address = parseAddress(
          $scope.tributeAddress.details.address_components
        );
        $scope.tributeAddress1 = address.address_street;
        $scope.tributeAddress2 = address.address_street2;
        $scope.tributeCountry = address.address_country;
        $scope.tributeState = address.address_state;
        $scope.tributeZip = address.address_zip;
        $scope.tributeCity = address.address_city;
      };

      var parseAddress = function (address) {
        const contactAddress = {};
        for (let det of Array.from(address)) {
          for (let type of Array.from(det.types)) {
            if (type === 'street_number') {
              if (contactAddress.address_street) {
                contactAddress.address_street =
                  det.short_name + ' ' + contactAddress.address_street;
              } else {
                contactAddress.address_street = det.short_name;
              }
            } else if (type === 'administrative_area_level_1') {
              contactAddress.address_state = det.short_name;
            } else if (type === 'country') {
              contactAddress.address_country = det.short_name;
            } else if (type === 'route') {
              if (contactAddress.address_street) {
                contactAddress.address_street =
                  contactAddress.address_street + ' ' + det.short_name;
              } else {
                contactAddress.address_street = det.short_name;
              }
            } else if (type === 'locality') {
              contactAddress.address_city = det.short_name;
            } else if (type === 'postal_code') {
              contactAddress.address_zip = det.short_name;
            }
          }
        }
        return contactAddress;
      };

      $scope.bulkImport = () =>
        Modals.open('offlineBulkImport').then(angular.noop, angular.noop);
    }
  );

function validateHonorees(honorees) {
  return (
    honorees.filter(
      (item) => !(item.firstname || item.lastname || item.message)
    ).length == 0
  );
}
