// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('idonate.gms').directive('side', () => ({
  restrict: 'A',
  link(scope, element, attrs) {
    if (attrs['side'] === 'left') {
      element.addClass('pull-left');
    }
    if (attrs['side'] === 'right') {
      return element.addClass('float-right');
    }
  },
}));
