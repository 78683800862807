AppConfig.$inject = [
  '$routeProvider',
  '$locationProvider',
  'RestangularProvider',
  'ChartJsProvider',
  'uiSelectConfig',
];
export function AppConfig(
  $routeProvider,
  $locationProvider,
  RestangularProvider,
  ChartJsProvider,
  uiSelectConfig
) {
  $locationProvider.hashPrefix('');

  // html5Mode is required for path-related things to work.
  $locationProvider.html5Mode(true);
  uiSelectConfig.theme = 'select2';
  ChartJsProvider.setOptions({
    elements: {
      line: { fill: false },
      point: { radius: 5 },
    },
  });

  RestangularProvider.addResponseInterceptor(
    function (data, operation, what, url, response) {
      // this is not fired if there's an error
      captureMessages(response);
      if (operation === 'remove') {
        return null;
      }
      return data;
    }
  );

  RestangularProvider.setErrorInterceptor(function (response) {
    if (
      (response.config.data != null
        ? response.config.data.preventShowError
        : undefined) === true ||
      (response.config.params != null
        ? response.config.params.preventShowError
        : undefined) === true
    ) {
    } else {
      captureWarnings(response);
      captureMessages(
        response,
        (response.data != null ? response.data.message : undefined) ||
          'Unknown error.'
      );
    }
    return true;
  });

  // TODO : move event-management routes to a more appropriate place.
  $routeProvider
    .when('/event-management', {
      // TODO : accept wildcard routes with this as the root and pass into iframe-based app
      templateUrl: '/scripts/organization/event-management.html',
      controller: 'EventMgmtCtrl',
      menu: {
        style: 'icon-ticket',
        name: 'Events',
        level: 1,
        position: 10,
        allowFor: 'allRoles',
        allowFeatureFor: 'event_management_2019',
      },
    })
    .when('/p2p-ui', {
      templateUrl: '/scripts/organization/peer-to-peer.html',
      controller: 'p2pUiCtrl',
      menu: {
        style: 'icon-peer',
        name: 'P2P (New)',
        level: 1,
        position: 6,
        allowFor: 'allRoles',
        allowFeatureFor: 'p2p-2019',
      },
    })
    .when('/p2p-ui/anchor-sites', {
      templateUrl: '/scripts/organization/anchor-sites.html',
      controller: 'anchorSiteCtrl',
      menu: {
        parent: 'P2P (New)',
        name: 'Anchor Sites',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'anchor-sites',
      },
    })
    .when('/organization', {
      templateUrl: '/scripts/organization/organization.html',
      controller: 'OrganizationCtrl',
      redirectTo: '/organization/account',
      menu: {
        style: 'icon-address-book',
        name: 'Organization',
        level: 1,
        position: 13,
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/organization/designated-funds', {
      templateUrl: '/scripts/organization/designated-funds.html',
      controller: 'OrganizationDesignatedFundsCtrl',
      menu: {
        parent: 'Organization',
        name: 'Designated Funds',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor', 'Viewer'],
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
        organizationAttributes(organizationService) {
          'ngInject';
          return organizationService.getOrganizationAttributes();
        },
      },
    })
    .when('/organization/designated-fund/import', {
      template: '<designated-fund-import></designated-fund-import>',
      menu: {
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
        parent: 'Organization',
        level: 3,
      },
      resolve: {
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationAttributes(organizationService) {
          'ngInject';
          return organizationService.getOrganizationAttributes();
        },
      },
    })
    .when('/organization/designated-fund/:designationId', {
      templateUrl: '/scripts/organization/designated-funds-item.html',
      controller: 'OrganizationDesignatedFundsDetailsCtrl',
      menu: {
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
        parent: 'Organization',
        level: 3,
      },
      resolve: {
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationAttributes(organizationService) {
          'ngInject';
          return organizationService.getOrganizationAttributes();
        },
      },
    })
    .when('/organization/designation-cart', {
      templateUrl: '/scripts/organization/designation-cart.html',
      controller: 'DesignationCartCtrl',
      menu: {
        parent: 'Organization',
        name: 'Designation Cart (Beta)',
        level: 2,
        allowFor: 'allRoles',
        allowFeatureFor: 'designation_cart',
      },
      resolve: {
        sessionService(SessionService) {
          'ngInject';
          return SessionService;
        },
      },
    })
    .when('/mobile-app/mobile-funds', {
      templateUrl: '/scripts/organization/mobile-funds.html',
      controller: 'OrganizationMobileFundsCtrl',
      menu: {
        parent: 'Mobile App',
        name: 'Mobile Funds',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'mobile',
      },
      resolve: {
        organizationDesignations(designationService) {
          'ngInject';
          return designationService.getOrganizationDesignations();
        },
        organizationCampaigns(CampaignService) {
          'ngInject';
          return CampaignService.getCampaigns();
        },
        organizationAttributes(organizationService) {
          'ngInject';
          return organizationService.getOrganizationAttributes();
        },
      },
    })
    .when('/organization/integrations', {
      templateUrl: '/scripts/organization/integrations.html',
      controller: 'integrationsUiCtrl',
      menu: {
        parent: 'Organization',
        name: 'Integration Exchange',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/organization/users', {
      templateUrl: '/scripts/organization/users.html',
      controller: 'OrganizationUsersCtrl',
      menu: {
        parent: 'Organization',
        name: 'Users',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        organizationUsers(orgUserService) {
          'ngInject';
          return orgUserService.getOrganizationUsers();
        },
      },
    })
    .when('/organization/users/edit/:userid', {
      templateUrl: '/scripts/organization/users-item.html',
      controller: 'OrganizationUsersItemCtrl',
      menu: {
        parent: 'Organization',
        name: 'User Creator',
        level: 3,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/organization/cash-options', {
      templateUrl: '/scripts/organization/cash-options.html',
      menu: {
        parent: 'Organization',
        name: 'Cash Options',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/organization/cash-options/:path*', {
      templateUrl: '/scripts/organization/cash-options.html',
      menu: {
        parent: 'Cash Options',
        name: 'Cash Options',
        level: 3,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/contacts', {
      templateUrl: '/scripts/organization/contacts.html',
      controller: 'ContactsCtrl',
      menu: {
        parent: 'Organization',
        style: 'icon-contact',
        name: 'Contacts',
        level: 2,
        allowFor: 'allRoles',
        allowFeatureFor: 'allFeatures',
      },
    })
    .when('/organization/account', {
      templateUrl: '/scripts/organization/account.html',
      controller: 'OrganizationAccountCtrl',
      menu: {
        parent: 'Organization',
        name: 'Account',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        fullOrgData(organizationService) {
          'ngInject';
          return organizationService.loadFullOrganizationInformation();
        },
        currentUserInfo(SessionService) {
          'ngInject';
          return SessionService.getCurrentUserInfo(true);
        },
        organizationImages(organizationService) {
          'ngInject';
          return organizationService.loadOrganizationImages();
        },
      },
    })
    .when('/organization/events/:eventId', {
      // 'events' here relate to webhook events, not 'Event Mgmt' events
      templateUrl: '/scripts/organization/webhooks/event-detail.html',
      controller: 'OrganizationEventDetailCtrl',
      menu: {
        parent: 'Organization',
        name: 'Events',
        level: 3,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        eventDetails(webhookService, $route) {
          'ngInject';
          return webhookService.getEventDetails($route.current.params.eventId);
        },
      },
    })
    .when('/organization/webhooks', {
      templateUrl: '/scripts/organization/webhooks/list.html',
      controller: 'OrganizationWebhooksIndexCtrl',
      menu: {
        parent: 'Organization',
        name: 'Webhooks',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'organization_webhooks',
      },
      resolve: {
        organizationWebhooks(webhookService) {
          'ngInject';
          return webhookService.listInstalled();
        },
        organizationRecentEvents(webhookService) {
          'ngInject';
          return webhookService.listEvents();
        },
        availableAdapters(webhookService) {
          'ngInject';
          return webhookService.listAvailableAdapters();
        },
      },
    })
    .when('/organization/webhooks/install/:adapterName', {
      templateUrl: '/scripts/organization/webhooks/edit.html',
      controller: 'OrganizationWebhookInstallCtrl',
      menu: {
        parent: 'Organization',
        name: 'Webhook',
        level: 3,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        webhook(webhookService, $route) {
          'ngInject';
          return webhookService.prepareNewWebhook(
            $route.current.params.adapterName
          );
        },
      },
    })
    .when('/organization/webhooks/:webhookId', {
      templateUrl: '/scripts/organization/webhooks/detail.html',
      controller: 'OrganizationWebhookDetailCtrl',
      menu: {
        parent: 'Organization',
        name: 'Webhook',
        level: 3,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        webhook(webhookService, $route) {
          'ngInject';
          return webhookService.getWebhookDetails(
            $route.current.params.webhookId
          );
        },
        recentEvents(webhookService, $route) {
          'ngInject';
          return webhookService.getEvents($route.current.params.webhookId, {
            sort: 'last_activity',
            sort_dir: 'desc',
            per_page: 12,
          });
        },
      },
    })
    .when('/organization/webhooks/:webhookId/edit', {
      templateUrl: '/scripts/organization/webhooks/edit.html',
      controller: 'OrganizationWebhookEditCtrl',
      menu: {
        parent: 'Organization',
        name: 'Webhook',
        level: 3,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'allFeatures',
      },
      resolve: {
        webhook(webhookService, $route) {
          'ngInject';
          return webhookService.getWebhookDetails(
            $route.current.params.webhookId
          );
        },
      },
    })
    .when('/mobile-app', {
      redirectTo: '/mobile-app/mobile-funds',
      menu: {
        name: 'Mobile App',
        style: 'icon-ipad-1',
        level: 1,
        position: 11,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'mobile',
      },
    })
    .when('/mobile-app/noncash-embed', {
      template: '<noncash-embed></noncash-embed>',
      menu: {
        parent: 'Mobile App',
        name: 'Noncash Embed',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'mobile',
      },
    })
    .when('/mobile-app/payment-configuration', {
      templateUrl: '/scripts/organization/mobile-payment-configuration.html',
      controller: 'OrganizationMobilePaymentConfigurationCtrl',
      menu: {
        parent: 'Mobile App',
        name: 'Payment Method',
        level: 2,
        allowFor: ['SuperAdmin', 'Admin', 'Editor'],
        allowFeatureFor: 'mobile',
      },
      resolve: {
        organizationAttributes(organizationService) {
          'ngInject';
          return organizationService.getOrganizationAttributes();
        },
      },
    })
    .when('/reconciliation', {
      templateUrl: '/scripts/organization/reconciliation.html',
      controller: 'reconciliationUiCtrl',
      menu: {
        style: 'icon-coin-stack',
        name: 'Reconciliation',
        level: 1,
        position: 11,
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'reconciliation',
      },
    })
    .when('/reconciliation/funding-batches/:batchId', {
      templateUrl: '/scripts/organization/reconciliation.html',
      controller: 'reconciliationUiCtrl',
      menu: {
        allowFor: ['SuperAdmin', 'Admin'],
        allowFeatureFor: 'reconciliation',
      },
    });
}

function captureMessages(response, default_message) {
  Array.from(
    (response.data != null ? response.data.messages : undefined) || [
      default_message,
    ]
  )
    .filter(
      (x) =>
        x &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/activate'
    )
    .map((x) =>
      new Noty({
        animation: {
          open: 'animated fadeIn',
          close: 'animated fadeOut',
          easing: 'swing',
          speed: 200,
        },
        text: `${x.message || x}`,
        layout: 'topRight',
        theme: 'idonate',
        type: x.category || 'alert',
        timeout: 4000,
      }).show()
    );
}

function toTitleCase(str) {
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str.replace(/(\-\w)/g, (m) => {
    return m[1].toUpperCase();
  });
}

function captureWarnings(response) {
  const result = response.data != null ? response.data.result : undefined;
  if (!result) {
    return;
  }
  const possibleErrors = [];
  angular.forEach(result, (res, key) => {
    angular.forEach(res, (possibleError) => {
      possibleErrors.push(`${toTitleCase(key)}: ${possibleError}`);
    });
  });

  Array.from(possibleErrors)
    .filter(
      (x) =>
        x &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/activate'
    )
    .map((x) =>
      new Noty({
        animation: {
          open: 'animated fadeIn',
          close: 'animated fadeOut',
          easing: 'swing',
          speed: 200,
        },
        text: `${x.message || x}`,
        layout: 'topRight',
        theme: 'idonate',
        type: 'info',
        timeout: 4000,
      }).show()
    );
}

function getUrlVars() {
  const vars = {};
  const { href } = window.location;
  const parts = href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (m, key, value) => (vars[key] = value)
  );
  return vars;
}
